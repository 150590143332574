import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

import { DetailPageSectionLayout } from "components/index";
import FormTable from "components/table/FormTable";
import FormInput from "components/input/FormInput";
import FormDropdown from "components/dropdown/FormDropdown";
import {
  memberAdminDuplicateNickname,
  memberAdminDuplicateNicknameReset,
} from "store/reducer/memberAdminCreate";
import FormIdInput from "components/input/FormIdInput";
import { isAdminListError } from "utils/helper/formatter";
import ErrorMessage from "components/errorMessage/ErrorMessage";

export default function ProfileInfo({
  adminList,
  watch,
  control,
  dropdowns,
  register,
  errors,
  setError,
  clearErrors,
  hookFormValidation,
}) {
  const dispatch = useDispatch();

  const { isDuplicate, isDone: isCheckNickname } = useSelector(
    (state) => state.memberAdminCreate.duplicateNickname,
  );

  const isProfileInfoError = isAdminListError(errors, adminList.profileInfo);

  const makeRegister = (content) =>
    register(content.key, {
      onBlur: (e) => {
        const value = e.target.value.trim();

        switch (content.key) {
          case "nickname":
            value
              ? dispatch(memberAdminDuplicateNickname({ nickname: value }))
              : dispatch(memberAdminDuplicateNicknameReset());
            break;
        }
      },
      validate: hookFormValidation[content.key],
      required: true,
    });

  const contentRender = (content) => {
    switch (content.key) {
      case "nickname":
        return (
          <FormIdInput
            placeholder={content.placeholder}
            maxLength={10}
            isError={errors[content.key]}
            register={makeRegister(content)}
            isDuplicate={isCheckNickname ? (isDuplicate ? true : false) : null}
          />
        );

      case "gender":
      case "userStatusId":
      case "internalGrade":
      case "area":
      case "height":
      case "bodyType":
      case "religion":
      case "drinking":
      case "smoking":
        return (
          <FormDropdown
            name={content.key}
            required={true}
            isError={errors[content.key]}
            setError={setError}
            clearErrors={clearErrors}
            Controller={Controller}
            control={control}
            options={dropdowns[content.key]}
            placeholder={content.placeholder ?? "전체"}
          />
        );

      case "education":
      case "job":
        return (
          <FormInput
            placeholder={content.placeholder}
            maxLength={10}
            isError={errors[content.key]}
            register={makeRegister(content)}
          />
        );
    }
  };

  const makeErrorMessage = () => {
    if (isDuplicate) {
      return <ErrorMessage msg="이미 사용중인 닉네임입니다." />;
    }

    if (isProfileInfoError) {
      return <ErrorMessage msg="필수 입력 값을 입력해주세요." />;
    }
  };

  useEffect(() => {
    if (isDuplicate && isCheckNickname) {
      setError("nickname");
    }
  }, [isDuplicate, isCheckNickname]);

  return (
    <DetailPageSectionLayout
      heading="프로필 정보"
      description="*필수 정보를 모두 입력해주세요."
    >
      <div>
        <FormTable>
          {adminList.profileInfo.map((row, rowI) => (
            <FormTable.Row key={rowI} contentCount={row.length}>
              {row.map((content, contentI) => (
                <FormTable.Content
                  key={content.heading + contentI}
                  require={content.require}
                  heading={content.heading}
                >
                  {contentRender(content)}
                </FormTable.Content>
              ))}
            </FormTable.Row>
          ))}
        </FormTable>
        {makeErrorMessage()}
      </div>
    </DetailPageSectionLayout>
  );
}
