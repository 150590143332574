import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { Heading } from "components/index";
import ReportFilter from "../containers/ReportFilter";
import ReportTable from "../containers/ReportTable";
import { reportIndianGame } from "store/reducer/reportIndianGame";
import { ReportIndianGameClassification } from "assets/static/dropdown";
import { reportIndianGameDetailReset } from "store/reducer/reportIndianGameDetail";

export default function ReportIndianGame() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { data, pageInfo } = useSelector((state) => state.reportIndianGame);

  useEffect(() => {
    dispatch(reportIndianGameDetailReset());
  }, []);

  useEffect(() => {
    const filterObj = { ...Object.fromEntries(searchParams) };

    dispatch(reportIndianGame(filterObj));
  }, [searchParams]);

  return (
    <Root>
      <Heading>인디언 게임 신고 관리</Heading>
      <ReportFilter
        reportTypeObj={ReportIndianGameClassification}
        actionFn={reportIndianGame}
      />
      <ReportTable data={data} pageData={pageInfo} excel="true" />
    </Root>
  );
}

const Root = styled.section`
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 50px;
`;
