import { createSlice } from "@reduxjs/toolkit";

export const reportIndianGameProcessSlice = createSlice({
  name: "reportIndianGameProcess",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    reportIndianGameProcess: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    reportIndianGameProcessSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    reportIndianGameProcessFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  reportIndianGameProcess,
  reportIndianGameProcessSuccess,
  reportIndianGameProcessFailure,
} = reportIndianGameProcessSlice.actions;

export default reportIndianGameProcessSlice.reducer;
