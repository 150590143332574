import { createSlice } from "@reduxjs/toolkit";

export const authInspectionDetailSlice = createSlice({
  name: "authInspectionDetail",
  initialState: {
    isLoading: false,
    isDone: false,
    certificationSrcArray: [],
    profileSrcArray: [],
    certification: {},
    error: null,
  },
  reducers: {
    authInspectionDetail: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    authInspectionDetailSuccess: (state, action) => {
      state.certificationSrcArray = action.data.certificationSrcArray;
      state.profileSrcArray = action.data.profileSrcArray;
      state.certification = action.data.certification;
      state.isLoading = false;
      state.isDone = true;
    },
    authInspectionDetailFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
    authInspectionDetailReset: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.certificationSrcArray = [];
      state.profileSrcArray = [];
      state.certification = {};
      state.error = null;
    },
    authInspectionDetailUnmasking: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = null;
    },
    authInspectionDetailUnmaskingSuccess: (state, action) => {
      const { email, name } = action.data;

      state.certification = { ...state.certification, email, name };
      state.isLoading = false;
      state.isDone = true;
    },
    authInspectionDetailUnmaskingFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  authInspectionDetail,
  authInspectionDetailSuccess,
  authInspectionDetailFailure,
  authInspectionDetailReset,
  authInspectionDetailUnmasking,
  authInspectionDetailUnmaskingSuccess,
  authInspectionDetailUnmaskingFailure,
} = authInspectionDetailSlice.actions;

export default authInspectionDetailSlice.reducer;
