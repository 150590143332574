import { authProfileInformation } from "assets/static/table";
import {
  Cell,
  DetailPageSectionLayout,
  ImageDetailViewModal,
  ImageView,
} from "components/index";
import React from "react";
import styled from "styled-components";

export default function ProfileInformation({
  profileSrcArray,
  profileInformation,
  user_type,
  handleClickCheckBox,
  status,
  ...restProps
}) {
  return (
    <DetailPageSectionLayout
      heading="프로필 정보"
      description={
        user_type === "신규회원" && "*반려 시 반려 항목을 체크해 주세요."
      }
      {...restProps}
    >
      <ProfileWrapper>
        <ProfileImageWrapper>
          <ProfileCheckBoxWrapper>
            {user_type === "신규회원" && status === "승인대기" && (
              <ProfileCheckBox
                type="checkbox"
                onChange={() => handleClickCheckBox("profile_img")}
                id="profile_img"
              />
            )}
            <ProfileImageLabel htmlFor="profile_img">
              프로필 사진
            </ProfileImageLabel>
          </ProfileCheckBoxWrapper>
          <ImageView
            srcArray={profileSrcArray}
            render={(
              handleModalClose,
              handleDimClick,
              selectImage,
              isModalOpen,
            ) => {
              return (
                <ImageDetailViewModal
                  srcArray={profileSrcArray}
                  handleModalClose={handleModalClose}
                  handleDimClick={handleDimClick}
                  selectImage={selectImage}
                  isModalOpen={isModalOpen}
                  title="인증 요청 사진"
                />
              );
            }}
          />
        </ProfileImageWrapper>
        <TableWrapper>
          {Object.entries(authProfileInformation).map(([key, value], index) => {
            if (index < 3) {
              return (
                <MiddleHeightCell
                  checkBox={user_type === "신규회원" && status === "승인대기"}
                  key={index}
                  title={value}
                  onChange={() => handleClickCheckBox(key)}
                >
                  {profileInformation[key]}
                </MiddleHeightCell>
              );
            } else {
              return (
                <TopHeightCell
                  checkBox={user_type === "신규회원" && status === "승인대기"}
                  key={index}
                  title={value}
                  onChange={() => handleClickCheckBox(key)}
                >
                  {profileInformation[key]}
                </TopHeightCell>
              );
            }
          })}
        </TableWrapper>
      </ProfileWrapper>
    </DetailPageSectionLayout>
  );
}

const ProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProfileImageWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const ProfileCheckBoxWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const ProfileCheckBox = styled.input`
  margin-right: 8px;
`;

const ProfileImageLabel = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_6C};
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const TableWrapper = styled.div`
  display: grid;
  column-gap: 1px;
  row-gap: 1px;
  width: 730px;
  height: fit-content;
  border-top: 1px solid ${({ theme }) => theme.color.gray_E6};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
  background-color: ${({ theme }) => theme.color.gray_E6};
`;

const MiddleHeightCell = styled(Cell)`
  & > div {
    min-height: 80px;
  }
`;

const TopHeightCell = styled(Cell)`
  & > div {
    min-height: 108px;
  }
`;
