import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Cell, InputCell, DropdownCell } from "components/index";

export default function ComplexTable({
  list,
  state,
  setState,
  gender,
  errorState,
  handleChangeInput,
  handleBlurError,
  duplicateState,
  ...restProps
}) {
  const { data } = useSelector(
    (state) => state.memberAdminDropdownList.dropdownList,
  );

  let dropdown =
    gender === "M"
      ? { ...data, body_type: data.body_type?.M }
      : { ...data, body_type: data.body_type?.F };

  return (
    <Root {...restProps}>
      {list.map((item, index) => {
        const {
          type,
          essential,
          label,
          placeholder,
          count,
          dropdownList,
          key,
        } = item;

        if (type === "input") {
          return (
            <InputCell
              key={index}
              name={key}
              title={label}
              essential={essential}
              inputPlaceholder={placeholder}
              inputCount={count}
              state={state[key]}
              errorState={errorState[key]?.state}
              handleChangeInput={handleChangeInput}
              handleBlurError={handleBlurError}
              duplicateState={duplicateState}
              type={
                (key === "password" || key === "passwordConfirm") && "password"
              }
            />
          );
        }

        if (type === "dropdown") {
          return (
            <DropdownCell
              key={index}
              name={key}
              title={label}
              essential={essential}
              list={dropdownList === undefined ? dropdown[key] : dropdownList}
              state={state[key]}
              setState={setState}
              errorState={errorState[key]?.state}
            />
          );
        }

        if (type === "cell") {
          if (key === "password") {
            return (
              <Cell key={index} title={label} error={errorState[key]}>
                &#65121;&#65121;&#65121;&#65121;&#65121;&#65121;
              </Cell>
            );
          } else if (key === "gender") {
            return (
              <Cell key={index} title={label} error={errorState[key]}>
                {state[key] === "M" ? "남자" : "여자"}
              </Cell>
            );
          } else {
            return (
              <Cell key={index} title={label} error={errorState[key]}>
                {state[key] ? state[key] : "-"}
              </Cell>
            );
          }
        }
      })}
    </Root>
  );
}

const Root = styled.div`
  width: 1080px;
  border-top: 1px solid ${({ theme }) => theme.color.gray_E6};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
  background-color: ${({ theme }) => theme.color.gray_E6};
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 1px;
  row-gap: 1px;
`;
