import { createSlice } from "@reduxjs/toolkit";

export const authProfileDetailApprovalSlice = createSlice({
  name: "authProfileDetailApproval",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    authProfileDetailApproval: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    authProfileDetailApprovalSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    authProfileDetailApprovalFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
    authProfileDetailApprovalReset: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = null;
    },
  },
});

export const {
  authProfileDetailApproval,
  authProfileDetailApprovalSuccess,
  authProfileDetailApprovalFailure,
  authProfileDetailApprovalReset,
} = authProfileDetailApprovalSlice.actions;

export default authProfileDetailApprovalSlice.reducer;
