import StatusLabel from "components/label/StatusLabel";
import React, { memo } from "react";
import styled from "styled-components";

const ListTable = memo(
  ({ type, list, width, data, handleGoToDetail, ...restProps }) => {
    return (
      <Root {...restProps}>
        <Thead>
          <Tr width={width}>
            {type === "checkTable" && (
              <Th>
                <input type="checkbox" />
              </Th>
            )}
            {Object.values(list).map((item, index) => {
              return <Th key={index}>{item}</Th>;
            })}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((dataItem, index) => {
            const detailId = dataItem.requestId;

            return (
              <CustomTr
                width={width}
                key={index}
                onClick={handleGoToDetail && handleGoToDetail(detailId)}
              >
                {type === "checkTable" && (
                  <Td handleGoToDetail={handleGoToDetail}>
                    <input type="checkbox" />
                  </Td>
                )}
                {Object.keys(list).map((listItem, index) => {
                  return (
                    <Td key={index} handleGoToDetail={handleGoToDetail}>
                      {listItem === "status" ? (
                        <StatusLabel status={dataItem[listItem]} />
                      ) : (
                        <span>{dataItem[listItem]}</span>
                      )}
                    </Td>
                  );
                })}
              </CustomTr>
            );
          })}
        </Tbody>
        {data.length === 0 && (
          <thead>
            <tr>
              <NoData>데이터가 없습니다.</NoData>
            </tr>
          </thead>
        )}
      </Root>
    );
  },
);

ListTable.displayName = "ListTable";

export default ListTable;

const Root = styled.table`
  width: 100%;
  height: 551px;
`;

const Thead = styled.thead`
  background-color: ${({ theme }) => theme.color.gray_F5};
  color: ${({ theme }) => theme.color.text_gray93};
`;

const Tr = styled.tr`
  display: grid;
  grid-template-columns: ${({ width }) => width};
  border-top: 1px solid ${({ theme }) => theme.color.border};
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  padding: 0 20px;
`;

const Th = styled.th`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 8px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.text_sub00};

  ::before {
    content: "";
    position: absolute;
    top: 11px;
    left: 0px;
    height: 16px;
    border-left: 1px solid ${({ theme }) => theme.color.border_gray93};
  }
`;

const Tbody = styled.tbody``;

const Td = styled.td`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 11px 0 11px 8px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.text_01};
  cursor: ${({ handleGoToDetail }) => handleGoToDetail && "pointer"};

  & > span {
    display: block;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const CustomTr = styled(Tr)`
  border-top: 0;

  :hover {
    background-color: rgba(245, 245, 245, 0.5);
  }
`;

const NoData = styled.th`
  width: 100%;
  height: 50px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.black};
  display: flex;
  justify-content: center;
  align-items: center;
`;
