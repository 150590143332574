import { createSlice } from "@reduxjs/toolkit";

export const memberUserDetailUserStatusSlice = createSlice({
  name: "memberUserDetailUserStatus",
  initialState: {
    isLoading: false,
    isDone: false,
    status: [],
    pageInfo: {},
    error: null,
  },
  reducers: {
    memberUserDetailUserStatus: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    memberUserDetailUserStatusSuccess: (state, action) => {
      const { status, pageInfo } = action;
      state.status = status;
      state.pageInfo = pageInfo;
      state.isLoading = false;
      state.isDone = true;
    },
    memberUserDetailUserStatusFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  memberUserDetailUserStatus,
  memberUserDetailUserStatusSuccess,
  memberUserDetailUserStatusFailure,
} = memberUserDetailUserStatusSlice.actions;

export default memberUserDetailUserStatusSlice.reducer;
