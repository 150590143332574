import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import { DetailPageSectionLayout } from "components/index";
import FormTable from "components/table/FormTable";
import FormImageUpload from "components/ImageUpload/FormImageUpload";
import FormIdInput from "components/input/FormIdInput";
import FormPasswordInput from "components/input/FormPasswordInput";
import FormInput from "components/input/FormInput";
import FormDropdown from "components/dropdown/FormDropdown";
import { checkEmailValidation } from "utils/helper/validation";
import {
  memberAdminDuplicateEmail,
  memberAdminDuplicateEmailReset,
} from "store/reducer/memberAdminCreate";
import { isAdminListError } from "utils/helper/formatter";
import ErrorMessage from "components/errorMessage/ErrorMessage";

export default function BasicInfo({
  adminList,
  setError,
  errors,
  clearErrors,
  watch,
  register,
  control,
  setValue,
  dropdowns,
  profiles,
  hookFormFormat,
  hookFormValidation,
  deleteProfiles,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { isDuplicate: isEmailDuplicate, isDone: isCheckEmail } = useSelector(
    (state) => state.memberAdminCreate.duplicateEmail,
  );

  const makeRegister = (content) =>
    register(content.key, {
      onChange: (e) => {
        switch (content.key) {
          case "phone":
          case "birthday":
            hookFormFormat(e, content.key);
            break;
        }
      },
      onBlur: (e) => {
        const value = e.target.value.trim();

        switch (content.key) {
          case "email":
            checkEmailValidation(value)
              ? dispatch(memberAdminDuplicateEmail({ email: value }))
              : dispatch(memberAdminDuplicateEmailReset());
            break;
        }
      },
      validate: hookFormValidation[content.key],
      ...(content.require && {
        required: true,
      }),
    });

  const contentRender = (content) => {
    switch (content.key) {
      case "email":
        if (id) {
          return watch("email");
        } else {
          return (
            <FormIdInput
              placeholder={content.placeholder}
              isError={errors[content.key]}
              register={makeRegister(content)}
              isDuplicate={
                isCheckEmail ? (isEmailDuplicate ? true : false) : null
              }
            />
          );
        }

      case "password":
        if (id) {
          return <>&#65121;&#65121;&#65121;&#65121;&#65121;&#65121;</>;
        } else {
          return (
            <FormPasswordInput
              placeholder={content.placeholder}
              isError={errors[content.key]}
              register={makeRegister(content)}
            />
          );
        }

      case "passwordConfirm":
        return (
          <FormPasswordInput
            placeholder={content.placeholder}
            isError={errors[content.key]}
            register={makeRegister(content)}
          />
        );

      case "name":
        if (id) {
          return watch("name");
        } else {
          return (
            <FormInput
              placeholder={content.placeholder}
              isError={errors[content.key]}
              register={makeRegister(content)}
            />
          );
        }

      case "nickname":
        return (
          <FormInput
            placeholder={content.placeholder}
            isError={errors[content.key]}
            register={makeRegister(content)}
          />
        );

      case "phone":
        return (
          <FormInput
            placeholder={content.placeholder}
            maxLength={13}
            isError={errors[content.key]}
            register={makeRegister(content)}
          />
        );

      case "birthday":
        if (id) {
          return watch("birthday");
        } else {
          return (
            <FormInput
              placeholder={content.placeholder}
              maxLength={10}
              isError={errors[content.key]}
              register={makeRegister(content)}
            />
          );
        }

      case "gender":
        if (id) {
          return watch("gender") === "M" ? "남자" : "여자";
        } else {
          return (
            <FormDropdown
              name={content.key}
              isError={errors[content.key]}
              setError={setError}
              clearErrors={clearErrors}
              Controller={Controller}
              control={control}
              options={dropdowns[content.key]}
              placeholder={content.placeholder ?? "전체"}
            />
          );
        }

      case "userStatusId":
      case "internalGrade":
        return (
          <FormDropdown
            name={content.key}
            isError={errors[content.key]}
            setError={setError}
            clearErrors={clearErrors}
            Controller={Controller}
            control={control}
            options={dropdowns[content.key]}
            placeholder={content.placeholder ?? "전체"}
          />
        );

      case "userNumber":
        return watch(content.key);

      case "created":
        if (id) {
          return watch(content.key);
        } else {
          return "-";
        }

      default:
        return "-";
    }
  };

  const makeErrorMessage = () => {
    const isProfilesError = errors.profiles;
    if (isProfilesError) {
      return <ErrorMessage msg="프로필 사진은 최소 3장 이상 등록해주세요." />;
    }

    if (errors.email?.type === "format") {
      return <ErrorMessage msg="이메일 형식이 아닙니다." />;
    }

    if (isEmailDuplicate) {
      return <ErrorMessage msg="이미 가입된 아이디입니다." />;
    }

    if (errors.password?.type === "format") {
      return (
        <ErrorMessage msg="비밀번호는 영문 대/소문자, 숫자, 특수문자 4가지 조합의 6~12자리입니다." />
      );
    }

    if (errors.passwordConfirm?.type === "passwordConfirmMismatch") {
      return <ErrorMessage msg="비밀번호가 일치하지 않습니다." />;
    }

    const isBasicInfoError = isAdminListError(errors, adminList.basicInfo);
    if (isBasicInfoError) {
      return <ErrorMessage msg="필수 입력 값을 입력해주세요." />;
    }
  };

  useEffect(() => {
    if (isEmailDuplicate && isCheckEmail) {
      setError("email");
    }
  }, [isEmailDuplicate, isCheckEmail]);

  useEffect(() => {
    if (profiles.filter((item) => item).length > 2 && errors.profiles)
      clearErrors("profiles");
  }, [profiles]);

  return (
    <CustomDetailPageSectionLayout
      heading="기본 정보"
      description="*필수 정보를 모두 입력해주세요."
    >
      <FormImageUpload
        isError={[
          errors.profile1,
          errors.profile2,
          errors.profile3,
          errors.profile4,
          errors.profile5,
        ]}
        profiles={profiles}
        setValue={setValue}
        register={register}
        deleteProfiles={deleteProfiles}
      />
      <div>
        <FormTable>
          {adminList.basicInfo.map((row, rowI) => (
            <FormTable.Row key={rowI} contentCount={row.length}>
              {row.map((content, contentI) => (
                <FormTable.Content
                  key={content.heading + contentI}
                  require={content.require}
                  heading={content.heading}
                >
                  {contentRender(content)}
                </FormTable.Content>
              ))}
            </FormTable.Row>
          ))}
        </FormTable>
        {makeErrorMessage()}
      </div>
    </CustomDetailPageSectionLayout>
  );
}

const CustomDetailPageSectionLayout = styled(DetailPageSectionLayout)`
  & > div {
    display: flex;
    column-gap: 30px;
  }
`;
