import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import styled from "styled-components";

import {
  DimLayout,
  PortalModal,
  PasswordInput,
  SubmitButton,
  CancelButton,
} from "components/index";
import { Close } from "assets/icon/index";
import { checkPasswordValidateLegacy } from "utils/helper/validation";

export default function ChangePasswordModal({
  handleModalClose,
  handleDimClick,
  handleChangePassword,
  ...restProps
}) {
  const [isPasswordConfirm, setIsPasswordConfirm] = useState(true);
  const [isPasswordValidation, setIsPasswordValidation] = useState(true);
  const [password, setPassword] = useImmer({
    passwordValue: "",
    passwordConfirm: "",
  });

  useEffect(() => {
    const { passwordValue, passwordConfirm } = password;
    if (passwordValue.length === 0) return;

    if (checkPasswordValidateLegacy(passwordValue)) {
      setIsPasswordValidation(true);
    } else {
      setIsPasswordValidation(false);
    }

    if (
      passwordValue === passwordConfirm &&
      checkPasswordValidateLegacy(passwordValue)
    ) {
      setIsPasswordConfirm(false);
    } else {
      setIsPasswordConfirm(true);
    }
  }, [password.passwordValue, password.passwordConfirm]);

  return (
    <PortalModal>
      <DimLayout handleDimClick={handleDimClick}>
        <Root {...restProps}>
          <Heading>비밀번호 변경</Heading>
          <InputWrraper>
            <PasswordInput
              value={password.password}
              name="passwordValue"
              setState={setPassword}
              placeholder="영문 대/소문자, 숫자, 특수문자 4가지 조합의 8-12자"
            />
            <PasswordInput
              value={password.passwordConfirm}
              name="passwordConfirm"
              setState={setPassword}
              placeholder="비밀번호 확인"
            />
            {isPasswordConfirm && !isPasswordValidation && (
              <ErrorMessage>
                조건에 맞지 않습니다.(8-12자, 영대 소문자/숫자/특수문자 4가지
                조합)
              </ErrorMessage>
            )}
            {isPasswordConfirm && password.passwordConfirm.length !== 0 && (
              <CustomErrorMessage>
                비밀번호가 일치하지 않습니다.
              </CustomErrorMessage>
            )}
          </InputWrraper>
          <ButtonWrapper>
            <CancelButton onClick={handleModalClose}>취소</CancelButton>
            <SubmitButton
              disabled={isPasswordConfirm}
              onClick={handleChangePassword(
                handleModalClose,
                password.passwordValue,
              )}
            >
              확인
            </SubmitButton>
          </ButtonWrapper>
          <CloseButton onClick={handleModalClose}>
            <Close width="16px" height="16px" />
          </CloseButton>
        </Root>
      </DimLayout>
    </PortalModal>
  );
}

const Root = styled.section`
  width: 500px;
  height: 262px;
  background-color: ${({ theme }) => theme.color.white};
  position: relative;
`;

const Heading = styled.h3`
  font-size: 18px;
  font-weight: 500;
  padding: 17px 0 17px 24px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.border_grayE6}`};
`;

const InputWrraper = styled.div`
  width: 452px;
  padding-top: 23px;
  margin: 0 auto 24px auto;

  div + div {
    margin-top: 12px;
  }
`;

const ErrorMessage = styled.span`
  display: block;
  margin-top: 12px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.red_FF};
`;

const CustomErrorMessage = styled(ErrorMessage)`
  margin-top: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 15px 24px 15px 0;
  background-color: ${({ theme }) => theme.color.gray_F5};

  button + button {
    margin-left: 10px;
  }
`;

const CloseButton = styled.button`
  width: 16px;
  height: 16px;
  background-color: inherit;
  position: absolute;
  top: 20px;
  right: 24px;
`;
