import createSagaMiddleware from "redux-saga";
// import logger from "redux-logger";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";

import rootSaga from "sagas/index";
import rootReducer from "./reducer/index";

// redux-persist 설정 redux storage 사용하는 거
const persistConfig = {
  key: "root",
  storage, // local storage에 저장 설정
  whitelist: [""], // storage에 저장할 slice 지정
};

const sagaMiddleware = createSagaMiddleware();

const middlewares =
  process.env.NODE_ENV === "development" ? [sagaMiddleware] : [sagaMiddleware];

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV === "development",
});

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
