import { createSlice } from "@reduxjs/toolkit";
import {
  convertUTCtoBirthday,
  userStatusIdFormatter,
} from "utils/helper/formatter";

export const memberUserSlice = createSlice({
  name: "memberUser",
  initialState: {
    isLoading: false,
    isDone: false,
    dataList: [],
    pageData: {},
    error: null,
  },
  reducers: {
    memberUser: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    memberUserSuccess: (state, action) => {
      const dataList = action.data.users.map((item) => {
        return {
          userNumber: item.user_number,
          email: item.email,
          name: item.name,
          nickname: item.nickname,
          birthday: convertUTCtoBirthday(item.birthday),
          internalGrade: item.internal_grade,
          phone: item.phone,
          gender: item.gender,
          admonishment: `${item.admonishment}회`,
          userStatusId: userStatusIdFormatter(item.user_status_id),
          created: convertUTCtoBirthday(item.created),
          lastLogin: item.last_login ? item.last_login : "-",
          requestId: item.user_id,
        };
      });
      state.isLoading = false;
      state.isDone = true;
      state.dataList = dataList;
      state.pageData = action.data.pageInfo;
    },
    memberUserFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const { memberUser, memberUserSuccess, memberUserFailure } =
  memberUserSlice.actions;

export default memberUserSlice.reducer;
