import { createSlice } from "@reduxjs/toolkit";

export const toastSlice = createSlice({
  name: "toast",
  initialState: {
    isShow: false,
    type: null,
    text: null,
  },
  reducers: {
    toastShow: (state, action) => {
      state.isShow = true;
      state.type = action.data.type;
      state.text = action.data.text;
    },
    toastHidden: (state) => {
      state.isShow = false;
      state.type = null;
      state.text = null;
    },
  },
});

export const { toastShow, toastHidden } = toastSlice.actions;

export default toastSlice.reducer;
