import React from "react";
import styled from "styled-components";

import { DetailPageSectionLayout, RadioTable } from "components/index";
import { memberUserAuthInformationTable } from "assets/static/table";
import { memberUserAuthInformationStage } from "assets/static/radioButton";

export default function MemberAdminAuthInformation({
  state,
  setState,
  tableErrorState,
}) {
  return (
    <DetailPageSectionLayout
      heading="인증 정보"
      description="*한 개 이상의 인증을 선택해 주세요."
    >
      <CustomRadioTable
        data={state}
        setRadioState={setState}
        list={memberUserAuthInformationTable}
        radioList={memberUserAuthInformationStage}
      />
      {tableErrorState.authInfoState.state && (
        <ErrorMessage>{tableErrorState.authInfoState.message}</ErrorMessage>
      )}
    </DetailPageSectionLayout>
  );
}

const CustomRadioTable = styled(RadioTable)`
  grid-template-columns: 100%;
`;

const ErrorMessage = styled.span`
  display: block;
  margin-top: 13px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.red_FF};
`;
