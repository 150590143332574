/* eslint-disable no-useless-escape */

export const checkPhoneNumValidation = (data) => {
  const regExp = /01[016789][^0][0-9]{3}[0-9]{4}/;

  return regExp.test(data);
};

export const checkEmailValidation = (data) => {
  if (!data) {
    return false;
  }

  const regExp = RegExp(/^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/);
  return regExp.test(data);
};

export const checkPasswordValidation = (data) => {
  if (!data) {
    return false;
  }

  const regExp =
    /(?=.+[A-Z])(?=.+[a-z])(?=.+\d)(?=.+[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  return regExp.test(data);
};

export const checkPasswordConfrimValidation = (password, data) => {
  return password === data;
};

export const checkPasswordValidateLegacy = (password) => {
  //  6~12자의 영문 대문자, 숫자, 특수문자 4가지 모두 만족
  const checkSpeacial = /(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])/; //  특수 문자 체크
  const checkNumber = /(?=.*[0-9])/; //  숫자 체크
  const checkBigLetter = /(?=.*[A-Z])/; //  대문자 체크
  const checkSmallLetter = /(?=.*[a-z])/; // 소문자 체크
  const checkLength = /^.{6,12}$/; //  길이 체크

  const checkList = [
    checkSpeacial.test(password),
    checkNumber.test(password),
    checkBigLetter.test(password),
    checkSmallLetter.test(password),
  ];

  const boolenCheckThreeOfTwo =
    checkList.filter((element) => true === element).length === 4;
  // 4개 중 2개가 true 이면 true

  return checkLength.test(password) && boolenCheckThreeOfTwo;
};
