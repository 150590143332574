export const toast = {
  appearanceReject: "외모 인증이 반려되었습니다.",
  appearanceSuccess: "외모 인증이 승인되었습니다.",
  bodyReject: "바디 인증이 반려되었습니다.",
  bodySuccess: "바디 인증이 승인되었습니다.",
  universityReject: "학력 인증이 반려되었습니다.",
  universitySuccess: "학력 인증이 승인되었습니다.",
  occupationReject: "직업 인증이 반려되었습니다.",
  occupationSuccess: "직업 인증이 승인되었습니다.",
  incomeReject: "소득 인증이 반려되었습니다.",
  incomeSuccess: "소득 인증이 승인되었습니다.",
  profileReject: "프로필 인증이 반려되었습니다.",
  profileSuccess: "프로필 인증이 승인되었습니다.",
  internalGradeFail: "내부 관리 등급 변경을 실패했습니다.",
  internalGradeSuccess: "내부 관리 등급이 변경되었습니다.",
  createMemberAdminFail: "어드민 회원 생성을 실패했습니다.",
  createMemberAdminSuccess: "어드민 회원이 생성되었습니다.",
  changeMemberAdminPasswordFail: "비밀번호 변경을 실패했습니다.",
  changeMemberAdminPasswordSuccess: "비밀번호 변경을 성공했습니다.",
  addMemberUserFreeStoneFail: "스톤 지급을 실패했습니다.",
  addMemberUserFreeStoneSuccess: "스톤 지급을 성공했습니다.",
  memberAdminModifyFail: "어드민 회원 수정을 실패했습니다.",
  memberAdminModifySuccess: "어드민 회원 수정을 성공했습니다.",
  memberAdminDeleteFail: "어드민 회원 삭제를 실패했습니다.",
  memberAdminDeleteSuccess: "어드민 회원 삭제를 성공했습니다.",
  reportProfileProcessFail: "신고처리를 실패했습니다.",
  reportProfileProcessSuccess: "신고처리가 완료되었습니다.",
  memberUserSuspendFail: "서비스 이용 정지를 실패했습니다.",
  memberUserSuspendSuccess: "서비스 이용이 정지되었습니다.",
  memberUserUnSuspendFail: "서비스 이용 정지 해제를 실패했습니다.",
  memberUserUnSuspendSuccess: "서비스 이용 정지가 해제 되었습니다.",
  memberUserModifyCertificateFail: "인증 정보 수정을 실패했습니다.",
  memberUserModifyCertificateSuccess: "인증 정보가 수정 되었습니다.",
};
