import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import DetailModalButton from "components/button/DetailModalButton";
import DetailPageModal from "./DetailPageModal";
import PasswordInput from "components/input/PasswordInput";
import { Unlock } from "assets/icon/index";

export default function UnmaskingModal({
  submitActionFunction,
  setPassword,
  ...restProps
}) {
  const dispatch = useDispatch();

  const handleUnlockInformation = (event, handleModalClose) => {
    event.preventDefault();

    dispatch(submitActionFunction);
    handleModalClose();
  };

  return (
    <Root
      render={(handleModalClose, handleDimClick) => {
        return (
          <CustomUnmaskingModal
            heading="마스킹 해제"
            handleModalClose={handleModalClose}
            handleDimClick={handleDimClick}
          >
            <form
              onSubmit={(event) => {
                handleUnlockInformation(event, handleModalClose);
              }}
            >
              <PasswordInputWrapper>
                <CustomPasswordInput
                  placeholder="비밀번호 입력"
                  setState={setPassword}
                  name="password"
                />
              </PasswordInputWrapper>
              <ButtonWrapper>
                <CancelButton type="button">취소</CancelButton>
                <SubmitButton>확인</SubmitButton>
              </ButtonWrapper>
            </form>
          </CustomUnmaskingModal>
        );
      }}
      {...restProps}
    >
      <Unlock />
    </Root>
  );
}

const Root = styled(DetailModalButton)`
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  border-radius: 5px;
`;

const CustomUnmaskingModal = styled(DetailPageModal)`
  width: 500px;
  height: auto;
`;

const PasswordInputWrapper = styled.div`
  margin: 23px 23px 24px 23px;
`;

const CustomPasswordInput = styled(PasswordInput)`
  &::placeholder {
    color: ${({ theme }) => theme.color.gray_AD};
  }
`;

const ButtonWrapper = styled.div`
  height: 66px;
  padding-right: 24px;
  background-color: ${({ theme }) => theme.color.gray_F5};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
`;

const CancelButton = styled.button`
  width: 70px;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.gray_6C};
  border: 1px solid ${({ theme }) => theme.color.gray_AD};
`;

const SubmitButton = styled.button`
  width: 70px;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
  background-color: ${({ theme }) => theme.color.blue_00};
  color: ${({ theme }) => theme.color.white};
`;
