import { createSlice } from "@reduxjs/toolkit";

export const reportCommunityDetailSlice = createSlice({
  name: "reportCommunityDetail",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
    fromUser: {},
    report: {},
    toUser: {},
  },
  reducers: {
    reportCommunityDetail: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    reportCommunityDetailSuccess: (state, action) => {
      const { from_user, report, to_user } = action.data;
      const reportObj = {
        ...report,
        reportCreated: report.created,
        admonishment: `${report.admonishment}`,
      };
      delete reportObj.created;

      state.fromUser = {
        ...from_user,
        gender: from_user.gender === "M" ? "남성" : "여성",
      };
      state.report = reportObj;
      state.toUser = {
        ...to_user,
        gender: to_user.gender === "M" ? "남성" : "여성",
      };
      state.isLoading = false;
      state.isDone = true;
    },
    reportCommunityDetailFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
    reportCommunityDetailReset: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = null;
      state.fromUser = {};
      state.report = {};
      state.toUser = {};
    },
    reportCommunityUnmasking: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = null;
    },
    reportCommunityUnmaskingSuccess: (state, action) => {
      const { email } = action.data;

      state.toUser = { ...state.toUser, email };
      state.isLoading = false;
      state.isDone = true;
    },
    reportCommunityUnmaskingFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  reportCommunityDetail,
  reportCommunityDetailSuccess,
  reportCommunityDetailFailure,
  reportCommunityDetailReset,
  reportCommunityUnmasking,
  reportCommunityUnmaskingSuccess,
  reportCommunityUnmaskingFailure,
} = reportCommunityDetailSlice.actions;

export default reportCommunityDetailSlice.reducer;
