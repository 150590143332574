import { createSlice } from "@reduxjs/toolkit";

export const reportCommunitySlice = createSlice({
  name: "reportCommunity",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
    data: [],
    pageInfo: {},
  },
  reducers: {
    reportCommunity: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    reportCommunitySuccess: (state, action) => {
      const { reports, pageInfo } = action.data;

      state.isLoading = false;
      state.isDone = true;
      state.data = reports;
      state.pageInfo = pageInfo;
    },
    reportCommunityFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  reportCommunity,
  reportCommunitySuccess,
  reportCommunityFailure,
} = reportCommunitySlice.actions;

export default reportCommunitySlice.reducer;
