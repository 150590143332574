import React from "react";
import Cookies from "js-cookie";
import styled from "styled-components";

import { Excel } from "assets/icon/index";

export default function ExcelDownButton({ ...restProps }) {
  return (
    <>
      {Cookies.get("auth") === "master" && (
        <Root {...restProps}>
          <Excel />
        </Root>
      )}
    </>
  );
}

const Root = styled.button`
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  border-radius: 5px;
`;
