import { createSlice } from "@reduxjs/toolkit";

export const reportProfileDetailSlice = createSlice({
  name: "reportProfileDetail",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
    fromUser: {},
    report: {},
    toUser: {},
  },
  reducers: {
    reportProfileDetail: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    reportProfileDetailSuccess: (state, action) => {
      const { from_user, report, to_user } = action.data;
      const reportObj = {
        ...report,
        reportCreated: report.created,
        admonishment: `${report.admonishment}`,
      };
      delete reportObj.created;

      state.fromUser = {
        ...from_user,
        gender: from_user.gender === "M" ? "남성" : "여성",
      };
      state.report = reportObj;
      state.toUser = {
        ...to_user,
        gender: to_user.gender === "M" ? "남성" : "여성",
      };
      state.isLoading = false;
      state.isDone = true;
    },
    reportProfileDetailFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
    reportProfileDetailReset: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = null;
      state.fromUser = {};
      state.report = {};
      state.toUser = {};
    },
    reportProfileUnmasking: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = null;
    },
    reportProfileUnmaskingSuccess: (state, action) => {
      const { email } = action.data;

      state.toUser = { ...state.toUser, email };
      state.isLoading = false;
      state.isDone = true;
    },
    reportProfileUnmaskingFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  reportProfileDetail,
  reportProfileDetailSuccess,
  reportProfileDetailFailure,
  reportProfileDetailReset,
  reportProfileUnmasking,
  reportProfileUnmaskingSuccess,
  reportProfileUnmaskingFailure,
} = reportProfileDetailSlice.actions;

export default reportProfileDetailSlice.reducer;
