import React from "react";
import styled from "styled-components";

export default function DetailPageSectionLayout({
  heading,
  children,
  description,
  ...restProps
}) {
  return (
    <Root {...restProps}>
      <Heading>
        {heading}
        {description && <Description>{description}</Description>}
      </Heading>
      <Content>{children}</Content>
    </Root>
  );
}

const Root = styled.section`
  width: 1140px;
  margin: 0 auto 32px auto;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.12);
`;

const Heading = styled.h3`
  padding: 17px 0 17px 30px;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.Black};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
`;

const Description = styled.span`
  margin-left: 12px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_93};
  font-weight: 300;
`;

const Content = styled.div`
  padding: 30px;
`;
