import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { PATH } from "utils/constants";
import { Arrow, Lock } from "assets/icon/index";
import Cookies from "js-cookie";

export default function CustomLink({
  fullPath = "",
  selected,
  content,
  subContent,
  assert,
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const openSubNavState = sessionStorage.getItem("isOpenSubNav");

  const [isOpenSubNav, setIsOpenSubNav] = useState(
    openSubNavState === null ? false : openSubNavState,
  );

  const handleGoAuthAppearance = () => {
    if (
      Cookies.get("auth").includes("master") ||
      Cookies.get("auth").includes("certificate")
    ) {
      if (!pathname.includes("/auth/inspection/")) {
        // 인증 검수 페이지가 아닐 때 인증 검수로 이동
        navigate(PATH.authAppearance.fullPath);
        setIsOpenSubNav(true);
      } else {
        // 인증 검수 페이지 일 때 subnav 닫힘
        setIsOpenSubNav(!isOpenSubNav);
      }
    } else {
      setIsOpenSubNav(!isOpenSubNav);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("isOpenSubNav", isOpenSubNav);
  }, [isOpenSubNav]);

  return (
    <>
      {subContent ? (
        <LinkButton
          onClick={handleGoAuthAppearance}
          to={fullPath}
          selected={selected}
        >
          {content}
          <ArrowSVG isopensubnav={isOpenSubNav.toString()} />
        </LinkButton>
      ) : Cookies.get("auth").split(",").includes("master") ||
        Cookies.get("auth").split(",").includes(assert) ? (
        <Link to={fullPath} selected={selected}>
          {content}
        </Link>
      ) : (
        <DisableLink to={fullPath} selected={selected}>
          {content}
          <CustomLock />
        </DisableLink>
      )}
      {subContent && isOpenSubNav && (
        <SubContentList>
          {subContent.map((item, index) => {
            const { fullPath, content } = item;
            const subSelected =
              pathname === fullPath || pathname.includes(fullPath);

            return (
              <SubContentItem key={index}>
                {Cookies.get("auth").split(",").includes("master") ||
                Cookies.get("auth").split(",").includes("certificate") ? (
                  <SubLink to={fullPath} selected={subSelected}>
                    {content}
                  </SubLink>
                ) : (
                  <DisableSubLink to={fullPath} selected={subSelected}>
                    {content}
                    <CustomLock />
                  </DisableSubLink>
                )}
              </SubContentItem>
            );
          })}
        </SubContentList>
      )}
    </>
  );
}

const Link = styled(NavLink)`
  display: block;
  width: 100%;
  height: 50px;
  padding-left: 24px;
  font-size: 14px;
  text-decoration: none;
  color: ${({ theme, selected }) =>
    selected ? theme.color.text_blue30 : theme.color.text_grayAd};
  background-color: ${({ theme, selected }) =>
    selected ? theme.color.nav_bg_selected : theme.color.nav_bg};
  font-weight: ${({ selected }) => (selected ? 700 : 500)};

  :hover {
    color: ${({ theme, selected }) => !selected && theme.color.text_gray7b};
  }
`;

const DisableLink = styled.button`
  display: block;
  height: 50px;
  padding-left: 24px;
  font-size: 14px;
  text-decoration: none;
  color: ${({ theme, selected }) =>
    selected ? theme.color.text_blue30 : theme.color.text_grayAd};
  background-color: ${({ theme, selected }) =>
    selected ? theme.color.nav_bg_selected : theme.color.nav_bg};
  font-weight: ${({ selected }) => (selected ? 700 : 500)};

  :hover {
    cursor: not-allowed;
  }
`;

const LinkButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 12px 0 24px;
  text-align: left;
  font-size: 14px;
  color: ${({ theme, selected }) =>
    selected ? theme.color.text_blue30 : theme.color.text_grayAd};
  background-color: ${({ theme, selected }) =>
    selected ? theme.color.nav_bg_selected : theme.color.nav_bg};
  font-weight: ${({ selected }) => (selected ? 700 : 500)};

  :hover {
    color: ${({ theme, selected }) => !selected && theme.color.text_gray7b};
  }
`;

const ArrowSVG = styled(Arrow)`
  transform: ${({ isopensubnav }) =>
    isopensubnav === "true" ? "rotate(0)" : "rotate(180deg)"};
`;

const SubContentList = styled.ul`
  position: relative;
`;

const SubContentItem = styled.li`
  color: ${({ theme, selected }) =>
    selected ? theme.color.text_blue30 : theme.color.text_grayAd};
`;

const SubLink = styled(NavLink)`
  display: block;
  width: 100%;
  height: 50px;
  padding-left: 44px;
  font-size: 14px;
  text-decoration: none;
  color: ${({ theme, selected }) =>
    selected ? theme.color.text_blue30 : theme.color.text_grayAd};
  font-weight: ${({ selected }) => (selected ? 700 : 500)};

  :hover {
    color: ${({ theme, selected }) => !selected && theme.color.text_gray7b};
  }
`;

const DisableSubLink = styled.button`
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 44px;
  font-size: 14px;
  text-decoration: none;
  color: ${({ theme, selected }) =>
    selected ? theme.color.text_blue30 : theme.color.text_grayAd};
  font-weight: ${({ selected }) => (selected ? 700 : 500)};

  :hover {
    color: ${({ theme, selected }) => !selected && theme.color.text_gray7b};
    cursor: not-allowed;
  }
`;

const CustomLock = styled(Lock)`
  margin-left: 4px;
`;
