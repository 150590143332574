import React from "react";
import styled from "styled-components";

import { DetailPageSectionLayout, RadioTable } from "components/index";
import { memberUserAuthInformationTable } from "assets/static/table";
import { memberUserAuthInformationStage } from "assets/static/radioButton";

export default function MemberAdminDetailAuthInformation({ data }) {
  return (
    <DetailPageSectionLayout heading="인증 정보">
      <CustomRadioTable
        list={memberUserAuthInformationTable}
        data={data}
        radioList={memberUserAuthInformationStage}
        disabled={true}
      />
    </DetailPageSectionLayout>
  );
}

const CustomRadioTable = styled(RadioTable)`
  grid-template-columns: 100%;
`;
