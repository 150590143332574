import React from "react";
import styled from "styled-components";

import { Spinner } from "components/index";

export default function Image({ src, alt, ...restProps }) {
  return (
    <Root {...restProps}>
      {src ? <CustomImage src={src} alt={alt} /> : <Spinner />}
    </Root>
  );
}

const Root = styled.div`
  position: relative;
`;

const CustomImage = styled.img`
  width: 100%;
  height: 100%;
`;
