import axios from "axios";
import Cookies from "js-cookie";

export const baseUrl = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  // url, body, header
  baseURL: baseUrl,
  timeout: 3000,
});

const createInstance = () => {
  return setInterceptors(instance);
};

const getAccessToken = async (error) => {
  const refreshToken = Cookies.get("refreshToken");
  try {
    const updateAccessToken = await axios.get(
      `${baseUrl}/common/refreshtoken`,
      { headers: { authorization: "bearer " + refreshToken } },
    );

    Cookies.set("userToken", updateAccessToken.data.accesstoken);

    return instance(error.config);
  } catch (error) {
    if (error.response.data.detail === "REFRESHTOKEN_EXPIRED") {
      alert("로그인 세션이 만료되었습니다.");
      Cookies.remove("userToken");
      Cookies.remove("refreshToken");
      window.location.reload();
    }
  }
};

const setInterceptors = (instance) => {
  instance.interceptors.request.use(
    function (config) {
      const accesstoken = Cookies.get("userToken");
      config.headers["authorization"] = accesstoken
        ? "bearer " + accesstoken
        : null;
      return config;
    },

    function (error) {
      return Promise.reject(error);
    },
  );
  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const {
        response: { status },
      } = error;
      const { detail } = error.response.data;

      if (status === 401) {
        if (
          detail === "토큰 타입을 확인해주세요." ||
          detail === "ACCESSTOKEN_EXPIRED"
        ) {
          return getAccessToken(error);
        }
      } else {
        if (detail) {
          alert(detail);
        }
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

export const ax = createInstance();
