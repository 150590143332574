import { createSlice } from "@reduxjs/toolkit";
import { convertUTCtoBirthday } from "utils/helper/formatter";

export const authProfileSlice = createSlice({
  name: "authProfile",
  initialState: {
    isLoading: false,
    isDone: false,
    dataList: [],
    pageData: {},
    error: null,
  },
  reducers: {
    authProfile: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    authProfileSuccess: (state, action) => {
      const dataList = action.data.profiles.map((item) => {
        return {
          userNumber: item.user_number,
          userType: item.user_type,
          profile_created: item.profile_created,
          email: item.email,
          name: item.name,
          nickname: item.nickname,
          birthday: convertUTCtoBirthday(item.birthday),
          phone: item.phone,
          gender: item.gender,
          status: item.status,
          created: convertUTCtoBirthday(item.created),
          requestId: item.profile_id,
        };
      });
      state.isLoading = false;
      state.isDone = true;
      state.dataList = dataList;
      state.pageData = action.data.pageInfo;
    },
    authProfileFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const { authProfile, authProfileSuccess, authProfileFailure } =
  authProfileSlice.actions;

export default authProfileSlice.reducer;
