import React from "react";
import styled from "styled-components";

export default function TextAreaCell({
  name,
  title,
  essential,
  textAreaPlaceholder,
  state,
  errorState,
  handleChangeInput,
  handleBlurError,
  ...restProps
}) {
  return (
    <Root {...restProps}>
      <TitleWrapper>
        <Title>
          {title}
          {essential && <Essential>*</Essential>}
        </Title>
      </TitleWrapper>
      <ContentWrapper>
        <TextArea
          name={name}
          placeholder={textAreaPlaceholder}
          onChange={handleChangeInput}
          value={state}
          onBlur={handleBlurError}
          errorState={errorState}
        />
      </ContentWrapper>
    </Root>
  );
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 190px 1fr;
  column-gap: 1px;
  row-gap: 1px;
`;

const TitleWrapper = styled.div`
  display: flex;
  padding-left: 20px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray_F5};
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_6C};
`;

const Title = styled.span`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const Essential = styled.span`
  margin-left: 2px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.red_FF};
`;

const ContentWrapper = styled.div`
  padding: 7px 58px 7px 20px;
  display: flex;
  background-color: ${({ theme }) => theme.color.white};
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 105px;
  padding: 12px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid
    ${({ theme, errorState }) =>
      errorState ? theme.color.red_FF : theme.color.gray_DE};
  border-radius: 5px;
  overflow-y: scroll;
  resize: none;
`;
