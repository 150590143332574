import React from "react";
import { useFieldArray } from "react-hook-form";
import { DetailPageSectionLayout } from "components/index";
import FormTable from "components/table/FormTable";
import FormRadioButton from "components/radioButton/FormRadioButton";
import styled from "styled-components";
import ErrorMessage from "components/errorMessage/ErrorMessage";

export default function RomanticStyle({
  adminList,
  answerList,
  register,
  control,
  watch,
  errors,
  clearErrors,
}) {
  const radioList = {};

  answerList.forEach((item, listIndex) => {
    item.answer.forEach((answer, answerIndex) => {
      radioList[listIndex + 1] = {
        ...radioList[listIndex + 1],
        [answerIndex]: answer,
      };
    });
  });

  const { fields } = useFieldArray({
    control,
    name: "romanticStyle",
  });

  const contentRender = (content) => {
    switch (content.key) {
      case "romanticStyle":
        return (
          <Wrapper>
            {fields.map((filed, i) => {
              const name = `${content.key}.${i}.value`;

              return (
                <AnswerWrapper key={i}>
                  <Question>{`${i + 1}. ${answerList[i]?.question}`}</Question>
                  <CustomFormRadioButton
                    radioName={filed.id}
                    radioList={radioList[i + 1]}
                    register={register(name, {
                      required: true,
                      onChange: () => {
                        clearErrors(name);
                      },
                    })}
                    watch={watch(name)}
                  />
                </AnswerWrapper>
              );
            })}
          </Wrapper>
        );
    }
  };

  return (
    <DetailPageSectionLayout
      heading="연애 스타일"
      description="*필수 정보를 모두 입력해주세요."
    >
      <div>
        <FormTable>
          {adminList.romanticStyle.map((row, rowI) => (
            <FormTable.Row key={rowI} contentCount={row.length}>
              {row.map((content, contentI) => (
                <FormTable.Content
                  key={content.heading + contentI}
                  require={content.require}
                  heading={content.heading}
                >
                  {contentRender(content)}
                </FormTable.Content>
              ))}
            </FormTable.Row>
          ))}
        </FormTable>
        {errors.romanticStyle && (
          <ErrorMessage msg="연애 스타일을 선택해주세요." />
        )}
      </div>
    </DetailPageSectionLayout>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 20px;
  width: 364px;
  margin: 15px 0;
`;

const CustomFormRadioButton = styled(FormRadioButton)`
  display: grid;
  grid-template-columns: repeat(2, 182px);
`;

const AnswerWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
`;

const Question = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_5B};
`;
