import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { LAYOUT_TYPE } from "utils/constants";
import { answerList } from "store/reducer/answerList";
import Navbar from "./navbar/Navbar";
import Header from "./header/Header";
import Toast from "components/toast/Toast";

const Layout = ({ children, type }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(answerList());
  }, []);

  return (
    <>
      {type === LAYOUT_TYPE.PUBLIC ? (
        <>
          <PublicMain>{children}</PublicMain>
        </>
      ) : (
        <>
          <Header />
          <Navbar />
          <PrivateMain>
            {children} <Toast />
          </PrivateMain>
        </>
      )}
    </>
  );
};

export default Layout;

const PublicMain = styled.main`
  width: 100%;
  min-height: 100vh;
`;

const PrivateMain = styled.main`
  position: relative;
  width: 100%;
  min-width: ${({ theme }) => theme.size.HEADER_FOOTER_MIN_WIDTH};
  min-height: 100vh;
  margin: 0 50px;
  padding-top: 50px;
  background-color: ${({ theme }) => theme.color.bg_primary};
  z-index: ${({ theme }) => theme.zIndex.MAIN};
  @media (max-height: 914px) {
    min-height: 914px;
  }
`;
