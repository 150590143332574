import React from "react";
import styled from "styled-components";

import { DetailPageSectionLayout } from "components/index";
import Table from "components/table/Table";
import UnmaskingModal from "components/modal/UnmaskingModal";

export default function BasicInformation({
  list,
  data,
  // ci,
  unMasking,
  submitActionFunction,
  setPassword,
}) {
  return (
    <CustomDetailPageSectionLayout heading="기본 정보">
      {unMasking && (
        <UnlockButton
          submitActionFunction={submitActionFunction}
          setPassword={setPassword}
        />
      )}
      {/* {ci ? (
        <CustomTable list={list} data={data} />
      ) : ( */}
      <Table list={list} data={data} />
      {/* )} */}
    </CustomDetailPageSectionLayout>
  );
}

const CustomDetailPageSectionLayout = styled(DetailPageSectionLayout)`
  position: relative;
`;

// const CustomTable = styled(Table)`
//   & > :last-child {
//     grid-column: auto / span 2;
//   }
// `;

const UnlockButton = styled(UnmaskingModal)`
  position: absolute;
  top: 9px;
  right: 30px;
  width: 38px;
  height: 38px;
`;
