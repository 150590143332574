import { call, put, takeLatest } from "redux-saga/effects";

import {
  getReportExcelDownload,
  getUserExcelDownload,
  getUserPaymentExcelDownload,
} from "api/index";

import {
  excelDownload,
  excelDownloadFailure,
  excelDownloadSuccess,
  paymentExcelDownload,
  paymentExcelDownloadFailure,
  paymentExcelDownloadSuccess,
  reportExcelDownload,
  reportExcelDownloadFailure,
  reportExcelDownloadSuccess,
} from "store/reducer/excelDownload";
import { excelDownloadHelper } from "utils/helper/index";

function* excelDownloadSaga(action) {
  const { fileName } = action.payload;
  try {
    const res = yield call(getUserExcelDownload, action.payload);
    excelDownloadHelper(res, fileName);

    yield put({
      type: excelDownloadSuccess,
      data: res.data,
    });
  } catch (err) {
    yield put({
      type: excelDownloadFailure,
      error: err.response.data,
    });
  }
}

export function* watchExcelDownload() {
  yield takeLatest(excelDownload, excelDownloadSaga);
}

function* paymentExcelDownloadSaga(action) {
  const { fileName } = action.payload;
  try {
    const res = yield call(getUserPaymentExcelDownload, action.payload);
    excelDownloadHelper(res, fileName);

    yield put({
      type: paymentExcelDownloadSuccess,
      data: res.data,
    });
  } catch (err) {
    yield put({
      type: paymentExcelDownloadFailure,
      error: err.response.data,
    });
  }
}

export function* watchPaymentExcelDownload() {
  yield takeLatest(paymentExcelDownload, paymentExcelDownloadSaga);
}

function* reportExcelDownloadSaga(action) {
  const { fileName } = action.payload;
  try {
    const res = yield call(getReportExcelDownload, action.payload);
    excelDownloadHelper(res, fileName);

    yield put({
      type: reportExcelDownloadSuccess,
      data: res.data,
    });
  } catch (err) {
    yield put({
      type: reportExcelDownloadFailure,
      error: err.response.data,
    });
  }
}

export function* watchReportExcelDownload() {
  yield takeLatest(reportExcelDownload, reportExcelDownloadSaga);
}
