import { useState, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const useDropdown = (initState, stateName) => {
  const [selectOption, setSelectOption] = useState("");
  const [searchParams] = useSearchParams();

  const handleClickOption = useCallback(
    (value) => () => {
      setSelectOption(value);
    },
    [],
  );

  useEffect(() => {
    if (initState === "nickname" || initState === "user_number") {
      searchParams.get("nickname")
        ? setSelectOption("nickname")
        : searchParams.get("user_number")
        ? setSelectOption("user_number")
        : setSelectOption(initState);
    } else if (initState === "to_nickname" || initState === "from_nickname") {
      searchParams.get("to_nickname")
        ? setSelectOption("to_nickname")
        : searchParams.get("from_nickname")
        ? setSelectOption("from_nickname")
        : setSelectOption(initState);
    } else {
      searchParams.get(stateName) === ""
        ? ""
        : searchParams.get(stateName)
        ? setSelectOption(searchParams.get(stateName))
        : setSelectOption(initState);
    }
  }, [initState, searchParams]);

  return [selectOption, handleClickOption];
};

export default useDropdown;
