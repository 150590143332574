import {
  getAuthInspectionDetail,
  getUnmasking,
  putAuthInspectionDetail,
} from "api/index";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  authInspectionDetail,
  authInspectionDetailFailure,
  authInspectionDetailSuccess,
  authInspectionDetailUnmasking,
  authInspectionDetailUnmaskingFailure,
  authInspectionDetailUnmaskingSuccess,
} from "store/reducer/authInspectionDetail";
import {
  authInspectionDetailApproval,
  authInspectionDetailApprovalFailure,
  authInspectionDetailApprovalSuccess,
} from "store/reducer/authInspectionDetailApproval";
import {
  authInspectionDetailReject,
  authInspectionDetailRejectFailure,
  authInspectionDetailRejectSuccess,
} from "store/reducer/authInspectionDetailReject";
import { toastShow } from "store/reducer/toast";

function* authInspectionDetailSaga(action) {
  try {
    const { data } = yield call(getAuthInspectionDetail, action.payload);

    const { profile_img, certification_img, certification } = data;
    const profileSrcArray = profile_img.map((item) => {
      const { presigned_url } = item;
      return presigned_url;
    });
    const certificationSrcArray = certification_img.map((item) => {
      const { presigned_url } = item;
      return presigned_url;
    });

    yield put({
      type: authInspectionDetailSuccess,
      data: { certificationSrcArray, profileSrcArray, certification },
    });
  } catch (err) {
    yield put({
      type: authInspectionDetailFailure,
      error: err.response.data,
    });
  }
}

export function* watchAuthInspectionDetail() {
  yield takeLatest(authInspectionDetail, authInspectionDetailSaga);
}

function* authInspectionDetailApprovalSaga(action) {
  const { navigate, beforePath, toastMessageKey, data, id } = action.payload;

  try {
    yield call(putAuthInspectionDetail, { data, id });
    yield put({
      type: authInspectionDetailApprovalSuccess,
    });

    navigate(beforePath);

    yield put({
      type: toastShow,
      data: { type: "success", text: toastMessageKey },
    });
  } catch (err) {
    yield put({
      type: authInspectionDetailApprovalFailure,
      error: err.response.data,
    });
  }
}

export function* watchAuthInspectionDetailApproval() {
  yield takeLatest(
    authInspectionDetailApproval,
    authInspectionDetailApprovalSaga,
  );
}

function* authInspectionDetailRejectSaga(action) {
  const { navigate, beforePath, toastMessageKey } = action.payload;

  try {
    yield call(putAuthInspectionDetail, action.payload);
    yield put({
      type: authInspectionDetailRejectSuccess,
    });

    navigate(beforePath);

    yield put({
      type: toastShow,
      data: { type: "fail", text: toastMessageKey },
    });
  } catch (err) {
    yield put({
      type: authInspectionDetailRejectFailure,
      error: err.response.data,
    });
  }
}

export function* watchAuthInspectionDetailReject() {
  yield takeLatest(authInspectionDetailReject, authInspectionDetailRejectSaga);
}

function* authInspectionDetailUnmaskingSaga(action) {
  try {
    const { data } = yield call(getUnmasking, action.payload);

    yield put({
      type: authInspectionDetailUnmaskingSuccess,
      data,
    });
  } catch (err) {
    yield put({
      type: authInspectionDetailUnmaskingFailure,
      error: err.response.data,
    });

    alert("처리 중에 오류가 발생했습니다. 다시 시도해 주세요.");
  }
}

export function* watchAuthInspectionDetailUnmasking() {
  yield takeLatest(
    authInspectionDetailUnmasking,
    authInspectionDetailUnmaskingSaga,
  );
}
