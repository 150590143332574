import { Arrow, Notification } from "assets/icon/index";
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom/index";

export default function NotFound() {
  const navigate = useNavigate();
  const handleClickBeforePage = () => {
    navigate(-1);
  };
  return (
    <Root>
      <Notification />
      <Heading>요청하신 페이지를 찾을 수 없습니다.</Heading>
      <Text>
        입력한 주소가 잘못되었거나,사용이 일시 중단되어 요청하신 페이지를 찾을
        수 없습니다. <br /> 입력하신 페이지의 주소가 정확한지 다시 한번 확인해
        주시기 바랍니다.
      </Text>
      <Button onClick={handleClickBeforePage}>
        이전페이지로 이동 <CustomArrow />
      </Button>
    </Root>
  );
}

const Root = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.h2`
  margin: 40px 0 14px 0;
  font-size: 18px;
  color: ${({ theme }) => theme.color.gray_2B};
`;

const Text = styled.p`
  margin-bottom: 32px;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.gray_6C};
`;

const Button = styled.button`
  width: 150px;
  height: 36px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_69};
  border: 1px solid ${({ theme }) => theme.color.gray_D6};
  border-radius: 5px;
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
`;

const CustomArrow = styled(Arrow)`
  transform: rotate(90deg);
`;
