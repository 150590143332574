import React from "react";
import styled from "styled-components";

import { DetailPageSectionLayout, Table } from "components/index";
import { memberAdminDetailIntroduceTable } from "assets/static/table";

export default function MemberAdminDetailIntroduce({ data }) {
  return (
    <DetailPageSectionLayout heading="회원 소개">
      <CustomComplexTable list={memberAdminDetailIntroduceTable} data={data} />
    </DetailPageSectionLayout>
  );
}

const CustomComplexTable = styled(Table)`
  grid-template-columns: 100%;
`;
