import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { makeCryptoFunction } from "utils/helper/index";
import { CheckCircle, Logo } from "assets/icon/index";
import { color } from "style/theme";
import { AccountInput, Validation } from "components/index";
import { login } from "store/reducer/login";
import { useSelector } from "react-redux";

const initLoginState = {
  id: "",
  password: "",
};

const keepLoginState =
  localStorage.getItem("keepLogin") === "true" ? true : false;

export default function Login() {
  const [loginState, setLoginState] = useImmer(initLoginState);
  const [isKeepLogin, setIsKeepLogin] = useState(keepLoginState);
  const [isIdValidation, setIsIdValidation] = useState(true);
  const [isPasswordValidation, setIsPasswordValidation] = useState(true);
  const dispatch = useDispatch();
  const error = useSelector(({ login }) => login.error);
  const navigate = useNavigate();

  let isLoginButtonValidation =
    loginState.id.length !== 0 && loginState.password.length !== 0;

  const handleLogin = (event) => {
    event.preventDefault();

    dispatch(
      login({
        email: loginState.id,
        password: makeCryptoFunction(loginState.password),
        navigate,
      }),
    );
  };

  const handleClickKeepLogin = () => {
    setIsKeepLogin(!isKeepLogin);
  };

  useEffect(() => {
    if (isKeepLogin) {
      localStorage.setItem("keepLogin", true);
    } else {
      localStorage.setItem("keepLogin", false);
    }
  }, [isKeepLogin]);

  useEffect(() => {
    if (error === "이메일이 올바르지 않습니다.") {
      setIsIdValidation(false);
    } else {
      setIsIdValidation(true);
    }

    if (error === "비밀번호가 올바르지 않습니다.") {
      setIsPasswordValidation(false);
    } else {
      setIsPasswordValidation(true);
    }
  }, [error]);

  return (
    <Root>
      <LoginBG>
        <LoginWrapper>
          <Title>
            <Logo width="248px" height="46px" />
          </Title>
          <FormWrapper onSubmit={handleLogin}>
            <InputWrapper>
              <AccountInput
                name="id"
                state={loginState.id}
                setState={setLoginState}
                validation={isIdValidation}
                placeholder="아이디"
              />
              {isIdValidation === false && (
                <Validation type="idNotFound" top="54px" />
              )}
              <AccountInput
                name="password"
                state={loginState.password}
                setState={setLoginState}
                validation={isPasswordValidation}
                placeholder="비밀번호"
                type="password"
              />
              {isPasswordValidation === false && (
                <Validation type="passwordError" top="136px" />
              )}
            </InputWrapper>
            <LoginButton validation={isLoginButtonValidation}>
              로그인
            </LoginButton>
          </FormWrapper>
          <KeepLogin type="button" onClick={handleClickKeepLogin}>
            <IcCheckCircle keeplogin={isKeepLogin.toString()} /> 로그인 상태
            유지
          </KeepLogin>
        </LoginWrapper>
      </LoginBG>
    </Root>
  );
}

const Root = styled.section`
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #201d1e, #3d3331, #372b22, #372e2d);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginBG = styled.div`
  width: 520px;
  height: 520px;
  background-color: rgba(255, 255, 255, 0.1);
`;

const LoginWrapper = styled.section`
  width: 520px;
  height: 520px;
  padding: 72px 85px;
`;

const Title = styled.div`
  width: 248px;
  margin: 0 auto 64px auto;
`;

const FormWrapper = styled.form``;

const InputWrapper = styled.div`
  height: 132px;
  margin-bottom: 48px;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

const LoginButton = styled.button`
  width: 350px;
  height: 50px;
  margin-bottom: 17px;
  background-color: ${color.button_bg_blue};
  color: ${color.white};
  font-size: 15px;
  border-radius: 2px;
  opacity: ${({ validation }) => (validation ? 1 : 0.3)};
`;

const KeepLogin = styled.button`
  width: 124px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  background-color: inherit;
  border: none;
  color: ${color.text_gray7b};
  display: flex;
  justify-content: space-between;
`;

const IcCheckCircle = styled(CheckCircle)`
  fill: ${({ keeplogin }) =>
    keeplogin === "true" ? "transparent" : color.text_gray7b};
  background-color: ${({ keeplogin }) =>
    keeplogin === "true" ? color.button_bg_blue : "transparent"};

  & .cls-2 {
    fill: ${({ keeplogin }) =>
      keeplogin === "true" ? color.button_bg_blue : color.text_gray7b};
  }
  & .cls-3 {
    fill: ${({ keeplogin }) =>
      keeplogin === "true" ? color.white : "transparent"};
  }
  border-radius: 50%;
`;
