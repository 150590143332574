import {
  DetailPageSectionLayout,
  ImageDetailViewModal,
  ImageView,
} from "components/index";
import React from "react";
import styled from "styled-components";

export default function AuthImageView({ heading, imageSrcArray }) {
  return (
    <CustomDetailPageSectionLayout heading={heading}>
      <ImageView
        srcArray={imageSrcArray}
        render={(
          handleModalClose,
          handleDimClick,
          selectImage,
          isModalOpen,
        ) => {
          return (
            <ImageDetailViewModal
              srcArray={imageSrcArray}
              handleModalClose={handleModalClose}
              handleDimClick={handleDimClick}
              selectImage={selectImage}
              isModalOpen={isModalOpen}
              title="인증 요청 사진"
            />
          );
        }}
      />
    </CustomDetailPageSectionLayout>
  );
}

const CustomDetailPageSectionLayout = styled(DetailPageSectionLayout)`
  width: 554px;
  margin: 0;
`;
