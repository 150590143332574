import { createSlice } from "@reduxjs/toolkit";
import { userStatusIdFormatter } from "utils/helper/formatter";

export const memberUserDetailSlice = createSlice({
  name: "memberUserDetail",
  initialState: {
    memberUserDetail: {
      isLoading: false,
      isDone: false,
      error: null,
    },
    memberUserDetailEditInternalGrade: {
      isLoading: false,
      isDone: false,
      error: null,
    },
    profileSrcArray: [],
    basicInformation: {},
    profileInformation: {},
    introductionInformation: {},
    authInformation: {},
    payment: {},
    admonishment: {},
  },
  reducers: {
    memberUserDetail: (state) => {
      state.memberUserDetail.isLoading = true;
      state.memberUserDetail.isDone = false;
      state.memberUserDetail.error = null;
    },
    memberUserDetailSuccess: (state, action) => {
      const {
        name,
        phone,
        user_number,
        ci,
        birthday,
        email,
        gender,
        user_status_id,
        created,
        last_login,
        avg_score,
        internal_grade,
        nickname,
        approved, //가입승인일
        height,
        body_type,
        hobby,
        area,
        religion,
        smoking,
        drinking,
        job,
        education,
        personality,
        interest,
        attraction,
        ideal,
        introduction,
        payment,
        admonishment,
        badges,
        answer,
      } = action.data;
      const basicInformation = {
        name: name,
        phone: phone,
        userNumber: user_number,
        ci: ci,
        birthday: birthday,
        email: email,
        gender: gender,
        userStatusId: userStatusIdFormatter(user_status_id),
        created: created,
        lastLogin: last_login ? last_login : "-",
        avgScore: avg_score,
        internalGrade: internal_grade,
        nickname: nickname,
        approved: approved ? approved : "-",
      };
      const profileInformation = {
        height: height,
        body_type: body_type,
        area: area,
        religion: religion,
        smoking: smoking,
        drinking: drinking,
        job: job,
        education: education,
      };
      const introductionInformation = {
        personality: personality,
        interest: interest,
        attraction: attraction,
        ideal: ideal,
        introduction: introduction,
        hobby: hobby,
      };
      const paymentInformation = {
        purchase: `${payment.purchase.toLocaleString("ko-KR")}원`,
        purchase_stone: `${payment.purchase_stone.toLocaleString("ko-KR")}개`,
        spend: `${payment.spend.toLocaleString("ko-KR")}원`,
        spend_stone: `${payment.spend_stone.toLocaleString("ko-KR")}개`,
        free: `${payment.free.toLocaleString("ko-KR")}개`,
        remain_stone: `${payment.remain_stone.toLocaleString("ko-KR")}개`,
      };
      const badgesObj = {};

      if (badges.length === 0) {
        badgesObj.appearance = 2;
        badgesObj.body = 2;
        badgesObj.occupation = 2;
        badgesObj.university = 2;
        badgesObj.income = 2;
      } else {
        badges.forEach((item, index) => {
          // tier => 라디오버튼에서 0: VVS, 1: VS, 2: 없음
          // API 통신 시 =>0: 없음, 1: VVS, 2: VS
          const grade = item === 0 ? 2 : item === 1 ? 1 : 0;
          switch (index) {
            case 0:
              return (badgesObj.appearance = grade);
            case 1:
              return (badgesObj.body = grade);
            case 2:
              return (badgesObj.occupation = grade);
            case 3:
              return (badgesObj.university = grade);
            case 4:
              return (badgesObj.income = grade);
          }
        });
      }
      state.authInformation = badgesObj;
      state.profileSrcArray = action.data.profile_img.map((item) => {
        return item.presigned_url;
      });
      state.basicInformation = basicInformation;
      state.profileInformation = profileInformation;
      state.introductionInformation = introductionInformation;
      state.answer = { answer };
      state.authInformation = badgesObj;
      state.payment = paymentInformation;
      state.admonishment = { admonishment: `${admonishment}회` };
      state.memberUserDetail.isLoading = false;
      state.memberUserDetail.isDone = true;
    },
    memberUserDetailFailure: (state, action) => {
      state.memberUserDetail.isLoading = false;
      state.memberUserDetail.isDone = false;
      state.memberUserDetail.error = action.error;
    },
    memberUserDetailReset: (state) => {
      state.memberUserDetail.isLoading = false;
      state.memberUserDetail.isDone = false;
      state.memberUserDetail.error = null;
      state.profileSrcArray = [];
      state.basicInformation = {};
      state.profileInformation = {};
      state.introductionInformation = {};
      state.authInformation = {};
      state.payment = {};
      state.admonishment = {};
    },
    memberUserDetailEditInternalGrade: (state) => {
      state.memberUserDetailEditInternalGrade.isLoading = true;
      state.memberUserDetailEditInternalGrade.isDone = false;
      state.memberUserDetailEditInternalGrade.error = null;
    },
    memberUserDetailEditInternalGradeSuccess: (state, action) => {
      state.basicInformation.internalGrade = action.data;
      state.memberUserDetailEditInternalGrade.isLoading = false;
      state.memberUserDetailEditInternalGrade.isDone = true;
    },
    memberUserDetailEditInternalGradeFailure: (state, action) => {
      state.memberUserDetailEditInternalGrade.isLoading = false;
      state.memberUserDetailEditInternalGrade.isDone = false;
      state.memberUserDetailEditInternalGrade.error = action.error;
    },
  },
});

export const {
  memberUserDetail,
  memberUserDetailSuccess,
  memberUserDetailFailure,
  memberUserDetailReset,
  memberUserDetailEditInternalGrade,
  memberUserDetailEditInternalGradeSuccess,
  memberUserDetailEditInternalGradeFailure,
} = memberUserDetailSlice.actions;

export default memberUserDetailSlice.reducer;
