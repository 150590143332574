import { makeCryptoFunction } from "./crypto";
import { v4 as uuidv4 } from "uuid";

export const phoneFormatter = (value) => {
  return value.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
};

export const numericOnly = (value) => {
  const regex = /[^0-9]/g;

  return value.replaceAll(regex, "");
};

export const convertUTCtoBirthday = (value) => {
  return value?.substring(0, 10);
};

export const deleteEmptyValueFromKey = (object) => {
  const obj = {};
  for (const property in object) {
    if ((object[property] !== "" || undefined) && object[property] !== "전체") {
      obj[property] = object[property];
    }
  }

  return obj;
};

export const authInformatinFormatter = (value) => {
  return value === 1 ? 1 : value === 0 ? 2 : 0;
};

export const userStatusIdFormatter = (userStatusId) => {
  if (userStatusId === 1) {
    return "활동";
  } else if (userStatusId === 3) {
    return "휴면";
  } else if (userStatusId === 4) {
    return "정지";
  } else if (userStatusId === 5) {
    return "제명";
  }
};

export const checkOnlyNumber = (string) => {
  if (!string) return;

  const check = /[^0-9]/g;
  string = string.replace(check, "");
  return string;
};

export const checkBirthday = (string) => {
  const check = /[^0-9]/g;
  string = string.replace(check, "");
  return string
    .replace(/^(\d{0,4})(\d{0,2})(\d{0,2})$/g, "$1-$2-$3")
    .replace(/-{1,2}$/g, "");
};

export const numberComma = (number) => {
  // 숫자에 천단위마다 콤마 찍기
  return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const addDashPhoneNumber = (string) => {
  if (!string) return;

  const check = /[^0-9]/g;
  string = string.replace(check, "");
  return string
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
    .replace(/-{1,2}$/g, "");
};

export const makeDropdownOptions = (dropdownObj) => {
  return dropdownObj
    ? Object.entries(dropdownObj).map(([key, value]) => {
        return {
          label: value,
          value: key,
        };
      })
    : [];
};

export const isAdminListError = (err, adminList) => {
  const makeAdminListKeys = adminList
    .map((items) => items.map((content) => content.key))
    .flat();

  return (
    Object.keys(err).filter((errKey) => makeAdminListKeys.includes(errKey))
      .length !== 0
  );
};

const makeAdminCreateFormData = (data) => {
  const answer = {};

  data.romanticStyle.forEach((item, i) => (answer[i] = item.value));
  return {
    email: data.email,
    password: makeCryptoFunction(data.password),
    name: data.name,
    phone: data.phone,
    birthday: data.birthday,
    gender: data.gender,
    nickname: data.nickname,
    area_id: data.area,
    height: data.height,
    body_type_id: data.bodyType,
    religion_id: data.religion,
    drinking_id: data.drinking,
    smoking_id: data.smoking,
    education: data.education,
    introduction: data.introduction,
    job: data.job,
    personality: [
      data.personality1,
      data.personality2,
      data.personality3,
      data.personality4,
      data.personality5,
    ],
    hobby: [data.hobby1, data.hobby2, data.hobby3, data.hobby4, data.hobby5],
    ideal: [data.ideal1, data.ideal2, data.ideal3, data.ideal4, data.ideal5],
    mbti: data.mbti1,
    answer,
    certification: [
      data.appearance,
      data.body,
      data.occupation,
      data.university,
      data.income,
    ],
    internal_grade: data.internalGrade,
    user_status_id: data.userStatusId,
  };
};

export const makeAdminCreateForm = (data, profiles) => {
  const submitData = makeAdminCreateFormData(data);

  return {
    ...submitData,
    images: profiles
      .filter(Boolean)
      .map((profile) => makeNewImageFileName(profile)),
    content_type: profiles
      .map((profile) => profile[0]?.type)
      .filter((item) => item),
  };
};

const makeAdminModifyFormData = (data) => {
  const answer = {};

  data.romanticStyle.forEach((item, i) => (answer[i] = item.value));
  return {
    phone: data.phone,
    nickname: data.nickname,
    area_id: data.area,
    height: data.height,
    body_type_id: data.bodyType,
    religion_id: data.religion,
    drinking_id: data.drinking,
    smoking_id: data.smoking,
    education: data.education,
    introduction: data.introduction,
    job: data.job,
    personality: [
      data.personality1,
      data.personality2,
      data.personality3,
      data.personality4,
      data.personality5,
    ],
    hobby: [data.hobby1, data.hobby2, data.hobby3, data.hobby4, data.hobby5],
    ideal: [data.ideal1, data.ideal2, data.ideal3, data.ideal4, data.ideal5],
    // interest: [
    //   data.interest1.trim(),
    //   data.interest2.trim(),
    //   data.interest3.trim(),
    //   data.interest4.trim(),
    //   data.interest5.trim(),
    // ].filter((item) => item),
    // attraction: [
    //   data.attraction1.trim(),
    //   data.attraction2.trim(),
    //   data.attraction3.trim(),
    //   data.attraction4.trim(),
    //   data.attraction5.trim(),
    // ].filter((item) => item),
    // ideal: [
    //   data.ideal1.trim(),
    //   data.ideal2.trim(),
    //   data.ideal3.trim(),
    //   data.ideal4.trim(),
    //   data.ideal5.trim(),
    // ].filter((item) => item),
    mbti: data.mbti1,
    answer,
    certification: [
      data.appearance,
      data.body,
      data.occupation,
      data.university,
      data.income,
    ],
    internal_grade: data.internalGrade,
    user_status_id: data.userStatusId,
  };
};

export const makeAdminModifyForm = (data, profiles, deleteProfileIndexs) => {
  const submitData = makeAdminModifyFormData(data);
  const newProfiles = profiles.filter((profile) => profile[0]?.name);

  const isRepImgModify = deleteProfileIndexs.includes(0);
  if (isRepImgModify) {
    if (newProfiles.length === 1) {
      // 대표 이미지만 수정
      return {
        ...submitData,
        rep_img: makeNewImageFileName(newProfiles[0]),
        rep_img_type: newProfiles[0][0].type,
        delete_file_idx: [0],
      };
    } else {
      // 대표 + 나머지 이미지 수정
      return {
        ...submitData,
        rep_img: makeNewImageFileName(newProfiles[0]),
        rep_img_type: newProfiles[0][0].type,
        new_profile_img: newProfiles
          .map((profile, i) => {
            if (i !== 0) {
              return makeNewImageFileName(profile);
            }
          })
          .filter((item) => item),
        new_profile_img_type: newProfiles
          .map((profile, i) => {
            if (i !== 0) {
              return profile[0].type;
            }
          })
          .filter((item) => item),
        delete_file_idx: deleteProfileIndexs,
      };
    }
  }

  const isDeleteImg = deleteProfileIndexs.length !== 0;

  if (newProfiles.length > 0) {
    // 나머지 이미지만 수정

    return {
      ...submitData,
      ...(isDeleteImg && { delete_file_idx: deleteProfileIndexs }),
      new_profile_img: newProfiles.map((profile) =>
        makeNewImageFileName(profile),
      ),
      new_profile_img_type: newProfiles.map((profile) => profile[0].type),
    };
  }

  if (isDeleteImg) {
    // 이미지 추가 없이 삭제만 할 때
    return {
      ...submitData,
      delete_file_idx: deleteProfileIndexs,
    };
  }

  // 이미지 수정 x
  return submitData;
};

export const makeNewImageFileName = (file) => {
  const extension = file[0].name.split(".").at(-1);

  return `${uuidv4()}.${extension}`;
};
