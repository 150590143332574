import { createSlice } from "@reduxjs/toolkit";

export const reportIndianGameSlice = createSlice({
  name: "reportIndianGame",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
    data: [],
    pageInfo: {},
  },
  reducers: {
    reportIndianGame: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    reportIndianGameSuccess: (state, action) => {
      const { reports, pageInfo } = action.data;

      state.isLoading = false;
      state.isDone = true;
      state.data = reports;
      state.pageInfo = pageInfo;
    },
    reportIndianGameFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  reportIndianGame,
  reportIndianGameSuccess,
  reportIndianGameFailure,
} = reportIndianGameSlice.actions;

export default reportIndianGameSlice.reducer;
