import React from "react";
import styled from "styled-components";

export default function FormTextarea({
  placeholder,
  maxLength,
  isError,
  register,
}) {
  return (
    <Textarea
      placeholder={placeholder}
      maxLength={maxLength}
      isError={isError}
      {...register}
    />
  );
}

const Textarea = styled.textarea`
  width: 100%;
  height: 105px;
  border: 1px solid
    ${({ theme, isError }) =>
      isError ? theme.color.red_FF : theme.color.gray_DE};
  border-radius: 5px;
  padding: 12px;
  background-color: ${({ theme }) => theme.color.white};
  overflow-y: scroll;
  resize: none;
`;
