import { ax } from "api/axios";

export const getUserExcelDownload = async (payload) => {
  return await ax.get(`/admin/user/excel?type=${payload.type}`, {
    responseType: "blob",
  });
};

export const getUserPaymentExcelDownload = async (payload) => {
  const { userID } = payload;
  return await ax.get(`/admin/user/${userID}/payment/excel`, {
    responseType: "blob",
  });
};

export const getReportExcelDownload = async () => {
  return await ax.get("/admin/report/profile/excel", {
    responseType: "blob",
  });
};
