import React from "react";
import styled from "styled-components";

import { DetailPageSectionLayout } from "components/index";
import Table from "components/table/Table";

export default function MemberUserIntroduction({ data, list }) {
  return (
    <DetailPageSectionLayout heading="회원 소개">
      <CustomTable data={data} list={list} />
    </DetailPageSectionLayout>
  );
}

const CustomTable = styled(Table)`
  grid-template-columns: 100%;
`;
