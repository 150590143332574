import { createSlice } from "@reduxjs/toolkit";

export const memberUserModifyCertificateSlice = createSlice({
  name: "memberUserModifyCertificate",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    memberUserModifyCertificate: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    memberUserModifyCertificateSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    memberUserModifyCertificateFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  memberUserModifyCertificate,
  memberUserModifyCertificateSuccess,
  memberUserModifyCertificateFailure,
} = memberUserModifyCertificateSlice.actions;

export default memberUserModifyCertificateSlice.reducer;
