import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { Layout } from "components/index";
import { LAYOUT_TYPE, PATH } from "utils/constants";
import { isAuth } from "utils/helper/checkAuth";

const PublicRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("keepLogin") === "true" && isAuth()) {
      navigate(PATH.memberUser.fullPath);
    }
  }, []);

  return (
    <Layout type={LAYOUT_TYPE.PUBLIC}>
      <Outlet />
    </Layout>
  );
};

export default PublicRoute;
