import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Heading, SubmitButton } from "components/index";
import BasicInfo from "../containers/BasicInfo";
import ProfileInfo from "../containers/ProfileInfo";
import Introduce from "../containers/Introduce";
import AuthInfo from "../containers/AuthInfo";
import {
  gender,
  height,
  internalGrade,
  mbti,
  userStatusId,
} from "assets/static/dropdown";
import { adminCreateInitState, adminCreateList } from "assets/static/adminForm";
import { memberAdminDropdownList } from "store/reducer/memberAdminDropdownList";
import {
  memberAdminCreate,
  memberAdminDuplicateEmailReset,
  memberAdminDuplicateNicknameReset,
} from "store/reducer/memberAdminCreate";
import AuthDetailPageRootLayout from "pages/auth/containers/AuthDetailPageRootLayout";
import {
  makeAdminCreateForm,
  makeDropdownOptions,
} from "utils/helper/formatter";
import useHookForm from "../hooks/useHookForm";
import RomanticStyle from "../containers/RomanticStyle";

export default function MemberAdminCreate2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    errors,
    watch,
    setError,
    trigger,
    setFocus,
    handleSubmit,
    hookFormProps,
    hookFormFormat,
    hookFormValidation,
  } = useHookForm(adminCreateInitState);

  const {
    area,
    body_type,
    drinking,
    hobby,
    personality,
    religion,
    smoking,
    ideal,
  } = useSelector((state) => state.memberAdminDropdownList.dropdownList.data);
  const answerList = useSelector((state) => state.answerList.data);
  const isCheckEmail = useSelector(
    (state) => state.memberAdminCreate.duplicateEmail.isDone,
  );
  const isCheckNickname = useSelector(
    (state) => state.memberAdminCreate.duplicateNickname.isDone,
  );
  const profiles = [
    watch("profile1"),
    watch("profile2"),
    watch("profile3"),
    watch("profile4"),
    watch("profile5"),
  ];

  const makeDropdowns = () => ({
    gender: makeDropdownOptions(gender),
    userStatusId: makeDropdownOptions(userStatusId),
    internalGrade: makeDropdownOptions(internalGrade),
    area: makeDropdownOptions(area),
    height: makeDropdownOptions(height),
    bodyType: makeDropdownOptions(
      watch("gender") === "F" ? body_type?.F : body_type?.M,
    ),
    religion: makeDropdownOptions(religion),
    drinking: makeDropdownOptions(drinking),
    smoking: makeDropdownOptions(smoking),
    personality: makeDropdownOptions(personality),
    hobby: makeDropdownOptions(hobby),
    ideal: makeDropdownOptions(ideal),
    mbti: makeDropdownOptions(mbti),
  });

  const handleCreateAdmin = () => {
    trigger();

    const authInfoKey = [
      "appearance",
      "body",
      "occupation",
      "university",
      "income",
    ];

    const isError = Object.keys(errors).length !== 0;
    const isAuthInfoError = authInfoKey
      .map((key) => watch(key))
      .every((state) => state === "0");
    if (isAuthInfoError) {
      setError("authInfo", { type: "required" });
    }

    const isProfilesError = profiles.filter((profile) => profile).length < 3;
    if (isProfilesError) {
      setError("profiles", { type: "required" });
      setFocus("profile1");
    }

    const isPassSubmitValidation =
      !isError &&
      !isAuthInfoError &&
      !isProfilesError &&
      isCheckEmail &&
      isCheckNickname;

    if (isPassSubmitValidation) {
      handleSubmit(
        (data) => {
          const submitData = makeAdminCreateForm(data, profiles);

          dispatch(memberAdminCreate({ submitData, profiles, navigate }));
          dispatch(memberAdminDuplicateEmailReset());
          dispatch(memberAdminDuplicateNicknameReset());
        },
        (error) => console.log("error", error),
      )();
    }
  };

  useEffect(() => {
    dispatch(memberAdminDropdownList());
  }, []);

  return (
    <AuthDetailPageRootLayout>
      <Heading>어드민 회원 추가</Heading>
      <BasicInfo
        adminList={adminCreateList}
        dropdowns={makeDropdowns()}
        profiles={profiles}
        hookFormFormat={hookFormFormat}
        hookFormValidation={hookFormValidation}
        {...hookFormProps}
      />
      <ProfileInfo
        adminList={adminCreateList}
        dropdowns={makeDropdowns()}
        hookFormValidation={hookFormValidation}
        {...hookFormProps}
      />
      <Introduce
        adminList={adminCreateList}
        dropdowns={makeDropdowns()}
        {...hookFormProps}
      />
      <RomanticStyle
        adminList={adminCreateList}
        answerList={answerList}
        {...hookFormProps}
      />
      <AuthInfo adminList={adminCreateList} {...hookFormProps} />
      <ButtonWrapper>
        <SubmitButton
          disabled={Object.keys(errors).length !== 0}
          onClick={handleCreateAdmin}
        >
          완료
        </SubmitButton>
      </ButtonWrapper>
    </AuthDetailPageRootLayout>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;

  button + button {
    margin-left: 10px;
  }
`;
