import { createSlice } from "@reduxjs/toolkit";

export const memberUserSuspendReasonSlice = createSlice({
  name: "memberUserSuspendReason",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
    data: {},
  },
  reducers: {
    memberUserSuspendReason: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    memberUserSuspendReasonSuccess: (state, action) => {
      state.isLoading = false;
      state.isDone = true;
      state.data = action.data;
    },
    memberUserSuspendReasonFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
    memberUserSuspendReasonReset: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = null;
    },
  },
});

export const {
  memberUserSuspendReason,
  memberUserSuspendReasonSuccess,
  memberUserSuspendReasonFailure,
  memberUserSuspendReasonReset,
} = memberUserSuspendReasonSlice.actions;

export default memberUserSuspendReasonSlice.reducer;
