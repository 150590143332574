import React from "react";
import styled from "styled-components";

import { DetailPageSectionLayout } from "components/index";
import { memberAdminIntroduceTable } from "assets/static/table";
import MemberAdminIntroduceTable from "./MemberAdminIntroduceTable";

export default function MemberAdminIntroduce({
  state,
  setState,
  errorState,
  handleChangeInput,
  handleBlurError,
  tableErrorState,
}) {
  return (
    <DetailPageSectionLayout
      heading="회원 소개"
      description="*필수 정보를 모두 입력해주세요. (항목당 3개 이상 작성)"
    >
      <MemberAdminIntroduceTable
        list={memberAdminIntroduceTable}
        state={state}
        setState={setState}
        errorState={errorState}
        handleChangeInput={handleChangeInput}
        handleBlurError={handleBlurError}
      />
      {tableErrorState.introduceState.state && (
        <ErrorMessage>{tableErrorState.introduceState.message}</ErrorMessage>
      )}
    </DetailPageSectionLayout>
  );
}

const ErrorMessage = styled.span`
  display: block;
  margin-top: 13px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.red_FF};
`;
