import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { CheckBlue, Close, NotArrow } from "assets/icon/index";
import { toast } from "assets/static/toast";
import { toastHidden } from "store/reducer/toast";

export default function Toast({ ...restProps }) {
  const dispatch = useDispatch();
  const { isShow, text, type } = useSelector((state) => state.toast);

  const handleCloseToast = () => {
    dispatch(toastHidden());
  };

  useEffect(() => {
    if (isShow) {
      setTimeout(() => dispatch(toastHidden()), 3000);
    }
  }, [isShow]);

  return (
    <>
      {isShow && (
        <Root type={type} {...restProps}>
          <TextWrapper>
            {type === "success" ? (
              <CustomCheckBlue width="20px" height="20px" />
            ) : (
              <NotArrow width="20px" height="20px" />
            )}
            {toast[text]}
          </TextWrapper>
          <Button onClick={handleCloseToast}>
            <Close width="12px" height="12px" />
          </Button>
        </Root>
      )}
    </>
  );
}

const Root = styled.div`
  animation: fadeIn 0.5s ease-in-out;
  width: 533px;
  height: 48px;
  padding: 0 16px 0 17px;
  border: 1px solid ${({ theme }) => theme.color.gray_E6};
  border-left: 4px solid
    ${({ theme, type }) =>
      type === "success" ? theme.color.blue_00 : theme.color.red_FF};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 0 10px 0 rgba(167, 167, 167, 0.16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%);

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const Button = styled.button`
  width: 12px;
  height: 12px;
  background-color: inherit;
`;

const CustomCheckBlue = styled(CheckBlue)`
  fill: ${({ theme }) => theme.color.blue_00};
`;
