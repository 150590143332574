import { createSlice } from "@reduxjs/toolkit";

export const memberUserAddFreeStoneSlice = createSlice({
  name: "memberUserAddFreeStone",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    memberUserAddFreeStone: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    memberUserAddFreeStoneSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    memberUserAddFreeStoneFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  memberUserAddFreeStone,
  memberUserAddFreeStoneSuccess,
  memberUserAddFreeStoneFailure,
} = memberUserAddFreeStoneSlice.actions;

export default memberUserAddFreeStoneSlice.reducer;
