import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { Heading, RefreshButton } from "components/index";
import { AuthTable } from "pages/auth/containers/index";
import { authProfile } from "store/reducer/authProfile";
import { authProfileDetailReset } from "store/reducer/authProfileDetail";
import { profileTable, profileTableWidth } from "assets/static/listTable";
import SearchFilter from "components/common/SearchFilter";
import { gender, personalInfo, status } from "assets/static/dropdown";
import useDropdown from "hooks/useDropdown";
import useFilter from "hooks/useFilter";

export default function AuthProfile() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { dataList, pageData } = useSelector((state) => state.authProfile);

  const [selectGender, handleClickGender] = useDropdown("", "gender");
  const [selectStatus, handleClickStatus] = useDropdown("", "status");
  const [selectSearchInputFilter, handleChangeSearchInputFilter] = useDropdown(
    "nickname",
    "personalInfo",
  );

  const {
    inputValue,
    handleFilterSubmit,
    handleChangeInputValue,
    handleResetSubmit,
  } = useFilter(
    {
      gender: selectGender,
      status: selectStatus,
      inputFilter: selectSearchInputFilter,
    },
    authProfile(),
  );

  useEffect(() => {
    dispatch(authProfileDetailReset());
  }, []);

  useEffect(() => {
    const filterObj = { ...Object.fromEntries(searchParams) };
    dispatch(authProfile(filterObj));
  }, [searchParams]);

  return (
    <Root>
      <Heading as="h2">프로필 검수</Heading>
      <SearchFilter
        topChildren={() => {
          return (
            <SearchFilter.FlexWrapper>
              <SearchFilter.LabelDropdown
                label="성별"
                selectOption={selectGender}
                optionObj={gender}
                handleClickOption={handleClickGender}
              />
              <SearchFilter.LastLabelDropdown
                label="처리상태"
                selectOption={selectStatus}
                optionObj={status}
                handleClickOption={handleClickStatus}
              />
            </SearchFilter.FlexWrapper>
          );
        }}
        bottomChildren={() => {
          return (
            <>
              <SearchFilter.FlexWrapper>
                <SearchFilter.InputFilter
                  selectOption={selectSearchInputFilter}
                  optionObj={personalInfo}
                  handleClickOption={handleChangeSearchInputFilter}
                />
                <SearchFilter.Input
                  value={inputValue}
                  handleChangeInputValue={handleChangeInputValue}
                  handleSubmit={handleFilterSubmit}
                />
              </SearchFilter.FlexWrapper>
              <SearchFilter.FlexWrapper>
                <SearchFilter.SubmitButton onClick={handleFilterSubmit} />
                <RefreshButton onClick={handleResetSubmit} />
              </SearchFilter.FlexWrapper>
            </>
          );
        }}
      />
      <AuthTable
        list={profileTable}
        width={profileTableWidth}
        data={dataList}
        pageData={pageData}
      />
    </Root>
  );
}

const Root = styled.section`
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 50px;
`;
