import React from "react";
import styled from "styled-components";

import { DetailPageSectionLayout, Table } from "components/index";
// import { Copy } from "assets/icon/index";
import { reporterInformationTable } from "assets/static/table";
import UnmaskingModal from "components/modal/UnmaskingModal";

export default function ReporterInformation({
  heading,
  data,
  unMasking,
  submitActionFunction,
  setPassword,
}) {
  // const handleCopyContent = () => {
  //   const textarea = document.createElement("textarea");
  //   textarea.value = data.ci;
  //   textarea.style.top = 0;
  //   textarea.style.left = 0;
  //   textarea.style.position = "fixed";
  //   document.body.appendChild(textarea);
  //   // focus() -> 사파리 브라우저 서포팅
  //   textarea.focus();
  //   textarea.select();
  //   document.execCommand("copy");
  //   document.body.removeChild(textarea);
  //   alert("클립보드에 복사되었습니다.");
  // };

  return (
    <CustomDetailPageSectionLayout heading={heading}>
      {unMasking && (
        <UnlockButton
          submitActionFunction={submitActionFunction}
          setPassword={setPassword}
        />
      )}
      <TableWrapper>
        <CustomTable list={reporterInformationTable} data={data} />
        {/* <CopyButton onClick={handleCopyContent}>
          <Copy />
          복사
        </CopyButton> */}
      </TableWrapper>
    </CustomDetailPageSectionLayout>
  );
}

const CustomDetailPageSectionLayout = styled(DetailPageSectionLayout)`
  position: relative;
`;

const UnlockButton = styled(UnmaskingModal)`
  position: absolute;
  top: 9px;
  right: 30px;
  width: 38px;
  height: 38px;
`;

const TableWrapper = styled.div`
  position: relative;
`;

const CustomTable = styled(Table)`
  width: 100%;
  grid-template-columns: 100%;
`;

// const CopyButton = styled.button`
//   width: 60px;
//   height: 34px;
//   position: absolute;
//   bottom: 56px;
//   right: 0px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   column-gap: 6px;
//   color: ${({ theme }) => theme.color.blue_00};

//   :hover {
//     background-color: ${({ theme }) => theme.color.gray_F5};
//   }
// `;
