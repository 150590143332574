import React, { useState } from "react";
import styled from "styled-components";

import { DimLayout, PortalModal } from "components/index";
import { Arrow, Close } from "assets/icon/index";

export default function PaginationImageDetailViewModal({
  title,
  images,
  imageIndex,
  handleDimClick,
  handleModalClose,
}) {
  const [modalImageIndex, setModalImageIndex] = useState(imageIndex);

  const handlePrevBtn = () => {
    if (modalImageIndex === 0) return;
    setModalImageIndex(modalImageIndex - 1);
  };

  const handleNextBtn = () => {
    if (images.filter((item) => item).length - 1 === modalImageIndex) return;

    setModalImageIndex(modalImageIndex + 1);
  };

  const handleClickModalDim = (e) => {
    if (e.target !== e.currentTarget) return;

    handleDimClick(e);
  };

  const handleClickModalClose = () => {
    handleModalClose();
  };

  return (
    <PortalModal>
      <DimLayout handleDimClick={handleClickModalDim}>
        <Root>
          <Heading>{title}</Heading>
          <ImageWrapper>
            <Image
              src={
                images[modalImageIndex]?.[0]?.name
                  ? URL.createObjectURL(images[modalImageIndex][0])
                  : images[modalImageIndex]
              }
              alt="확대 이미지"
            />
          </ImageWrapper>
          <ButtonWrapper>
            <PageButtonWrapper>
              <Button onClick={handlePrevBtn} disabled={modalImageIndex === 0}>
                <CustomArrow direction="left" />
              </Button>
              {`${modalImageIndex + 1}/${images.filter((item) => item).length}`}
              <Button
                onClick={handleNextBtn}
                disabled={
                  modalImageIndex === images.filter((item) => item).length - 1
                }
              >
                <CustomArrow direction="right" />
              </Button>
            </PageButtonWrapper>
          </ButtonWrapper>
          <CloseButton onClick={handleClickModalClose}>
            <Close width="16px" height="16px" />
          </CloseButton>
        </Root>
      </DimLayout>
    </PortalModal>
  );
}

const Root = styled.section`
  width: 630px;
  height: 748px;
  background-color: ${({ theme }) => theme.color.white};
  position: relative;
`;

const Heading = styled.h2`
  padding: 17px 0 17px 24px;
  font-size: 18px;
  font-weight: 500;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.color.border};
`;

const Image = styled.img`
  width: 534px;
  height: 600px;
  margin-top: 23px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 136px;
  margin-top: 12px;
  color: ${({ theme }) => theme.color.gray_6C};
`;

const PageButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const Button = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  opacity: ${({ disabled }) => disabled && 0.3};

  &:disabled {
    cursor: not-allowed;
  }
`;

const CustomArrow = styled(Arrow)`
  transform: ${({ direction }) =>
    direction === "left" ? "rotate(-90deg)" : "rotate(90deg)"};
`;

const CloseButton = styled.button`
  width: 16px;
  height: 16px;
  background-color: inherit;
  position: absolute;
  top: 20px;
  right: 24px;
`;
