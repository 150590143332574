import { CancelButton, RejectModal, SubmitButton } from "components/index";
import React from "react";
import styled from "styled-components";

export default function SubmitCancelButton({
  rejectList,
  rejectAction,
  handleSubbmit,
  checkState,
  beforePath,
  toastMessageKey,
}) {
  return (
    <Root>
      <CancelButton
        render={(handleModalClose, handleDimClick) => {
          return (
            <RejectModal
              handleModalClose={handleModalClose}
              handleDimClick={handleDimClick}
              rejectList={rejectList}
              rejectAction={rejectAction}
              checkState={checkState}
              beforePath={beforePath}
              toastMessageKey={toastMessageKey}
            />
          );
        }}
      >
        반려
      </CancelButton>
      <SubmitButton onClick={handleSubbmit}>승인</SubmitButton>
    </Root>
  );
}

const Root = styled.div`
  width: 1140px;
  display: flex;
  justify-content: end;
  margin: 0 auto;

  button + button {
    margin-left: 10px;
  }
`;
