import { createSlice } from "@reduxjs/toolkit";

export const memberUserDetailPaymentDetailSlice = createSlice({
  name: "memberUserDetailPaymentDetail",
  initialState: {
    isLoading: false,
    isDone: false,
    payments: [],
    pageInfo: {},
    error: null,
  },
  reducers: {
    memberUserDetailPaymentDetail: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    memberUserDetailPaymentDetailSuccess: (state, action) => {
      const { payments, pageInfo } = action.data;

      state.payments = payments;
      state.pageInfo = pageInfo;
      state.isLoading = false;
      state.isDone = true;
    },
    memberUserDetailPaymentDetailFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  memberUserDetailPaymentDetail,
  memberUserDetailPaymentDetailSuccess,
  memberUserDetailPaymentDetailFailure,
} = memberUserDetailPaymentDetailSlice.actions;

export default memberUserDetailPaymentDetailSlice.reducer;
