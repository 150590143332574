import React from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  DimLayout,
  PortalModal,
  SubmitButton,
  CancelButton,
} from "components/index";
import { memberAdminDelete } from "store/reducer/memberAdminDelete";

export default function DeleteConfirmModal({
  handleModalClose,
  handleDimClick,
  ...restProps
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    dispatch(memberAdminDelete({ id, navigate }));
  };

  return (
    <PortalModal>
      <DimLayout handleDimClick={handleDimClick}>
        <Root {...restProps}>
          <Heading>회원을 삭제하시겠습니까?</Heading>
          <Content>
            회원 삭제 시 리스트에서 보이지 않게 되며, 회원 정보가 모두
            삭제됩니다.
          </Content>
          <ButtonWrapper>
            <CancelButton onClick={handleModalClose}>취소</CancelButton>
            <SubmitButton onClick={handleSubmit}>확인</SubmitButton>
          </ButtonWrapper>
        </Root>
      </DimLayout>
    </PortalModal>
  );
}

const Root = styled.section`
  width: 500px;
  background-color: ${({ theme }) => theme.color.white};
  position: relative;
`;

const Heading = styled.h3`
  padding: 24px 0 0 24px;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 500;
`;

const Content = styled.span`
  display: block;
  padding-left: 24px;
  margin-bottom: 24px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_5B};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 15px 24px 15px 0;
  background-color: ${({ theme }) => theme.color.gray_F5};

  button + button {
    margin-left: 10px;
  }
`;
