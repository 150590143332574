import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import styled from "styled-components";

import { Arrow, ArrowDouble } from "assets/icon/index";

export default function Pagination({ pageData, ...restProps }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { totalPages, currentPage, totalData } = pageData;
  const [paginationNumber, setPaginationNumber] = useState(0);

  const paginationNumberArray = [];

  for (let i = 0; i < totalPages / 5; i++) {
    const temp = [];
    for (let j = 0 + i * 5; j < 5 + i * 5; j++) {
      if (j < totalPages) {
        temp.push(j + 1);
      }
    }
    paginationNumberArray.push(temp);
  }

  const currentPageNumber = searchParams.get("page")
    ? +searchParams.get("page")
    : 1;

  const handleNumberClick = (pageNumber) => {
    setSearchParams({ ...Object.fromEntries(searchParams), page: pageNumber });
  };

  const handleDublePreButtonClick = () => {
    setPaginationNumber(0);
    navigate(`${pathname}?page=${1}`, {
      replace: true,
    });
  };

  const handlePreButtonClick = () => {
    if (currentPageNumber === 1) return;

    if (currentPageNumber % 5 === 1) {
      setPaginationNumber(paginationNumber - 1);
    }

    const prePageNumber = +currentPageNumber - 1;
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: prePageNumber,
    });
  };

  const handleDubleNextButtonClick = () => {
    setPaginationNumber(Math.ceil(totalPages / 5) - 1);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: totalPages,
    });
  };

  const handleNextButtonClick = () => {
    if (currentPageNumber === totalPages) return;

    if (currentPageNumber % 5 === 0) {
      setPaginationNumber(paginationNumber + 1);
    }

    const nextPageNumber = +currentPageNumber + 1;
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: nextPageNumber,
    });
  };

  useEffect(() => {
    paginationNumberArray.forEach((item, index) => {
      if (item.includes(+searchParams.get("page"))) {
        setPaginationNumber(index);
      }
    });
  }, [paginationNumberArray]);

  return (
    <Root {...restProps}>
      <Button onClick={handleDublePreButtonClick}>
        <CustomArrowDouble />
      </Button>
      <Button onClick={handlePreButtonClick}>
        <CustomArrow />
      </Button>
      <PageNumber
        currentPage={currentPage}
        totalData={totalData}
        paginationNumberArray={paginationNumberArray[paginationNumber]}
        handleNumberClick={handleNumberClick}
      />
      <Button {...restProps} onClick={handleNextButtonClick}>
        <CustomArrow right={true.toString()} />
      </Button>
      <Button {...restProps} onClick={handleDubleNextButtonClick}>
        <CustomArrowDouble right={true.toString()} />
      </Button>
    </Root>
  );
}

const PageNumber = function PageNumber({
  currentPage,
  totalData,
  paginationNumberArray,
  handleNumberClick,
  ...restProps
}) {
  return (
    <PageNumberWrapper {...restProps}>
      {totalData !== 0 ? (
        paginationNumberArray &&
        paginationNumberArray.map((item, index) => {
          return (
            <NumberButton
              key={index}
              name={item}
              currentPage={currentPage}
              onClick={() => handleNumberClick(item)}
            >
              {item}
            </NumberButton>
          );
        })
      ) : (
        <NumberButton>1</NumberButton>
      )}
    </PageNumberWrapper>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;

  & + & {
    margin-left: 10px;
  }
`;

const CustomArrowDouble = styled(ArrowDouble)`
  width: 12px;
  height: 12px;
  transform: ${({ right }) =>
    right === "true" ? "rotate(180deg)" : "rotate(0deg)"};
`;

const CustomArrow = styled(Arrow)`
  width: 12px;
  height: 12px;
  transform: ${({ right }) =>
    right === "true" ? "rotate(90deg)" : "rotate(-90deg)"};
`;

const PageNumberWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NumberButton = styled.button`
  width: 32px;
  height: 32px;
  font-size: 14px;
  background-color: inherit;
  font-weight: 500;
  color: ${({ theme, currentPage, name }) =>
    currentPage === name ? theme.color.black : theme.color.black_7B};

  :first-child {
    margin-left: 20px;
  }

  :last-child {
    margin-right: 20px;
  }
`;
