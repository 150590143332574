import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import styled from "styled-components";

import { Logo } from "assets/icon/index";
import { headerList } from "assets/static/headerLink";
import { PATH } from "utils/constants";
import { logout } from "store/reducer/logout";

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const res = /([a-zA-Z]+)/g; // pathname에서 주소를 /기준으로 배열로 가져옴

  const handleLogout = () => {
    dispatch(logout({ navigate }));
  };

  const mainPage = (auth) => {
    if (auth[0] === "master") {
      return PATH.memberUser.fullPath;
    } else if (auth[0] === "user") {
      return PATH.memberUser.fullPath;
    } else if (auth[0] === "admin_user") {
      return PATH.memberAdmin.fullPath;
    } else if (auth[0] === "profile") {
      return PATH.authProfile.fullPath;
    } else if (auth[0] === "certificate") {
      return PATH.authAppearance.fullPath;
    } else if (auth[0] === "profile_report") {
      return PATH.reportProfile.fullPath;
    } else if (auth[0] === "community_report") {
      return PATH.reportCommunity.fullPath;
    }
  };

  return (
    <Root>
      <HeaderList>
        <HeaderListItem>
          <Link to={mainPage(Cookies.get("auth").split(","))}>
            <Logo width="114px" height="20px" />
          </Link>
        </HeaderListItem>
        {headerList.map((item, index) => {
          const { path, content, assert, navPathArray } = item;

          // 등급에 따른 헤더 접근제한
          const headerAssert =
            Cookies.get("auth")
              .split(",")
              .map((cookiesItem) => {
                if (assert.includes(cookiesItem) === true) return true;
              })
              .filter((item) => item === true).length !== 0;

          // 등급에 따른 헤더 클릭 시 navPathArray에서 첫 번째로 접근 가능한 index
          const navPathIndex = Cookies.get("auth")
            .split(",")
            .map((cookiesItem) => {
              if (assert.indexOf(cookiesItem) !== -1)
                return assert.indexOf(cookiesItem);
            })
            .filter((item) => item !== undefined)[0];

          return (
            <HeaderListItem
              key={index}
              selected={pathname.match(res)[0] === path.match(res)[0]}
            >
              {headerAssert && index < 3 ? (
                // <Link to={path}>{content}</Link>
                <Link to={navPathArray[navPathIndex]}>{content}</Link>
              ) : (
                <div>{content}</div>
              )}
            </HeaderListItem>
          );
        })}
      </HeaderList>
      <LogoutButton onClick={handleLogout}>로그아웃</LogoutButton>
    </Root>
  );
}

const Root = styled.header`
  width: 100%;
  height: 50px;
  padding-right: 50px;
  display: flex;
  align-items: center;
  position: fixed;
  right: 0;
  top: 0;
  background-color: ${({ theme }) => theme.color.header_bg};
  z-index: ${({ theme }) => theme.zIndex.HEADER_FOOTER};
`;

const HeaderList = styled.ul`
  min-width: 628px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  & li:first-child {
    width: 200px;
    padding-left: 24px;
    margin-right: 28px;
  }
`;

const HeaderListItem = styled.li`
  width: 100px;
  padding-left: 24px;
  a {
    display: block;
    color: ${({ theme, selected }) =>
      selected ? theme.color.text_white : theme.color.text_gray7b};
    font-size: 14px;
    text-decoration: none;
    font-weight: ${({ selected }) => (selected ? 700 : 500)};

    :hover {
      color: ${({ theme, selected }) => !selected && theme.color.text_grayAd};
    }
  }

  div {
    display: block;
    color: ${({ theme, selected }) =>
      selected ? theme.color.text_white : theme.color.text_gray7b};
    font-size: 14px;
    text-decoration: none;
    font-weight: ${({ selected }) => (selected ? 700 : 500)};
    cursor: not-allowed;

    :hover {
      color: ${({ theme, selected }) => !selected && theme.color.text_grayAd};
    }
  }
`;

const LogoutButton = styled.button`
  min-width: 52px;
  height: 18px;
  background-color: inherit;
  color: ${({ theme }) => theme.color.text_gray7b};
  font-size: 14px;
  font-weight: 500;

  :hover {
    font-weight: 700;
    color: ${({ theme, selected }) => !selected && theme.color.text_grayAd};
  }
`;
