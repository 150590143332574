import React from "react";
import styled from "styled-components";

import useModalPagination from "hooks/useModalPagination";
import { Arrow, ArrowDouble } from "assets/icon/index";

export default function ModalPagination({
  pageData,
  pageNumber,
  setPageNumber,
  ...restProps
}) {
  const { totalData } = pageData;
  const {
    pageNumberList,
    handleClickNumber,
    handleDublePreButtonClick,
    handlePreButtonClick,
    handleNextButtonClick,
    handleDubleNextButtonClick,
  } = useModalPagination(pageData, pageNumber, setPageNumber);

  return (
    <Root {...restProps}>
      <Button onClick={handleDublePreButtonClick}>
        <CustomArrowDouble />
      </Button>
      <Button onClick={handlePreButtonClick}>
        <CustomArrow />
      </Button>
      <NumberButtonWrapper>
        {totalData !== 0 ? (
          pageNumberList.map((item, index) => {
            return (
              <NumberButton
                key={index}
                number={item}
                currentPage={pageNumber}
                onClick={() => handleClickNumber(item)}
              >
                {item}
              </NumberButton>
            );
          })
        ) : (
          <NumberButton>1</NumberButton>
        )}
      </NumberButtonWrapper>
      <Button {...restProps} onClick={handleNextButtonClick}>
        <CustomArrow right={true.toString()} />
      </Button>
      <Button {...restProps} onClick={handleDubleNextButtonClick}>
        <CustomArrowDouble right={true.toString()} />
      </Button>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;

  & + & {
    margin-left: 10px;
  }
`;

const NumberButtonWrapper = styled.div``;

const NumberButton = styled.button`
  width: 32px;
  height: 32px;
  font-size: 14px;
  background-color: inherit;
  font-weight: 500;
  color: ${({ theme, currentPage, number }) =>
    currentPage === number ? theme.color.black : theme.color.black_7B};

  :first-child {
    margin-left: 20px;
  }

  :last-child {
    margin-right: 20px;
  }
`;

const CustomArrowDouble = styled(ArrowDouble)`
  width: 12px;
  height: 12px;
  transform: ${({ right }) =>
    right === "true" ? "rotate(180deg)" : "rotate(0deg)"};
`;

const CustomArrow = styled(Arrow)`
  width: 12px;
  height: 12px;
  transform: ${({ right }) =>
    right === "true" ? "rotate(90deg)" : "rotate(-90deg)"};
`;
