import React, { forwardRef } from "react";
import styled from "styled-components";

const FormInput = forwardRef(
  ({ className, type, placeholder, maxLength, isError, register }, ref) => {
    return (
      <Input
        className={className}
        ref={ref}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        isError={isError}
        {...register}
      />
    );
  },
);

export default FormInput;

FormInput.displayName = "FormInput";

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 30px 8px 12px;
  border: 1px solid
    ${({ theme, isError }) =>
      isError ? theme.color.red_FF : theme.color.gray_DE};
  border-radius: 5px;

  ::placeholder {
    color: ${({ theme }) => theme.color.gray_AD};
  }
`;
