import {
  getReportCommunity,
  getReportProfile,
  getReportProfileDetail,
  putReportProfileProcess,
  getPresigned,
  getUnmasking,
  getReportIndianGame,
  getReportCommunityDetail,
  putReportCommunityProcess,
  getReportIndianGameDetail,
  putReportIndianGameProcess,
} from "api/index";
import { reportProcssType } from "assets/static/table";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  reportProfile,
  reportProfileFailure,
  reportProfileSuccess,
} from "store/reducer/reportProfile";
import {
  reportProfileDetail,
  reportProfileDetailFailure,
  reportProfileDetailSuccess,
  reportProfileUnmasking,
  reportProfileUnmaskingSuccess,
  reportProfileUnmaskingFailure,
} from "store/reducer/reportProfileDetail";
import {
  reportProfileProcess,
  reportProfileProcessFailure,
  reportProfileProcessSuccess,
} from "store/reducer/reportProfileProcess";
import {
  reportCommunity,
  reportCommunityFailure,
  reportCommunitySuccess,
} from "store/reducer/reportCommunity";
import {
  reportCommunityDetail,
  reportCommunityDetailFailure,
  reportCommunityDetailSuccess,
  reportCommunityUnmasking,
  reportCommunityUnmaskingSuccess,
  reportCommunityUnmaskingFailure,
} from "store/reducer/reportCommunityDetail";
import {
  reportCommunityProcess,
  reportCommunityProcessFailure,
  reportCommunityProcessSuccess,
} from "store/reducer/reportCommunityProcess";
import { toastShow } from "store/reducer/toast";
import {
  reportIndianGame,
  reportIndianGameSuccess,
  reportIndianGameFailure,
} from "store/reducer/reportIndianGame";
import {
  reportIndianGameDetail,
  reportIndianGameDetailSuccess,
  reportIndianGameDetailFailure,
  reportIndianGameDetailUnmasking,
  reportIndianGameDetailUnmaskingSuccess,
  reportIndianGameDetailUnmaskingFailure,
} from "store/reducer/reportIndianGameDetail";
import {
  reportIndianGameProcess,
  reportIndianGameProcessFailure,
  reportIndianGameProcessSuccess,
} from "store/reducer/reportIndianGameProcess";

function* reportProfileSaga(action) {
  try {
    const { data } = yield call(getReportProfile, action.payload);
    const { reports, pageInfo } = data;

    yield put({
      type: reportProfileSuccess,
      data: {
        pageInfo,
        reports: reports.map((item) => {
          return {
            requestId: item.report_id,
            created: item.created,
            fromName: item.from_name,
            fromNickname: item.from_nickname,
            fromUserNumber: item.from_user_number,
            processType: item.process_type
              ? reportProcssType[item.process_type]
              : "-",
            processed: item.processed,
            reportId: item.report_id,
            toEmail: item.to_email,
            toName: item.to_name,
            toNickname: item.to_nickname,
            toUserNumber: item.to_user_number,
            toPhone: item.to_phone,
            type: item.type,
          };
        }),
      },
    });
  } catch (err) {
    yield put({
      type: reportProfileFailure,
      error: err.response.data,
    });
  }
}

export function* watchReportProfile() {
  yield takeLatest(reportProfile, reportProfileSaga);
}

function* reportProfileDetailSaga(action) {
  try {
    const { data } = yield call(getReportProfileDetail, action.payload);
    const { from_user, report, to_user } = data;

    yield put({
      type: reportProfileDetailSuccess,
      data: { from_user, report, to_user },
    });
  } catch (err) {
    yield put({
      type: reportProfileDetailFailure,
      error: err.response.data,
    });
  }
}

export function* watchReportProfileDetail() {
  yield takeLatest(reportProfileDetail, reportProfileDetailSaga);
}

function* reportProfileProcessSaga(action) {
  try {
    yield call(putReportProfileProcess, action.payload);

    yield put({
      type: reportProfileProcessSuccess,
    });
    yield put({
      type: toastShow,
      data: { type: "success", text: "reportProfileProcessSuccess" },
    });
  } catch (err) {
    yield put({
      type: reportProfileProcessFailure,
      error: err.response.data,
    });

    alert("처리 중에 오류가 발생했습니다. 다시 시도해 주세요.");
  }
}

export function* watchReportProfileProcess() {
  yield takeLatest(reportProfileProcess, reportProfileProcessSaga);
}

function* reportCommunitySaga(action) {
  try {
    const { data } = yield call(getReportCommunity, action.payload);
    const { reports, pageInfo } = data;

    yield put({
      type: reportCommunitySuccess,
      data: {
        pageInfo,
        reports: reports.map((item) => {
          return {
            requestId: item.report_id,
            created: item.created,
            fromName: item.from_name,
            fromNickname: item.from_nickname,
            fromUserNumber: item.from_user_number,
            processType: item.process_type
              ? reportProcssType[item.process_type]
              : "-",
            processed: item.processed,
            reportId: item.report_id,
            toEmail: item.to_email,
            toName: item.to_name,
            toNickname: item.to_nickname,
            toUserNumber: item.to_user_number,
            toPhone: item.to_phone,
            type: item.type,
          };
        }),
      },
    });
  } catch (err) {
    yield put({
      type: reportCommunityFailure,
      error: err.response.data,
    });
  }
}

export function* watchReportCommunity() {
  yield takeLatest(reportCommunity, reportCommunitySaga);
}

function* reportCommunityDetailSaga(action) {
  try {
    const { data } = yield call(getReportCommunityDetail, action.payload);
    let { from_user, report, to_user } = data;
    let imageUrl = "";

    if (
      report.reported_content !== undefined &&
      report.reported_content.file_id !== null
    ) {
      const { file_id, s3_folder } = report.reported_content;
      const { data } = yield call(getPresigned, { file_id, s3_folder });

      imageUrl = data.presigned_url;
    }
    report = {
      ...report,
      admonishment: `${report.admonishment}회`,
      contentType: report.content_type === "post" ? "게시글 신고" : "댓글 신고",
      delete_content: report.delete_content === 1 ? "O" : "X",
      ...(report.reported_content !== undefined && {
        image: report.reported_content?.file_id === null ? null : imageUrl,
      }),
    };

    yield put({
      type: reportCommunityDetailSuccess,
      data: { from_user, report, to_user },
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: reportCommunityDetailFailure,
      error: err.response.data,
    });
  }
}

export function* watchReportCommunityDetail() {
  yield takeLatest(reportCommunityDetail, reportCommunityDetailSaga);
}

function* reportCommunityProcessSaga(action) {
  try {
    yield call(putReportCommunityProcess, action.payload);

    yield put({
      type: reportCommunityProcessSuccess,
    });
    yield put({
      type: toastShow,
      data: { type: "success", text: "reportProfileProcessSuccess" },
    });
  } catch (err) {
    yield put({
      type: reportCommunityProcessFailure,
      error: err.response.data,
    });

    alert("처리 중에 오류가 발생했습니다. 다시 시도해 주세요.");
  }
}

export function* watchReportCommunityProcess() {
  yield takeLatest(reportCommunityProcess, reportCommunityProcessSaga);
}

function* reportCommunityUnmaskingSaga(action) {
  try {
    const { data } = yield call(getUnmasking, action.payload);

    yield put({
      type: reportCommunityUnmaskingSuccess,
      data,
    });
  } catch (err) {
    yield put({
      type: reportCommunityUnmaskingFailure,
      error: err.response.data,
    });

    alert("처리 중에 오류가 발생했습니다. 다시 시도해 주세요.");
  }
}

export function* watchReportCommunityUnmasking() {
  yield takeLatest(reportCommunityUnmasking, reportCommunityUnmaskingSaga);
}

function* reportProfileUnmaskingSaga(action) {
  try {
    const { data } = yield call(getUnmasking, action.payload);

    yield put({
      type: reportProfileUnmaskingSuccess,
      data,
    });
  } catch (err) {
    yield put({
      type: reportProfileUnmaskingFailure,
      error: err.response.data,
    });

    alert("처리 중에 오류가 발생했습니다. 다시 시도해 주세요.");
  }
}

export function* watchReportProfileUnmasking() {
  yield takeLatest(reportProfileUnmasking, reportProfileUnmaskingSaga);
}

function* reportIndianGameSaga(action) {
  try {
    const { data } = yield call(getReportIndianGame, action.payload);
    const { reports, pageInfo } = data;

    yield put({
      type: reportIndianGameSuccess,
      data: {
        pageInfo,
        reports: reports.map((item) => {
          return {
            requestId: item.report_id,
            created: item.created,
            fromName: item.from_name,
            fromNickname: item.from_nickname,
            fromUserNumber: item.from_user_number,
            processType: item.process_type
              ? reportProcssType[item.process_type]
              : "-",
            processed: item.processed,
            reportId: item.report_id,
            toEmail: item.to_email,
            toName: item.to_name,
            toNickname: item.to_nickname,
            toUserNumber: item.to_user_number,
            toPhone: item.to_phone,
            type: item.type,
          };
        }),
      },
    });
  } catch (err) {
    yield put({
      type: reportIndianGameFailure,
      error: err.response.data,
    });
  }
}

export function* watchReportIndianGame() {
  yield takeLatest(reportIndianGame, reportIndianGameSaga);
}

function* reportIndianGameDetailSaga(action) {
  try {
    const { data } = yield call(getReportIndianGameDetail, action.payload);
    let { from_user, report, to_user } = data;
    let imageUrl = "";

    if (
      report.reported_content !== undefined &&
      report.reported_content.file_id !== null
    ) {
      const { file_id, s3_folder } = report.reported_content;
      const { data } = yield call(getPresigned, { file_id, s3_folder });

      imageUrl = data.presigned_url;
    }
    report = {
      ...report,
      admonishment: `${report.admonishment}회`,
      contentType: report.content_type === "post" ? "게시글 신고" : "댓글 신고",
      delete_content: report.delete_content === 1 ? "O" : "X",
      ...(report.reported_content !== undefined && {
        image: report.reported_content?.file_id === null ? null : imageUrl,
      }),
    };

    yield put({
      type: reportIndianGameDetailSuccess,
      data: { from_user, report, to_user },
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: reportIndianGameDetailFailure,
      error: err.response.data,
    });
  }
}

export function* watchReportIndianGameDetail() {
  yield takeLatest(reportIndianGameDetail, reportIndianGameDetailSaga);
}

function* reportIndianGameProcessSaga(action) {
  try {
    yield call(putReportIndianGameProcess, action.payload);

    yield put({
      type: reportIndianGameProcessSuccess,
    });
    yield put({
      type: toastShow,
      data: { type: "success", text: "reportProfileProcessSuccess" },
    });
  } catch (err) {
    yield put({
      type: reportIndianGameProcessFailure,
      error: err.response.data,
    });

    alert("처리 중에 오류가 발생했습니다. 다시 시도해 주세요.");
  }
}

export function* watchReportIndianGameProcess() {
  yield takeLatest(reportIndianGameProcess, reportIndianGameProcessSaga);
}

function* reportIndianGameDetailUnmaskingSaga(action) {
  try {
    const { data } = yield call(getUnmasking, action.payload);

    yield put({
      type: reportIndianGameDetailUnmaskingSuccess,
      data,
    });
  } catch (err) {
    yield put({
      type: reportIndianGameDetailUnmaskingFailure,
      error: err.response.data,
    });

    alert("처리 중에 오류가 발생했습니다. 다시 시도해 주세요.");
  }
}

export function* watchReportIndianGameDetailUnmasking() {
  yield takeLatest(
    reportIndianGameDetailUnmasking,
    reportIndianGameDetailUnmaskingSaga,
  );
}
