import React from "react";
import styled from "styled-components";

import { DetailPageSectionLayout } from "components/index";
import Table from "components/table/Table";

export default function MemberUserReportInformation({ list, data }) {
  return (
    <CustomDetailPageSectionLayout heading="신고 내역">
      <CustomTable data={data} list={list} />
    </CustomDetailPageSectionLayout>
  );
}

const CustomDetailPageSectionLayout = styled(DetailPageSectionLayout)`
  position: relative;
`;

const CustomTable = styled(Table)`
  grid-template-columns: 100%;
`;
