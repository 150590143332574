import React, { useState } from "react";
import styled from "styled-components";

import { Arrow } from "assets/icon/index";
import Image from "components/image/Image";
import useModal from "hooks/useModal";

export default function ImageView({ srcArray, render, ...restProps }) {
  const [selectImage, setSelectImage] = useState(0);
  const { handleModalOpen, isModalOpen, handleModalClose, handleDimClick } =
    useModal();

  const handleClickLeftButton = () => {
    if (selectImage === 0) return;
    setSelectImage(selectImage - 1);
  };

  const handleClickRightButton = () => {
    if (selectImage === srcArray.length - 1) return;
    setSelectImage(selectImage + 1);
  };

  const handleClickSmallImage = (index) => {
    setSelectImage(index);
  };

  return (
    <Root {...restProps}>
      <CustomImage
        src={srcArray[selectImage]}
        alt={"인증 요청사진"}
        onClick={handleModalOpen}
      />
      {isModalOpen &&
        render(handleModalClose, handleDimClick, selectImage, isModalOpen)}
      <SmallImageContainer>
        {srcArray.map((src, index) => {
          const select = selectImage === index;

          return (
            <SmallImageWrapper key={index}>
              <SmallImage
                src={src}
                key={index}
                alt={`인증 요청사진 미리보기 ${index + 1}번`}
                onClick={() => handleClickSmallImage(index)}
              />
              {select && <SelectImageColor />}
            </SmallImageWrapper>
          );
        })}
      </SmallImageContainer>
      <ButtonWrapper>
        <Button onClick={handleClickLeftButton} disabled={selectImage === 0}>
          <CustomArrow direction="left" />
        </Button>
        <Button
          onClick={handleClickRightButton}
          disabled={selectImage === srcArray.length - 1}
        >
          <CustomArrow direction="right" />
        </Button>
      </ButtonWrapper>
    </Root>
  );
}

const Root = styled.figure`
  width: 100%;
`;

const CustomImage = styled(Image)`
  width: 320px;
  height: 358px;
  margin: 0 auto;
  margin-bottom: 10px;
  display: block;
  cursor: zoom-in;
`;

const SmallImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  div + div {
    margin-left: 10px;
  }
`;

const SmallImageWrapper = styled.div`
  position: relative;
`;

const SmallImage = styled(Image)`
  width: 56px;
  height: 56px;
  cursor: pointer;
`;

const SelectImageColor = styled.div`
  width: 56px;
  height: 56px;
  background-color: rgba(0, 105, 249, 0.2);
  border: 2px solid ${({ theme }) => theme.color.blue_00};
  position: absolute;
  top: 0;
  left: -10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  opacity: ${({ disabled }) => disabled && 0.3};

  & + & {
    margin-left: 16px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const CustomArrow = styled(Arrow)`
  transform: ${({ direction }) =>
    direction === "left" ? "rotate(-90deg)" : "rotate(90deg)"};
`;
