import { createSlice } from "@reduxjs/toolkit";

export const logoutSlice = createSlice({
  name: "logout",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    logoutSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    logoutFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const { logout, logoutSuccess, logoutFailure } = logoutSlice.actions;

export default logoutSlice.reducer;
