import React from "react";
import styled from "styled-components";

export default function FormRadioButton({
  className,
  radioName,
  radioList,
  register,
}) {
  return (
    <RadioWrapper className={className}>
      {radioList &&
        Object.entries(radioList).map(([key, value], i) => {
          return (
            <RadioItem key={key + i}>
              <input
                type="radio"
                id={`${radioName}radio${i}`}
                value={key}
                {...register}
              />
              <Label htmlFor={`${radioName}radio${i}`}>{value}</Label>
            </RadioItem>
          );
        })}
    </RadioWrapper>
  );
}

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
  background-color: ${({ theme }) => theme.color.white};
`;

const Label = styled.label`
  margin-left: 8px;
  font-size: 14px;
  color: ${({ value, theme }) =>
    value ? theme.color.black : theme.color.gray_6C};
`;

const RadioItem = styled.div`
  display: flex;
`;
