import { createSlice } from "@reduxjs/toolkit";

export const passwordSlice = createSlice({
  name: "changeMemberAdminPassword",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    changeMemberAdminPassword: (state) => {
      state.isLoading = true;
    },
    changeMemberAdminPasswordSuccess: (state) => {
      state.isLoading = false;
      state.isDone = null;
    },
    changeMemberAdminPasswordFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    },
  },
});

export const {
  changeMemberAdminPassword,
  changeMemberAdminPasswordSuccess,
  changeMemberAdminPasswordFailure,
} = passwordSlice.actions;

export default passwordSlice.reducer;
