const getWidthPercent = (cellWidth) => {
  const width = cellWidth / 1080;
  return `${(width * 100).toFixed(4)}%`;
};

export const memberUserTable = {
  userNumber: "회원번호",
  email: "아이디",
  name: "이름",
  nickname: "닉네임",
  birthday: "생년월일",
  internalGrade: "관리 등급",
  gender: "성별",
  admonishment: "경고",
  userStatusId: "활동 상태",
  created: "가입일자",
  lastLogin: "최근 접속일시",
};
export const memberUserTableWidth = `${getWidthPercent(80)} ${getWidthPercent(
  105,
)} ${getWidthPercent(70)} ${getWidthPercent(145)} ${getWidthPercent(
  103,
)} ${getWidthPercent(92)} ${getWidthPercent(54)} ${getWidthPercent(
  54,
)} ${getWidthPercent(78)} ${getWidthPercent(100)} ${getWidthPercent(160)}`;

export const profileTable = {
  userNumber: "회원번호",
  userType: "구분",
  email: "아이디",
  name: "이름",
  nickname: "닉네임",
  birthday: "생년월일",
  gender: "성별",
  created: "가입일",
  status: "처리상태",
  profile_created: "신청일시",
};
export const profileTableWidth = `${getWidthPercent(90)} ${getWidthPercent(
  80,
)} ${getWidthPercent(137)} ${getWidthPercent(70)} ${getWidthPercent(
  153,
)} ${getWidthPercent(100)} ${getWidthPercent(60)} ${getWidthPercent(
  102,
)} ${getWidthPercent(90)} ${getWidthPercent(158)}`;

export const authTable = {
  userNumber: "회원번호",
  userType: "구분",
  tier: "결과",
  email: "아이디",
  name: "이름",
  nickname: "닉네임",
  birthday: "생년월일",
  gender: "성별",
  status: "처리상태",
  created: "인증 신청일시",
};
export const authTableWidth = `${getWidthPercent(90)} ${getWidthPercent(
  80,
)} ${getWidthPercent(60)} ${getWidthPercent(179)} ${getWidthPercent(
  70,
)} ${getWidthPercent(153)} ${getWidthPercent(100)} ${getWidthPercent(
  60,
)} ${getWidthPercent(90)} ${getWidthPercent(158)}`;

export const reportTable = {
  toUserNumber: "피신고자 회원번호",
  toNickname: "피신고자 닉네임",
  fromUserNumber: "신고자 회원번호",
  fromNickname: "신고자 닉네임",
  type: "신고 분류",
  created: "신고일시",
  processType: "결과",
  processed: "처리상태",
};
export const reportTableWidth = `${getWidthPercent(130)} ${getWidthPercent(
  150,
)} ${getWidthPercent(120)} ${getWidthPercent(150)} ${getWidthPercent(
  170,
)} ${getWidthPercent(165)} ${getWidthPercent(84)} ${getWidthPercent(71)}`;

export const memberUserProfileMatchHistoryModalTable = {
  no: "NO.",
  email: "아이디",
  name: "이름",
  nickname: "닉네임",
  type: "가입방식",
  age: "나이",
  internalGrade: "관리 등급",
  created: "매치일시",
};
export const memberUserProfileMatchHistoryModalTableWidth = `${getWidthPercent(
  70,
)} ${getWidthPercent(150)} ${getWidthPercent(120)} ${getWidthPercent(
  250,
)}  ${getWidthPercent(120)} ${getWidthPercent(80)} ${getWidthPercent(
  120,
)} ${getWidthPercent(200)}`;

export const memberUserBasicUserStatusTable = {
  no: "NO.",
  userStatusId: "상태",
  reason: "사유",
  created: "상태 변경일시",
};
export const memberUserBasicUserStatusTableWidth = `${getWidthPercent(
  70,
)} ${getWidthPercent(80)} ${getWidthPercent(260)} ${getWidthPercent(630)}`;

export const memberUserPaymentDetailTable = {
  type: "구분",
  price: "결제금액",
  stone: "스톤 갯수",
  pic: "CS 담당자",
  created: "거래일시",
};
export const memberUserPaymentDetailTableWidth = `${getWidthPercent(
  130,
)} ${getWidthPercent(130)} ${getWidthPercent(130)} ${getWidthPercent(
  130,
)} ${getWidthPercent(450)}`;
