import { createPageList } from "utils/helper/pagination";

const useModalPagination = (pageData, pageNumber, setPageNumber) => {
  const { currentPage, totalPages } = pageData;
  const pageNumberList = createPageList(currentPage, 5, totalPages);

  const handleClickNumber = (number) => {
    setPageNumber(number);
  };

  const handleDublePreButtonClick = () => {
    setPageNumber(1);
  };

  const handlePreButtonClick = () => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  };

  const handleNextButtonClick = () => {
    if (pageNumber === totalPages) return;
    setPageNumber(pageNumber + 1);
  };

  const handleDubleNextButtonClick = () => {
    setPageNumber(totalPages);
  };

  return {
    pageNumberList,
    handleClickNumber,
    handleDublePreButtonClick,
    handlePreButtonClick,
    handleNextButtonClick,
    handleDubleNextButtonClick,
  };
};

export default useModalPagination;
