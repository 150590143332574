import { ax } from "api/axios";

export const getMemberAmdinAreaList = async () => {
  return await ax.get(`/user/profile/area`);
};

export const getMemberAmdinBodyTypeList = async () => {
  return await ax.get(`/user/profile/bodytype`);
};

export const getMemberAmdinDrinkingList = async () => {
  return await ax.get(`/user/profile/drinking`);
};

export const getMemberAmdinPersonalityList = async () => {
  return await ax.get(`/user/profile/personality`);
};

export const getMemberAmdinReligionList = async () => {
  return await ax.get(`/user/profile/religion`);
};

export const getMemberAmdinSmokingList = async () => {
  return await ax.get(`/user/profile/smoking`);
};

export const getMemberAdminHobbyList = async () => {
  return await ax.get(`/user/profile/hobby`);
};

export const getMemberAdminIdealList = async () => {
  return await ax.get(`/user/profile/ideal`);
};
