import RadioCell from "components/cell/RadioCell";
import React from "react";
import styled from "styled-components";

export default function RadioTable({
  list,
  data,
  radioList,
  //   radioState,
  setRadioState,
  disabled,
  ...restProps
}) {
  return (
    <Root {...restProps}>
      {Object.entries(list).map(([key, value], index) => {
        return (
          <RadioCell
            key={index}
            title={value}
            radioList={radioList}
            // radioState={radioState}
            setRadioState={setRadioState}
            selectRadioState={data[key]}
            radioName={key}
            disabled={disabled}
          >
            {data[key]}
          </RadioCell>
        );
      })}
    </Root>
  );
}

const Root = styled.div`
  width: 1080px;
  border-top: 1px solid ${({ theme }) => theme.color.gray_E6};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
  background-color: ${({ theme }) => theme.color.gray_E6};
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 1px;
  row-gap: 1px;
`;
