import { put, delay, takeLatest } from "redux-saga/effects";

import { toastShow, toastHidden } from "store/reducer/toast";

function* toastSaga() {
  yield delay(3000);
  yield put({
    type: toastHidden,
  });
}

export function* watchToast() {
  yield takeLatest(toastShow, toastSaga);
}
