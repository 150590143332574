import React from "react";
import styled from "styled-components";

import useModal from "hooks/useModal";

export default function DetailModalButton({ render, children, ...restProps }) {
  const { isModalOpen, handleModalOpen, handleModalClose, handleDimClick } =
    useModal();

  return (
    <>
      <Root {...restProps} onClick={handleModalOpen}>
        {children}
      </Root>
      {isModalOpen && render(handleModalClose, handleDimClick)}
    </>
  );
}

const Root = styled.button`
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.blue_00};
`;
