import React from "react";
import styled from "styled-components";

import { DimLayout } from "components/index";
import PortalModal from "./PortalModal";

export default function CloseModal({ modalRef, handleDimClick }) {
  return (
    <PortalModal>
      <DimLayout handleDimClick={handleDimClick}>
        <Root ref={modalRef} tabIndex="-1">
          <input />
          <input />
          <input />
          <input />
        </Root>
      </DimLayout>
    </PortalModal>
  );
}

const Root = styled.article`
  width: 500px;
  height: 500px;
  background-color: white;
`;
