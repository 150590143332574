import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { Heading, Pagination, ListTable } from "components/index";
import useListTable from "hooks/useListTable";

export default function AuthTable({
  list,
  width,
  data,
  pageData,
  ...restProps
}) {
  const { pathname } = useLocation();
  const { handleGoToDetail } = useListTable(
    pathname.split("/").length === 3
      ? `${pathname}/${pathname.split("/")[2]}detail`
      : `${pathname}/${pathname.split("/")[3]}detail`,
  );

  return (
    <Root {...restProps}>
      <CustomHeading as="h3">요청 목록</CustomHeading>
      <TableWrapper>
        <ListTable
          list={list}
          width={width}
          data={data}
          handleGoToDetail={handleGoToDetail}
        />
      </TableWrapper>
      <PaginationWrapper>
        <Pagination pageData={pageData} />
      </PaginationWrapper>
    </Root>
  );
}

const Root = styled.div`
  min-width: 1140px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.12);
`;

const CustomHeading = styled(Heading)`
  padding: 17px 0 17px 30px;
  font-size: 18px;
  font-weight: 500;
`;

const TableWrapper = styled.div`
  padding: 30px 30px 40px 30px;
  border-top: 1px solid ${({ theme }) => theme.color.gray_E6};
`;

const PaginationWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: end;
  padding-right: 30px;
  background-color: ${({ theme }) => theme.color.gray_F5};
`;
