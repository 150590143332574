import { call, takeLatest, put } from "redux-saga/effects";
import {
  getMemberUserDetail,
  postUserAddFreeStone,
  putMemberUserSuspend,
  getMemberUserMatchHistory,
  getMemberUserPaymentDetail,
  getMemberUserSuspendReason,
  getMemberUserUserStatusIdDetail,
  putMemberUserEditInternalgrade,
  putMemberUserModifyCertificate,
} from "api/index";
import {
  memberUserSuspend,
  memberUserSuspendFailure,
  memberUserSuspendSuccess,
  memberUserSuspendReset,
} from "store/reducer/memberUserSuspend";
import {
  memberUserSuspendReason,
  memberUserSuspendReasonSuccess,
  memberUserSuspendReasonFailure,
} from "store/reducer/memberUserSuspendReason";
import {
  memberUserDetail,
  memberUserDetailFailure,
  memberUserDetailSuccess,
  memberUserDetailEditInternalGrade,
  memberUserDetailEditInternalGradeFailure,
  memberUserDetailEditInternalGradeSuccess,
} from "store/reducer/memberUserDetail";
import {
  memberUserDetailMatchHistory,
  memberUserDetailMatchHistoryFailure,
  memberUserDetailMatchHistorySuccess,
} from "store/reducer/memberUserDetailMatchHistory";
import {
  memberUserDetailPaymentDetail,
  memberUserDetailPaymentDetailFailure,
  memberUserDetailPaymentDetailSuccess,
} from "store/reducer/memberUserDetailPaymentDetail";
import {
  memberUserDetailUserStatus,
  memberUserDetailUserStatusFailure,
  memberUserDetailUserStatusSuccess,
} from "store/reducer/memberUserDetailUserStatus";
import {
  memberUserAddFreeStone,
  memberUserAddFreeStoneFailure,
  memberUserAddFreeStoneSuccess,
} from "store/reducer/memberUserFreeStone";
import { toastShow } from "store/reducer/toast";
import { userStatusIdFormatter } from "utils/helper/formatter";
import { numberComma } from "utils/helper/index";
import {
  memberUserModifyCertificate,
  memberUserModifyCertificateFailure,
  memberUserModifyCertificateSuccess,
} from "store/reducer/memberUserModifyCertificate";

function* memberUserDetailSaga(action) {
  try {
    const { data } = yield call(getMemberUserDetail, action.payload);
    yield put({ type: memberUserDetailSuccess, data: data });
  } catch (err) {
    yield put({ type: memberUserDetailFailure, error: err.response.data });
  }
}

export function* watchMemberUserDetail() {
  yield takeLatest(memberUserDetail, memberUserDetailSaga);
}

function* memberUserDetailMatchHistorySaga(action) {
  try {
    const {
      data: { matchs, pageInfo },
    } = yield call(getMemberUserMatchHistory, action.payload);
    yield put({
      type: memberUserDetailMatchHistorySuccess,
      matchs: matchs.map((item, index) => {
        return {
          ...item,
          no:
            pageInfo.totalData -
            (pageInfo.currentPage - 1) * pageInfo.dataPerPage -
            index,
          internalGrade: item.internal_grade,
        };
      }),
      pageInfo: pageInfo,
    });
  } catch (err) {
    yield put({
      type: memberUserDetailMatchHistoryFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberUserDetailMatchHistory() {
  yield takeLatest(
    memberUserDetailMatchHistory,
    memberUserDetailMatchHistorySaga,
  );
}

function* memberUserSuspendSaga(action) {
  const { status } = action.payload.data;

  try {
    const { data } = yield call(putMemberUserSuspend, action.payload);

    yield put({
      type: memberUserSuspendSuccess,
    });
    yield put({
      type: memberUserSuspendReset,
    });
    if (status === 4) {
      yield put({
        type: toastShow,
        data: { type: "success", text: "memberUserSuspendSuccess" },
      });
    } else {
      yield put({
        type: toastShow,
        data: { type: "success", text: "memberUserUnSuspendSuccess" },
      });
    }
    const res = yield call(getMemberUserDetail, data.user_id);
    yield put({ type: memberUserDetailSuccess, data: res.data });
  } catch (err) {
    yield put({
      type: memberUserSuspendFailure,
      error: err.response.data,
    });
    if (status === 4) {
      yield put({
        type: toastShow,
        data: { type: "fail", text: "memberUserSuspendFail" },
      });
    } else {
      yield put({
        type: toastShow,
        data: { type: "fail", text: "memberUserSuspendSuccess" },
      });
    }
  }
}

export function* watchMemberUserSuspend() {
  yield takeLatest(memberUserSuspend, memberUserSuspendSaga);
}
function* memberUserSuspendReasonSaga(action) {
  try {
    const { data } = yield call(getMemberUserSuspendReason, action.payload);

    yield put({
      type: memberUserSuspendReasonSuccess,
      data: data,
    });
  } catch (err) {
    yield put({
      type: memberUserSuspendReasonFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberUserSuspendReason() {
  yield takeLatest(memberUserSuspendReason, memberUserSuspendReasonSaga);
}

function* memberUserDetailPaymentDetailSaga(action) {
  try {
    const res = yield call(getMemberUserPaymentDetail, action.payload);
    const { payments, pageInfo } = res.data;

    yield put({
      type: memberUserDetailPaymentDetailSuccess,
      data: {
        payments: payments.map((item) => {
          const obj = {};
          obj.price = item.price ? `${numberComma(item.price)}원` : "-";
          obj.stone = numberComma(item.stone);
          obj.created = item.created;
          obj.pic = item.pic ? item.pic : "-";
          obj.type = item.type;
          return obj;
        }),
        pageInfo,
      },
    });
  } catch (err) {
    yield put({
      type: memberUserDetailPaymentDetailFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberUserDetailPaymentDetail() {
  yield takeLatest(
    memberUserDetailPaymentDetail,
    memberUserDetailPaymentDetailSaga,
  );
}

function* memberUserDetailUserStatusSaga(action) {
  try {
    const {
      data: { status, pageInfo },
    } = yield call(getMemberUserUserStatusIdDetail, action.payload);
    yield put({
      type: memberUserDetailUserStatusSuccess,
      status: status.map((item, index) => {
        return {
          ...item,
          reason: item.reason,
          userStatusId: userStatusIdFormatter(item.user_status_id),
          no:
            pageInfo.totalData -
            (pageInfo.currentPage - 1) * pageInfo.dataPerPage -
            index,
        };
      }),
      pageInfo: pageInfo,
    });
  } catch (err) {
    yield put({
      type: memberUserDetailUserStatusFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberUserDetailUserStatus() {
  yield takeLatest(memberUserDetailUserStatus, memberUserDetailUserStatusSaga);
}

function* memberUserDetailEditInternalGradeSaga(action) {
  try {
    yield call(putMemberUserEditInternalgrade, action.payload);

    yield put({
      type: memberUserDetailEditInternalGradeSuccess,
      data: action.payload.data.internal_grade,
    });
  } catch (err) {
    yield put({
      type: memberUserDetailEditInternalGradeFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberUserDetailEditInternalGrade() {
  yield takeLatest(
    memberUserDetailEditInternalGrade,
    memberUserDetailEditInternalGradeSaga,
  );
}

function* memberUserFreeStoneSaga(action) {
  try {
    yield call(postUserAddFreeStone, action.payload);

    yield put({
      type: memberUserAddFreeStoneSuccess,
    });
    const { data } = yield call(getMemberUserDetail, action.payload.id);
    yield put({ type: memberUserDetailSuccess, data: data });
    yield put({
      type: toastShow,
      data: { type: "success", text: "addMemberUserFreeStoneSuccess" },
    });
  } catch (err) {
    yield put({
      type: memberUserAddFreeStoneFailure,
      error: err.response.data,
    });
    yield put({
      type: toastShow,
      data: { type: "fail", text: "addMemberUserFreeStoneFail" },
    });
  }
}

export function* watchMemberUserFreeStone() {
  yield takeLatest(memberUserAddFreeStone, memberUserFreeStoneSaga);
}
function* memberUserModifyCertificateSaga(action) {
  const { id } = action.payload;
  try {
    yield call(putMemberUserModifyCertificate, action.payload);

    yield put({
      type: memberUserModifyCertificateSuccess,
    });
    const { data } = yield call(getMemberUserDetail, id);
    yield put({ type: memberUserDetailSuccess, data: data });
    yield put({
      type: toastShow,
      data: { type: "success", text: "memberUserModifyCertificateSuccess" },
    });
  } catch (err) {
    yield put({
      type: memberUserModifyCertificateFailure,
      error: err.response.data,
    });
    yield put({
      type: toastShow,
      data: { type: "fail", text: "memberUserModifyCertificateFail" },
    });
  }
}

export function* watchMemberUserCertificate() {
  yield takeLatest(
    memberUserModifyCertificate,
    memberUserModifyCertificateSaga,
  );
}
