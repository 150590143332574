import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  Heading,
  DetailModalButton,
  MemberUserSuspendModal,
  MemberUserClearSuspendModal,
} from "components/index";

export default function MemberUserCustomHeading() {
  const { userStatusId } = useSelector(
    (state) => state.memberUserDetail.basicInformation,
  );

  return (
    <Root>
      <CustomHeading as="h2">일반회원 상세</CustomHeading>
      {userStatusId === "정지" ? (
        <CustomSuspendButton
          render={(handleModalClose, handleDimClick) => {
            return (
              <MemberUserClearSuspendModal
                handleModalClose={handleModalClose}
                handleDimClick={handleDimClick}
              />
            );
          }}
        >
          이용정지 해제
        </CustomSuspendButton>
      ) : (
        <CustomSuspendButton
          render={(handleModalClose, handleDimClick) => {
            return (
              <MemberUserSuspendModal
                handleModalClose={handleModalClose}
                handleDimClick={handleDimClick}
              />
            );
          }}
        >
          이용정지
        </CustomSuspendButton>
      )}
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 13px 0;
`;

const CustomHeading = styled(Heading)`
  font-size: 21px;
  font-weight: ${({ as }) => (as === "h2" ? 700 : 500)};
  color: ${({ theme }) => theme.color.black};
`;

const CustomSuspendButton = styled(DetailModalButton)`
  width: 96px;
  height: 40px;
  color: ${({ theme }) => theme.color.gray_6C};
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray_AD};
  border-radius: 5px;
`;
