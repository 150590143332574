import React from "react";
import styled from "styled-components";

import useModal from "hooks/useModal";

export default function ModalOpenButton({ render, children, ...restProps }) {
  const { isModalOpen, handleModalOpen, handleModalClose, handleDimClick } =
    useModal();
  return (
    <>
      <Root onClick={handleModalOpen} {...restProps}>
        {children}
      </Root>
      {isModalOpen && render(handleModalClose, handleDimClick)}
    </>
  );
}

const Root = styled.button``;
