import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { InputCell } from "components/index";

export default function IdCell({
  state,
  setState,
  error,
  handleChangeInput,
  handleBlurError,
  ...restProps
}) {
  const { isDuplicate } = useSelector(
    (state) => state.memberAdminCreate.duplicateEmail,
  );

  return (
    <Root>
      <CustomInputCell
        type="email"
        onChange={handleChangeInput}
        onBlur={handleBlurError}
        state={state}
        setState={setState}
        errorState={error.state || isDuplicate}
        {...restProps}
      />
    </Root>
  );
}

const Root = styled.div`
  position: relative;
`;

const CustomInputCell = styled(InputCell)``;
