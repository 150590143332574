import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

export default function Footer() {
  const isFoldNav = useSelector((state) => state.common.isFoldNav);
  return <Root isFoldNav={isFoldNav}>Footer</Root>;
}

const Root = styled.footer.attrs(({ isFoldNav, theme }) => ({
  width: isFoldNav
    ? `calc(100% - ${theme.size.NAV_FOLD_WIDTH})`
    : `calc(100% - ${theme.size.NAV_UNFOLD_WIDTH})`,
}))`
  width: ${({ width }) => width};
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  height: ${({ theme }) => theme.size.FOOTER_HEIGHT};
  border-top: 1px solid ${({ theme }) => theme.color.border};
  padding: 0 ${({ theme }) => theme.size.LAYOUT_PADDING};
  font-size: 14px;
  color: ${({ theme }) => theme.color.text_gray_dark};
  background-color: ${({ theme }) => theme.color.bg_primary};
  z-index: ${({ theme }) => theme.zIndex.HEADER_FOOTER};
`;
