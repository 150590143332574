import React from "react";
import styled from "styled-components";

export default function AuthDetailPageRootLayout({ children }) {
  return <Root>{children}</Root>;
}

const Root = styled.section`
  width: 1140px;
  min-height: 1070px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.color.bg_primary};
  padding-bottom: 50px;
`;
