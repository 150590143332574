import { ax } from "api/axios";

export const putMemberUserSuspend = async (payload) => {
  const { userID, data } = payload;
  return await ax.put(`/admin/user/${userID}/status`, data);
};

export const getMemberUserDetail = async (payload) => {
  return await ax.get(`/admin/user/${payload}`);
};

export const getMemberUserUserStatusIdDetail = async (payload) => {
  const { id, pageNumber } = payload;
  return await ax.get(`/admin/user/${id}/status?page=${pageNumber}`);
};

export const getMemberUserMatchHistory = async (payload) => {
  const { id, pageNumber } = payload;
  const params = {
    ...(pageNumber && { page: pageNumber }),
  };
  return await ax.get(`/admin/user/${id}/match`, { params });
};

export const getMemberUserPaymentDetail = async (payload) => {
  const { id, type, pageNumber } = payload;
  const params = {
    ...(type && { type }),
    ...(pageNumber && { page: pageNumber }),
  };
  return await ax.get(`/admin/user/${id}/payment`, { params });
};

export const putMemberUserEditInternalgrade = async (payload) => {
  const { data, id } = payload;
  return await ax.put(`/admin/user/${id}/internalgrade`, data);
};

export const getMemberUserSuspendReason = async (payload) => {
  const { id } = payload;

  return await ax.get(`/admin/user/${id}/suspend`);
};

export const putMemberUserModifyCertificate = async (payload) => {
  const { id, badges } = payload;
  return await ax.put(`/admin/user/${id}/certificate`, { badges });
};
