import { createSlice } from "@reduxjs/toolkit";

export const authProfileDetailRejectSlice = createSlice({
  name: "authProfileDetailReject",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    authProfileDetailReject: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    authProfileDetailRejectSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    authProfileDetailRejectFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
    authProfileDetailRejectReset: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = null;
    },
  },
});

export const {
  authProfileDetailReject,
  authProfileDetailRejectSuccess,
  authProfileDetailRejectFailure,
  authProfileDetailRejectReset,
} = authProfileDetailRejectSlice.actions;

export default authProfileDetailRejectSlice.reducer;
