import { ax } from "api/axios";

export const getReportProfile = async (payload) => {
  return await ax.get("/admin/report/profile", {
    params: payload,
  });
};

export const getReportProfileDetail = async (payload) => {
  const { id } = payload;
  return await ax.get(`/admin/report/profile/${id}`);
};

export const putReportProfileProcess = async (payload) => {
  const { id, picState, reasonState, submitType, dayState } = payload;

  const day = (dayState) => {
    if (dayState === 0) {
      return 3;
    } else if (dayState === 1) {
      return 7;
    } else if (dayState === 2) {
      return 14;
    } else if (dayState === 3) {
      return 30;
    }
  };

  if (submitType === 3) {
    return await ax.put(`/admin/report/profile/${id}`, {
      pic: picState,
      reason: reasonState,
      process_type: submitType,
      suspend_days: day(dayState),
    });
  } else {
    return await ax.put(`/admin/report/profile/${id}`, {
      pic: picState,
      reason: reasonState,
      process_type: submitType,
    });
  }
};

export const getReportCommunity = async (payload) => {
  return await ax.get("/admin/report/community", {
    params: payload,
  });
};

export const getReportCommunityDetail = async (payload) => {
  const { id } = payload;
  return await ax.get(`/admin/report/community/${id}`);
};

export const putReportCommunityProcess = async (payload) => {
  const { id, picState, reasonState, submitType, dayState, deletePostState } =
    payload;

  const day = (dayState) => {
    if (dayState === 0) {
      return 3;
    } else if (dayState === 1) {
      return 7;
    } else if (dayState === 2) {
      return 14;
    } else if (dayState === 3) {
      return 30;
    }
  };

  if (submitType === 3) {
    return await ax.put(`/admin/report/community/${id}`, {
      pic: picState,
      reason: reasonState,
      process_type: submitType,
      suspend_days: day(dayState),
      delete_content: deletePostState === 1 ? 0 : 1,
    });
  } else {
    return await ax.put(`/admin/report/community/${id}`, {
      pic: picState,
      reason: reasonState,
      process_type: submitType,
      delete_content: deletePostState === 1 ? 0 : 1,
    });
  }
};

export const getReportIndianGame = async (payload) => {
  return await ax.get("/admin/report/indian", { params: payload });
};

export const getReportIndianGameDetail = async (payload) => {
  const { id } = payload;
  return await ax.get(`/admin/report/indian/${id}`);
};

export const putReportIndianGameProcess = async (payload) => {
  const { id, picState, reasonState, submitType, dayState } = payload;

  const day = (dayState) => {
    if (dayState === 0) {
      return 3;
    } else if (dayState === 1) {
      return 7;
    } else if (dayState === 2) {
      return 14;
    } else if (dayState === 3) {
      return 30;
    }
  };

  if (submitType === 3) {
    return await ax.put(`/admin/report/indian/${id}`, {
      pic: picState,
      reason: reasonState,
      process_type: submitType,
      suspend_days: day(dayState),
    });
  } else {
    return await ax.put(`/admin/report/indian/${id}`, {
      pic: picState,
      reason: reasonState,
      process_type: submitType,
    });
  }
};
