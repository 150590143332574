import { createSlice } from "@reduxjs/toolkit";

export const memberUserSuspendSlice = createSlice({
  name: "memberUserSuspend",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    memberUserSuspend: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    memberUserSuspendSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    memberUserSuspendFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
    memberUserSuspendReset: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = null;
    },
  },
});

export const {
  memberUserSuspend,
  memberUserSuspendSuccess,
  memberUserSuspendFailure,
  memberUserSuspendReset,
} = memberUserSuspendSlice.actions;

export default memberUserSuspendSlice.reducer;
