import { PATH } from "utils/constants";

export const headerList = [
  {
    path: PATH.memberUser.fullPath,
    content: "회원 관리",
    assert: ["master", "user", "admin_user"],
    navPathArray: [
      PATH.memberUser.fullPath,
      PATH.memberUser.fullPath,
      PATH.memberAdmin.fullPath,
    ],
  },
  {
    path: PATH.authProfile.fullPath,
    content: "인증 관리",
    assert: ["master", "profile", "certificate"],
    navPathArray: [
      PATH.authProfile.fullPath,
      PATH.authProfile.fullPath,
      PATH.authAppearance.fullPath,
    ],
  },
  {
    path: PATH.reportProfile.fullPath,
    content: "신고 관리",
    assert: ["master", "profile_report", "community_report"],
    navPathArray: [
      PATH.reportProfile.fullPath,
      PATH.reportProfile.fullPath,
      PATH.reportCommunity.fullPath,
    ],
  },
  {
    path: PATH.payment.fullPath,
    content: "결제 관리",
    assert: [],
    navPathArray: [],
  },
  {
    path: PATH.common.fullPath,
    content: "공통 관리",
    assert: [],
    navPathArray: [],
  },
];
