import React from "react";
import styled from "styled-components";

import {
  DetailModalButton,
  DetailPageModal,
  DetailPageSectionLayout,
  Table,
} from "components/index";

import MatchHistoryDetail from "../MemberUser/MemberUserDetail/containers/MatchHistoryDetail";

export default function MemberProfileInformation({ list, data }) {
  return (
    <CustomDetailPageSectionLayout heading="프로필 정보">
      <MatchHistory
        render={(handleModalClose, handleDimClick) => {
          return (
            <DetailPageModal
              heading="매치 히스토리"
              handleModalClose={handleModalClose}
              handleDimClick={handleDimClick}
            >
              <MatchHistoryDetail />
            </DetailPageModal>
          );
        }}
      >
        매치 히스토리
      </MatchHistory>
      <Table list={list} data={data} />
    </CustomDetailPageSectionLayout>
  );
}

const CustomDetailPageSectionLayout = styled(DetailPageSectionLayout)`
  position: relative;
`;

const MatchHistory = styled(DetailModalButton)`
  position: absolute;
  top: 11px;
  right: 30px;
  color: ${({ theme }) => theme.color.blue_00};
  font-size: 14px;
`;
