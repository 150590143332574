import React from "react";
import styled from "styled-components";

import { Dropdown } from "components/index";

export default function DropdownCells({
  title,
  essential,
  dropdownCount,
  list,
  state,
  setState,
  stateName,
  errorState,
  ...restProps
}) {
  const dropdownArray = new Array(dropdownCount).fill(null);
  const validationArray = new Array(dropdownCount).fill(false);

  return (
    <Root {...restProps}>
      <TitleWrapper>
        <Title>
          {title}
          {essential && <Essential>*</Essential>}
        </Title>
      </TitleWrapper>
      <ContentWrapper>
        {dropdownArray.map((_, dropdownIndex) => {
          return (
            <CustomDropdown
              key={dropdownIndex}
              selectLabel={list?.[state[dropdownIndex]]}
              isValidation={validationArray[dropdownIndex]}
              errorState={errorState[dropdownIndex]}
            >
              {list &&
                Object.entries(list).map(([key, value], index) => {
                  return (
                    <Dropdown.Option
                      key={index}
                      state={state}
                      setDropdownState={setState}
                      name={stateName}
                      label={key}
                      value={value}
                      dropdownCount={dropdownCount}
                      index={dropdownIndex}
                    >
                      {value}
                    </Dropdown.Option>
                  );
                })}
            </CustomDropdown>
          );
        })}
      </ContentWrapper>
    </Root>
  );
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 190px 1fr;
  column-gap: 1px;
  row-gap: 1px;
`;

const TitleWrapper = styled.div`
  height: 48px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray_F5};
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_6C};
`;

const Title = styled.span`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const Essential = styled.span`
  margin-left: 2px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.red_FF};
`;

const ContentWrapper = styled.div`
  padding: 7px 0px 7px 20px;
  display: flex;
  background-color: ${({ theme }) => theme.color.white};
`;

const CustomDropdown = styled(Dropdown)`
  width: 156px;
  height: 34px;

  & > button {
    border: ${({ theme, errorState }) =>
      errorState && ` 1px solid ${theme.color.red_FF}`};
  }

  & + & {
    margin-left: 8px;
  }
`;
