import React from "react";
import styled from "styled-components";

import Dropdown from "./Dropdown";

export default function LabelDropdown({ className, label, ...restProps }) {
  return (
    <Root className={className}>
      {label && <Label>{label}</Label>}
      <CustomDropdown {...restProps} />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    ::after {
      content: "";
      height: 24px;
      border-right: 1px solid ${({ theme }) => theme.color.border_grayDe};
      margin: 0 28px;
    }
  }
`;

const Label = styled.label`
  font-size: 14px;
  margin-right: 10px;
  color: ${({ theme }) => theme.color.text_gray5b};
`;

const CustomDropdown = styled(Dropdown)``;
