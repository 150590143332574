export const appearanceRejectReason = {
  0: "낮은 화질로 식별 불가",
  1: "부적절한 사진 사용",
  2: "개인정보 노출",
  3: "과한 왜곡이 있는 사진",
  4: "본인이 아닌 사진 사용",
  5: "마스크, 뒷모습 등으로 본인 식별 불가",
  6: "앱내 오류 발생",
  7: "제한적인 사진 구도",
  8: "얼굴이 잘 보이지 않는 사진",
};

export const bodyRejectReason = {
  0: "낮은 화질로 식별 불가",
  1: "부적절한 사진 사용",
  2: "개인정보 노출",
  3: "과한 왜곡이 있는 사진",
  4: "본인이 아닌 사진 사용",
  5: "업로드 시 요구되는 배치 및 사항 미충족",
  6: "마스크, 뒷모습 등으로 본인 식별 불가",
  7: "앱내 오류 발생",
  8: "제한적인 사진 구도",
  9: "본인임을 확인할 수 있는 정보가 없음",
  10: `얼굴을 "Face Area"에 맞춰주세요`,
};

export const educationRejectReason = {
  0: "낮은 화질로 식별 불가",
  1: "부적절한 사진 사용",
  2: "개인정보 노출",
  3: "합성이 의심되는 사진",
  4: "본인이 아닌 사람의 서류 사용",
  5: "VVS 기준 미충족",
  6: "정보(이름 생년월일 등)부족",
  7: "앱내 오류 발생",
  8: "제한적인 사진 구도",
  9: "석박사가 아닌 학사 기준 요청",
};

export const occupationRejectReason = {
  0: "낮은 화질로 식별 불가",
  1: "부적절한 사진 사용",
  2: "개인정보 노출",
  3: "합성이 의심되는 사진",
  4: "본인이 아닌 사람의 서류 사용",
  5: "VVS 기준 미충족",
  6: "정보(이름 생년월일 등)부족",
  7: "앱내 오류 발생",
  8: "제한적인 사진 구도",
  9: "추가 서류 요청(직무, 연봉)",
};

export const incomeRejectReason = {
  0: "낮은 화질로 식별 불가",
  1: "부적절한 사진 사용",
  2: "개인정보 노출",
  3: "합성이 의심되는 사진",
  4: "본인이 아닌 사람의 서류 사용",
  5: "VVS 기준 미충족",
  6: "정보(이름 생년월일 등)부족",
  7: "앱내 오류 발생",
  8: "제한적인 사진 구도",
  9: "최신 정보 기재 서류 요청",
};

export const profileRejectReason = {
  0: "낮은 화질로 식별 불가",
  1: "부적절한 사진 사용",
  2: "개인정보 노출",
  3: "프로필 정보 내 부적절한 문구 사용",
  4: "본인이 아닌 사진 사용",
  5: "과한 왜곡이 있는 사진",
  6: "마스크, 뒷모습 등으로 본인 식별 불가",
  7: "앱내 오류 발생",
  8: "제한적인 사진 구도",
  9: "얼굴이 잘 보이지 않는 사진",
};

export const profileImageRejectReason = {
  0: "낮은 화질로 식별 불가",
  1: "부적절한 사진 사용",
  2: "본인이 아닌 사진 사용",
  3: "개인정보 노출",
  4: "과한 왜곡이 있는 사진",
  5: "마스크, 뒷모습 등으로 본인 식별 불가",
  6: "앱내 오류 발생",
  7: "제한적인 사진 구도",
  8: "프로필 정보 내 부적절한 문구 사용",
  9: "얼굴이 잘 보이지 않는 사진",
};

export const authStage = {
  1: "VS",
  2: "VVS",
};

export const memberUserAuthInformationStage = {
  1: "VVS",
  2: "VS",
  3: "없음",
};

export const memberUserSuspendDay = {
  3: "3일",
  7: "7일",
  14: "14일",
  30: "1달",
  36500: "영구정지",
};

export const reportType = {
  1: "반려",
  2: "경고",
  3: "정지",
  4: "영구정지",
};

export const deletePost = {
  1: "O",
  2: "X",
};
