import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { Heading, RefreshButton } from "components/index";
import MemberTable from "../containers/MemberTable";
import { PATH } from "utils/constants";
import { memberAdmin } from "store/reducer/memberAdmin";
import { memberAdminDetailReset } from "store/reducer/memberAdminDetail";
import SearchFilter from "components/common/SearchFilter";
import {
  adminUserStatusId,
  admonishment,
  gender,
  personalInfo,
} from "assets/static/dropdown";
import useDropdown from "hooks/useDropdown";
import useFilter from "hooks/useFilter";

export default function MemberAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { users, pageData } = useSelector((state) => state.memberAdmin);
  const [selectGender, handleClickGender] = useDropdown("", "gender");
  const [selectUserStatusId, handleChangeUserStatusId] = useDropdown(
    "",
    "user_status_id",
  );
  const [selectAdmonishment, handleChangeAdmonishment] = useDropdown(
    "",
    "admonishment",
  );
  const [selectSearchInputFilter, handleChangeSearchInputFilter] = useDropdown(
    "nickname",
    "personalInfo",
  );
  const {
    inputValue,
    handleFilterSubmit,
    handleChangeInputValue,
    handleResetSubmit,
  } = useFilter(
    {
      gender: selectGender,
      user_status_id: selectUserStatusId,
      admonishment: selectAdmonishment,
      inputFilter: selectSearchInputFilter,
    },
    memberAdmin({ type: "admin" }),
  );

  const handleClickAddMemberAdmin = () => {
    navigate(`${PATH.memberAdminCreate.fullPath}`);
  };

  useEffect(() => {
    dispatch(memberAdminDetailReset());
  }, []);

  useEffect(() => {
    const filterObj = { ...Object.fromEntries(searchParams) };
    dispatch(memberAdmin({ ...filterObj, type: "admin" }));
  }, [searchParams]);

  return (
    <Root>
      <HeadingWrapper>
        <CustomHeading as="h2">어드민 회원 관리</CustomHeading>
        <AddMemberAdminButton onClick={handleClickAddMemberAdmin}>
          + 회원 추가
        </AddMemberAdminButton>
      </HeadingWrapper>
      <SearchFilter
        topChildren={() => {
          return (
            <SearchFilter.FlexWrapper>
              <SearchFilter.LabelDropdown
                label="성별"
                selectOption={selectGender}
                optionObj={gender}
                handleClickOption={handleClickGender}
              />
              <SearchFilter.LabelDropdown
                label="활동 상태"
                selectOption={selectUserStatusId}
                optionObj={adminUserStatusId}
                handleClickOption={handleChangeUserStatusId}
              />
              <SearchFilter.LastLabelDropdown
                label="경고 횟수"
                selectOption={selectAdmonishment}
                optionObj={admonishment}
                handleClickOption={handleChangeAdmonishment}
              />
            </SearchFilter.FlexWrapper>
          );
        }}
        bottomChildren={() => {
          return (
            <>
              <SearchFilter.FlexWrapper>
                <SearchFilter.InputFilter
                  selectOption={selectSearchInputFilter}
                  optionObj={personalInfo}
                  handleClickOption={handleChangeSearchInputFilter}
                />
                <SearchFilter.Input
                  value={inputValue}
                  handleChangeInputValue={handleChangeInputValue}
                  handleSubmit={handleFilterSubmit}
                />
              </SearchFilter.FlexWrapper>
              <SearchFilter.FlexWrapper>
                <SearchFilter.SubmitButton onClick={handleFilterSubmit} />
                <RefreshButton onClick={handleResetSubmit} />
              </SearchFilter.FlexWrapper>
            </>
          );
        }}
      />
      <MemberTable
        data={users}
        pageData={pageData}
        pageName="memberAdmin"
        excel="true"
      />
    </Root>
  );
}

const Root = styled.section`
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 50px;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomHeading = styled(Heading)`
  padding: 32px 0 20px 0;
  font-size: 21px;
  font-weight: ${({ as }) => (as === "h2" ? 700 : 500)};
  color: ${({ theme }) => theme.color.black};
  margin: 0;
`;

const AddMemberAdminButton = styled.button`
  width: 98px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.color.gray_AD};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.gray_6C};
  font-weight: 500;
  margin-top: 10px;
`;
