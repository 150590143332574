import React, { useState } from "react";
import styled from "styled-components";

import { CheckBlue, HiddenPassword, ShowPassword } from "assets/icon/index";

export default function InputCell({
  name,
  title,
  essential,
  inputPlaceholder,
  inputCount,
  state,
  errorState,
  handleChangeInput,
  handleBlurError,
  duplicateState,
  type,
  ...restProps
}) {
  const [isVisible, setIsVisible] = useState(false);

  const handleChangeVisibleState = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Root {...restProps}>
      <TitleWrapper>
        <Title>
          {title}
          {essential && <Essential>*</Essential>}
        </Title>
      </TitleWrapper>
      <ContentWrapper inputCount={inputCount}>
        <Input
          name={name}
          placeholder={inputPlaceholder}
          type={isVisible ? "text" : type ? type : "text"}
          value={state || ""}
          errorState={errorState}
          onChange={handleChangeInput}
          onBlur={handleBlurError}
          required={essential}
        />
        {name === "email" && (
          <CustomCheck duplicatestate={duplicateState.email.toString()} />
        )}
        {name === "nickname" && (
          <CustomCheck duplicatestate={duplicateState.nickname.toString()} />
        )}
        {type === "password" && (
          <ChangeInputTypeButton onClick={handleChangeVisibleState}>
            {isVisible ? <HiddenPassword /> : <ShowPassword />}
          </ChangeInputTypeButton>
        )}
      </ContentWrapper>
    </Root>
  );
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 190px 1fr;
  column-gap: 1px;
  row-gap: 1px;
`;

const TitleWrapper = styled.div`
  height: 48px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray_F5};
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_6C};
`;

const Title = styled.span`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const Essential = styled.span`
  margin-left: 2px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.red_FF};
`;

const ContentWrapper = styled.div`
  position: relative;
  padding: 7px 20px 7px 20px;
  display: flex;
  background-color: ${({ theme }) => theme.color.white};
`;

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 30px 8px 12px;
  border: 1px solid
    ${({ theme, errorState }) =>
      errorState ? theme.color.red_FF : theme.color.gray_DE};
  border-radius: 5px;

  ::placeholder {
    color: ${({ theme }) => theme.color.gray_AD};
  }

  & + & {
    margin-left: 8px;
  }
`;

const ChangeInputTypeButton = styled.button.attrs(() => ({ tabIndex: -1 }))`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 16px;
  right: 32px;
`;

const CustomCheck = styled(CheckBlue)`
  position: absolute;
  top: 14px;
  right: 30px;
  fill: ${({ theme, duplicatestate }) =>
    duplicatestate === "true" ? theme.color.blue_00 : theme.color.gray_AD};
`;
