import React, { useState } from "react";
import FormInput from "./FormInput";
import styled from "styled-components";

import { HiddenPassword, ShowPassword } from "assets/icon/index";

export default function FormPasswordInput({
  isError,
  placeholder,
  register,
  className,
}) {
  const [isShow, setIsShow] = useState(false);

  const handleClickShowBtn = () => {
    setIsShow(!isShow);
  };

  return (
    <Root>
      <FormInput
        className={className}
        type={isShow ? "text" : "password"}
        placeholder={placeholder}
        isError={isError}
        register={register}
      />
      <ChangeInputTypeButton onClick={handleClickShowBtn}>
        {isShow ? <HiddenPassword /> : <ShowPassword />}
      </ChangeInputTypeButton>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
  width: 100%;
`;

const ChangeInputTypeButton = styled.button.attrs(() => ({ tabIndex: -1 }))`
  position: absolute;
  top: 50%;
  right: 10px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
`;
