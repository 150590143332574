import { createSlice } from "@reduxjs/toolkit";

export const memberAdminSlice = createSlice({
  name: "memberAdmin",
  initialState: {
    isLoading: false,
    isDone: false,
    users: [],
    pageData: {},
    error: null,
  },
  reducers: {
    memberAdmin: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    memberAdminSuccess: (state, action) => {
      const { users, pageInfo } = action.data;
      state.isLoading = false;
      state.isDone = true;
      state.users = users;
      state.pageData = pageInfo;
    },
    memberAdminFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const { memberAdmin, memberAdminSuccess, memberAdminFailure } =
  memberAdminSlice.actions;

export default memberAdminSlice.reducer;
