import React from "react";

import { RefreshButton } from "components/index";
import SearchFilter from "components/common/SearchFilter";
import { gender, personalInfo, status } from "assets/static/dropdown";
import useDropdown from "hooks/useDropdown";
import useFilter from "hooks/useFilter";

export default function AuthSearchFilter({ actionFn }) {
  const [selectGender, handleClickGender] = useDropdown("", "gender");
  const [selectStatus, handleClickStatus] = useDropdown("", "status");
  const [selectSearchInputFilter, handleChangeSearchInputFilter] = useDropdown(
    "nickname",
    "personalInfo",
  );

  const {
    inputValue,
    handleFilterSubmit,
    handleChangeInputValue,
    handleResetSubmit,
  } = useFilter(
    {
      gender: selectGender,
      status: selectStatus,
      inputFilter: selectSearchInputFilter,
    },
    actionFn,
  );

  return (
    <SearchFilter
      topChildren={() => {
        return (
          <SearchFilter.FlexWrapper>
            <SearchFilter.LabelDropdown
              label="성별"
              selectOption={selectGender}
              optionObj={gender}
              handleClickOption={handleClickGender}
            />
            <SearchFilter.LastLabelDropdown
              label="처리상태"
              selectOption={selectStatus}
              optionObj={status}
              handleClickOption={handleClickStatus}
            />
          </SearchFilter.FlexWrapper>
        );
      }}
      bottomChildren={() => {
        return (
          <>
            <SearchFilter.FlexWrapper>
              <SearchFilter.InputFilter
                selectOption={selectSearchInputFilter}
                optionObj={personalInfo}
                handleClickOption={handleChangeSearchInputFilter}
              />
              <SearchFilter.Input
                value={inputValue}
                handleChangeInputValue={handleChangeInputValue}
                handleSubmit={handleFilterSubmit}
              />
            </SearchFilter.FlexWrapper>
            <SearchFilter.FlexWrapper>
              <SearchFilter.SubmitButton onClick={handleFilterSubmit} />
              <RefreshButton onClick={handleResetSubmit} />
            </SearchFilter.FlexWrapper>
          </>
        );
      }}
    />
  );
}
