import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import styled from "styled-components";

import { ListTable, Table } from "components/index";
import { memberUserDetailPaymentDetail } from "store/reducer/memberUserDetailPaymentDetail";
import PaymentDetailFilter from "./PaymentDetailFilter";
import ModalPagination from "components/pagination/ModalPagination";
import {
  memberUserPaymentDetailTable,
  memberUserPaymentDetailTableWidth,
} from "assets/static/listTable";

export default function PaymentDetail({ list, data }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useImmer({ type: "" });
  const [pageNumber, setPageNumber] = useState(1);
  const { payments, pageInfo } = useSelector(
    (state) => state.memberUserDetailPaymentDetail,
  );

  useEffect(() => {
    dispatch(
      memberUserDetailPaymentDetail({
        id,
        ...(filterState.type && { type: filterState.type }),
        pageNumber,
      }),
    );
  }, [pageNumber]);

  return (
    <Root>
      <TableWrapper>
        <CustomTable list={list} data={data} />
        <PaymentDetailFilter
          filterState={filterState}
          setFilterState={setFilterState}
        />
        <ListTable
          list={memberUserPaymentDetailTable}
          width={memberUserPaymentDetailTableWidth}
          data={payments}
        />
      </TableWrapper>
      <PaginationWrapper>
        <ModalPagination
          pageData={pageInfo}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </PaginationWrapper>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  padding: 30px 30px 0 30px;

  & > :nth-child(2) {
    margin: 42px 0 13px 0;
  }
`;

const CustomTable = styled(Table)`
  width: 100%;
`;

const PaginationWrapper = styled.div`
  height: 60px;
  padding: 14px 30px 14px 0;
  margin-top: 30px;
  display: flex;
  justify-content: end;
  background-color: ${({ theme }) => theme.color.gray_F5};
`;
