import { getAuthProfile } from "api/index";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  authProfile,
  authProfileFailure,
  authProfileSuccess,
} from "store/reducer/authProfile";

function* authProfileSaga(action) {
  try {
    const res = yield call(getAuthProfile, action.payload);

    yield put({
      type: authProfileSuccess,
      data: res.data,
    });
  } catch (err) {
    yield put({
      type: authProfileFailure,
      error: err.response.data,
    });
  }
}

export function* watchAuthProfile() {
  yield takeLatest(authProfile, authProfileSaga);
}
