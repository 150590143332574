import React from "react";
import styled from "styled-components";

export default function RadioCell({
  title,
  radioList,
  setRadioState,
  selectRadioState,
  radioName,
  disabled,
  ...restProps
}) {
  const handleClickRadioButton = (index) => {
    setRadioState((draft) => {
      draft[radioName] = index;
    });
  };

  return (
    <Root {...restProps}>
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>
      <RadioWrapper>
        {Object.entries(radioList).map(([key, value], index) => {
          return (
            <RadioItem key={key + index}>
              <input
                type="radio"
                id={`${radioName}radio${index}`}
                name={radioName}
                value={value}
                checked={selectRadioState === index}
                onChange={() => handleClickRadioButton(index)}
                disabled={disabled}
              />
              <Label htmlFor={`${radioName}radio${index}`}>{value}</Label>
            </RadioItem>
          );
        })}
      </RadioWrapper>
    </Root>
  );
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 190px 1fr;
  column-gap: 1px;
  row-gap: 1px;
`;

const TitleWrapper = styled.div`
  height: 48px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray_F5};
  color: ${({ theme }) => theme.color.gray_6C};
`;

const Title = styled.span`
  font-size: 14px;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const RadioWrapper = styled.div`
  padding-left: 20px;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  column-gap: 40px;
`;

const Label = styled.label`
  margin-left: 8px;
  font-size: 14px;
  color: ${({ value, theme }) =>
    value ? theme.color.black : theme.color.gray_6C};
`;

const RadioItem = styled.div`
  display: flex;
`;
