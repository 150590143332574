import { createSlice } from "@reduxjs/toolkit";

export const memberAdminDropdownListSlice = createSlice({
  name: "memberAdminDropdownList",
  initialState: {
    area: {
      data: [],
      isLoading: false,
      isDone: false,
      error: null,
    },
    bodyType: {
      data: [],
      isLoading: false,
      isDone: false,
      error: null,
    },
    drinking: {
      data: [],
      isLoading: false,
      isDone: false,
      error: null,
    },
    personality: {
      data: [],
      isLoading: false,
      isDone: false,
      error: null,
    },
    religion: {
      data: [],
      isLoading: false,
      isDone: false,
      error: null,
    },
    smoking: {
      data: [],
      isLoading: false,
      isDone: false,
      error: null,
    },
    dropdownList: {
      data: {},
      isLoading: false,
      isDone: false,
      error: null,
    },
  },
  reducers: {
    memberAdminAreaDropdownList: (state) => {
      state.area.isLoading = true;
      state.area.isDone = false;
      state.area.error = null;
    },
    memberAdminAreaDropdownListSuccess: (state, action) => {
      state.area.data = action.data;
      state.area.isLoading = false;
      state.area.isDone = true;
    },
    memberAdminAreaDropdownListFailure: (state, action) => {
      state.area.isLoading = false;
      state.area.isDone = false;
      state.area.error = action.error;
    },
    memberAdminBodyTypeDropdownList: (state) => {
      state.bodyType.isLoading = true;
      state.bodyType.isDone = false;
      state.bodyType.error = null;
    },
    memberAdminBodyTypeDropdownListSuccess: (state, action) => {
      state.bodyType.data = action.data;
      state.bodyType.isLoading = false;
      state.bodyType.isDone = true;
    },
    memberAdminBodyTypeDropdownListFailure: (state, action) => {
      state.bodyType.isLoading = false;
      state.bodyType.isDone = false;
      state.bodyType.error = action.error;
    },
    memberAdminDrinkingDropdownList: (state) => {
      state.drinking.isLoading = true;
      state.drinking.isDone = false;
      state.drinking.error = null;
    },
    memberAdminDrinkingDropdownListSuccess: (state, action) => {
      state.drinking.data = action.data;
      state.drinking.isLoading = false;
      state.drinking.isDone = true;
    },
    memberAdminDrinkingDropdownListFailure: (state, action) => {
      state.drinking.isLoading = false;
      state.drinking.isDone = false;
      state.drinking.error = action.error;
    },
    memberAdminPersonalityDropdownList: (state) => {
      state.personality.isLoading = true;
      state.personality.isDone = false;
      state.personality.error = null;
    },
    memberAdminPersonalityDropdownListSuccess: (state, action) => {
      state.personality.data = action.data;
      state.personality.isLoading = false;
      state.personality.isDone = true;
    },
    memberAdminPersonalityDropdownListFailure: (state, action) => {
      state.personality.isLoading = false;
      state.personality.isDone = false;
      state.personality.error = action.error;
    },
    memberAdminReligionDropdownList: (state) => {
      state.religion.isLoading = true;
      state.religion.isDone = false;
      state.religion.error = null;
    },
    memberAdminReligionDropdownListSuccess: (state, action) => {
      state.religion.data = action.data;
      state.religion.isLoading = false;
      state.religion.isDone = true;
    },
    memberAdminReligionDropdownListFailure: (state, action) => {
      state.religion.isLoading = false;
      state.religion.isDone = false;
      state.religion.error = action.error;
    },
    memberAdminSmokingDropdownList: (state) => {
      state.smoking.isLoading = true;
      state.smoking.isDone = false;
      state.smoking.error = null;
    },
    memberAdminSmokingDropdownListSuccess: (state, action) => {
      state.smoking.data = action.data;
      state.smoking.isLoading = false;
      state.smoking.isDone = true;
    },
    memberAdminSmokingDropdownListFailure: (state, action) => {
      state.smoking.isLoading = false;
      state.smoking.isDone = false;
      state.smoking.error = action.error;
    },
    memberAdminDropdownList: (state) => {
      state.dropdownList.isLoading = true;
      state.dropdownList.isDone = false;
      state.dropdownList.error = null;
    },
    memberAdminDropdownListSuccess: (state, action) => {
      state.dropdownList.data = action.data;
      state.dropdownList.isLoading = false;
      state.dropdownList.isDone = true;
    },
    memberAdminDropdownListFailure: (state, action) => {
      state.dropdownList.isLoading = false;
      state.dropdownList.isDone = false;
      state.dropdownList.error = action.error;
    },
  },
});

export const {
  memberAdminAreaDropdownList,
  memberAdminAreaDropdownListSuccess,
  memberAdminAreaDropdownListFailure,
  memberAdminBodyTypeDropdownList,
  memberAdminBodyTypeDropdownListSuccess,
  memberAdminBodyTypeDropdownListFailure,
  memberAdminDrinkingDropdownList,
  memberAdminDrinkingDropdownListSuccess,
  memberAdminDrinkingDropdownListFailure,
  memberAdminPersonalityDropdownList,
  memberAdminPersonalityDropdownListSuccess,
  memberAdminPersonalityDropdownListFailure,
  memberAdminReligionDropdownList,
  memberAdminReligionDropdownListSuccess,
  memberAdminReligionDropdownListFailure,
  memberAdminSmokingDropdownList,
  memberAdminSmokingDropdownListSuccess,
  memberAdminSmokingDropdownListFailure,
  memberAdminDropdownList,
  memberAdminDropdownListSuccess,
  memberAdminDropdownListFailure,
} = memberAdminDropdownListSlice.actions;

export default memberAdminDropdownListSlice.reducer;
