import { getMemberUser } from "api/index";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  memberUser,
  memberUserFailure,
  memberUserSuccess,
} from "store/reducer/memberUser";

function* memberUserSaga(action) {
  try {
    const res = yield call(getMemberUser, action.payload);

    yield put({
      type: memberUserSuccess,
      data: res.data,
    });
  } catch (err) {
    yield put({
      type: memberUserFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberUser() {
  yield takeLatest(memberUser, memberUserSaga);
}
