import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { navList } from "assets/static/navLink";
import CustomLink from "./CustomLink";

export default function Navbar() {
  const { pathname } = useLocation();

  const res = /([a-zA-Z]+)/g; // pathname에서 주소를 /기준으로 배열로 가져옴
  const seletedCategoryNavItemList = navList[pathname.match(res)[0]];

  return (
    <Root>
      <NavbarList>
        {seletedCategoryNavItemList.map((item, index) => {
          const { fullPath, Icon, content, subContent, assert } = item;
          const selected =
            pathname === fullPath ||
            fullPath.match(res)[1] === pathname.match(res)[1];

          return (
            <NavbarListItem key={index}>
              <CustomLink
                fullPath={fullPath}
                Icon={Icon}
                content={content}
                selected={selected}
                subContent={subContent}
                assert={assert}
              />
            </NavbarListItem>
          );
        })}
      </NavbarList>
    </Root>
  );
}

const Root = styled.nav`
  z-index: ${({ theme }) => theme.zIndex.NAV};
  min-width: 200px;
  height: 100%;
  padding-top: 50px;
`;

const NavbarList = styled.ul`
  width: 200px;
  height: inherit;
  padding-top: 20px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.color.nav_bg};
  position: fixed;
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.12);
`;

const NavbarListItem = styled.li`
  width: 200px;
  height: 50px;
  line-height: 50px;
  display: block;
  position: relative;
`;
