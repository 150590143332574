import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { memberAdminDetail } from "store/reducer/memberAdminDetail";
import { memberAdminDropdownList } from "store/reducer/memberAdminDropdownList";
import AuthDetailPageRootLayout from "pages/auth/containers/AuthDetailPageRootLayout";
import { Heading, SubmitButton } from "components/index";
import BasicInfo from "../containers/BasicInfo";
import {
  gender,
  height,
  internalGrade,
  mbti,
  userStatusId,
} from "assets/static/dropdown";
import { adminCreateInitState, adminModifyList } from "assets/static/adminForm";
import {
  convertUTCtoBirthday,
  makeAdminModifyForm,
  makeDropdownOptions,
} from "utils/helper/formatter";
import ProfileInfo from "../containers/ProfileInfo";
import Introduce from "../containers/Introduce";
import AuthInfo from "../containers/AuthInfo";
import useHookForm from "../hooks/useHookForm";
import { memberAdminModify } from "store/reducer/memberAdminModify";
import {
  memberAdminDuplicateEmailReset,
  memberAdminDuplicateNicknameReset,
} from "store/reducer/memberAdminCreate";
import RomanticStyle from "../containers/RomanticStyle";

export default function MemberAdminModify2() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: adminData } = useSelector((state) => state.memberAdminDetail);
  const answerList = useSelector((state) => state.answerList.data);

  const {
    errors,
    watch,
    setError,
    reset,
    setValue,
    handleSubmit,
    hookFormProps,
    hookFormFormat,
    hookFormValidation,
  } = useHookForm(adminCreateInitState);

  const {
    area,
    body_type,
    drinking,
    hobby,
    personality,
    religion,
    smoking,
    ideal,
  } = useSelector((state) => state.memberAdminDropdownList.dropdownList.data);
  const isCheckNickname = useSelector(
    (state) => state.memberAdminCreate.duplicateNickname.isDone,
  );
  const profiles = [
    watch("profile1"),
    watch("profile2"),
    watch("profile3"),
    watch("profile4"),
    watch("profile5"),
  ];

  const [deleteProfileIndexs, setDeleteProfileIndexs] = useState([]);

  const makeDropdowns = () => ({
    gender: makeDropdownOptions(gender),
    userStatusId: makeDropdownOptions(userStatusId),
    internalGrade: makeDropdownOptions(internalGrade),
    area: makeDropdownOptions(area),
    height: makeDropdownOptions(height),
    bodyType: makeDropdownOptions(
      watch("gender") === "F" ? body_type?.F : body_type?.M,
    ),
    religion: makeDropdownOptions(religion),
    drinking: makeDropdownOptions(drinking),
    smoking: makeDropdownOptions(smoking),
    personality: makeDropdownOptions(personality),
    hobby: makeDropdownOptions(hobby),
    ideal: makeDropdownOptions(ideal),
    mbti: makeDropdownOptions(mbti),
  });

  const deleteProfiles = (profileIndex) => {
    setDeleteProfileIndexs([...deleteProfileIndexs, profileIndex]);
  };

  const handleModifyAdmin = () => {
    const authInfoKey = [
      "appearance",
      "body",
      "occupation",
      "university",
      "income",
    ];

    const isAuthInfoError = authInfoKey
      .map((key) => watch(key))
      .every((state) => state === "0");
    if (isAuthInfoError) setError("authInfo", { required: true });

    const isError = Object.keys(errors).length !== 0;
    const isPassSubmitValidation = !isError && !isAuthInfoError;

    if (isPassSubmitValidation) {
      handleSubmit(
        (data) => {
          const submitData = makeAdminModifyForm(
            data,
            profiles,
            deleteProfileIndexs,
          );

          dispatch(memberAdminModify({ submitData, profiles, id, navigate }));
          dispatch(memberAdminDuplicateEmailReset());
          dispatch(memberAdminDuplicateNicknameReset());
        },
        (err) => console.log("err", err),
      )();
    }
  };

  useEffect(() => {
    if (Object.keys(adminData).length === 0) return;

    const initState = {
      profile1: adminData.profile_img[0],
      profile2: adminData.profile_img[1],
      profile3: adminData.profile_img[2],
      profile4: adminData.profile_img[3] ?? "",
      profile5: adminData.profile_img[4] ?? "",
      email: adminData.email,
      userNumber: adminData.user_number,
      name: adminData.name,
      phone: adminData.phone,
      birthday: adminData.birthday,
      gender: adminData.gender === "남자" ? "M" : "F",
      userStatusId: adminData.user_status_id + "",
      created: convertUTCtoBirthday(adminData.created),
      lastLogin: adminData.last_login,
      internalGrade: adminData.internal_grade,
      nickname: adminData.nickname,
      area: adminData.area_id + "",
      height: adminData.height,
      bodyType: adminData.body_type_id + "",
      religion: adminData.religion_id + "",
      drinking: adminData.drinking_id + "",
      smoking: adminData.smoking_id + "",
      education: adminData.education,
      job: adminData.job,
      hobby1: adminData.hobby_id[0] + "",
      hobby2: adminData.hobby_id[1] + "",
      hobby3: adminData.hobby_id[2] + "",
      hobby4: adminData.hobby_id[3] + "",
      hobby5: adminData.hobby_id[4] + "",
      personality1: adminData.personality_id[0] + "",
      personality2: adminData.personality_id[1] + "",
      personality3: adminData.personality_id[2] + "",
      personality4: adminData.personality_id[3] + "",
      personality5: adminData.personality_id[4] + "",
      ideal1: adminData.ideal_id[0] + "",
      ideal2: adminData.ideal_id[1] + "",
      ideal3: adminData.ideal_id[2] + "",
      ideal4: adminData.ideal_id[3] + "",
      ideal5: adminData.ideal_id[4] + "",
      mbti1: adminData.mbti + "",
      introduction: adminData.introduction,
      romanticStyle: Object.values(adminData.answer).map((item) => ({
        value: item,
      })),
      appearance: adminData.badges[0] + "",
      body: adminData.badges[1] + "",
      occupation: adminData.badges[2] + "",
      university: adminData.badges[3] + "",
      income: adminData.badges[4] + "",
    };

    reset(initState);
  }, [adminData, reset]);

  useEffect(() => {
    profiles.forEach((profile, i) => {
      if (typeof profile === "object" && profile.length === 0) {
        setValue(`profile${[i + 1]}`, "");
      }
    });
  }, [profiles]);

  useEffect(() => {
    dispatch(memberAdminDetail(id));
    dispatch(memberAdminDropdownList());
  }, []);

  return (
    <AuthDetailPageRootLayout>
      <HeadingWrapper>
        <Heading>어드민 회원 수정</Heading>
        <CustomSubmitButton
          onClick={handleModifyAdmin}
          disabled={Object.keys(errors).length !== 0}
        >
          저장
        </CustomSubmitButton>
      </HeadingWrapper>
      <BasicInfo
        adminList={adminModifyList}
        profiles={profiles}
        dropdowns={makeDropdowns()}
        deleteProfiles={deleteProfiles}
        hookFormFormat={hookFormFormat}
        hookFormValidation={hookFormValidation}
        {...hookFormProps}
      />
      <ProfileInfo
        adminList={adminModifyList}
        dropdowns={makeDropdowns()}
        hookFormValidation={hookFormValidation}
        {...hookFormProps}
      />
      <Introduce
        adminList={adminModifyList}
        dropdowns={makeDropdowns()}
        {...hookFormProps}
      />
      <RomanticStyle
        adminList={adminModifyList}
        answerList={answerList}
        {...hookFormProps}
      />
      <AuthInfo adminList={adminModifyList} {...hookFormProps} />
    </AuthDetailPageRootLayout>
  );
}

const HeadingWrapper = styled.div`
  position: relative;
`;

const CustomSubmitButton = styled(SubmitButton)`
  width: 70px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25px;
  right: 0px;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;
