import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  DropdownCell,
  DropdownCells,
  // InputCell,
  InputCells,
  TextAreaCell,
} from "components/index";

export default function MemberAdminIntroduceTable({
  list,
  state,
  setState,
  errorState,
  handleChangeInput,
  handleBlurError,
  ...restProps
}) {
  const personalityList = useSelector(
    (state) => state.memberAdminDropdownList.dropdownList.data.personality,
  );
  const hobbyList = useSelector(
    (state) => state.memberAdminDropdownList.dropdownList.data.hobby,
  );

  return (
    <Root {...restProps}>
      {list.map((item, index) => {
        const { type, essential, label, placeholder, count, key } = item;

        if (type === "inputs") {
          return (
            <InputCells
              name={key}
              key={index}
              title={label}
              essential={essential}
              inputPlaceholder={placeholder}
              inputCount={count}
              width="156px"
              state={state[key]}
              setState={setState}
              stateName={key}
              errorState={errorState[key]?.state}
              handleChangeInput={handleChangeInput}
              handleBlurError={handleBlurError}
            />
          );
        }
        if (type === "dropdown") {
          return (
            <DropdownCell
              key={index}
              title={label}
              essential={essential}
              dropdownCount={count}
              list={personalityList}
              state={state[key]}
              setState={setState}
            />
          );
        }
        if (type === "dropdowns") {
          if (key === "personality") {
            return (
              <DropdownCells
                key={index}
                title={label}
                essential={essential}
                dropdownCount={count}
                list={personalityList}
                stateName={key}
                state={state[key]}
                setState={setState}
                errorState={errorState[key]?.state}
              />
            );
          }

          if (key === "hobby") {
            return (
              <DropdownCells
                key={index}
                title={label}
                essential={essential}
                dropdownCount={count}
                list={hobbyList}
                stateName={key}
                state={state[key]}
                setState={setState}
                errorState={errorState[key]?.state}
              />
            );
          }
        }
        if (type === "textArea") {
          return (
            <TextAreaCell
              name={key}
              key={index}
              title={label}
              essential={essential}
              textAreaPlaceholder="내용을 입력해 주세요."
              state={state[key]}
              setState={setState}
              stateName={key}
              errorState={errorState[key]?.state}
              handleChangeInput={handleChangeInput}
              handleBlurError={handleBlurError}
            />
          );
        }
      })}
    </Root>
  );
}

const Root = styled.div`
  width: 1080px;
  border-top: 1px solid ${({ theme }) => theme.color.gray_E6};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
  background-color: ${({ theme }) => theme.color.gray_E6};
  display: grid;
  grid-template-columns: 100%;
  column-gap: 1px;
  row-gap: 1px;
`;
