import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoading: false,
    isDone: false,
    userData: null,
    error: null,
  },
  reducers: {
    login: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.userData = null;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.isDone = true;
      state.userData = action.data;
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.userData = null;
      state.error = action.error;
    },
  },
});

export const { login, loginSuccess, loginFailure } = loginSlice.actions;

export default loginSlice.reducer;
