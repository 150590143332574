import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Heading, SubmitButton } from "components/index";
import MemberAdminBasicInformation from "../containers/MemberAdminBasicInformation";
import MemberAdminProfileInformation from "../containers/MemberAdminProfileInformation";
import MemberAdminIntroduce from "../containers/MemberAdminIntroduce";
import MemberAdminAuthInformation from "../containers/MemberAdminAuthInformation";
import AuthDetailPageRootLayout from "pages/auth/containers/AuthDetailPageRootLayout";
import { memberAdminDropdownList } from "store/reducer/memberAdminDropdownList";
import { checkBirthday, checkOnlyNumber } from "utils/helper/formatter";
import {
  addDashPhoneNumber,
  checkEmailValidation,
  makeCryptoFunction,
} from "utils/helper/index";
import {
  memberAdminCreate,
  memberAdminDuplicateEmail,
  memberAdminDuplicateEmailReset,
  memberAdminDuplicateNickname,
  memberAdminDuplicateNicknameReset,
} from "store/reducer/memberAdminCreate";
import { authInformatinFormatter } from "utils/helper/formatter";
import {
  memberAdminBasicInformation,
  memberAdminIntroduceTable,
  memberAdminProfileTable,
} from "assets/static/table";
import { checkPasswordValidateLegacy } from "utils/helper/validation";

const initBasicState = {
  image: {
    imageUrl: [],
    value: [],
  },
  email: "",
  password: "",
  passwordConfirm: "",
  name: "",
  phone: "",
  birthday: "",
  gender: "M",
  userStatusId: "1",
  created: "",
  lastLogin: "",
  internalGrade: "일반",
};

const initProfileState = {
  nickname: "",
  area: "",
  height: "",
  body_type: "",
  religion: "",
  drinking: "",
  smoking: "",
  education: "",
  job: "",
};

const initIntroduceState = {
  personality: ["", "", ""],
  hobby: ["", "", ""],
  interest: ["", "", "", "", ""],
  attraction: ["", "", "", "", ""],
  ideal: ["", "", "", "", ""],
  introduction: "",
};

const initAuthInfoState = {
  appearance: 2,
  body: 2,
  occupation: 2,
  university: 2,
  income: 2,
};

const initBasicErrorState = {
  image: {
    state: false,
    message: "",
    touched: false,
  },
  email: {
    state: false,
    message: "",
    touched: false,
  },
  password: {
    state: false,
    message: "",
    touched: false,
  },
  passwordConfirm: {
    state: false,
    message: "",
    touched: false,
  },
  name: {
    state: false,
    message: "",
    touched: false,
  },
  phone: {
    state: false,
    message: "",
    touched: false,
  },
  birthday: {
    state: false,
    message: "",
    touched: false,
  },
  userStatusId: {
    state: false,
    message: "",
    touched: false,
  },
  internalGrade: {
    state: false,
    message: "",
    touched: false,
  },
};

const initProfileErrorState = {
  nickname: {
    state: false,
    message: "",
    touched: false,
  },
  area: {
    state: false,
    message: "",
    touched: false,
  },
  height: {
    state: false,
    message: "",
    touched: false,
  },
  body_type: {
    state: false,
    message: "",
    touched: false,
  },
  religion: {
    state: false,
    message: "",
    touched: false,
  },
  drinking: {
    state: false,
    message: "",
    touched: false,
  },
  smoking: {
    state: false,
    message: "",
    touched: false,
  },
  education: {
    state: false,
    message: "",
    touched: false,
  },
  job: {
    state: false,
    message: "",
    touched: false,
  },
};

const initIntroduceErrorState = {
  personality: {
    state: [false, false, false],
    message: "",
    touched: false,
  },
  hobby: {
    state: [false, false, false],
    message: "",
    touched: false,
  },
  interest: {
    state: [false, false, false, false, false],
    message: "",
    touched: [false, false, false, false, false],
  },
  attraction: {
    state: [false, false, false, false, false],
    message: "",
    touched: [false, false, false, false, false],
  },
  ideal: {
    state: [false, false, false, false, false],
    message: "",
    touched: [false, false, false, false, false],
  },
  introduction: {
    state: false,
    message: "",
    touched: false,
  },
};

const initTableErrorState = {
  basicState: {
    state: false,
    message: "",
  },
  profileState: {
    state: false,
    message: "",
  },
  introduceState: {
    state: false,
    message: "",
  },
  authInfoState: {
    state: false,
    message: "",
    touched: false,
  },
};

export default function MemberAdminCreate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [basicState, setBasicState] = useImmer(initBasicState);
  const [profileState, setProfileState] = useImmer(initProfileState);
  const [introduceState, setIntroduceState] = useImmer(initIntroduceState);
  const [authInfoState, setAuthInfoState] = useImmer(initAuthInfoState);
  const [basicErrorState, setBasicErrorState] = useImmer(initBasicErrorState);
  const [profileErrorState, setProfileErrorState] = useImmer(
    initProfileErrorState,
  );
  const [introduceErrorState, setIntroduceErrorState] = useImmer(
    initIntroduceErrorState,
  );
  const [tableErrorState, setTableErrorState] = useImmer(initTableErrorState);
  const [duplicateState, setDuplicateState] = useImmer({
    email: false,
    nickname: false,
  });
  const emailDuplicate = useSelector(
    ({ memberAdminCreate }) => memberAdminCreate.duplicateEmail.isDuplicate,
  );
  const nicknameDuplicate = useSelector(
    ({ memberAdminCreate }) => memberAdminCreate.duplicateNickname.isDuplicate,
  );

  const handleSubmit = () => {
    if (basicState.image.imageUrl.length < 2) {
      setBasicErrorState((draft) => {
        draft.image.state = true;
      });
    }
    requiredValueCheck();
    requiredValidateionMessage(basicState, "basicState");
    requiredValidateionMessage(profileState, "profileState");
    requiredValidateionMessage(introduceState, "introduceState");

    const submitState = {
      email: basicState.email,
      password: makeCryptoFunction(basicState.password),
      phone: basicState.phone,
      birthday: basicState.birthday,
      name: basicState.name,
      gender: basicState.gender,
      nickname: profileState.nickname,
      area_id: profileState.area,
      height: profileState.height,
      body_type_id: profileState.body_type,
      religion_id: profileState.religion,
      smoking_id: profileState.smoking,
      drinking_id: profileState.drinking,
      job: profileState.job,
      education: profileState.education,
      introduction: introduceState.introduction,
      personality: introduceState.personality,
      hobby: introduceState.hobby,
      interest: introduceState.interest.filter((item) => item !== ""),
      attraction: introduceState.attraction.filter((item) => item !== ""),
      ideal: introduceState.ideal.filter((item) => item !== ""),
      certification: [
        authInformatinFormatter(authInfoState.appearance),
        authInformatinFormatter(authInfoState.body),
        authInformatinFormatter(authInfoState.occupation),
        authInformatinFormatter(authInfoState.university),
        authInformatinFormatter(authInfoState.income),
      ],
      internal_grade: basicState.internalGrade,
      user_status_id: basicState.userStatusId,
      content_type: basicState.image.value.map((item) => item.type),
    };

    if (
      authInfoState.appearance === 2 &&
      authInfoState.body === 2 &&
      authInfoState.occupation === 2 &&
      authInfoState.university === 2 &&
      authInfoState.income === 2
    ) {
      setTableErrorState((draft) => {
        draft.authInfoState.state = true;
        draft.authInfoState.message = "최소 1개 이상의 등급을 선택해 주세요.";
      });
      return;
    }

    dispatch(
      memberAdminCreate({
        submitState,
        files: basicState.image.value,
        contentType: submitState.content_type,
        navigate,
      }),
    );
    dispatch(memberAdminDuplicateEmailReset());
    dispatch(memberAdminDuplicateNicknameReset());
  };

  const handleChangeBasicInput = (event) => {
    let { name, value } = event.target;

    if (name === "email") {
      if (value.length === 0) {
        setDuplicateState((draft) => {
          draft.email = false;
        });
      }
      if (value.length > 40) return;
    }

    if (name === "phone") {
      if (value.length > 13) return;
      if (value.length === 13) {
        setBasicErrorState((draft) => {
          draft[name].state = false;
        });
      }
      value = addDashPhoneNumber(checkOnlyNumber(value));
    }

    if (name === "birthday") {
      if (value.length > 10) return;
      value = checkBirthday(value);
      if (value.length === 10) {
        setBasicErrorState((draft) => {
          draft.birthday.state = false;
        });
      }
    }

    setBasicState((draft) => {
      draft[name] = value;
    });
  };

  const handleChangeProfileInput = (event) => {
    let { name, value } = event.target;

    if (name === "nickname" || name === "education" || name === "job") {
      if (value.length !== 0) {
        setProfileErrorState((draft) => {
          draft[name].state = false;
        });
      }
    }

    setProfileState((draft) => {
      draft[name] = value;
    });
  };

  const handleChangeIntroduceInput = (event, index) => {
    let { name, value } = event.target;

    if (name === "introduction") {
      setIntroduceState((draft) => {
        draft[name] = value;
      });

      if (value.length > 0) {
        setIntroduceErrorState((draft) => {
          draft[name].state = false;
        });
      }
    } else {
      if (value.length > 0) {
        setIntroduceErrorState((draft) => {
          draft[name].state[index] = false;
        });
      } else {
        setIntroduceErrorState((draft) => {
          draft[name].state[index] = true;
        });
      }

      setIntroduceState((draft) => {
        draft[name][index] = value;
      });
    }
  };

  const handleBlurBasicError = (event) => {
    const { name, value } = event.target;

    const checkDuplicateEmail = (value) => {
      if (value.length === 0) return;
      dispatch(memberAdminDuplicateEmail({ email: value }));
    };

    if (value.length === 0) {
      setBasicErrorState((draft) => {
        draft[name].state = true;
      });
    } else {
      setBasicErrorState((draft) => {
        draft[name].state = false;
      });
    }

    if (name === "email") {
      const isEmail = checkEmailValidation(value);
      if (isEmail) {
        checkDuplicateEmail(value);
        if (!tableErrorState.basicState) {
          setTableErrorState((draft) => {
            draft.basicState.state = false;
            draft.basicState.message = "";
          });
        }
      } else {
        setBasicErrorState((draft) => {
          draft[name].state = true;
        });
        setTableErrorState((draft) => {
          draft.basicState.state = true;
          draft.basicState.message = "이메일 형식이 아닙니다.";
        });
        setDuplicateState((draft) => {
          draft.email = false;
        });
      }
    }

    if (name === "password") {
      if (checkPasswordValidateLegacy(value)) {
        setTableErrorState((draft) => {
          draft.basicState.state = false;
          draft.basicState.message = "";
        });
      } else {
        setBasicErrorState((draft) => {
          draft[name].state = true;
        });
        setTableErrorState((draft) => {
          draft.basicState.state = true;
          draft.basicState.message =
            "비밀번호는 영문 대/소문자, 숫자, 특수문자 4가지 조합의 6~12자리입니다.";
        });
      }
    }

    if (name === "passwordConfirm") {
      if (basicState.password !== basicState.passwordConfirm) {
        setBasicErrorState((draft) => {
          draft[name].state = true;
        });
        setTableErrorState((draft) => {
          draft.basicState.state = true;
          draft.basicState.message = "비밀번호가 일치하지 않습니다.";
        });
      } else {
        setTableErrorState((draft) => {
          draft.basicState.state = false;
          draft.basicState.message = "";
        });
      }
    }

    if (name === "phone") {
      if (value.length < 13) {
        setBasicErrorState((draft) => {
          draft[name].state = true;
        });
      } else {
        setBasicErrorState((draft) => {
          draft[name].state = false;
        });
      }
    }

    if (name === "birthday") {
      if (value.length < 9) {
        setBasicErrorState((draft) => {
          draft[name].state = true;
        });
      } else {
        setBasicErrorState((draft) => {
          draft[name].state = false;
        });
      }
    }
  };

  const handleBlurProfileError = (event) => {
    let { name, value } = event.target;

    const handleCheckDuplicateNickName = (value) => {
      if (value.length === 0) return;
      dispatch(memberAdminDuplicateNickname({ nickname: value }));
    };

    if (name === "nickname") {
      handleCheckDuplicateNickName(value);
    }

    if (value.length === 0) {
      setProfileErrorState((draft) => {
        draft[name].state = true;
      });
    } else {
      setProfileErrorState((draft) => {
        draft[name].state = false;
      });
    }
  };

  const handleBlurIntroduceError = (event, index) => {
    if (index > 2) return;

    let { name, value } = event.target;

    if (name === "introduction") {
      if (value.length === 0) {
        setIntroduceErrorState((draft) => {
          draft[name].state = true;
        });
      } else {
        setIntroduceErrorState((draft) => {
          draft[name].state = false;
        });
      }
    } else {
      if (value.length === 0) {
        setIntroduceErrorState((draft) => {
          draft[name].state[index] = true;
        });
      } else {
        setIntroduceErrorState((draft) => {
          draft[name].state[index] = false;
        });
      }
    }
  };

  const requiredValueCheck = () => {
    const listRequiredValueCheck = (list, state, stateName) => {
      if (stateName !== "introduceState") {
        return list
          .filter((item) => item.essential === true)
          .forEach((item) => {
            if (state[item.key] === "") {
              if (stateName === "basicState") {
                setBasicErrorState((draft) => {
                  draft[item.key].state = true;
                });
              } else if (stateName === "profileState") {
                setProfileErrorState((draft) => {
                  draft[item.key].state = true;
                });
              }
            }
          });
      } else {
        return Object.entries(state).map(([key, value]) => {
          if (typeof value === "object") {
            value.forEach((item, index) => {
              if (item === "") {
                setIntroduceErrorState((draft) => {
                  if (index > 2) return;
                  draft[key].state[index] = true;
                });
              }
            });
          } else {
            if (value === "") {
              setIntroduceErrorState((draft) => {
                draft[key].state = true;
              });
            }
          }
        });
      }
    };

    listRequiredValueCheck(
      memberAdminBasicInformation,
      basicState,
      "basicState",
    );

    listRequiredValueCheck(
      memberAdminProfileTable,
      profileState,
      "profileState",
    );

    listRequiredValueCheck(
      memberAdminIntroduceTable,
      introduceState,
      "introduceState",
    );
  };

  const requiredValidateionMessage = (state, stateName) => {
    let temp = null;
    if (stateName === "basicState") {
      const tempObj = { ...state };
      delete tempObj.lastLogin;
      delete tempObj.created;

      temp = Object.values(tempObj).filter((item) => {
        if (typeof item === "object") {
          return item.imageUrl.length < 2;
        } else {
          return item === "";
        }
      });
    }

    if (stateName === "profileState") {
      temp = Object.values(state).filter((item) => item === "");
    }

    if (stateName === "introduceState") {
      temp = Object.values(state).filter((item) => {
        if (typeof item === "string") {
          return item === "";
        } else {
          return item.filter((item) => item === "").length !== 0;
        }
      });
    }

    if (temp.length > 0) {
      setTableErrorState((draft) => {
        draft[stateName].state = true;
        draft[stateName].message = "필수 입력 값을 입력해주세요.";
      });
    } else {
      setTableErrorState((draft) => {
        draft[stateName].state = false;
        draft[stateName].message = "";
      });
    }
  };

  const deleteTableErrorMessage = (state, stateName) => {
    let errorState = null;

    if (stateName === "introduceState") {
      errorState = Object.values(state).filter((item) => {
        if (typeof item === "string") {
          item.state === true;
        } else {
          if (
            item.state[0] === true ||
            item.state[1] === true ||
            item.state[2] === true
          ) {
            return true;
          }
        }
      });
    } else {
      errorState = Object.values(state).filter(({ state }) => state === true);
    }

    if (errorState.length === 0) {
      setTableErrorState((draft) => {
        draft[stateName].state = false;
        draft[stateName].message = "";
      });
    }
  };

  useEffect(() => {
    dispatch(memberAdminDropdownList());
  }, []);

  useEffect(() => {
    if (profileState.area !== "") {
      setProfileErrorState((draft) => {
        draft.area.state = false;
      });
    }
    if (profileState.height !== "") {
      setProfileErrorState((draft) => {
        draft.height.state = false;
      });
    }
    if (profileState.body_type !== "") {
      setProfileErrorState((draft) => {
        draft.body_type.state = false;
      });
    }
    if (profileState.religion !== "") {
      setProfileErrorState((draft) => {
        draft.religion.state = false;
      });
    }
    if (profileState.drinking !== "") {
      setProfileErrorState((draft) => {
        draft.drinking.state = false;
      });
    }
    if (profileState.smoking !== "") {
      setProfileErrorState((draft) => {
        draft.smoking.state = false;
      });
    }
    if (introduceState.personality[0] !== "") {
      setIntroduceErrorState((draft) => {
        draft.personality.state[0] = false;
      });
    }
    if (introduceState.personality[1] !== "") {
      setIntroduceErrorState((draft) => {
        draft.personality.state[1] = false;
      });
    }
    if (introduceState.personality[2] !== "") {
      setIntroduceErrorState((draft) => {
        draft.personality.state[2] = false;
      });
    }
    if (introduceState.hobby[0] !== "") {
      setIntroduceErrorState((draft) => {
        draft.hobby.state[0] = false;
      });
    }
    if (introduceState.hobby[1] !== "") {
      setIntroduceErrorState((draft) => {
        draft.hobby.state[1] = false;
      });
    }
    if (introduceState.hobby[2] !== "") {
      setIntroduceErrorState((draft) => {
        draft.hobby.state[2] = false;
      });
    }
  }, [profileState, introduceState]);
  useEffect(() => {
    if (basicState.email.length === 0) return;

    if (!emailDuplicate) {
      setDuplicateState((draft) => {
        draft.email = true;
      });
    } else {
      setDuplicateState((draft) => {
        draft.email = false;
      });
    }
  }, [emailDuplicate]);
  useEffect(() => {
    if (profileState.nickname.length === 0) return;

    if (!nicknameDuplicate) {
      setDuplicateState((draft) => {
        draft.nickname = true;
      });
    } else {
      setDuplicateState((draft) => {
        draft.nickname = false;
      });
    }
  }, [nicknameDuplicate]);

  useEffect(() => {
    deleteTableErrorMessage(basicErrorState, "basicState");
  }, [basicErrorState]);

  useEffect(() => {
    deleteTableErrorMessage(profileErrorState, "profileState");
  }, [profileErrorState]);

  useEffect(() => {
    deleteTableErrorMessage(introduceErrorState, "introduceState");
  }, [introduceErrorState]);

  useEffect(() => {
    if (
      Object.values(authInfoState).filter((value) => value === 2).length !== 5
    ) {
      setTableErrorState((draft) => {
        draft.authInfoState.touched = true;
      });
    }

    if (
      Object.values(authInfoState).filter((value) => value === 2).length < 5
    ) {
      setTableErrorState((draft) => {
        draft.authInfoState.state = false;
      });
    } else {
      if (tableErrorState.authInfoState.touched) {
        setTableErrorState((draft) => {
          draft.authInfoState.state = true;
          draft.authInfoState.message = "최소 1개 이상의 등급을 선택해 주세요.";
        });
      }
    }
  }, [authInfoState]);

  return (
    <AuthDetailPageRootLayout>
      <Heading>어드민 회원 추가</Heading>
      <MemberAdminBasicInformation
        state={basicState}
        setState={setBasicState}
        errorState={basicErrorState}
        handleChangeInput={handleChangeBasicInput}
        handleBlurError={handleBlurBasicError}
        tableErrorState={tableErrorState}
        duplicateState={duplicateState}
      />
      <MemberAdminProfileInformation
        state={profileState}
        setState={setProfileState}
        gender={basicState.gender}
        errorState={profileErrorState}
        handleChangeInput={handleChangeProfileInput}
        handleBlurError={handleBlurProfileError}
        tableErrorState={tableErrorState}
        duplicateState={duplicateState}
      />
      <MemberAdminIntroduce
        state={introduceState}
        setState={setIntroduceState}
        errorState={introduceErrorState}
        handleChangeInput={handleChangeIntroduceInput}
        handleBlurError={handleBlurIntroduceError}
        tableErrorState={tableErrorState}
      />
      <MemberAdminAuthInformation
        state={authInfoState}
        setState={setAuthInfoState}
        tableErrorState={tableErrorState}
      />
      <ButtonWrapper>
        <SubmitButton onClick={handleSubmit}>완료</SubmitButton>
      </ButtonWrapper>
    </AuthDetailPageRootLayout>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 15px 0 15px 0;

  button + button {
    margin-left: 10px;
  }
`;
