import React from "react";
import styled from "styled-components";

export default function FormTable({ children }) {
  return <Root>{children}</Root>;
}

FormTable.Row = function Row({ contentCount, children }) {
  return <RowRoot contentCount={contentCount}>{children}</RowRoot>;
};

FormTable.Content = function Content({ heading, require, children }) {
  return (
    <ContentRoot>
      <Heading>
        {heading}
        {require && <Require>*</Require>}
      </Heading>
      <ContentWrapper>{children}</ContentWrapper>
    </ContentRoot>
  );
};

const Root = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 1px;
  height: min-content;
  border-top: 1px solid ${({ theme }) => theme.color.gray_E6};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
  background-color: ${({ theme }) => theme.color.gray_E6};
`;

const RowRoot = styled.div`
  display: grid;
  grid-template-columns: ${({ contentCount }) =>
    `repeat(${contentCount}, 1fr)`};
  column-gap: 1px;
  min-height: 48px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  width: 190px;
  padding-left: 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_6C};
  background-color: ${({ theme }) => theme.color.gray_F5};
`;

const ContentRoot = styled.div`
  display: grid;
  grid-template-columns: 190px 1fr;
  column-gap: 1px;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 20px;
  background-color: white;
`;

const Require = styled.span`
  margin-left: 2px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.red_FF};
`;
