import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { memberUserDetailPaymentDetail } from "store/reducer/memberUserDetailPaymentDetail";
import { paymentDetailFilter } from "assets/static/dropdown";
import {
  Dropdown,
  LabelDropdown,
  RefreshButton,
  SubmitButton,
} from "components/index";
import { paymentExcelDownload } from "store/reducer/excelDownload";
import ExcelDownButton from "components/button/ExcelDownButton";

export default function PaymentDetailFilter({ filterState, setFilterState }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userName = useSelector(
    (state) => state.memberUserDetail.basicInformation.name,
  );

  const handleClickSearch = () => {
    const obj = { id };

    if (filterState.type !== "전체") {
      obj.type = filterState.type;
    }
    if (filterState.type === "" || filterState.type === "전체") {
      delete obj.type;
    }
    obj.page = 1;

    dispatch(memberUserDetailPaymentDetail(obj));
  };

  const handleClickRefresh = () => {
    dispatch(memberUserDetailPaymentDetail({ id }));
    setFilterState((draft) => {
      draft.type = "";
    });
  };

  const handleExcelDownload = () => {
    dispatch(
      paymentExcelDownload({
        userID: id,
        fileName: `스톤히스토리_${userName}`,
      }),
    );
  };

  return (
    <Root>
      <LeftSection>
        <CustomLabelDropdown
          selectLabel={paymentDetailFilter[filterState.type]}
          label="구분"
        >
          <Dropdown.Option
            setDropdownState={setFilterState}
            name="type"
            label="전체"
          >
            전체
          </Dropdown.Option>
          {Object.entries(paymentDetailFilter).map(([key, value], index) => {
            return (
              <Dropdown.Option
                key={index}
                setDropdownState={setFilterState}
                name="type"
                label={key}
                value={value}
              >
                {value}
              </Dropdown.Option>
            );
          })}
        </CustomLabelDropdown>
        <CustomSubmitButton onClick={handleClickSearch}>
          조회
        </CustomSubmitButton>
        <RefreshButton onClick={handleClickRefresh}>새로고침</RefreshButton>
      </LeftSection>
      <ExcelDownButton onClick={handleExcelDownload} />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
`;

const LeftSection = styled.div`
  display: flex;
`;

const CustomLabelDropdown = styled(LabelDropdown)`
  margin: 0 20px 0 0;

  &:not(:last-child) {
    ::after {
      margin: 0;
      border: none;
    }
  }
`;

const CustomSubmitButton = styled(SubmitButton)`
  margin-right: 10px;
`;
