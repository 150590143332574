import { useState, useEffect, useRef, useCallback } from "react";

const useModal = (modalType) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpener, setModalOpener] = useState(null);

  const modalRef = useRef(null);

  const handleModalOpen = useCallback((event) => {
    event.stopPropagation();
    setModalOpener(document.activeElement);
    setIsModalOpen(true);
    document.body.style.cssText = "overflow: hidden";
    setTimeout(() => modalRef.current?.focus());
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    document.body.style.cssText = "overflow: auto";
    modalOpener && modalOpener.focus();
  }, [modalOpener]);

  const handleDimClick = useCallback((event) => {
    const { target, currentTarget } = event;
    target === currentTarget && handleModalClose();
  }, []);

  const handleKeyTrap = useCallback((event) => {
    if (!modalRef.current) {
      return;
    }
    const focusableNodeList = modalRef.current.querySelectorAll(
      "[href], [tabIndex], button, input, textarea, select",
    );
    const shiftKey = event.shiftKey;
    const eventTarget = event.target;
    const firstFocusableNdoe = focusableNodeList[0];
    const lastFocusableNode = focusableNodeList[focusableNodeList.length - 1];
    const isFirstFocusableNode = Object.is(eventTarget, firstFocusableNdoe);
    const isLastFocusableNode = Object.is(eventTarget, lastFocusableNode);

    if (shiftKey && isFirstFocusableNode) {
      event.preventDefault();
      lastFocusableNode.focus();
    }
    if (!shiftKey && isLastFocusableNode) {
      event.preventDefault();
      firstFocusableNdoe.focus();
    }
  }, []);

  useEffect(() => {
    const keyListenerMap = new Map([
      [27, modalType === "close" && handleModalClose],
      [9, handleKeyTrap],
    ]);

    const handleKeyListener = (event) => {
      const listener = keyListenerMap.get(event.keyCode);
      typeof listener === "function" && listener(event);
    };

    window.addEventListener("keydown", handleKeyListener);

    return () => {
      window.removeEventListener("keydown", handleKeyListener);
    };
  }, []);

  return {
    isModalOpen,
    modalRef,
    handleModalOpen,
    handleModalClose,
    handleDimClick,
  };
};

export default useModal;
