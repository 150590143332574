import { call, put, takeLatest } from "redux-saga/effects";

import { logoutAPI } from "api/service/logout";
import { logout, logoutSuccess, logoutFailure } from "store/reducer/logout";
import { PATH } from "utils/constants";

function* logoutSaga(action) {
  try {
    yield call(logoutAPI);
    yield put({
      type: logoutSuccess,
    });

    action.payload.navigate(PATH.root);
  } catch (err) {
    yield put({
      type: logoutFailure,
      error: err.response.data,
    });
  }
}

export function* watchLogout() {
  yield takeLatest(logout, logoutSaga);
}
