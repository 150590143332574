import React from "react";
import styled from "styled-components";

import {
  AuthInformationImageView,
  AuthInformationRadioButton,
  ImageDetailViewModal,
} from "components/index";
import { authStage } from "assets/static/radioButton";

export default function AuthInformation({
  data,
  pageName,
  handleChangeInputValue,
  handleClickRadioButton,
  status,
  ...restProps
}) {
  const { authState, authImageArray } = data;

  return (
    <Root {...restProps}>
      <Heading>인증 정보</Heading>
      <TableWrapper>
        <NicknameTitle>닉네임</NicknameTitle>
        <Nickname>{data["nickname"]}</Nickname>
        {pageName === "education" && (
          <>
            <EducationTitle>학력</EducationTitle>
            <Education>
              {status === "승인대기" ? (
                <Input
                  value={data["university"]}
                  onChange={handleChangeInputValue}
                />
              ) : (
                <span>{data["university"]}</span>
              )}
            </Education>
          </>
        )}
        {pageName === "occupation" && (
          <>
            <EducationTitle>직업</EducationTitle>
            <Education>
              {status === "승인대기" ? (
                <Input
                  value={data["occupation"]}
                  onChange={handleChangeInputValue}
                />
              ) : (
                <span>{data["occupation"]}</span>
              )}
            </Education>
          </>
        )}
        {pageName === "income" && (
          <>
            <IncomeTitle />
            <Income></Income>
          </>
        )}
        <AuthImageTitle>인증서류</AuthImageTitle>
        <AuthImageItem>
          <AuthInformationImageView
            srcArray={authImageArray}
            render={(
              handleModalClose,
              handleDimClick,
              selectImage,
              isModalOpen,
            ) => {
              return (
                <ImageDetailViewModal
                  srcArray={authImageArray}
                  handleModalClose={handleModalClose}
                  handleDimClick={handleDimClick}
                  selectImage={selectImage}
                  isModalOpen={isModalOpen}
                  title="인증 요청 사진"
                />
              );
            }}
          />
        </AuthImageItem>
        <AuthStageTitle>인증단계</AuthStageTitle>
        {status === "반려" ? (
          <AuthStageNull>-</AuthStageNull>
        ) : (
          <AuthStageItem
            list={authStage}
            selectRadioState={authState}
            handleClickRadioButton={handleClickRadioButton}
            status={status}
          />
        )}
      </TableWrapper>
    </Root>
  );
}

const Root = styled.section`
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.12);
`;

const Heading = styled.h3`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.black};
  padding: 17px 0 17px 30px;
`;

const Input = styled.input`
  width: 310px;
  height: 34px;
  padding: 8px 0 8px 12px;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
`;

const TableWrapper = styled.div`
  padding: 30px;
  display: grid;
  border-top: 1px solid ${({ theme }) => theme.color.gray_E6};
  grid-template-columns: 190px 350px 190px 350px;
  grid-template-areas:
    "nickname nicknameItem education educationItem"
    "authImageArray authImageArrayItem authImageArrayItem authImageArrayItem"
    "authStage authStageItem authStageItem authStageItem";
`;

const ItemTitle = styled.div`
  min-width: 190px;
  height: 48px;
  line-height: 48px;
  padding-left: 20px;
  background-color: ${({ theme }) => theme.color.gray_F5};
  border-top: 1px solid ${({ theme }) => theme.color.gray_E6};
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_6C};

  :last-child {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
  }
`;

const NicknameTitle = styled(ItemTitle)`
  grid-area: nickname;
  border-right: 1px solid ${({ theme }) => theme.color.gray_E6};
`;

const EducationTitle = styled(ItemTitle)`
  grid-area: education;
  border-left: 1px solid ${({ theme }) => theme.color.gray_E6};
  border-right: 1px solid ${({ theme }) => theme.color.gray_E6};
`;

const IncomeTitle = styled(ItemTitle)`
  grid-area: education;
  background-color: inherit;
`;

const AuthImageTitle = styled(ItemTitle)`
  grid-area: authImageArray;
  height: 160px;
  line-height: 160px;
  border-right: 1px solid ${({ theme }) => theme.color.gray_E6};
`;

const AuthStageTitle = styled(ItemTitle)`
  grid-area: authStage;
  border-right: 1px solid ${({ theme }) => theme.color.gray_E6};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
`;

const Item = styled.div`
  font-size: 14px;
  min-width: 350px;
  height: 48px;
  line-height: 48px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.color.gray_E6};
`;

const Nickname = styled(Item)`
  grid-area: nicknameItem;
`;

const Education = styled(Item)`
  grid-area: educationItem;
`;

const AuthImageItem = styled(Item)`
  grid-area: authImageArrayItem;
  width: 890px;
  height: 160px;
`;

const Income = styled(Item)`
  grid-area: educationItem;
`;

const AuthStageItem = styled(AuthInformationRadioButton)`
  display: flex;
  align-items: center;
  padding-left: 20px;
  grid-area: authStageItem;
  border-top: 1px solid ${({ theme }) => theme.color.gray_E6};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
`;

const AuthStageNull = styled.span`
  display: flex;
  align-items: center;
  padding-left: 20px;
  grid-area: authStageItem;
  border-top: 1px solid ${({ theme }) => theme.color.gray_E6};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
`;
