export const adminCreateList = {
  basicInfo: [
    [
      {
        key: "email",
        heading: "아이디",
        require: true,
        placeholder: "이메일 입력",
      },
    ],
    [
      {
        key: "password",
        heading: "비밀번호",
        require: true,
        placeholder: "비밀번호 입력",
      },
      {
        key: "passwordConfirm",
        heading: "비밀번호 확인",
        require: true,
        placeholder: "비밀번호 확인",
      },
    ],
    [
      {
        key: "name",
        heading: "이름",
        require: true,
        placeholder: "이름 입력",
      },
      {
        key: "phone",
        heading: "연락처",
        require: true,
        placeholder: "휴대폰번호 입력",
      },
    ],
    [
      {
        key: "birthday",
        heading: "생년월일",
        require: true,
        placeholder: "YYYYMMDD",
      },
      { key: "gender", heading: "성별" },
    ],
    [
      { key: "userStatusId", heading: "활동 상태" },
      { key: "created", heading: "가입일" },
    ],
    [
      { key: "lastLogin", heading: "최근 접속일" },
      { key: "internalGrade", heading: "내부 관리 등급" },
    ],
  ],
  profileInfo: [
    [
      {
        key: "nickname",
        heading: "닉네임",
        require: true,
        placeholder: "닉네임 입력",
      },
    ],
    [
      {
        key: "area",
        heading: "활동지역",
        require: true,
      },
      {
        key: "height",
        heading: "키",
        require: true,
      },
    ],
    [
      {
        key: "bodyType",
        heading: "체형",
        require: true,
      },
      {
        key: "religion",
        heading: "종교",
        require: true,
      },
    ],
    [
      {
        key: "drinking",
        heading: "음주",
        require: true,
      },
      {
        key: "smoking",
        heading: "흡연",
        require: true,
      },
    ],
    [
      {
        key: "education",
        heading: "학력",
        require: true,
        placeholder: "학력 입력",
      },
      {
        key: "job",
        heading: "직업",
        require: true,
        placeholder: "직업명 입력",
      },
    ],
  ],
  introduce: [
    [
      {
        key: "hobby",
        heading: "나의 취미 & 관심분야",
        require: true,
      },
    ],
    [
      {
        key: "personality",
        heading: "나의 성격 & 장점은?",
        require: true,
      },
    ],
    [
      {
        key: "ideal",
        heading: "내가 선호하는 사람은?",
        require: true,
      },
    ],
    [
      {
        key: "mbti",
        heading: "나의 MBTI는?",
        require: true,
      },
    ],
    [
      {
        key: "introduction",
        heading: "자기소개",
        require: true,
        placeholder: "내용을 입력해 주세요.",
      },
    ],
  ],
  romanticStyle: [
    [{ key: "romanticStyle", heading: "연애 스타일", require: true }],
  ],
  authInfo: [
    [{ key: "appearance", heading: "외모" }],
    [{ key: "body", heading: "바디" }],
    [{ key: "occupation", heading: "직업" }],
    [{ key: "university", heading: "학력" }],
    [{ key: "income", heading: "소득" }],
  ],
};

export const adminCreateInitState = {
  profile1: "",
  profile2: "",
  profile3: "",
  profile4: "",
  profile5: "",
  email: "",
  password: "",
  passwordConfirm: "",
  name: "",
  phone: "",
  birthday: "",
  gender: "M",
  userStatusId: "1",
  internalGrade: "일반",
  nickname: "",
  area: "",
  height: "",
  bodyType: "",
  religion: "",
  drinking: "",
  smoking: "",
  education: "",
  job: "",
  hobby1: "",
  hobby2: "",
  hobby3: "",
  hobby4: "",
  hobby5: "",
  personality1: "",
  personality2: "",
  personality3: "",
  personality4: "",
  personality5: "",
  ideal1: "",
  ideal2: "",
  ideal3: "",
  ideal4: "",
  ideal5: "",
  mbti: "",
  introduction: "",
  romanticStyle: [
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
  ],
  appearance: "0",
  body: "0",
  occupation: "0",
  university: "0",
  income: "0",
};

const tempAdminModifyList = JSON.parse(JSON.stringify(adminCreateList));
tempAdminModifyList.basicInfo[1][1] = {
  key: "userNumber",
  heading: "회원번호",
};

export const adminModifyList = { ...tempAdminModifyList };

export const adminDetailList = {
  romanticStyle: [[{ key: "romanticStyle", heading: "연애 스타일" }]],
};
