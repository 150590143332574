import React from "react";
import styled from "styled-components";

export default function ErrorMessage({ msg }) {
  return <ErrorMsg>{msg}</ErrorMsg>;
}

const ErrorMsg = styled.span`
  display: block;
  margin-top: 13px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.red_FF};
`;
