import { createSlice } from "@reduxjs/toolkit";

export const authInspectionDetailApprovalSlice = createSlice({
  name: "authInspectionDetailApproval",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    authInspectionDetailApproval: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    authInspectionDetailApprovalSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    authInspectionDetailApprovalFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
    authInspectionDetailApprovalReset: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = null;
    },
  },
});

export const {
  authInspectionDetailApproval,
  authInspectionDetailApprovalSuccess,
  authInspectionDetailApprovalFailure,
  authInspectionDetailApprovalReset,
} = authInspectionDetailApprovalSlice.actions;

export default authInspectionDetailApprovalSlice.reducer;
