import { getAuthInspection } from "api/index";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  authInspection,
  authInspectionFailure,
  authInspectionSuccess,
} from "store/reducer/authInspection";

function* authInspectionSaga(action) {
  try {
    const res = yield call(getAuthInspection, action.payload);

    yield put({
      type: authInspectionSuccess,
      data: res.data,
    });
  } catch (err) {
    yield put({
      type: authInspectionFailure,
      error: err.response.data,
    });
  }
}

export function* watchAuthInspection() {
  yield takeLatest(authInspection, authInspectionSaga);
}
