import { createSlice } from "@reduxjs/toolkit";

export const memberAdminModifySlice = createSlice({
  name: "memberAdminModify",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    memberAdminModify: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    memberAdminModifySuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    memberAdminModifyFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  memberAdminModify,
  memberAdminModifySuccess,
  memberAdminModifyFailure,
} = memberAdminModifySlice.actions;

export default memberAdminModifySlice.reducer;
