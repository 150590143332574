import React, { useState } from "react";
import styled from "styled-components";

import { InputCell } from "components/index";
import { HiddenPassword, ShowPassword } from "assets/icon/index";

export default function PasswordCell({ ...restProps }) {
  const [isVisible, setIsVisible] = useState(false);

  const handleChangeVisibleState = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Root>
      <CustomInputCell type={isVisible ? "text" : "password"} {...restProps} />
      <ChangeInputTypeButton onClick={handleChangeVisibleState}>
        {isVisible ? <HiddenPassword /> : <ShowPassword />}
      </ChangeInputTypeButton>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
`;

const CustomInputCell = styled(InputCell)`
  input {
    padding-right: 30px;
  }
`;

const ChangeInputTypeButton = styled.button`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 16px;
  right: 32px;
`;
