import { gender, height, internalGrade, userStatusId } from "./dropdown";

export const basicInformationTable = {
  user_type: "구분",
  email: "아이디",
  name: "이름",
  nickname: "닉네임",
  birthday: "생년월일",
  gender: "성별",
  status: "처리상태",
  created: "신청일시",
};

export const profileBasicInformationTable = {
  name: "이름",
  user_number: "회원번호",
  user_type: "구분",
  gender: "성별",
  birthday: "생년월일",
  email: "아이디",
  created: "가입일",
  status: "처리상태",
  // ci: "CI",
};

export const memberUserBasicInformationTable = {
  name: "이름",
  nickname: "닉네임",
  userNumber: "회원번호",
  birthday: "생년월일",
  gender: "성별",
  userStatusId: "활동 상태",
  email: "아이디",
  internalGrade: "관리 등급",
  avgScore: "전체 평점",
  created: "가입일",
  approved: "가입 승인일",
  lastLogin: "최근 접속일시",
  // ci: "CI",
};

export const memberUserProfileInformationTable = {
  height: "키",
  body_type: "체형",
  area: "활동 지역",
  religion: "종교",
  smoking: "흡연",
  drinking: "음주",
  job: "직업",
  education: "학력",
};

export const memberUserIntroductionInformationTable = {
  hobby: "나의 취미 & 관심분야",
  personality: "나의 성격 & 장점은?",
  ideal: "내가 선호하는 사람은?",
  mbti: "나의 MBTI는?",
  introduction: "자기소개",
};

export const memberUserAuthInformationTable = {
  appearance: "외모",
  body: "바디",
  occupation: "직업",
  university: "학력",
  income: "소득",
};

export const memberUserPaymentInformationTable = {
  purchase: "누적 결제 금액",
  purchase_stone: "누적 구매 스톤",
  spend: "누적 소비 금액",
  spend_stone: "누적 소비 스톤",
  free: "받은 누적 무료 스톤",
  remain_stone: "잔여 스톤",
};

export const memberUserReportInformationTable = {
  admonishment: "누적 경고 횟수",
};

export const authProfileInformation = {
  nickname: "닉네임",
  job: "직업",
  education: "학력",
  introduction: "자기소개",
};

export const authInformation = {
  nickname: "닉네임",
  education: "학력",
  authImageArray: "인증서류",
  authStage: "인증단계",
};

export const memberAdminIntroduceTable = [
  {
    key: "personality",
    label: "성격 & 가치관",
    type: "dropdowns",
    count: 3,
    essential: true,
  },
  {
    key: "hobby",
    label: "취미",
    type: "dropdowns",
    count: 3,
    essential: true,
  },
  {
    key: "interest",
    label: "관심분야",
    type: "inputs",
    essential: true,
    count: 5,
  },
  {
    key: "attraction",
    label: "나만의 매력은",
    type: "inputs",
    essential: true,
    count: 5,
  },
  {
    key: "ideal",
    label: "내 이상형은",
    type: "inputs",
    essential: true,
    count: 5,
  },
  {
    key: "introduction",
    label: "자기소개",
    type: "textArea",
    essential: true,
  },
];

export const memberAdminProfileTable = [
  {
    key: "nickname",
    label: "닉네임",
    type: "input",
    essential: true,
    placeholder: "닉네임 입력",
  },
  {
    key: "area",
    label: "활동지역",
    type: "dropdown",
    essential: true,
  },
  {
    key: "height",
    label: "키",
    type: "dropdown",
    essential: true,
    dropdownList: height,
  },
  {
    key: "body_type",
    label: "체형",
    type: "dropdown",
    essential: true,
  },
  {
    key: "religion",
    label: "종교",
    type: "dropdown",
    essential: true,
  },
  {
    key: "drinking",
    label: "음주",
    type: "dropdown",
    essential: true,
  },
  {
    key: "smoking",
    label: "흡연",
    type: "dropdown",
    essential: true,
  },
  {
    key: "education",
    label: "학력",
    type: "input",
    essential: true,
    placeholder: "학력 입력",
  },
  {
    key: "job",
    label: "직업",
    type: "input",
    essential: true,
    placeholder: "직업명 입력",
  },
];

export const memberAdminBasicInformation = [
  {
    key: "email",
    label: "아이디",
    type: "input",
    essential: true,
    placeholder: "이메일 입력",
  },
  {
    key: "password",
    label: "비밀번호",
    type: "input",
    essential: true,
    placeholder: "비밀번호 입력",
  },
  {
    key: "passwordConfirm",
    label: "비밀번호 확인",
    type: "input",
    essential: true,
    placeholder: "비밀번호 확인",
  },
  {
    key: "name",
    label: "이름",
    type: "input",
    essential: true,
    placeholder: "이름 입력",
  },
  {
    key: "phone",
    label: "연락처",
    type: "input",
    essential: true,
    placeholder: "휴대폰번호 입력",
  },
  {
    key: "birthday",
    label: "생년월일",
    type: "input",
    essential: true,
    placeholder: "YYYYMMDD",
  },
  {
    key: "gender",
    label: "성별",
    type: "dropdown",
    dropdownList: gender,
  },
  {
    key: "userStatusId",
    label: "활동 상태",
    type: "dropdown",
    dropdownList: userStatusId,
  },
  {
    key: "created",
    label: "가입일",
    type: "cell",
  },
  {
    key: "lastLogin",
    label: "최근 접속일",
    type: "cell",
  },
  {
    key: "internalGrade",
    label: "내부 관리 등급",
    type: "dropdown",
    dropdownList: internalGrade,
  },
];

export const memberAdminDetailIntroduceTable = {
  hobby: "나의 취미 & 관심분야",
  personality: "나의 성격 & 장점은?",
  ideal: "내가 선호하는 사람은?",
  mbti: "나의 MBTI는?",
  introduction: "자기소개",
};

export const memberAdminDetailProfileTable = {
  nickname: "닉네임",
  area: "활동지역",
  height: "키",
  body_type: "체형",
  religion: "종교",
  drinking: "음주",
  smoking: "흡연",
  education: "학력",
  job: "직업",
};

export const memberAdminDetailBasicInformation = {
  email: "아이디",
  password: "비밀번호",
  user_number: "회원번호",
  name: "이름",
  phone: "연락처",
  birthday: "생년월일",
  gender: "성별",
  user_status_id: "활동 상태",
  created: "가입일",
  last_login: "최근 접속일",
  internal_grade: "내부 관리 등급",
};

export const memberAdminModifyBasicInformation = [
  {
    key: "email",
    label: "아이디",
    type: "cell",
    essential: true,
    placeholder: "이메일 입력",
  },
  {
    key: "password",
    label: "비밀번호",
    type: "cell",
    essential: true,
    placeholder: "비밀번호 입력",
  },
  {
    key: "userNumber",
    label: "회원번호",
    type: "cell",
  },
  {
    key: "name",
    label: "이름",
    type: "cell",
    essential: true,
    placeholder: "이름 입력",
  },
  {
    key: "phone",
    label: "연락처",
    type: "input",
    essential: true,
    placeholder: "휴대폰번호 입력",
  },
  {
    key: "birthday",
    label: "생년월일",
    type: "cell",
    essential: true,
    placeholder: "YYYYMMDD",
  },
  {
    key: "gender",
    label: "성별",
    type: "cell",
    essential: true,
    dropdownList: gender,
  },
  {
    key: "userStatusId",
    label: "활동 상태",
    type: "dropdown",
    dropdownList: userStatusId,
  },
  {
    key: "created",
    label: "가입일",
    type: "cell",
  },
  {
    key: "lastLogin",
    label: "최근 접속일",
    type: "cell",
  },
  {
    key: "internalGrade",
    label: "내부 관리 등급",
    type: "dropdown",
    dropdownList: internalGrade,
  },
];

export const initUniversityAuthInformation = {
  nickname: "",
  university: "",
  authImageArray: [],
  authState: null,
};

export const initOccupationAuthInformation = {
  nickname: "-",
  occupation: "-",
  authImageArray: [],
  authState: null,
};

export const initIncomeAuthInformation = {
  nickname: "-",
  authImageArray: [],
  authState: null,
};

export const memberUserClearSuspendTable = {
  pic: "담당자",
  period: "정지 기간",
  reason: "정지 사유",
};

export const reporterInformationTable = {
  nickname: "닉네임",
  user_number: "회원번호",
  gender: "성별",
  created: "가입일",
  email: "아이디",
  birthday: "생년월일",
  // ci: "CI",
  last_login: "최근 접속일시",
};

export const reportInformationTable = {
  processed: "처리상태",
  type: "신고 유형",
  admonishment: "(피신고자) 누적 경고",
  reportCreated: "신고 일시",
  content: "신고 내용",
};

export const reportCommunityContentTable = {
  processed: "처리상태",
  type: "신고 유형",
  admonishment: "(피신고자) 누적 경고",
  reportCreated: "신고 일시",
  contentType: "분류",
  image: "첨부 이미지",
  content: "신고 내용",
  reported_content: "게시글 내용",
};

export const reportCommunityReplyTable = {
  processed: "처리상태",
  type: "신고 유형",
  admonishment: "(피신고자) 누적 경고",
  reportCreated: "신고 일시",
  contentType: "분류",
  image: "첨부 이미지",
  content: "신고 내용",
  reported_reply: "댓글 내용",
};

export const reportProcssType = {
  1: "반려",
  2: "경고",
  3: "정지",
  4: "영구정지",
};
