import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { Heading, Pagination, ListTable } from "components/index";
import { excelDownload } from "store/reducer/excelDownload";
import ExcelDownButton from "components/button/ExcelDownButton";
import { memberUserTable, memberUserTableWidth } from "assets/static/listTable";
import useListTable from "hooks/useListTable";

export default function MemberTable({ data, pageData, pageName, excel }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { handleGoToDetail } = useListTable(
    `${pathname}/${pathname.split("/")[2]}detail`,
  );

  const handleExcelDownload = () => {
    const type = pageName === "memberUser" ? "normal" : "admin";
    const fileName =
      pageName === "memberUser" ? "일반회원목록" : "어드민회원목록";
    dispatch(excelDownload({ type, fileName }));
  };

  return (
    <Root>
      <CustomHeading as="h3">
        회원 목록
        {excel && <CustomExcelDownload onClick={handleExcelDownload} />}
      </CustomHeading>
      <TableWrapper>
        <ListTable
          list={memberUserTable}
          width={memberUserTableWidth}
          data={data}
          handleGoToDetail={handleGoToDetail}
        />
      </TableWrapper>
      <PaginationWrapper>
        <Pagination pageData={pageData} pageName={pageName} />
      </PaginationWrapper>
    </Root>
  );
}

const Root = styled.div`
  min-width: 1140px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.12);
`;

const CustomHeading = styled(Heading)`
  padding: 17px 0 17px 30px;
  font-size: 18px;
  font-weight: 500;
  position: relative;
`;

const CustomExcelDownload = styled(ExcelDownButton)`
  position: absolute;
  top: 7px;
  right: 30px;
`;

const TableWrapper = styled.div`
  padding: 30px 30px 40px 30px;
  border-top: 1px solid ${({ theme }) => theme.color.gray_E6};
`;

const PaginationWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: end;
  padding-right: 30px;
  background-color: ${({ theme }) => theme.color.gray_F5};
`;
