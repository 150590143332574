import { call, put, takeLatest } from "redux-saga/effects";
import Cookies from "js-cookie";

import { loginAPI } from "api/service/login";
import { login, loginSuccess, loginFailure } from "store/reducer/login";
import { PATH } from "utils/constants";

function* loginSaga(action) {
  try {
    const res = yield call(loginAPI, action.payload);
    yield put({
      type: loginSuccess,
      data: res.data,
    });

    const parseJwt = (token) => {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );

      return JSON.parse(jsonPayload).expires_in;
    };

    Cookies.set("userToken", res.data.accesstoken);
    Cookies.set("refreshToken", res.data.refreshtoken, {
      ...(localStorage.getItem("keepLogin") === "true" && {
        expires: new Date(parseJwt(res.data.refreshtoken)),
      }),
    });
    Cookies.set("auth", res.data.auth);

    const auth = res.data.auth;

    if (auth.includes("master") || auth.includes("user")) {
      action.payload.navigate(PATH.memberUser.fullPath);
    } else if (auth.includes("admin_user")) {
      action.payload.navigate(PATH.memberAdmin.fullPath);
    } else if (auth.includes("profile")) {
      action.payload.navigate(PATH.authProfile.fullPath);
    } else if (auth.includes("certificate")) {
      action.payload.navigate(PATH.authAppearance.fullPath);
    } else if (auth.includes("profile_report")) {
      action.payload.navigate(PATH.reportProfile.fullPath);
    } else if (auth.includes("community_report")) {
      action.payload.navigate(PATH.reportCommunity.fullPath);
    }
  } catch (err) {
    yield put({
      type: loginFailure,
      error: err.response.data.detail,
    });
  }
}

export function* watchLogin() {
  yield takeLatest(login, loginSaga);
}
