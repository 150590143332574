const size = {
  HEADER_HEIGHT: "54px",
  FOOTER_HEIGHT: "60px",
  HEADER_FOOTER_MIN_WIDTH: "1140px",
  NAV_FOLD_WIDTH: "68px",
  NAV_UNFOLD_WIDTH: "230px",
  DETAIL_WIDTH: "1110px",
  LAYOUT_PADDING: "50px",
};

const zIndex = {
  MAIN: 10,
  NAV: 100,
  HEADER_FOOTER: 1000,
  MODAL: 10000,
};

export const color = {
  white: "#ffffff",
  black: "#000000",
  black_E6: "#e6e6e6",
  black_7B: "#7b7b7b",
  blue_00: "#0069f9",
  blue_57: "#5786ed",
  blue_EB: "#ebf3ff",
  blue_F5: "#F5F9FF",
  gray_AD: "#ADADAD",
  gray_F5: "#f5f5f5",
  gray_E6: "#e6e6e6",
  gray_DE: "#dedede",
  gray_D6: "#d6d6d6",
  gray_5B: "#5b5b5b",
  gray_93: "#939393",
  gray_6C: "#6c6c6c",
  gray_69: "#696969",
  gray_E2: "#e2e2e2",
  gray_2B: "#2b2b2b",
  green_E3: "#E3F6E0",
  green_46: "#469038",
  red_FC: "#fcd7d7",
  red_D5: "#d55e5e",
  red_FF: "#ff5050",
  nav_bg: "#ffffff",
  nav_bg_selected: "#ebf3ff",
  navSelectedBackground: "#12171d",
  navNotSelectedColor: "#b1b1b1",
  brand_primary: "#4493F9",
  bg_primary: "#FAFAFA",
  bg_secondray: "#0F1822",
  bg_secondray_alpha: "rgba(15, 24, 34, 0.85)",
  bg_white: "#fff",
  division_border: "#e6e6e6",
  header_bg: "#2b2b2b",
  input_border_white: "#adadad",
  input_border_blue: "#0069f9",
  input_bg_brown: "#403530",
  input_error: "#ff3333",
  text_white: "#ffffff",
  text_blue30: "#5786ed",
  text_blue00: "#0069f9",
  text_grayAd: "#adadad",
  text_gray7b: "#7b7b7b",
  text_gray5b: "#5b5b5b",
  text_gray93: "#939393",
  text_error: "#ff5050",
  button_border_gray_light: "#D6DDE0",
  button_bg_blue: "#0069f9",
  step_wait_reservation: "#B7E3FF",
  step_complete_reservation: "#3ABAFF",
  step_proceeding: "#1A7BFF",
  step_complete: "#414D6B",
  step_cancel_rservation: "#D3DBE0",
  bg_box_F5: "#F5F7F8",
  bg_box_F9: "#F9FAFb",
  bg_header: "#FFF",
  bg_alpha_2: "rgba(255,255,255, 0.2)",
  bg_alpha_3: "rgba(255,255,255, 0.3)",
  bg_select: "#ECF4FF",
  bg_select_bay: "rgba(68, 147, 249, 0.1)",
  border: "#E5E8EB",
  border_grayE6: "#e6e6e6",
  border_grayDe: "#dedede",
  border_gray93: "#939393",
  bg_blueEb: "#ebf3ff",
  error: "#FF0B0B",
  disabled: "#E5E8EB",
};

export default { size, zIndex, color };
