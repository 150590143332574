import { createSlice } from "@reduxjs/toolkit";

export const reportProfileSlice = createSlice({
  name: "reportProfile",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
    data: [],
    pageInfo: {},
  },
  reducers: {
    reportProfile: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    reportProfileSuccess: (state, action) => {
      const { reports, pageInfo } = action.data;

      state.isLoading = false;
      state.isDone = true;
      state.data = reports;
      state.pageInfo = pageInfo;
    },
    reportProfileFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const { reportProfile, reportProfileSuccess, reportProfileFailure } =
  reportProfileSlice.actions;

export default reportProfileSlice.reducer;
