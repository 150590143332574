import { ReplyIcon } from "assets/icon/index";
import React from "react";
import styled from "styled-components";

export default function CommunityReportCell({
  title,
  replyData,
  ...restProps
}) {
  return (
    <Root {...restProps}>
      <TitleWrapper>
        <Title htmlFor={title} title={title}>
          {title}
        </Title>
      </TitleWrapper>
      <ContentWrapper>
        {replyData?.map((item, index) => {
          const { nickname, content, mention_nickname, is_reported } = item;
          return (
            <ReplyWrapper key={index}>
              {mention_nickname && <CustomReplyIcon />}
              <Nickname isReported={is_reported}>{nickname}:</Nickname>
              <Reply isReported={is_reported}>
                {mention_nickname && `@${mention_nickname},`}
                {content}
              </Reply>
            </ReplyWrapper>
          );
        })}
      </ContentWrapper>
    </Root>
  );
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 190px 1fr;
  column-gap: 1px;
  row-gap: 1px;
`;

const TitleWrapper = styled.div`
  height: 48px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray_F5};
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_6C};
`;

const Title = styled.label`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const ContentWrapper = styled.div`
  height: 48px;
  padding: 16px 20px 0 20px;
  background-color: ${({ theme }) => theme.color.white};
  overflow-y: scroll;
`;

const ReplyWrapper = styled.div`
  margin-bottom: 3px;
`;

const CustomReplyIcon = styled(ReplyIcon)`
  padding-top: 5px;
`;

const Nickname = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  color: ${({ theme, isReported }) => isReported && theme.color.red_D5};
`;

const Reply = styled.span`
  font-size: 14px;
  line-height: 1.5;
  padding-left: 5px;
  color: ${({ theme, isReported }) =>
    isReported ? theme.color.red_D5 : theme.color.gray_2B};
`;
