import { call, put, takeLatest, all } from "redux-saga/effects";

import {
  deleteMemberAdmin,
  getMemberUserDetail,
  postAWS,
  postPresigned,
  putMemberAdminModify,
} from "api/index";
import {
  memberAdminDetail,
  memberAdminDetailFailure,
  memberAdminDetailSuccess,
} from "store/reducer/memberAdminDetail";
import {
  memberAdminModify,
  memberAdminModifySuccess,
  memberAdminModifyFailure,
} from "store/reducer/memberAdminModify";
import {
  memberAdminDelete,
  memberAdminDeleteSuccess,
  memberAdminDeleteFailure,
} from "store/reducer/memberAdminDelete";
import { authInformatinFormatter } from "utils/helper/index";
import { toastShow } from "store/reducer/toast";
import { PATH } from "utils/constants";

function* memberAdminDetailSaga(action) {
  try {
    const { data } = yield call(getMemberUserDetail, action.payload);

    const dataObj = {
      ...data,
      profile_img: data.profile_img.map(({ presigned_url }) => presigned_url),
      appearance: authInformatinFormatter(data.badges[0]),
      body: authInformatinFormatter(data.badges[1]),
      occupation: authInformatinFormatter(data.badges[2]),
      university: authInformatinFormatter(data.badges[3]),
      income: authInformatinFormatter(data.badges[4]),
    };
    yield put({
      type: memberAdminDetailSuccess,
      data: dataObj,
    });
  } catch (err) {
    yield put({
      type: memberAdminDetailFailure,
      data: err.response.data,
    });
  }
}

export function* watchMemberAdminDetail() {
  yield takeLatest(memberAdminDetail, memberAdminDetailSaga);
}

function* memberAdminModifySaga(action) {
  try {
    const { profiles, submitData, id, navigate } = action.payload;

    const isRepModifyImg = !!submitData.rep_img;
    const isModifyImg = !!submitData.new_profile_img;

    if (isRepModifyImg && isModifyImg) {
      // 대표 + 나머지 이미지 수정

      const res = yield all(
        [submitData.rep_img, ...submitData.new_profile_img].map((item) =>
          postPresigned({ s3_folder: "profile", file_id: item }),
        ),
      );

      if (res.every((item) => item.status === 200)) {
        const newProfiles = profiles.filter((profile) => profile[0]?.name);

        const postRes = yield all(
          res.map((item, i) => {
            if (i === 0) {
              return postAWS(item, submitData.rep_img_type, newProfiles[i][0]);
            } else {
              return postAWS(
                item,
                // submitData.new_profile_img_type[i],
                submitData.new_profile_img_type[i - 1],
                newProfiles[i][0],
              );
            }
          }),
        );

        if (postRes.every((item) => item.status === 204)) {
          delete submitData.rep_img_type;
          delete submitData.new_profile_img_type;
          const modifyRes = yield call(putMemberAdminModify, {
            id,
            data: submitData,
          });
          if (modifyRes.status === 200) {
            yield put({ type: memberAdminModifySuccess });
            yield put({
              type: toastShow,
              data: { type: "success", text: "memberAdminModifySuccess" },
            });
            navigate(`${PATH.memberAdminDetail.fullPath}/${id}`);
          }
        }
      }
    } else if (isRepModifyImg) {
      // 대표 이미지만 수정
      const res = yield call(postPresigned, {
        s3_folder: "profile",
        file_id: submitData.rep_img,
      });

      if (res.status === 200) {
        const newProfiles = profiles.filter((profile) => profile[0]?.name);

        yield all(postAWS(res, submitData.rep_img_type, newProfiles[0][0]));

        delete submitData.rep_img_type;
        yield call(putMemberAdminModify, {
          id,
          data: submitData,
        });

        yield put({ type: memberAdminModifySuccess });
        yield put({
          type: toastShow,
          data: { type: "success", text: "memberAdminModifySuccess" },
        });
        navigate(`${PATH.memberAdminDetail.fullPath}/${id}`);
      }
    } else if (isModifyImg) {
      // 나머지 이미지만 수정
      const res = yield all(
        submitData.new_profile_img.map((item) =>
          postPresigned({ s3_folder: "profile", file_id: item }),
        ),
      );

      if (res.every((item) => item.status === 200)) {
        const newProfiles = profiles.filter((profile) => profile[0]?.name);

        const postRes = yield all(
          res.map((item, i) =>
            postAWS(
              item,
              submitData.new_profile_img_type[i],
              newProfiles[i][0],
            ),
          ),
        );

        if (postRes.every((item) => item.status === 204)) {
          delete submitData.new_profile_img_type;
          const modifyRes = yield call(putMemberAdminModify, {
            id,
            data: submitData,
          });

          if (modifyRes.status === 200) {
            yield put({ type: memberAdminModifySuccess });
            yield put({
              type: toastShow,
              data: { type: "success", text: "memberAdminModifySuccess" },
            });
            navigate(`${PATH.memberAdminDetail.fullPath}/${id}`);
          }
        }
      }
    } else {
      // 이미지 삭제만 할 때 or 이미지 수정없이 정보 수정 할 때
      delete submitData.new_profile_img_type;
      const modifyRes = yield call(putMemberAdminModify, {
        id,
        data: submitData,
      });

      if (modifyRes.status === 200) {
        yield put({ type: memberAdminModifySuccess });
        yield put({
          type: toastShow,
          data: { type: "success", text: "memberAdminModifySuccess" },
        });
        navigate(`${PATH.memberAdminDetail.fullPath}/${id}`);
      }
    }
  } catch (err) {
    if (err.response.status === 400) {
      alert(err.response.data.detail);
    }
    yield put({
      type: memberAdminModifyFailure,
      data: err.response.data,
    });
    yield put({
      type: toastShow,
      data: { type: "fail", text: "memberAdminModifyFail" },
    });
  }
}

export function* watchMemberAdminModify() {
  yield takeLatest(memberAdminModify, memberAdminModifySaga);
}

function* memberAdminDeleteSaga(action) {
  const { id, navigate } = action.payload;
  try {
    yield call(deleteMemberAdmin, { id });

    yield put({ type: memberAdminDeleteSuccess });
    navigate(PATH.memberAdmin.fullPath);
    yield put({
      type: toastShow,
      data: { type: "success", text: "memberAdminDeleteSuccess" },
    });
  } catch (err) {
    yield put({ type: memberAdminDeleteFailure });
    yield put({
      type: toastShow,
      data: { type: "fail", text: "memberAdminDeleteSuccess" },
    });
  }
}

export function* watchMemberAdminDelete() {
  yield takeLatest(memberAdminDelete, memberAdminDeleteSaga);
}
