import { createSlice } from "@reduxjs/toolkit";

export const reportCommunityProcessSlice = createSlice({
  name: "reportCommunityProcess",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    reportCommunityProcess: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    reportCommunityProcessSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    reportCommunityProcessFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  reportCommunityProcess,
  reportCommunityProcessSuccess,
  reportCommunityProcessFailure,
} = reportCommunityProcessSlice.actions;

export default reportCommunityProcessSlice.reducer;
