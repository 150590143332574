import React from "react";
import styled from "styled-components";

import { Search } from "assets/icon/index";

export default function SearchInput({
  value,
  handleChangeInputValue,
  handleSubmit,
  ...restProps
}) {
  const handleCustomSubmit = (event) => {
    event.preventDefault();

    if (value === "") return;
    handleSubmit(event);
  };

  return (
    <Root onSubmit={handleCustomSubmit}>
      <Input
        placeholder="검색어를 입력해 주세요."
        onChange={handleChangeInputValue}
        value={value}
        {...restProps}
      />
      <SearchButton>
        <Search />
      </SearchButton>
    </Root>
  );
}

const Root = styled.form`
  width: 270px;
  height: 40px;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.color.border_grayE6};
  border-radius: 5px;
  padding: 0 30px 0 12px;

  &::placeholder {
    color: ${({ theme }) => theme.color.text_grayAd};
  }
`;

const SearchButton = styled.button`
  width: 14px;
  height: 14px;
  position: absolute;
  top: 13px;
  right: 12px;
  background-color: inherit;
`;
