import Dropdown from "components/dropdown/Dropdown";
import React from "react";
import styled from "styled-components";

export default function DropdownCell({
  name,
  title,
  essential,
  list,
  state,
  setState,
  errorState,
  ...restProps
}) {
  return (
    <Root {...restProps}>
      <TitleWrapper>
        <Title>
          {title}
          {essential && <Essential>*</Essential>}
        </Title>
      </TitleWrapper>
      <ContentWrapper>
        <CustomDropdown selectLabel={list?.[state]} errorState={errorState}>
          {list &&
            Object.entries(list).map(([key, value], index) => {
              return (
                <Dropdown.Option
                  key={index}
                  setDropdownState={setState}
                  name={name}
                  label={key}
                  value={value}
                >
                  {value}
                </Dropdown.Option>
              );
            })}
        </CustomDropdown>
      </ContentWrapper>
    </Root>
  );
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 190px 1fr;
  column-gap: 1px;
  row-gap: 1px;
`;

const TitleWrapper = styled.div`
  height: 48px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray_F5};
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_6C};
`;

const Title = styled.span`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const Essential = styled.span`
  margin-left: 2px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.red_FF};
`;

const ContentWrapper = styled.div`
  padding: 7px 0px 7px 20px;
  display: flex;
  background-color: ${({ theme }) => theme.color.white};
`;

const CustomDropdown = styled(Dropdown)`
  width: 156px;
  height: 34px;

  & > button {
    border: ${({ theme, errorState }) =>
      errorState && ` 1px solid ${theme.color.red_FF}`};
  }

  & + & {
    margin-left: 8px;
  }
`;
