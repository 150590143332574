import { ax } from "api/axios";

export const getAuthInspectionDetail = async (payload) => {
  return await ax.get(`/admin/certificate/${payload}`);
};

export const putAuthInspectionDetail = async (payload) => {
  const { data, id } = payload;
  return await ax.put(`/admin/certificate/${id}`, data);
};
