import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { Heading } from "components/index";
import ReportFilter from "../containers/ReportFilter";
import ReportTable from "../containers/ReportTable";
import { reportProfile } from "store/reducer/reportProfile";
import { reportProfileDetailReset } from "store/reducer/reportProfileDetail";
import { ReportProfileClassification } from "assets/static/dropdown";

export default function ReportProfile() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { data, pageInfo } = useSelector((state) => state.reportProfile);

  useEffect(() => {
    dispatch(reportProfileDetailReset());
  }, []);

  useEffect(() => {
    const filterObj = { ...Object.fromEntries(searchParams) };

    dispatch(reportProfile(filterObj));
  }, [searchParams]);

  return (
    <Root>
      <Heading>프로필 신고 관리</Heading>
      <ReportFilter
        reportTypeObj={ReportProfileClassification}
        actionFn={reportProfile}
      />
      <ReportTable data={data} pageData={pageInfo} excel="true" />
    </Root>
  );
}

const Root = styled.section`
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 50px;
`;
