import { createSlice } from "@reduxjs/toolkit";
import { convertUTCtoBirthday } from "utils/helper/formatter";

export const authProfileDetailSlice = createSlice({
  name: "authProfileDetail",
  initialState: {
    isLoading: false,
    isDone: false,
    certification: {},
    profileInformation: {},
    profileSrcArray: [],
    error: null,
  },
  reducers: {
    authProfileDetail: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    authProfileDetailSuccess: (state, action) => {
      const certificationObj = {
        approved: action.data.approved,
        name: action.data.name,
        birthday: action.data.birthday,
        ci: action.data.ci,
        created: convertUTCtoBirthday(action.data.created),
        user_type: action.data.user_type,
        status: action.data.status,
        email: action.data.email,
        phone: action.data.phone,
        gender: action.data.gender,
        profile_created: action.data.profile_created,
        user_number: action.data.user_number,
      };
      const profileInformationObj = {
        nickname: action.data.nickname,
        job: action.data.job,
        education: action.data.education,
        personality: action.data.personality,
        interest: action.data.interest,
        attraction: action.data.attraction,
        ideal: action.data.ideal,
        introduction: action.data.introduction,
        hobby: action.data.hobby,
      };
      const profileSrcArray = action.data.profile_img.map(
        (item) => item.presigned_url,
      );
      state.certification = certificationObj;
      state.profileInformation = profileInformationObj;
      state.profileSrcArray = profileSrcArray;
      state.isLoading = false;
      state.isDone = true;
    },
    authProfileDetailFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
    authProfileDetailReset: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.certification = {};
      state.profileInformation = {};
      state.profileSrcArray = [];
      state.error = null;
    },
  },
});

export const {
  authProfileDetail,
  authProfileDetailSuccess,
  authProfileDetailFailure,
  authProfileDetailReset,
} = authProfileDetailSlice.actions;

export default authProfileDetailSlice.reducer;
