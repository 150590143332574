import React from "react";
import styled from "styled-components";

export default function Cell({ title, children, checkBox, ...restProps }) {
  return (
    <Root {...restProps}>
      <TitleWrapper>
        {checkBox && <Checkbox type="checkbox" id={title} />}
        <Title htmlFor={title} title={title}>
          {title}
        </Title>
      </TitleWrapper>
      <ContentWrapper>
        <Content title={title}>{children}</Content>
      </ContentWrapper>
    </Root>
  );
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 190px 1fr;
  column-gap: 1px;
  row-gap: 1px;
`;

const TitleWrapper = styled.div`
  min-height: 48px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray_F5};
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_6C};
`;

const Title = styled.label`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const Checkbox = styled.input`
  margin-right: 8px;
`;

const ContentWrapper = styled.div`
  min-height: 48px;
  padding: 15px 20px;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.span`
  display: block;
  width: ${({ title }) => title === "CI" && "152px"};
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;
