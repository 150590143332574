import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import {
  DimLayout,
  PortalModal,
  SubmitButton,
  CancelButton,
  Table,
} from "components/index";
import { Close } from "assets/icon/index";
import { memberUserClearSuspendTable } from "assets/static/table";
import { memberUserSuspend } from "store/reducer/memberUserSuspend";
import { memberUserSuspendReason } from "store/reducer/memberUserSuspendReason";

export default function MemberUserClearSuspendModal({
  handleModalClose,
  handleDimClick,
  ...restProps
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [pic, setPic] = useState("");
  const [reason, setReason] = useState("");
  const isDone = useSelector(
    ({ memberUserSuspend }) => memberUserSuspend.isDone,
  );
  const userSuspendReason = useSelector(
    (state) => state.memberUserSuspendReason.data,
  );

  const handleChangePic = (event) => {
    const { value } = event.target;

    setPic(value);
  };

  const handleChangeReason = (event) => {
    const { value } = event.target;

    setReason(value);
  };

  const handleUnSuspendSubmit = () => {
    const obj = {};
    obj.status = 1;
    obj.pic = pic;
    obj.reason = reason;

    dispatch(memberUserSuspend({ userID: id, data: obj }));
  };

  useEffect(() => {
    if (isDone) {
      handleModalClose();
    }
  }, [isDone]);

  useEffect(() => {
    dispatch(memberUserSuspendReason({ id }));
  }, []);

  return (
    <PortalModal>
      <DimLayout handleDimClick={handleDimClick}>
        <Root {...restProps}>
          <Heading>서비스 이용정지 해제</Heading>
          <MainWrapper>
            <CustomTable
              list={memberUserClearSuspendTable}
              data={userSuspendReason}
            />
            <FlexWrapper>
              <Title>담당자</Title>
              <CustomInput
                placeholder="이름을 입력해 주세요"
                value={pic}
                onChange={handleChangePic}
              />
            </FlexWrapper>
            <FlexWrapper>
              <Title>해제 사유</Title>
              <CustomTextArea
                placeholder="내용을 입력해 주세요"
                value={reason}
                onChange={handleChangeReason}
              />
            </FlexWrapper>
          </MainWrapper>
          <ButtonWrapper>
            <CancelButton onClick={handleModalClose}>취소</CancelButton>
            <SubmitButton
              onClick={handleUnSuspendSubmit}
              disabled={pic === "" || reason === ""}
            >
              확인
            </SubmitButton>
          </ButtonWrapper>
          <CloseButton onClick={handleModalClose}>
            <Close width="16px" height="16px" />
          </CloseButton>
        </Root>
      </DimLayout>
    </PortalModal>
  );
}

const Root = styled.section`
  width: 608px;
  background-color: ${({ theme }) => theme.color.white};
  position: relative;
`;

const Heading = styled.h3`
  font-size: 18px;
  font-weight: 500;
  padding: 17px 0 17px 24px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.border_grayE6}`};
`;

const MainWrapper = styled.div`
  margin: 20px 24px 28px 24px;
`;

const CustomTable = styled(Table)`
  width: 100%;
  margin: 0 auto 29px auto;
  grid-template-columns: 100%;
`;

const FlexWrapper = styled.div`
  display: flex;
  margin: 0 0 21px 0;
`;

const Title = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_6C};
  font-weight: 500;

  &::after {
    content: " *";
    color: ${({ theme }) => theme.color.red_FF};
  }
`;

const CustomInput = styled.input`
  width: 214px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  border-radius: 5px;
  margin-left: 62px;
  padding: 11px 12px;
  font-size: 14px;

  ::placeholder {
    color: ${({ theme }) => theme.color.gray_AD};
  }
`;

const CustomTextArea = styled.textarea`
  width: 446px;
  height: 164px;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  border-radius: 5px;
  margin-left: 46px;
  padding: 12px;
  resize: none;
  font-size: 14px;

  ::placeholder {
    color: ${({ theme }) => theme.color.gray_AD};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 15px 24px 15px 0;
  background-color: ${({ theme }) => theme.color.gray_F5};

  button + button {
    margin-left: 10px;
  }
`;

const CloseButton = styled.button`
  width: 16px;
  height: 16px;
  background-color: inherit;
  position: absolute;
  top: 20px;
  right: 24px;
`;
