import dayjs from "dayjs";

export const excelDownloadHelper = (res, filename) => {
  const url = window.URL.createObjectURL(
    new Blob([res.data], { type: res.headers["content-type"] }),
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}_${dayjs().format("YYMMDD")}.xlsx`);
  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }, 100);
};
