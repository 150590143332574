import { call, put, takeLatest, all } from "redux-saga/effects";

import {
  postMemberAdminCreate,
  getDuplicateEmail,
  getDuplicateNickname,
  postAWS,
  postPresigned,
} from "api/index";

import {
  memberAdminCreate,
  memberAdminCreateFailure,
  memberAdminCreateSuccess,
  memberAdminDuplicateEmail,
  memberAdminDuplicateEmailDuplicate,
  memberAdminDuplicateEmailFailure,
  memberAdminDuplicateEmailSuccess,
  memberAdminDuplicateNickname,
  memberAdminDuplicateNicknameDuplicate,
  memberAdminDuplicateNicknameFailure,
  memberAdminDuplicateNicknameSuccess,
} from "store/reducer/memberAdminCreate";
import { toastShow } from "store/reducer/toast";
import { PATH } from "utils/constants";

function* memberAdminCreateSaga(action) {
  try {
    const { submitData, profiles, navigate } = action.payload;

    const postPresignedRes = yield all(
      submitData.images.map((image) =>
        postPresigned({
          s3_folder: "profile",
          file_id: image,
        }),
      ),
    );

    if (postPresignedRes.every((item) => item.status === 200)) {
      const postRes = yield all(
        postPresignedRes.map((item, i) =>
          postAWS(item, submitData.content_type[i], profiles[i][0]),
        ),
      );

      if (postRes.every((item) => item.status === 204)) {
        delete submitData.content_type;
        const createMemberRes = yield call(postMemberAdminCreate, submitData);

        if (createMemberRes.status === 200) {
          navigate(PATH.memberAdmin.fullPath);

          yield put({ type: memberAdminCreateSuccess });
          yield put({
            type: toastShow,
            data: { type: "success", text: "createMemberAdminSuccess" },
          });
        }
      }
    }
  } catch (err) {
    console.error(err);
    yield put({
      type: memberAdminCreateFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberAdminCreate() {
  yield takeLatest(memberAdminCreate, memberAdminCreateSaga);
}

function* memberAdminDuplicateEmailSaga(action) {
  try {
    const { data } = yield call(getDuplicateEmail, action.payload);
    if (data.exists === 1) {
      alert("이미 가입된 아이디입니다.");
      yield put({
        type: memberAdminDuplicateEmailDuplicate,
      });
    } else {
      alert("사용 가능한 아이디입니다.");
      yield put({
        type: memberAdminDuplicateEmailSuccess,
      });
    }
  } catch (err) {
    alert(err.response.data.detail);
    yield put({
      type: memberAdminDuplicateEmailFailure,
      error: err.response.data.detail,
    });
  }
}

export function* watchMemberAdminDuplicateEmail() {
  yield takeLatest(memberAdminDuplicateEmail, memberAdminDuplicateEmailSaga);
}

function* memberAdminDuplicateNicknameSaga(action) {
  try {
    const { data } = yield call(getDuplicateNickname, action.payload);
    if (data === 1) {
      alert("이미 사용중인 닉네임입니다.");
      yield put({
        type: memberAdminDuplicateNicknameDuplicate,
      });
    } else {
      alert("사용 가능한 닉네임입니다.");
      yield put({
        type: memberAdminDuplicateNicknameSuccess,
      });
    }
  } catch (err) {
    yield put({
      type: memberAdminDuplicateNicknameFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberAdminDuplicateNickname() {
  yield takeLatest(
    memberAdminDuplicateNickname,
    memberAdminDuplicateNicknameSaga,
  );
}
