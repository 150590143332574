import React from "react";
import styled from "styled-components";

import { DetailPageSectionLayout, Table } from "components/index";
import {
  reportCommunityContentTable,
  reportCommunityReplyTable,
} from "assets/static/table";

export default function ReportCommunityReportInformation({ report }) {
  return (
    <DetailPageSectionLayout heading="신고 내용">
      {report.reported_content === undefined ? (
        <CustomTable list={reportCommunityReplyTable} data={report} />
      ) : (
        <CustomTable list={reportCommunityContentTable} data={report} />
      )}
    </DetailPageSectionLayout>
  );
}

const CustomTable = styled(Table)`
  & > :nth-child(7),
  & > :nth-child(8) {
    grid-column: auto / span 2;
  }

  & > :nth-child(7) > div {
    min-height: 98px;
  }

  & > :last-child > div {
    min-height: 120px;
  }
`;
