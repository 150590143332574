import { all, fork } from "redux-saga/effects";

import { watchLogin } from "./loginSaga";
import { watchLogout } from "./logoutSaga";
import { watchToast } from "./toastSaga";
import {
  watchAuthInspectionDetail,
  watchAuthInspectionDetailApproval,
  watchAuthInspectionDetailReject,
  watchAuthInspectionDetailUnmasking,
} from "./authInspectionDetailSaga";
import { watchAuthInspection } from "./authInspectionSaga";
import { watchAuthProfile } from "./authProfileSaga";
import {
  watchAuthProfileDetail,
  watchAuthProfileDetailApproval,
  watchAuthProfileDetailReject,
} from "./authProfileDetailSaga";
import { watchMemberUser } from "./memberUserSaga";
import {
  watchMemberUserSuspend,
  watchMemberUserDetail,
  watchMemberUserDetailEditInternalGrade,
  watchMemberUserDetailMatchHistory,
  watchMemberUserDetailPaymentDetail,
  watchMemberUserDetailUserStatus,
  watchMemberUserFreeStone,
  watchMemberUserSuspendReason,
  watchMemberUserCertificate,
} from "./memberUserDetailSaga";
import { watchMemberAdmin } from "./memberAdminSaga";
import {
  watchMemberAdminAreaList,
  watchMemberAdminBodyTypeList,
  watchMemberAdminDrinkingSagaDetail,
  watchMemberAdminDropdownListSaga,
  watchMemberAdminPersonality,
  watchMemberAdminReligion,
  watchMemberAdminSmoking,
} from "./getMemberAdminDropdownListSaga";
import {
  watchMemberAdminCreate,
  watchMemberAdminDuplicateEmail,
  watchMemberAdminDuplicateNickname,
} from "./memberAdminCreateSaga";
import {
  watchMemberAdminDetail,
  watchMemberAdminModify,
  watchMemberAdminDelete,
} from "./memberAdminDetailSaga";
import { watchChangeMemberAdminPassword } from "./passwordSaga";
import {
  watchReportProfile,
  watchReportProfileDetail,
  watchReportProfileProcess,
  watchReportProfileUnmasking,
  watchReportCommunity,
  watchReportCommunityDetail,
  watchReportCommunityProcess,
  watchReportCommunityUnmasking,
  watchReportIndianGame,
  watchReportIndianGameDetail,
  watchReportIndianGameProcess,
  watchReportIndianGameDetailUnmasking,
} from "./reportSaga";
import {
  watchExcelDownload,
  watchPaymentExcelDownload,
  watchReportExcelDownload,
} from "./excelDownloadSaga";
import { watchAnswerList } from "./answerList";

export default function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchLogout),
    fork(watchToast),
    fork(watchAuthInspection),
    fork(watchAuthInspectionDetail),
    fork(watchAuthInspectionDetailApproval),
    fork(watchAuthInspectionDetailReject),
    fork(watchAuthProfile),
    fork(watchAuthProfileDetail),
    fork(watchAuthProfileDetailApproval),
    fork(watchAuthProfileDetailReject),
    fork(watchMemberUser),
    fork(watchMemberUserSuspend),
    fork(watchMemberUserSuspendReason),
    fork(watchMemberUserDetail),
    fork(watchMemberUserDetailMatchHistory),
    fork(watchMemberUserDetailPaymentDetail),
    fork(watchMemberUserDetailUserStatus),
    fork(watchMemberUserDetailEditInternalGrade),
    fork(watchMemberUserCertificate),
    fork(watchMemberAdmin),
    fork(watchMemberAdminAreaList),
    fork(watchMemberAdminBodyTypeList),
    fork(watchMemberAdminDrinkingSagaDetail),
    fork(watchMemberAdminPersonality),
    fork(watchMemberAdminReligion),
    fork(watchMemberAdminSmoking),
    fork(watchMemberAdminDropdownListSaga),
    fork(watchMemberAdminCreate),
    fork(watchMemberAdminDuplicateEmail),
    fork(watchMemberAdminDuplicateNickname),
    fork(watchMemberAdminDetail),
    fork(watchMemberAdminModify),
    fork(watchChangeMemberAdminPassword),
    fork(watchMemberUserFreeStone),
    fork(watchMemberAdminDelete),
    fork(watchReportProfile),
    fork(watchReportProfileDetail),
    fork(watchReportProfileProcess),
    fork(watchReportCommunity),
    fork(watchReportCommunityDetail),
    fork(watchReportCommunityProcess),
    fork(watchExcelDownload),
    fork(watchPaymentExcelDownload),
    fork(watchReportExcelDownload),
    fork(watchReportCommunityUnmasking),
    fork(watchReportProfileUnmasking),
    fork(watchReportIndianGame),
    fork(watchReportIndianGameDetail),
    fork(watchReportIndianGameProcess),
    fork(watchReportIndianGameDetailUnmasking),
    fork(watchAuthInspectionDetailUnmasking),
    fork(watchAnswerList),
  ]);
}
