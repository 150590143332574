import { createSlice } from "@reduxjs/toolkit";

export const presignedSlice = createSlice({
  name: "presigned",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    presigned: (state) => {
      state.isLoading = true;
    },
    presignedSuccess: (state) => {
      state.isLoading = false;
      state.isDone = null;
    },
    presignedFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    },
  },
});

export const { presigned, presignedSuccess, presignedFailure } =
  presignedSlice.actions;

export default presignedSlice.reducer;
