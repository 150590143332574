import React, { useState } from "react";
import styled from "styled-components";

import useModal from "hooks/useModal";

export default function AuthInformationImageView({
  render,
  srcArray,
  ...restProps
}) {
  const [selectImage, setSelectImage] = useState(0);
  const { handleModalOpen, isModalOpen, handleModalClose, handleDimClick } =
    useModal();

  const handleClickImage = (index) => (event) => {
    setSelectImage(index);

    handleModalOpen(event);
  };

  return (
    <Root {...restProps}>
      {srcArray.map((item, index) => {
        return (
          <ImageWrapper key={index}>
            <Image
              src={item}
              alt={`인증서류 ${index + 1}`}
              onClick={handleClickImage(index)}
            />
          </ImageWrapper>
        );
      })}

      {isModalOpen &&
        render(handleModalClose, handleDimClick, selectImage, isModalOpen)}
    </Root>
  );
}

const Root = styled.div`
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  div + div {
    margin-left: 10px;
  }
`;

const ImageWrapper = styled.div`
  width: 120px;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 90%;
  height: 90%;
`;
