export const gender = {
  M: "남자",
  F: "여자 ",
};

export const status = {
  0: "반려",
  1: "승인대기",
  2: "승인완료",
};

export const examinationStatus = {
  0: "반려",
  1: "승인대기",
  2: "승인완료",
  3: "심사중",
};

export const personalInfo = {
  nickname: "닉네임",
  user_number: "회원번호",
};

export const internalGrade = {
  VIP: "VIP",
  IP: "IP",
  블랙리스트: "블랙리스트",
  서포터즈: "서포터즈",
  일반: "일반",
};

export const userStatusId = {
  1: "활동",
  3: "휴면",
  4: "정지",
  5: "제명",
};

export const adminUserStatusId = {
  1: "활동",
  3: "휴면",
  4: "정지",
};

export const admonishment = {
  0: "0회",
  1: "1회",
  2: "2회",
  3: "정지",
};

export const memberPersonalInfo = {
  nickname: "닉네임",
  user_number: "회원번호",
};

export const paymentDetailFilter = {
  결제: "결제",
  소비: "소비",
  무료: "무료",
};

export const height = {
  "140cm 이하": "140cm 이하",
  "141cm": "141cm",
  "142cm": "142cm",
  "143cm": "143cm",
  "144cm": "144cm",
  "145cm": "145cm",
  "146cm": "146cm",
  "147cm": "147cm",
  "148cm": "148cm",
  "149cm": "149cm",
  "150cm": "150cm",
  "151cm": "151cm",
  "152cm": "152cm",
  "153cm": "153cm",
  "154cm": "154cm",
  "155cm": "155cm",
  "156cm": "156cm",
  "157cm": "157cm",
  "158cm": "158cm",
  "159cm": "159cm",
  "160cm": "160cm",
  "161cm": "161cm",
  "162cm": "162cm",
  "163cm": "163cm",
  "164cm": "164cm",
  "165cm": "165cm",
  "166cm": "166cm",
  "167cm": "167cm",
  "168cm": "168cm",
  "169cm": "169cm",
  "170cm": "170cm",
  "171cm": "171cm",
  "172cm": "172cm",
  "173cm": "173cm",
  "174cm": "174cm",
  "175cm": "175cm",
  "176cm": "176cm",
  "177cm": "177cm",
  "178cm": "178cm",
  "179cm": "179cm",
  "180cm": "180cm",
  "181cm": "181cm",
  "182cm": "182cm",
  "183cm": "183cm",
  "184cm": "184cm",
  "185cm": "185cm",
  "186cm": "186cm",
  "187cm": "187cm",
  "188cm": "188cm",
  "189cm": "189cm",
  "190cm": "190cm",
  "191cm": "191cm",
  "192cm": "192cm",
  "193cm": "193cm",
  "194cm": "194cm",
  "195cm": "195cm",
  "196cm": "196cm",
  "197cm": "197cm",
  "198cm": "198cm",
  "199cm": "199cm",
  "200cm 이상": "200cm 이상",
};

export const ReportStatus = {
  processed: "처리완료",
  waiting: "미처리",
};

export const ReportSearchFilter = {
  from_nickname: "신고자 닉네임",
  to_nickname: "피신고자 닉네임",
};

export const ReportProfileClassification = {
  "허위 프로필": "허위 프로필",
  "사진 도용": "사진 도용",
  "선정적인 컨텐츠": "선정적인 컨텐츠",
  "연락처 공유 및 유도": "연락처 공유 및 유도",
  "광고성 컨텐츠": "광고성 컨텐츠",
  직접입력: "직접입력",
};

export const ReportCommunityClassification = {
  "비매너 행위": "비매너 행위",
  "사진 도용": "사진 도용",
  "선정적인 컨텐츠": "선정적인 컨텐츠",
  "불법행위 및 불법행위 유도": "불법행위 및 불법행위 유도",
  "연락처 공유 및 유도": "연락처 공유 및 유도",
  "광고성 컨텐츠": "광고성 컨텐츠",
  직접입력: "직접입력",
};

export const ReportIndianGameClassification = {
  "비매너 행위": "비매너 행위",
  "사진 도용": "사진 도용",
  "선정적인 컨텐츠": "선정적인 컨텐츠",
  "불법행위 및 불법행위 유도": "불법행위 및 불법행위 유도",
  "연락처 공유 및 유도": "연락처 공유 및 유도",
  "광고성 컨텐츠": "광고성 컨텐츠",
  직접입력: "직접입력",
};

export const mbti = {
  INFP: "INFP",
  INFJ: "INFJ",
  ISTJ: "ISTJ",
  INTJ: "INTJ",
  INTP: "INTP",
  ISFJ: "ISFJ",
  ISFP: "ISFP",
  ISTP: "ISTP",
  ESFJ: "ESFJ",
  ESTJ: "ESTJ",
  ENFP: "ENFP",
  ENFJ: "ENFJ",
  ESFP: "ESFP",
  ESTP: "ESTP",
  ENTP: "ENTP",
  ENTJ: "ENTJ",
};
