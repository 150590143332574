import React from "react";
import HashLoader from "react-spinners/HashLoader";

import { css } from "styled-components";

const override = css`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999999;
`;

export default function Spinner({ ...restProps }) {
  return (
    <HashLoader
      {...restProps}
      size={30}
      color="#b1b1b1"
      loading="true"
      css={override}
    />
  );
}
