import React, { useState, useRef } from "react";
import styled from "styled-components";

import { Arrow } from "assets/icon/index";
import useOnClickOutside from "hooks/useOnClickOutside";
export default function Dropdown({ children, selectLabel, ...restProps }) {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const ref = useRef();

  const handleClickCloseDropdown = () => {
    setIsOpenDropdown(false);
  };

  const handleClickArrowButton = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  useOnClickOutside(ref, handleClickCloseDropdown);

  const label = (selectState) => {
    return selectState || "전체";
  };

  return (
    <Root ref={ref} {...restProps}>
      <DropdownWrapper onClick={handleClickArrowButton}>
        <SelectLabel>{label(selectLabel)}</SelectLabel>
        <CustomArrow isopendropdown={isOpenDropdown.toString()} />
        {isOpenDropdown && <List>{children}</List>}
      </DropdownWrapper>
    </Root>
  );
}

Dropdown.Option = function Option({
  state,
  setDropdownState,
  name,
  label,
  dropdownCount,
  index,
  children,
  ...restProps
}) {
  const handleClickOption = () => {
    if (dropdownCount) {
      if (state.includes(label)) {
        alert("중복 선택입니다.");
      } else {
        setDropdownState((draft) => {
          draft[name][index] = label;
        });
      }
    } else {
      setDropdownState((draft) => {
        draft[name] = label;
      });
    }
  };
  return (
    <Item onClick={handleClickOption} {...restProps}>
      {children}
    </Item>
  );
};

const Root = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const DropdownWrapper = styled.button`
  width: 100%;
  height: inherit;
  padding-left: 12px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.border_grayE6};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const SelectLabel = styled.span`
  width: 99px;
  text-align: left;
  color: ${({ theme }) => theme.color.text_gray5b};
`;

const CustomArrow = styled(Arrow)`
  width: 28px;
  fill: ${({ theme }) => theme.color.text_gray5b};
  border-left: ${({ isopendropdown, theme }) =>
    isopendropdown === "true" && `1px solid ${theme.color.border_grayDe}}`};
  border-right: ${({ isopendropdown, theme }) =>
    isopendropdown === "false" && `1px solid ${theme.color.border_grayDe}}`};
  transform: ${({ isopendropdown }) =>
    isopendropdown === "true" ? "rotate(0)" : "rotate(180deg)"};
`;

const List = styled.ul`
  width: 100%;
  max-height: 362px;
  position: absolute;
  top: 46px;
  left: 0px;
  border: 1px solid ${({ theme }) => theme.color.border_grayE6};
  border-radius: 5px;
  z-index: 9999;
  overflow-y: auto;
`;

const Item = styled.li`
  width: ${({ width }) => (width ? width : "inherit")};
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  text-align: left;
  color: ${({ theme }) => theme.color.text_gray93};
  background-color: ${({ theme }) => theme.color.white};

  :hover {
    background-color: ${({ theme }) => theme.color.bg_blueEb};
    color: ${({ theme }) => theme.color.text_blue00};
  }
`;
