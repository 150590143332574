import React, { useState, useRef } from "react";
import styled from "styled-components";

import { color } from "style/theme";
import { HiddenPassword, ShowPassword } from "assets/icon/index";

export default function AccountInput({
  validation,
  state,
  setState,
  type,
  name,
  ...props
}) {
  const [isVisible, setIsVisible] = useState(false);
  const input = useRef();

  const handleChangeVisibleState = () => {
    setIsVisible(!isVisible);
  };

  const handleChangeInput = (event) => {
    const { value } = event.target;

    setState((draft) => {
      draft[name] = value;
    });
  };

  return (
    <Root>
      <Input
        value={state}
        validation={validation}
        onChange={handleChangeInput}
        type={type !== "password" ? "text" : isVisible ? "text" : "password"}
        ref={input}
        {...props}
      />
      {name === "password" && (
        <ButtonWrapper type="button" onClick={handleChangeVisibleState}>
          {isVisible ? <HiddenPassword /> : <ShowPassword />}
        </ButtonWrapper>
      )}
    </Root>
  );
}

const Root = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 50px;
  padding: 16px 40px 16px 16px;
  border-radius: 5px;
  border: ${({ validation }) =>
    validation
      ? `1px solid ${color.input_border_white}`
      : `1px solid ${color.input_error}`};
  background-color: ${color.input_bg_brown};
  color: ${color.white};

  &:focus {
    border: 1px solid ${color.input_border_blue};
  }
`;

const ButtonWrapper = styled.button`
  background-color: inherit;
  position: absolute;
  top: 17px;
  right: 16px;
`;
