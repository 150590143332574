import React from "react";
import styled from "styled-components";

import FormTable from "components/table/FormTable";
import { DetailPageSectionLayout } from "components/index";

export default function MemberUserRomanticStyle({
  adminList,
  answerList,
  data,
}) {
  const radioList = {};

  answerList.forEach((item, listIndex) => {
    item.answer.forEach((answer, answerIndex) => {
      radioList[listIndex + 1] = {
        ...radioList[listIndex + 1],
        [answerIndex]: answer,
      };
    });
  });

  return (
    <DetailPageSectionLayout heading="연애 스타일">
      <FormTable>
        {adminList.romanticStyle.map((row, rowI) => (
          <FormTable.Row key={rowI} contentCount={row.length}>
            {row.map((content, contentI) => (
              <FormTable.Content
                key={content.heading + contentI}
                require={content.require}
                heading={content.heading}
              >
                <Wrapper>
                  {Object.values(radioList).map((value, radioI) => (
                    <AnswerWrapper key={radioI}>
                      <Question>{`${radioI + 1}. ${
                        answerList?.[radioI]?.question
                      } `}</Question>
                      <CustomFormRadioButton key={radioI}>
                        {Object.entries(value).map(([key, value]) => {
                          return (
                            <RadioWrapper key={value}>
                              <RadioItem>
                                <input
                                  type="radio"
                                  id={value}
                                  checked={data?.answer?.[radioI] === +key}
                                  disabled
                                />
                                <Label htmlFor={value}>{value}</Label>
                              </RadioItem>
                            </RadioWrapper>
                          );
                        })}
                      </CustomFormRadioButton>
                    </AnswerWrapper>
                  ))}
                </Wrapper>
              </FormTable.Content>
            ))}
          </FormTable.Row>
        ))}
      </FormTable>
    </DetailPageSectionLayout>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 20px;
  width: 364px;
  margin: 15px 0;
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
  background-color: ${({ theme }) => theme.color.white};
`;

const CustomFormRadioButton = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 182px);
`;

const Label = styled.label`
  margin-left: 8px;
  font-size: 14px;
  color: ${({ value, theme }) =>
    value ? theme.color.black : theme.color.gray_6C};
`;

const RadioItem = styled.div`
  display: flex;
`;

const AnswerWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
`;

const Question = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_5B};
`;
