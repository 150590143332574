import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { Heading } from "components/index";
import { AuthSearchFilter, AuthTable } from "pages/auth/containers/index";
import { authInspection } from "store/reducer/authInspection";
import { authInspectionDetailReset } from "store/reducer/authInspectionDetail";
import { authTable, authTableWidth } from "assets/static/listTable";

export default function AuthUniversity() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { dataList, pageData } = useSelector((state) => state.authInspection);

  useEffect(() => {
    dispatch(authInspectionDetailReset());
  }, []);

  useEffect(() => {
    const filterObj = { ...Object.fromEntries(searchParams) };
    filterObj.badge_category_id = 4;

    dispatch(authInspection(filterObj));
  }, [searchParams]);

  return (
    <Root>
      <Heading as="h2">학력 인증 관리</Heading>
      <AuthSearchFilter actionFn={authInspection({ badge_category_id: 4 })} />
      <AuthTable
        list={authTable}
        width={authTableWidth}
        data={dataList}
        pageData={pageData}
      />
    </Root>
  );
}

const Root = styled.section`
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 50px;
`;
