import { createSlice } from "@reduxjs/toolkit";

export const excelDownloadSlice = createSlice({
  name: "excelDownload",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    excelDownload: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    excelDownloadSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    excelDownloadFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
    paymentExcelDownload: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    paymentExcelDownloadSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    paymentExcelDownloadFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
    reportExcelDownload: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    reportExcelDownloadSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    reportExcelDownloadFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  excelDownload,
  excelDownloadSuccess,
  excelDownloadFailure,
  paymentExcelDownload,
  paymentExcelDownloadSuccess,
  paymentExcelDownloadFailure,
  reportExcelDownload,
  reportExcelDownloadSuccess,
  reportExcelDownloadFailure,
} = excelDownloadSlice.actions;

export default excelDownloadSlice.reducer;
