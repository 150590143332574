import { Arrow } from "assets/icon/index";
import { Image } from "components/index";
import React, { useState } from "react";
import styled from "styled-components";

export default function MemberImageView({ srcArray }) {
  const [selectImage, setSelectImage] = useState(0);

  const handleClickLeftButton = () => {
    if (selectImage === 0) return;
    setSelectImage(selectImage - 1);
  };

  const handleClickRightButton = () => {
    if (selectImage === srcArray.length - 1) return;
    setSelectImage(selectImage + 1);
  };

  const handleClickSmallImage = (index) => {
    setSelectImage(index);
  };

  return (
    <Root>
      <ImageWrapper>
        <CustomImage src={srcArray[selectImage]} alt={"인증 요청사진"} />
      </ImageWrapper>
      <SmallImageContainer>
        {srcArray.map((src, index) => {
          const select = selectImage === index;

          return (
            <SmallImageWrapper key={index}>
              <SmallImage
                src={src}
                key={index}
                alt={`인증 요청사진 미리보기 ${index + 1}번`}
                onClick={() => handleClickSmallImage(index)}
              />
              {select && <SelectImageColor />}
            </SmallImageWrapper>
          );
        })}
      </SmallImageContainer>
      <ButtonWrapper>
        <Button onClick={handleClickLeftButton} disabled={selectImage === 0}>
          <CustomArrow direction="left" />
        </Button>
        <Button
          onClick={handleClickRightButton}
          disabled={selectImage === srcArray.length - 1}
        >
          <CustomArrow direction="right" />
        </Button>
      </ButtonWrapper>
    </Root>
  );
}

const Root = styled.figure`
  width: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 238px;
  margin-bottom: 10px;
  background-color: black;
`;

const CustomImage = styled(Image)`
  width: 100%;

  & > img {
    max-height: 238px;
  }
`;

const SmallImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;

  div + div {
    margin-left: 10px;
  }
`;

const SmallImageWrapper = styled.div`
  position: relative;
`;

const SmallImage = styled.img`
  width: 36px;
  height: 40px;
  cursor: pointer;
`;

const SelectImageColor = styled.div`
  width: 36px;
  height: 40px;
  background-color: rgba(0, 105, 249, 0.2);
  border: 2px solid ${({ theme }) => theme.color.blue_00};
  position: absolute;
  top: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  opacity: ${({ disabled }) => disabled && 0.3};

  & + & {
    margin-left: 16px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const CustomArrow = styled(Arrow)`
  transform: ${({ direction }) =>
    direction === "left" ? "rotate(-90deg)" : "rotate(90deg)"};
`;
