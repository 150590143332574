import React, { useState } from "react";
import styled from "styled-components";

import { HiddenPassword, ShowPassword } from "assets/icon/index";

export default function PasswordInput({
  value,
  name,
  setState,
  placeholder,
  ...restProps
}) {
  const [isVisible, setIsVisible] = useState(false);

  const handleChangeVisibleState = () => {
    setIsVisible(!isVisible);
  };

  const handleChangeValue = (event) => {
    setState((draft) => {
      draft[name] = event.target.value;
    });
  };

  return (
    <Root>
      <Input
        type={isVisible ? "text" : "password"}
        placeholder={placeholder}
        value={value}
        onChange={handleChangeValue}
        {...restProps}
      />
      <ChangeInputTypeButton type="button" onClick={handleChangeVisibleState}>
        {isVisible ? <HiddenPassword /> : <ShowPassword />}
      </ChangeInputTypeButton>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 8px 30px 8px 12px;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  border-radius: 5px;
`;

const ChangeInputTypeButton = styled.button`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  right: 12px;
`;
