import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Heading } from "components/index";
import { appearanceRejectReason } from "assets/static/radioButton";
import { basicInformationTable } from "assets/static/table";
import { authInspectionDetail } from "store/reducer/authInspectionDetail";
import { authInspectionDetailApproval } from "store/reducer/authInspectionDetailApproval";
import { authInspectionDetailReject } from "store/reducer/authInspectionDetailReject";
import {
  AuthDetailPageRootLayout,
  SubmitCancelButton,
  BasicInformation,
  AuthImageView,
} from "pages/auth/containers/index";
import { PATH } from "utils/constants";

export default function AuthAppearanceDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { certificationSrcArray, profileSrcArray, certification } = useSelector(
    (state) => state.authInspectionDetail,
  );

  const handleSubbmit = () => {
    dispatch(
      authInspectionDetailApproval({
        id: id,
        data: {
          type: "approve",
        },
        navigate,
        beforePath: PATH.authAppearance.fullPath,
        toastMessageKey: "appearanceSuccess",
      }),
    );
  };

  useEffect(() => {
    dispatch(authInspectionDetail(id));
  }, []);

  return (
    <AuthDetailPageRootLayout>
      <Heading as="h2">외모 인증 상세</Heading>
      <ImageWrapper>
        <AuthImageView
          heading="인증 요청 사진"
          imageSrcArray={certificationSrcArray}
        />
        <AuthImageView heading="프로필 사진" imageSrcArray={profileSrcArray} />
      </ImageWrapper>
      <BasicInformationWrapper>
        <BasicInformation data={certification} list={basicInformationTable} />
      </BasicInformationWrapper>
      {certification.status === "승인대기" && (
        <SubmitCancelButton
          rejectList={appearanceRejectReason}
          rejectAction={authInspectionDetailReject}
          handleSubbmit={handleSubbmit}
          beforePath={PATH.authAppearance.fullPath}
          toastMessageKey="appearanceReject"
        />
      )}
    </AuthDetailPageRootLayout>
  );
}

const ImageWrapper = styled.div`
  width: 1140px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 32px auto;
`;

const BasicInformationWrapper = styled.div`
  width: 1140px;
  margin: 0 auto 24px auto;
`;
