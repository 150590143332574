import { createSlice } from "@reduxjs/toolkit";

export const memberUserDetailMatchHistorySlice = createSlice({
  name: "memberUserDetailMatchHistory",
  initialState: {
    isLoading: false,
    isDone: false,
    matchs: [],
    pageInfo: {},
    error: null,
  },
  reducers: {
    memberUserDetailMatchHistory: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    memberUserDetailMatchHistorySuccess: (state, action) => {
      const { matchs, pageInfo } = action;
      state.matchs = matchs;
      state.pageInfo = pageInfo;
      state.isLoading = false;
      state.isDone = true;
    },
    memberUserDetailMatchHistoryFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  memberUserDetailMatchHistory,
  memberUserDetailMatchHistorySuccess,
  memberUserDetailMatchHistoryFailure,
} = memberUserDetailMatchHistorySlice.actions;

export default memberUserDetailMatchHistorySlice.reducer;
