import { combineReducers } from "redux";
import login from "./login";
import logoutReducer, { logout } from "./logout";
import toast from "./toast";
import changeMemberAdminPassword from "./password";
import authInspection from "./authInspection";
import authInspectionDetail from "./authInspectionDetail";
import authInspectionDetailApproval from "./authInspectionDetailApproval";
import authInspectionDetailReject from "./authInspectionDetailReject";
import authProfile from "./authProfile";
import authProfileDetail from "./authProfileDetail";
import authProfileDetailApproval from "./authProfileDetailApproval";
import authProfileDetailReject from "./authProfileDetailReject";
import memberUser from "./memberUser";
import memberUserSuspend from "./memberUserSuspend";
import memberUserDetail from "./memberUserDetail";
import memberUserDetailMatchHistory from "./memberUserDetailMatchHistory";
import memberUserAddFreeStone from "./memberUserFreeStone";
import memberUserDetailPaymentDetail from "./memberUserDetailPaymentDetail";
import memberUserDetailUserStatus from "./memberUserDetailUserStatus";
import memberUserSuspendReason from "./memberUserSuspendReason";
import memberUserModifyCertificate from "./memberUserModifyCertificate";
import memberAdmin from "./memberAdmin";
import memberAdminCreate from "./memberAdminCreate";
import memberAdminDelete from "./memberAdminDelete";
import memberAdminDetail from "./memberAdminDetail";
import memberAdminModify from "./memberAdminModify";
import memberAdminDropdownList from "./memberAdminDropdownList";
import reportProfile from "./reportProfile";
import reportProfileDetail from "./reportProfileDetail";
import reportProfileProcess from "./reportProfileProcess";
import reportCommunity from "./reportCommunity";
import reportCommunityDetail from "./reportCommunityDetail";
import reportCommunityProcess from "./reportCommunityProcess";
import reportIndianGame from "./reportIndianGame";
import reportIndianGameDetail from "./reportIndianGameDetail";
import reportIndianGameProcess from "./reportIndianGameProcess";
import presigned from "./presigned";
import excelDownload from "./excelDownload";
import answerList from "./answerList";

const appReducer = combineReducers({
  // slice 목록
  login,
  logoutReducer,
  toast,
  changeMemberAdminPassword,
  authInspection,
  authInspectionDetail,
  authInspectionDetailApproval,
  authInspectionDetailReject,
  authProfile,
  authProfileDetail,
  authProfileDetailApproval,
  authProfileDetailReject,
  memberUser,
  memberUserSuspend,
  memberUserDetail,
  memberUserDetailMatchHistory,
  memberUserDetailPaymentDetail,
  memberUserAddFreeStone,
  memberUserDetailUserStatus,
  memberUserSuspendReason,
  memberUserModifyCertificate,
  memberAdmin,
  memberAdminCreate,
  memberAdminDelete,
  memberAdminDetail,
  memberAdminModify,
  memberAdminDropdownList,
  reportProfile,
  reportProfileDetail,
  reportProfileProcess,
  reportCommunity,
  reportCommunityDetail,
  reportCommunityProcess,
  reportIndianGame,
  reportIndianGameDetail,
  reportIndianGameProcess,
  presigned,
  excelDownload,
  answerList,
});

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
