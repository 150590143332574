import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { Arrow } from "assets/icon/index";

const Dropdown2 = ({
  selectOption,
  placeholder,
  optionObj,
  handleClickOption,
  ...restProps
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClickDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e) => {
    if (isOpen && !ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <Root ref={ref} onClick={handleOnClickDropdown} {...restProps}>
      <SelectOption type="button" isopen={isOpen.toString()}>
        {selectOption === ""
          ? "전체"
          : optionObj[selectOption]
          ? optionObj[selectOption]
          : placeholder}
        <CustomDropdownIcon isopen={isOpen.toString()} />
      </SelectOption>
      {isOpen && (
        <OptionWrapper>
          <Option onClick={handleClickOption("")}>전체</Option>
          {Object.entries(optionObj).map(([key, value], i) => {
            return (
              <Option key={i} onClick={handleClickOption(key)}>
                {value}
              </Option>
            );
          })}
        </OptionWrapper>
      )}
    </Root>
  );
};

export default Dropdown2;

const Root = styled.div`
  position: relative;
  display: flex;
  column-gap: 10px;
  align-items: center;
  width: 100%;
  height: 40px;
  cursor: pointer;
  font-size: 13px;
`;

const SelectOption = styled.button`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  width: inherit;
  height: inherit;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 5px;
  padding-left: 12px;
  color: ${({ theme }) => theme.color.text_gray5b};
  background-color: ${({ theme }) => theme.color.white};

  &:disabled {
    cursor: not-allowed;
  }

  ::after {
    content: "";
    position: absolute;
    right: 28px;
    width: 1px;
    height: 14px;
    border-right: 1px solid ${({ theme }) => theme.color.border};
  }
`;

const CustomDropdownIcon = styled(Arrow)`
  position: absolute;
  top: 13px;
  right: 8px;
  border-left: ${({ isOpen, theme }) =>
    isOpen === "true" && `1px solid ${theme.color.border_grayDe}}`};
  border-right: ${({ isOpen, theme }) =>
    isOpen === "false" && `1px solid ${theme.color.border_grayDe}}`};
  fill: ${({ theme }) => theme.color.text_gray5b};
  transform: ${({ isopen }) => isopen === "false" && `rotate(180deg)`};
`;

const OptionWrapper = styled.ul`
  position: absolute;
  top: 46px;
  left: 0px;
  width: inherit;
  max-height: 120px;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 5px;
  overflow-y: overlay;
  background-color: ${({ theme }) => theme.color.white};
  z-index: 9999;
  overflow-y: auto;

  & > :last-child {
    border-bottom: 0;
  }
`;

const Option = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding-left: 10px;
  color: ${({ theme }) => theme.color.text_gray93};
  background-color: ${({ theme }) => theme.color.white};

  :hover {
    background-color: ${({ theme }) => theme.color.bg_blueEb};
    color: ${({ theme }) => theme.color.text_blue00};
  }
`;
