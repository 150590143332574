import React from "react";
import Select from "react-select";
import styled from "styled-components";

export default function FormDropdowns({
  name,
  placeholder,
  isError,
  setError,
  clearErrors,
  Controller,
  control,
  options,
  watch,
  dropdownCount,
}) {
  const isDuplicateSelect = (value) =>
    [...Array(dropdownCount)]
      .map((_, i) => watch(`${name}${i + 1}`))
      .indexOf(value) !== -1;

  return (
    <FlexWrapper>
      {[...Array(dropdownCount)].map((_, i) => (
        <Controller
          key={i}
          render={({ field: { value, onChange } }) => (
            <Select
              placeholder={placeholder}
              options={options}
              value={options.find((option) => option.value === value)}
              onChange={({ value }) => {
                if (isDuplicateSelect(value)) {
                  alert("중복입니다.");
                } else {
                  onChange(value);
                  clearErrors(`${name}${i + 1}`);
                }
              }}
              onBlur={() => {
                if (!value) setError(`${name}${i + 1}`, { type: "required" });
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "156px",
                  height: "34px",
                  minHeight: "34px",
                  borderColor: isError[`${name}${i + 1}`]
                    ? "#ff5050"
                    : "#dedede",
                  borderRadius: "5px",
                  fontSize: "13px",
                  boxShadow: state.isFocused ? 0 : 0,

                  "&:hover": {
                    border: "1px solid",
                    borderColor: isError[`${name}${i + 1}`]
                      ? "#ff5050"
                      : "#dedede",
                  },
                }),
                valueContainer: (base) => ({
                  ...base,
                  padding: "0 0 0 12px",
                }),
                input: (base) => ({
                  ...base,
                  margin: 0,
                  padding: 0,
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  padding: "4px",
                  color: "#5b5b5b",

                  svg: {
                    width: "18px",
                    height: "18px",
                  },

                  "&:hover": {
                    color: "#5b5b5b",
                  },
                }),
                menuList: (base) => ({
                  ...base,
                  padding: 0,
                  fontSize: "13px",
                  color: "#939393",

                  "& > li:hover": {
                    color: "#0069f9",
                  },
                }),
              }}
            />
          )}
          name={`${name}${i + 1}`}
          control={control}
          rules={{ required: true }}
        />
      ))}
    </FlexWrapper>
  );
}

const FlexWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;
