import { getMemberAdmin } from "api/index";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  memberAdmin,
  memberAdminFailure,
  memberAdminSuccess,
} from "store/reducer/memberAdmin";
import {
  convertUTCtoBirthday,
  userStatusIdFormatter,
} from "utils/helper/formatter";

function* memberAdminSaga(action) {
  try {
    const res = yield call(getMemberAdmin, action.payload);
    yield put({
      type: memberAdminSuccess,
      data: {
        pageInfo: res.data.pageInfo,
        users: res.data.users.map((item) => {
          return {
            requestId: item.user_id,
            admonishment: `${item.admonishment}회`,
            birthday: convertUTCtoBirthday(item.birthday),
            created: convertUTCtoBirthday(item.created),
            internalGrade: item.internal_grade,
            lastLogin: item.lastLogin ? item.lastLogin : "-",
            email: item.email,
            gender: item.gender,
            userNumber: item.user_number,
            name: item.name,
            nickname: item.nickname,
            phone: item.phone,
            userStatusId: userStatusIdFormatter(item.user_status_id),
          };
        }),
      },
    });
  } catch (err) {
    yield put({
      type: memberAdminFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberAdmin() {
  yield takeLatest(memberAdmin, memberAdminSaga);
}
