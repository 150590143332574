import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import Cookies from "js-cookie";
import styled from "styled-components";

import { Heading } from "components/index";
import { AuthDetailPageRootLayout } from "pages/auth/containers/index";
import ReporterInformation from "pages/report/containers/ReporterInformation";
import ReportCommunityReportInformation from "pages/report/ReportCommunity/ReportCommunityDetail/containers/ReportCommunityReportInformation";
import ReportIndianGameProcess from "./containers/ReportIndianGameProcess";
import { makeCryptoFunction } from "utils/helper/crypto";
import {
  reportIndianGameDetail,
  reportIndianGameDetailUnmasking,
} from "store/reducer/reportIndianGameDetail";
import { reportIndianGameProcess } from "store/reducer/reportIndianGameProcess";

export default function ReportIndianGameDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { fromUser, toUser, report } = useSelector(
    (state) => state.reportIndianGameDetail,
  );
  const { processed, process_type, pic, reason, delete_content } = report;
  const [typeState, setTypeState] = useState(0);
  const [dayState, setDayState] = useState(0);
  const [picState, setPicState] = useState("");
  const [reasonState, setReasonState] = useState("");
  const [password, setPassword] = useImmer({ password: "" });
  const master = Cookies.get("auth").split(",").includes("master");

  const handleChangePicState = (event) => {
    const { value } = event.target;

    setPicState(value);
  };

  const handleChangeReasonState = (event) => {
    const { value } = event.target;

    setReasonState(value);
  };

  const handleProcessClick = () => {
    const submitType = typeState + 1;

    if (!picState || !reasonState) return alert("필수값을 확인해주세요.");

    dispatch(
      reportIndianGameProcess({
        id,
        picState,
        submitType,
        dayState,
        reasonState,
        deletePostState: delete_content === 1 && 1,
      }),
    );
    dispatch(reportIndianGameDetail({ id }));
  };

  useEffect(() => {
    dispatch(reportIndianGameDetail({ id }));
  }, []);

  useEffect(() => {
    if (JSON.stringify(report) === "{}") return;

    process_type && setTypeState(process_type - 1);
    pic && setPicState(pic);
    reason && setReasonState(reason);
  }, [report]);

  return (
    <AuthDetailPageRootLayout>
      <Heading as="h2">인디언 게임 신고 상세</Heading>
      <InformationWrapper>
        <ReporterInformation heading="신고자 정보" data={fromUser} />
        <ReporterInformation
          heading="피신고자 정보"
          data={toUser}
          unMasking={master}
          submitActionFunction={reportIndianGameDetailUnmasking({
            id: toUser.user_id,
            password: makeCryptoFunction(password.password),
          })}
          setPassword={setPassword}
        />
      </InformationWrapper>
      <ReportCommunityReportInformation report={report} />
      <ReportIndianGameProcess
        data={report}
        typeState={typeState}
        setTypeState={setTypeState}
        dayState={dayState}
        setDayState={setDayState}
        picState={picState}
        reasonState={reasonState}
        handleChangePicState={handleChangePicState}
        handleChangeReasonState={handleChangeReasonState}
      />
      {processed !== "처리완료" && (
        <ButtonWrapper>
          <SubmitButton onClick={handleProcessClick}>처리하기</SubmitButton>
        </ButtonWrapper>
      )}
    </AuthDetailPageRootLayout>
  );
}

const InformationWrapper = styled.div`
  width: 1140px;
  display: flex;
  column-gap: 32px;
  justify-content: space-between;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const SubmitButton = styled.button`
  width: 96px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.color.gray_AD};
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.gray_6C};

  :disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;
