import { call, takeLatest, put, all } from "redux-saga/effects";

import {
  getMemberAmdinAreaList,
  getMemberAmdinBodyTypeList,
  getMemberAmdinDrinkingList,
  getMemberAmdinPersonalityList,
  getMemberAmdinReligionList,
  getMemberAmdinSmokingList,
  getMemberAdminHobbyList,
  getMemberAdminIdealList,
} from "api/index";
import {
  memberAdminDropdownList,
  memberAdminDropdownListFailure,
  memberAdminDropdownListSuccess,
  memberAdminAreaDropdownList,
  memberAdminAreaDropdownListFailure,
  memberAdminAreaDropdownListSuccess,
  memberAdminBodyTypeDropdownList,
  memberAdminBodyTypeDropdownListFailure,
  memberAdminBodyTypeDropdownListSuccess,
  memberAdminDrinkingDropdownList,
  memberAdminDrinkingDropdownListSuccess,
  memberAdminDrinkingDropdownListFailure,
  memberAdminPersonalityDropdownList,
  memberAdminPersonalityDropdownListSuccess,
  memberAdminPersonalityDropdownListFailure,
  memberAdminReligionDropdownList,
  memberAdminReligionDropdownListSuccess,
  memberAdminReligionDropdownListFailure,
  memberAdminSmokingDropdownList,
  memberAdminSmokingDropdownListSuccess,
  memberAdminSmokingDropdownListFailure,
} from "store/reducer/memberAdminDropdownList";

function* memberAdminAreaListSaga(action) {
  try {
    const { data } = yield call(getMemberAmdinAreaList, action.payload);
    yield put({ type: memberAdminAreaDropdownListSuccess, data: data });
  } catch (err) {
    yield put({
      type: memberAdminAreaDropdownListFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberAdminAreaList() {
  yield takeLatest(memberAdminAreaDropdownList, memberAdminAreaListSaga);
}

function* memberAdminBodyTypeListSaga(action) {
  try {
    const { data } = yield call(getMemberAmdinBodyTypeList, action.payload);
    yield put({
      type: memberAdminBodyTypeDropdownListSuccess,
      data: data,
    });
  } catch (err) {
    yield put({
      type: memberAdminBodyTypeDropdownListFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberAdminBodyTypeList() {
  yield takeLatest(
    memberAdminBodyTypeDropdownList,
    memberAdminBodyTypeListSaga,
  );
}

function* memberAdminDrinkingSaga(action) {
  try {
    const { data } = yield call(getMemberAmdinDrinkingList, action.payload);
    yield put({
      type: memberAdminDrinkingDropdownListSuccess,
      data: data,
    });
  } catch (err) {
    yield put({
      type: memberAdminDrinkingDropdownListFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberAdminDrinkingSagaDetail() {
  yield takeLatest(memberAdminDrinkingDropdownList, memberAdminDrinkingSaga);
}

function* memberAdminPersonalitySaga(action) {
  try {
    const { data } = yield call(getMemberAmdinPersonalityList, action.payload);
    yield put({
      type: memberAdminPersonalityDropdownListSuccess,
      data: data,
    });
  } catch (err) {
    yield put({
      type: memberAdminPersonalityDropdownListFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberAdminPersonality() {
  yield takeLatest(
    memberAdminPersonalityDropdownList,
    memberAdminPersonalitySaga,
  );
}

function* memberAdminReligionSaga(action) {
  try {
    const { data } = yield call(getMemberAmdinReligionList, action.payload);

    yield put({
      type: memberAdminReligionDropdownListSuccess,
      data: data,
    });
  } catch (err) {
    yield put({
      type: memberAdminReligionDropdownListFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberAdminReligion() {
  yield takeLatest(memberAdminReligionDropdownList, memberAdminReligionSaga);
}

function* memberAdminSmokingSaga(action) {
  try {
    const { data } = yield call(getMemberAmdinSmokingList, action.payload);

    yield put({
      type: memberAdminSmokingDropdownListSuccess,
      data: data,
    });
  } catch (err) {
    yield put({
      type: memberAdminSmokingDropdownListFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberAdminSmoking() {
  yield takeLatest(memberAdminSmokingDropdownList, memberAdminSmokingSaga);
}

function* memberAdminDropdownListSaga(action) {
  try {
    const res = yield all([
      call(getMemberAmdinAreaList, action.payload),
      call(getMemberAmdinBodyTypeList, action.payload),
      call(getMemberAmdinDrinkingList, action.payload),
      call(getMemberAmdinPersonalityList, action.payload),
      call(getMemberAmdinReligionList, action.payload),
      call(getMemberAmdinSmokingList, action.payload),
      call(getMemberAdminHobbyList, action.payload),
      call(getMemberAdminIdealList, action.payload),
    ]);

    const arrayToObject = (array, name) => {
      return {
        ...array.data.reduce(function (target, key, index) {
          target[index + 1] = key[name];
          return target;
        }, {}),
      };
    };

    const bodyTypeArrayToObject = (array, name) => {
      return {
        ...array.reduce(function (target, key) {
          target[key.body_type_id] = key[name];
          return target;
        }, {}),
      };
    };

    const obj = {
      area: arrayToObject(res[0], "area"),
      body_type: {
        F: bodyTypeArrayToObject(
          res[1].data.filter((item) => item.gender === "F"),
          "body_type",
        ),
        M: bodyTypeArrayToObject(
          res[1].data.filter((item) => item.gender === "M"),
          "body_type",
        ),
      },
      drinking: arrayToObject(res[2], "drinking"),
      personality: arrayToObject(res[3], "personality"),
      religion: arrayToObject(res[4], "religion"),
      smoking: arrayToObject(res[5], "smoking"),
      hobby: arrayToObject(res[6], "hobby"),
      ideal: arrayToObject(res[7], "ideal"),
    };

    yield put({
      type: memberAdminDropdownListSuccess,
      data: obj,
    });
  } catch (err) {
    yield put({
      type: memberAdminDropdownListFailure,
      error: err.response.data,
    });
  }
}

export function* watchMemberAdminDropdownListSaga() {
  yield takeLatest(memberAdminDropdownList, memberAdminDropdownListSaga);
}
