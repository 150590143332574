import React from "react";
import Select from "react-select";

export default function FormDropdown({
  name,
  required,
  isError,
  setError,
  clearErrors,
  placeholder,
  Controller,
  control,
  options,
}) {
  return (
    <Controller
      render={({ field: { value, onChange } }) => (
        <Select
          options={options}
          placeholder={placeholder}
          value={options.find((option) => option.value === value)}
          onChange={(value) => {
            onChange(value.value);
            clearErrors(name);
          }}
          onBlur={() => {
            if (!value) setError(name, { type: "required" });
          }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              width: "156px",
              height: "34px",
              minHeight: "34px",
              borderColor: isError ? "#ff5050" : "#dedede",
              borderRadius: "5px",
              fontSize: "13px",
              boxShadow: state.isFocused ? 0 : 0,

              "&:hover": {
                border: "1px solid",
                borderColor: isError ? "#ff5050" : "#dedede",
              },
            }),
            valueContainer: (base) => ({
              ...base,
              padding: "0 0 0 12px",
            }),
            input: (base) => ({
              ...base,
              margin: 0,
              padding: 0,
            }),
            dropdownIndicator: (base) => ({
              ...base,
              padding: "4px",
              color: "#5b5b5b",

              svg: {
                width: "18px",
                height: "18px",
              },

              "&:hover": {
                color: "#5b5b5b",
              },
            }),
            menuList: (base) => ({
              ...base,
              padding: 0,
              fontSize: "13px",
              color: "#939393",

              "& > li:hover": {
                color: "#0069f9",
              },
            }),
          }}
        />
      )}
      name={name}
      control={control}
      rules={{ required }}
    />
  );
}
