import React from "react";
import styled from "styled-components";

export default function StatusLabel({ status, ...restProps }) {
  switch (status) {
    case "승인대기":
      return <Wait {...restProps}>승인대기</Wait>;
    case "승인완료":
      return <Complete {...restProps}>승인완료</Complete>;
    case "반려":
      return <Deny {...restProps}>반려</Deny>;
    case "심사중":
      return <Examination {...restProps}>심사중</Examination>;
    default:
      return <div>-</div>;
  }
}

const Wait = styled.div`
  width: 70px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background-color: ${({ theme }) => theme.color.blue_EB};
  color: ${({ theme }) => theme.color.blue_57};
  border-radius: 4px;
`;

const Complete = styled(Wait)`
  background-color: ${({ theme }) => theme.color.black_E6};
  color: ${({ theme }) => theme.color.black_7B};
`;

const Deny = styled(Wait)`
  background-color: ${({ theme }) => theme.color.red_FC};
  color: ${({ theme }) => theme.color.red_D5};
`;

const Examination = styled(Wait)`
  background-color: ${({ theme }) => theme.color.green_E3};
  color: ${({ theme }) => theme.color.green_46};
`;
