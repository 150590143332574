import { createSlice } from "@reduxjs/toolkit";

export const answerListSlice = createSlice({
  name: "answerList",
  initialState: {
    data: [],
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    answerList: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    answerListSuccess: (state, action) => {
      state.data = action.data;
      state.isLoading = false;
      state.isDone = true;
    },
    answerListFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const { answerList, answerListSuccess, answerListFailure } =
  answerListSlice.actions;

export default answerListSlice.reducer;
