import React from "react";
import styled from "styled-components";

export default function AuthInformationRadioButton({
  list,
  selectRadioState,
  handleClickRadioButton,
  status,
  ...restProps
}) {
  return (
    <Root {...restProps}>
      {Object.entries(list)
        .reverse()
        .map(([value, label], index) => {
          return (
            <RadioItem key={label + index}>
              <input
                type="radio"
                id={`radio${index}`}
                name="radio"
                value={value}
                checked={selectRadioState === Number(value)}
                onChange={() => handleClickRadioButton(index)}
                disabled={status !== "승인대기"}
              />
              <Label htmlFor={`radio${index}`}>{label}</Label>
            </RadioItem>
          );
        })}
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  column-gap: 34px;
`;

const RadioItem = styled.div`
  display: flex;
  & > input,
  & > label {
    cursor: pointer;
  }
`;

const Label = styled.label`
  padding-left: 8px;
  font-size: 14px;
  color: ${({ value, theme }) =>
    value ? theme.color.black : theme.color.gray_6C};
`;
