import { createSlice } from "@reduxjs/toolkit";

export const memberAdminDeleteSlice = createSlice({
  name: "memberAdminDelete",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    memberAdminDelete: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    memberAdminDeleteSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    memberAdminDeleteFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  memberAdminDelete,
  memberAdminDeleteSuccess,
  memberAdminDeleteFailure,
} = memberAdminDeleteSlice.actions;

export default memberAdminDeleteSlice.reducer;
