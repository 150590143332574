import { createSlice } from "@reduxjs/toolkit";

export const memberAdminDetailSlice = createSlice({
  name: "memberAdminDetail",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
    data: {},
  },
  reducers: {
    memberAdminDetail: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    memberAdminDetailSuccess: (state, action) => {
      state.isLoading = false;
      state.isDone = true;
      state.data = action.data;
    },
    memberAdminDetailFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
    memberAdminDetailReset: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = null;
      state.data = {};
    },
  },
});

export const {
  memberAdminDetail,
  memberAdminDetailSuccess,
  memberAdminDetailFailure,
  memberAdminDetailReset,
} = memberAdminDetailSlice.actions;

export default memberAdminDetailSlice.reducer;
