import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { Heading } from "components/index";
import ReportFilter from "../containers/ReportFilter";
import ReportTable from "../containers/ReportTable";
import { reportCommunity } from "store/reducer/reportCommunity";
import { ReportCommunityClassification } from "assets/static/dropdown";
import { reportCommunityDetailReset } from "store/reducer/reportCommunityDetail";

export default function ReportCommunity() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { data, pageInfo } = useSelector((state) => state.reportCommunity);

  useEffect(() => {
    dispatch(reportCommunityDetailReset());
  }, []);

  useEffect(() => {
    const filterObj = { ...Object.fromEntries(searchParams) };

    dispatch(reportCommunity(filterObj));
  }, [searchParams]);

  return (
    <Root>
      <Heading>커뮤니티 신고 관리</Heading>
      <ReportFilter
        reportTypeObj={ReportCommunityClassification}
        actionFn={reportCommunity}
      />
      <ReportTable data={data} pageData={pageInfo} excel="true" />
    </Root>
  );
}

const Root = styled.section`
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 50px;
`;
