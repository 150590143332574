import { getAuthProfileDetail, putAuthProfileDetail } from "api/index";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  authProfileDetail,
  authProfileDetailFailure,
  authProfileDetailSuccess,
} from "store/reducer/authProfileDetail";
import {
  authProfileDetailApproval,
  authProfileDetailApprovalFailure,
  authProfileDetailApprovalReset,
  authProfileDetailApprovalSuccess,
} from "store/reducer/authProfileDetailApproval";
import {
  authProfileDetailReject,
  authProfileDetailRejectFailure,
  authProfileDetailRejectReset,
  authProfileDetailRejectSuccess,
} from "store/reducer/authProfileDetailReject";
import { toastShow } from "store/reducer/toast";
import { PATH } from "utils/constants";

function* authProfileDetailSaga(action) {
  try {
    const { data } = yield call(getAuthProfileDetail, action.payload);

    yield put({
      type: authProfileDetailSuccess,
      data: data,
    });
  } catch (err) {
    yield put({
      type: authProfileDetailFailure,
      error: err.response.data,
    });
  }
}

export function* watchAuthProfileDetail() {
  yield takeLatest(authProfileDetail, authProfileDetailSaga);
}

function* authProfileDetailApprovalSaga(action) {
  const { data, id, navigate } = action.payload;
  try {
    yield call(putAuthProfileDetail, { data, id });
    yield put({
      type: authProfileDetailApprovalSuccess,
    });

    navigate(PATH.authProfile.fullPath);

    yield put({
      type: toastShow,
      data: { type: "success", text: "profileSuccess" },
    });
    yield put({
      type: authProfileDetailApprovalReset,
    });
  } catch (err) {
    yield put({
      type: authProfileDetailApprovalFailure,
      error: err.response.data,
    });
  }
}

export function* watchAuthProfileDetailApproval() {
  yield takeLatest(authProfileDetailApproval, authProfileDetailApprovalSaga);
}

function* authProfileDetailRejectSaga(action) {
  const { navigate } = action.payload;
  try {
    yield call(putAuthProfileDetail, action.payload);
    yield put({
      type: authProfileDetailRejectSuccess,
    });

    navigate(PATH.authProfile.fullPath);

    yield put({
      type: toastShow,
      data: { type: "success", text: "profileReject" },
    });
    yield put({
      type: authProfileDetailRejectReset,
    });
  } catch (err) {
    yield put({
      type: authProfileDetailRejectFailure,
      error: err.response.data,
    });
  }
}

export function* watchAuthProfileDetailReject() {
  yield takeLatest(authProfileDetailReject, authProfileDetailRejectSaga);
}
