import { createSlice } from "@reduxjs/toolkit";

export const authInspectionDetailRejectSlice = createSlice({
  name: "authInspectionDetailReject",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    authInspectionDetailReject: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    authInspectionDetailRejectSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    authInspectionDetailRejectFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
    authInspectionDetailRejectReset: (state) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = null;
    },
  },
});

export const {
  authInspectionDetailReject,
  authInspectionDetailRejectSuccess,
  authInspectionDetailRejectFailure,
  authInspectionDetailRejectReset,
} = authInspectionDetailRejectSlice.actions;

export default authInspectionDetailRejectSlice.reducer;
