import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import {
  DimLayout,
  PortalModal,
  SubmitButton,
  CancelButton,
} from "components/index";
import { Close } from "assets/icon/index";
import { memberUserAddFreeStone } from "store/reducer/memberUserFreeStone";
import { numberComma } from "utils/helper/formatter";

export default function StoneProvideModal({
  handleModalClose,
  handleDimClick,
  ...restProps
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { remain_stone } = useSelector(
    (state) => state.memberUserDetail.payment,
  );
  const [clickButtonIndex, setClickButtonIndex] = useState(null);
  const addStoneCount = {
    27: 10,
    28: 50,
    29: 100,
    30: 500,
    31: 1000,
  };
  const AddStoneCount =
    clickButtonIndex !== null &&
    `(+${+Object.entries(addStoneCount)[clickButtonIndex][1]})`;

  const handleClickButton = (index) => () => {
    setClickButtonIndex(index);
  };

  const handleSubmit = () => {
    if (clickButtonIndex === null) return;
    dispatch(
      memberUserAddFreeStone({
        id,
        stoneId: clickButtonIndex + 27,
      }),
    );
    setClickButtonIndex(null);
    handleModalClose();
  };

  return (
    <PortalModal>
      <DimLayout handleDimClick={handleDimClick}>
        <Root {...restProps}>
          <Heading>스톤 지급하기</Heading>
          <RemainingStoneWrapper>
            <RemainingStone>잔여 스톤</RemainingStone>
            <CountWrapper>
              <StoneCount AddStoneCount={AddStoneCount}>
                {remain_stone.slice(0, -1)}
              </StoneCount>
            </CountWrapper>
          </RemainingStoneWrapper>
          <CountButtonWrapper>
            {Object.entries(addStoneCount).map(([, value], index) => {
              return (
                <CountButton
                  key={index}
                  clicked={clickButtonIndex === index}
                  onClick={handleClickButton(index)}
                >
                  {numberComma(value)}
                </CountButton>
              );
            })}
          </CountButtonWrapper>
          <ButtonWrapper>
            <CancelButton onClick={handleModalClose}>취소</CancelButton>
            <SubmitButton
              onClick={handleSubmit}
              disabled={clickButtonIndex === null}
            >
              확인
            </SubmitButton>
          </ButtonWrapper>
          <CloseButton onClick={handleModalClose}>
            <Close width="16px" height="16px" />
          </CloseButton>
        </Root>
      </DimLayout>
    </PortalModal>
  );
}

const Root = styled.section`
  width: 420px;
  background-color: ${({ theme }) => theme.color.white};
  position: relative;
`;

const Heading = styled.h3`
  font-size: 18px;
  font-weight: 500;
  padding: 17px 0 17px 24px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.border_grayE6}`};
`;

const RemainingStoneWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 10px;
  align-items: center;
  margin: 27px 0 36px 0;
`;

const RemainingStone = styled.span`
  display: block;
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_93};
`;

const CountWrapper = styled.div`
  display: flex;
`;

const StoneCount = styled.span`
  display: block;
  font-size: 21px;
  font-weight: 500;
  position: relative;

  ::after {
    content: "${({ AddStoneCount }) => AddStoneCount}";
    font-size: 21px;
    font-weight: 500;
    position: absolute;
    color: ${({ theme }) => theme.color.blue_00};
  }
`;

const CountButtonWrapper = styled.div`
  display: flex;
  margin: 0 24px 45px 24px;
  flex-wrap: wrap;

  button {
    margin: 0 8px 0 0;
  }

  & :nth-child(5) {
    margin: 0;
  }

  & :nth-child(6),
  & :nth-child(7) {
    margin-top: 8px;
  }
`;

const CountButton = styled.button`
  width: 68px;
  height: 35px;
  font-size: 15px;
  color: ${({ theme }) => theme.color.gray_5B};
  background-color: ${({ theme, clicked }) =>
    clicked ? theme.color.blue_F5 : theme.color.white};
  border-radius: 5px;
  border: 1px solid
    ${({ theme, clicked }) =>
      clicked ? theme.color.blue_00 : theme.color.gray_E2};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 15px 24px 15px 0;
  background-color: ${({ theme }) => theme.color.gray_F5};

  button + button {
    margin-left: 10px;
  }
`;

const CloseButton = styled.button`
  width: 16px;
  height: 16px;
  background-color: inherit;
  position: absolute;
  top: 20px;
  right: 24px;
`;
