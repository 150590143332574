import React, { useState, useContext, createContext } from "react";
import styled from "styled-components";

import { Arrow, CheckGray, CloseWhite, Image, Plus } from "assets/icon/index";
import useModal from "hooks/useModal";
import { Fragment } from "react";

const imageNumberContext = createContext();

export default function MemberAdminImageUpload({
  children,
  imageArray,
  imageUrl,
  setState,
  isError,
  ...restProps
}) {
  const [imageNumber, setImageNumber] = useState(0);

  const handleDeleteImage = () => {
    setState((draft) => {
      draft.image.imageUrl = imageUrl.filter((_, index) => {
        return index !== imageNumber;
      });
      draft.image.value = imageArray.filter((_, index) => {
        return index !== imageNumber;
      });
    });

    if (imageNumber !== 0) {
      if (imageArray.length - 1 === imageNumber) {
        setImageNumber(imageNumber - 1);
      }
    }
  };

  return (
    <imageNumberContext.Provider
      value={{
        imageNumber,
        setImageNumber,
        imageArray,
        isError,
      }}
    >
      <Root {...restProps}>
        {children}
        {imageArray.length !== 0 && imageArray[0] !== null && (
          <DeleteButton onClick={handleDeleteImage}>
            <CloseWhite />
          </DeleteButton>
        )}
      </Root>
    </imageNumberContext.Provider>
  );
}

MemberAdminImageUpload.UploadImage = function UploadImage({
  className,
  type,
  imageArray,
  imageUrl,
  setState,
  handleUploadImage,
  render,
}) {
  const { imageNumber, setImageNumber, isError } =
    useContext(imageNumberContext);
  const { isModalOpen, handleModalOpen, handleModalClose, handleDimClick } =
    useModal();

  const handleClickRepresent = () => {
    const indexChange = (array, changeIndex, changeIndex2) => {
      const tempArray = [...array];
      const temp = array[changeIndex];
      const temp2 = array[changeIndex2];
      tempArray[changeIndex2] = temp;
      tempArray[changeIndex] = temp2;

      return tempArray;
    };

    setState((draft) => {
      draft.image.imageUrl = indexChange(imageUrl, imageNumber, 0);
      draft.image.value = indexChange(imageArray, imageNumber, 0);
      setImageNumber(0);
    });
  };

  return (
    <>
      {imageArray.length === 0 ||
      (imageArray[0] === null && imageNumber === 0) ? (
        <>
          <UploadInput
            className={className}
            type="file"
            id="uploadInput"
            multiple
            accept="image/png, image/jpeg"
            onChange={handleUploadImage}
          />
          <UploadLabel isError={isError}>
            <Image />
            이미지를 업로드해주세요.
          </UploadLabel>
        </>
      ) : (
        <ImageViewWrapper>
          <ImageView src={imageUrl[imageNumber]} onClick={handleModalOpen} />
          {type === "create" && (
            <RepresentImageButton
              onClick={handleClickRepresent}
              represent={imageNumber === 0}
            >
              대표사진 <CheckGray />
            </RepresentImageButton>
          )}
        </ImageViewWrapper>
      )}
      {isModalOpen &&
        render(
          imageUrl,
          imageNumber,
          imageArray,
          setState,
          handleModalClose,
          handleDimClick,
        )}
    </>
  );
};

MemberAdminImageUpload.SmallImageViewList = function SmallImageViewList({
  imageUrl,
  handleUploadImage,
  className,
}) {
  return (
    <SmallImageViewWrapper className={className}>
      {imageUrl.length > 0 && (
        <>
          <SmallImageView src={imageUrl[0]} />
          <SelectRepresentImage>대표</SelectRepresentImage>
        </>
      )}
      {imageUrl &&
        imageUrl.map((item, index) => {
          if (index !== 0) {
            return <SmallImageView src={item} key={index} />;
          }
        })}
      {imageUrl && imageUrl.length < 5 && (
        <>
          <SmallUploadInput
            type="file"
            id="smallUploadInput"
            multiple
            accept="image/png, image/jpeg"
            onChange={handleUploadImage}
          />
          <SmallUploadLabel htmlFor="smallUploadInput">
            <CustomPlus />
          </SmallUploadLabel>
        </>
      )}
    </SmallImageViewWrapper>
  );
};

MemberAdminImageUpload.Buttons = function Buttons({ ...restProps }) {
  const { imageNumber, setImageNumber, imageArray } =
    useContext(imageNumberContext);

  const handleClickPrevButton = () => {
    if (imageNumber === 0) return;

    setImageNumber(imageNumber - 1);
  };

  const handleClickNextButotn = () => {
    if (imageNumber === imageArray.length - 1) return;

    setImageNumber(imageNumber + 1);
  };
  return (
    <ButtonWrapper {...restProps}>
      <PrevButton
        imageNumber={imageNumber}
        imageArray={imageArray}
        onClick={handleClickPrevButton}
      >
        <CustomArrow direction="left" />
      </PrevButton>
      <NextButton
        imageNumber={imageNumber}
        imageArray={imageArray}
        onClick={handleClickNextButotn}
      >
        <CustomArrow />
      </NextButton>
    </ButtonWrapper>
  );
};

const Root = styled.div`
  width: 204px;
  position: relative;
`;

const UploadInput = styled.input`
  position: absolute;
  top: -9999px;
  left: -9999px;
`;

const UploadLabel = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  row-gap: 13px;
  width: 100%;
  height: 228px;
  background-color: ${({ theme }) => theme.color.gray_F5};
  font-size: 13px;
  color: ${({ theme }) => theme.color.gray_93};
  border: ${({ theme, isError }) =>
    isError
      ? `1px solid ${theme.color.error}`
      : `1px solid ${theme.color.gray_E6}`};
`;

const ImageViewWrapper = styled.div`
  position: relative;
`;

const ImageView = styled.img`
  width: 204px;
  height: 228px;

  :hover {
    cursor: zoom-in;
  }
`;

const RepresentImageButton = styled.button`
  width: 103px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  color: ${({ theme, represent }) =>
    represent ? theme.color.blue_00 : theme.color.gray_7B};
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid
    ${({ theme, represent }) =>
      represent ? theme.color.blue_00 : theme.color.gray_DE};
  border-radius: 5px;
  position: absolute;
  bottom: 12px;
  left: 12px;
`;

const DeleteButton = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.black};
  border-radius: 50%;
  opacity: 0.7;
  position: absolute;
  top: 8px;
  right: 8px;
`;

const SmallImageViewWrapper = styled.div`
  display: flex;
  column-gap: 6px;
  margin-top: 8px;
  position: relative;
`;

const SelectRepresentImage = styled.div`
  width: 36px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 105, 249, 0.2);
  color: ${({ theme }) => theme.color.white};
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
`;

const SmallImageView = styled.img`
  width: 36px;
  height: 40px;
`;

const SmallUploadInput = styled.input`
  position: absolute;
  top: -9999px;
  left: -9999px;
`;

const SmallUploadLabel = styled.label`
  width: 36px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.gray_E6};

  :hover {
    cursor: pointer;
  }
`;

const CustomPlus = styled(Plus)`
  width: 12px;
  height: 12px;
`;

const ButtonWrapper = styled.div`
  width: 72px;
  margin: 12px auto 0 auto;
  display: flex;
  column-gap: 16px;
`;

const PrevButton = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  opacity: ${({ imageNumber, imageArray }) =>
    imageArray.length === 0 || imageNumber === 0 ? "0.5" : "1"};

  :hover {
    cursor: ${({ imageNumber, imageArray }) =>
      imageArray.length === 0 || imageNumber === 0 ? "not-allowed" : "curser"};
  }
`;

const NextButton = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  opacity: ${({ imageNumber, imageArray }) =>
    imageArray.length === 0 || imageNumber === imageArray.length - 1
      ? "0.5"
      : "1"};

  :hover {
    cursor: ${({ imageNumber, imageArray }) =>
      imageArray.length === 0 || imageNumber === imageArray.length - 1
        ? "not-allowed"
        : "curser"};
  }
`;

const CustomArrow = styled(Arrow)`
  width: 28px;
  fill: ${({ theme }) => theme.color.text_gray5b};
  transform: ${({ direction }) =>
    direction === "left" ? "rotate(-90deg)" : "rotate(90deg)"};
`;
