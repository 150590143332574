import axios from "axios";
import { ax } from "api/axios";

export const postAWS = async (data, type, file) => {
  const { fields, url } = data.data;

  const formData = new FormData();

  for (const [key, value] of Object.entries(fields)) {
    formData.append(key, value);
  }

  formData.append("Content-Type", type);
  formData.append("file", file);

  return await axios.post(url, formData);
};

export const postPresigned = async (payload) => {
  return await ax.post("/common/presigned", payload);
};

export const getPresigned = async (payload) => {
  const { s3_folder, file_id } = payload;

  return await ax.get(
    `/common/presigned?s3_folder=${s3_folder}&file_id=${file_id}`,
  );
};
