import { ax } from "api/axios";

export const getUnmasking = async (payload) => {
  const { id, password } = payload;
  return await ax.get(`/admin/user/${id}/info?password=${password}`);
};

export const getAnswerList = async () => {
  return await ax.get("/common/question");
};
