import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ListTable } from "components/index";
import { memberUserDetailMatchHistory } from "store/reducer/memberUserDetailMatchHistory";
import styled from "styled-components";
import ModalPagination from "components/pagination/ModalPagination";
import {
  memberUserProfileMatchHistoryModalTable,
  memberUserProfileMatchHistoryModalTableWidth,
} from "assets/static/listTable";

export default function MatchHistoryDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const { matchs, pageInfo } = useSelector(
    (state) => state.memberUserDetailMatchHistory,
  );

  useEffect(() => {
    dispatch(
      memberUserDetailMatchHistory({
        id,
        pageNumber,
      }),
    );
  }, [pageNumber]);

  return (
    <Root>
      <TableWrapper>
        <ListTable
          list={memberUserProfileMatchHistoryModalTable}
          width={memberUserProfileMatchHistoryModalTableWidth}
          data={matchs}
        />
      </TableWrapper>
      <PaginationWrapper>
        <ModalPagination
          pageData={pageInfo}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </PaginationWrapper>
    </Root>
  );
}

const Root = styled.div`
  height: 660px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  padding: 30px 30px 0 30px;
`;

const PaginationWrapper = styled.div`
  height: 60px;
  padding: 14px 30px 14px 0;
  display: flex;
  justify-content: end;
  background-color: ${({ theme }) => theme.color.gray_F5};
`;
