import { put, call, takeLatest } from "redux-saga/effects";

import {
  changeMemberAdminPassword,
  changeMemberAdminPasswordFailure,
  changeMemberAdminPasswordSuccess,
} from "store/reducer/password";
import { putChangePassword } from "api/index";
import { toastShow } from "store/reducer/toast";

function* changeMemberAmdinPassword(action) {
  try {
    yield call(putChangePassword, action.payload);

    yield put({
      type: changeMemberAdminPasswordSuccess,
    });
    yield put({
      type: toastShow,
      data: { type: "success", text: "changeMemberAdminPasswordSuccess" },
    });
  } catch (err) {
    yield put({
      type: changeMemberAdminPasswordFailure,
      error: err.response.data.detail,
    });
    yield put({
      type: toastShow,
      data: { type: "fail", text: "changeMemberAdminPasswordFail" },
    });
  }
}

export function* watchChangeMemberAdminPassword() {
  yield takeLatest(changeMemberAdminPassword, changeMemberAmdinPassword);
}
