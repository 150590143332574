import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const useListTable = (path) => {
  const navigate = useNavigate();

  const handleGoToDetail = useCallback(
    (id) => () => {
      navigate(`${path}/${id}`);
    },
    [],
  );

  return { handleGoToDetail };
};

export default useListTable;
