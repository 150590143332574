import { PATH } from "utils/constants";
const navList = {
  member: [
    {
      path: PATH.memberUser.path,
      fullPath: PATH.memberUser.fullPath,
      content: "일반회원 관리",
      assert: "user",
    },
    {
      path: PATH.memberAdmin.path,
      fullPath: PATH.memberAdmin.fullPath,
      content: "어드민 회원 관리",
      assert: "admin_user",
    },
    // {
    //   path: PATH.memberReport.path,
    //   fullPath: PATH.memberReport.fullPath,
    //   content: "신고 관리",
    // },
    // {
    //   path: PATH.memberSuspended.path,
    //   fullPath: PATH.memberSuspended.fullPath,
    //   content: "정지회원 관리",
    // },
    // {
    //   path: PATH.memberPush.path,
    //   fullPath: PATH.memberPush.fullPath,
    //   content: "PUSH 관리",
    // },
    // {
    //   path: PATH.memberNoti,
    //   fullPath: PATH.memberNoti.fullPath,
    //   content: "알리미 관리",
    // },
  ],
  auth: [
    {
      path: PATH.authProfile.path,
      fullPath: PATH.authProfile.fullPath,
      content: "프로필 검수",
      assert: "profile",
    },
    {
      path: PATH.authAppearance.path,
      fullPath: PATH.authAppearance.fullPath,
      content: "인증 검수",
      assert: "certificate",
      subContent: [
        {
          path: PATH.authAppearance.path,
          fullPath: PATH.authAppearance.fullPath,
          content: "외모 인증 관리",
        },
        {
          path: PATH.authBody.path,
          fullPath: PATH.authBody.fullPath,
          content: "바디 인증 관리",
        },
        {
          path: PATH.authUniversity.path,
          fullPath: PATH.authUniversity.fullPath,
          content: "학력 인증 관리",
        },
        {
          path: PATH.authOccupation.path,
          fullPath: PATH.authOccupation.fullPath,
          content: "직업 인증 관리",
        },
        {
          path: PATH.authIncome.path,
          fullPath: PATH.authIncome.fullPath,
          content: "소득 인증 관리",
        },
      ],
    },
  ],
  report: [
    {
      path: PATH.reportProfile.path,
      fullPath: PATH.reportProfile.fullPath,
      content: "프로필 신고 관리",
      assert: "profile_report",
    },
    {
      path: PATH.reportCommunity.path,
      fullPath: PATH.reportCommunity.fullPath,
      content: "커뮤니티 신고 관리",
      assert: "community_report",
    },
    {
      path: PATH.reportIndianGame.path,
      fullPath: PATH.reportIndianGame.fullPath,
      content: "인디언 게임 신고 관리",
      assert: "indian_report",
    },
  ],
  payment: [
    {
      path: PATH.payment.path,
      fullPath: PATH.payment.fullPath,
      content: "없음",
    },
  ],
  common: [
    {
      path: PATH.common.path,
      fullPath: PATH.common.fullPath,
      content: "없음",
    },
  ],
};

export { navList };
