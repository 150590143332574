import { createSlice } from "@reduxjs/toolkit";

export const reportProfileProcessSlice = createSlice({
  name: "reportProfileProcess",
  initialState: {
    isLoading: false,
    isDone: false,
    error: null,
  },
  reducers: {
    reportProfileProcess: (state) => {
      state.isLoading = true;
      state.isDone = false;
      state.error = null;
    },
    reportProfileProcessSuccess: (state) => {
      state.isLoading = false;
      state.isDone = true;
    },
    reportProfileProcessFailure: (state, action) => {
      state.isLoading = false;
      state.isDone = false;
      state.error = action.error;
    },
  },
});

export const {
  reportProfileProcess,
  reportProfileProcessSuccess,
  reportProfileProcessFailure,
} = reportProfileProcessSlice.actions;

export default reportProfileProcessSlice.reducer;
