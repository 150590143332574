import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { memberAdminDuplicateNickname } from "store/reducer/memberAdminCreate";
import { InputCell } from "components/index";

export default function NicknameCell({
  state,
  setState,
  stateName,
  ...restProps
}) {
  const dispatch = useDispatch();

  const handleChangeInputValue = (event) => {
    const { value } = event.target;
    setState((draft) => {
      draft[stateName] = value;
    });
  };

  const handleCheckDuplicateNickName = (event) => {
    const { value } = event.target;
    if (value.length === 0) return;
    dispatch(memberAdminDuplicateNickname({ nickname: value }));
  };

  return (
    <Root>
      <CustomInputCell
        onChange={handleChangeInputValue}
        onBlur={handleCheckDuplicateNickName}
        state={state}
        setState={setState}
        {...restProps}
      />
    </Root>
  );
}

const Root = styled.div`
  position: relative;
`;

const CustomInputCell = styled(InputCell)`
  /* input {
    border: ${({ theme, validation }) =>
    validation === false
      ? `1px solid ${theme.color.red_FF}`
      : `1px solid ${theme.color.gray_DE}`};
  } */
`;
