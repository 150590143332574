import React from "react";
import styled from "styled-components";

export default function FilterWrapper({
  spaceBetween,
  children,
  ...restProps
}) {
  return (
    <Root spaceBetween={spaceBetween} {...restProps}>
      {children}
    </Root>
  );
}

const Root = styled.div`
  min-width: 1140px;
  height: 77px;
  display: flex;
  justify-content: ${({ spaceBetween }) => spaceBetween && "space-Between"};
  align-items: center;
  border-top: ${({ theme }) => `1px solid ${theme.color.border_grayE6}`};
`;
