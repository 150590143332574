import React from "react";
import { Controller } from "react-hook-form";

import FormTable from "components/table/FormTable";
import { DetailPageSectionLayout } from "components/index";
import FormDropdowns from "components/dropdown/FormDropdowns";
import FormTextarea from "components/input/FormTextarea";
import ErrorMessage from "components/errorMessage/ErrorMessage";
import { isAdminListError } from "utils/helper/formatter";

export default function Introduce({
  adminList,
  control,
  dropdowns,
  watch,
  register,
  errors,
  setError,
  clearErrors,
}) {
  const isIntroduceError = isAdminListError(errors, adminList.introduce);

  const contentRender = (content) => {
    switch (content.key) {
      case "hobby":
      case "personality":
      case "ideal":
        return (
          <FormDropdowns
            name={content.key}
            placeholder={content.placeholder ?? "전체"}
            isError={errors}
            setError={setError}
            clearErrors={clearErrors}
            Controller={Controller}
            control={control}
            options={dropdowns[content.key]}
            watch={watch}
            dropdownCount={5}
          />
        );

      case "mbti":
        return (
          <FormDropdowns
            name={content.key}
            placeholder={content.placeholder ?? "전체"}
            isError={errors}
            setError={setError}
            clearErrors={clearErrors}
            Controller={Controller}
            control={control}
            options={dropdowns[content.key]}
            watch={watch}
            dropdownCount={1}
          />
        );

      case "introduction":
        return (
          <FormTextarea
            placeholder="내용을 입력해주세요."
            maxLength={300}
            isError={errors.introduction}
            register={register(content.key, {
              required: true,
            })}
          />
        );
    }
  };

  const makeErrorMessage = () => {
    const isErrorHobby =
      !!errors.hobby1 ||
      !!errors.hobby2 ||
      !!errors.hobby3 ||
      !!errors.hobby4 ||
      !!errors.hobby5;
    const isErrorPersonality =
      !!errors.personality1 ||
      !!errors.personality2 ||
      !!errors.personality3 ||
      !!errors.personality4 ||
      !!errors.personality5;
    const isErrorIdeal =
      !!errors.ideal1 ||
      !!errors.ideal2 ||
      !!errors.ideal3 ||
      !!errors.ideal4 ||
      !!errors.ideal5;
    const isErrorMBTI = !!errors.mbti1;

    if (
      isIntroduceError ||
      isErrorHobby ||
      isErrorPersonality ||
      isErrorIdeal ||
      isErrorMBTI
    ) {
      return <ErrorMessage msg="필수 입력 값을 입력해주세요." />;
    }
  };

  return (
    <DetailPageSectionLayout
      heading="회원 소개"
      description="*필수 정보를 모두 입력해주세요."
    >
      <div>
        <FormTable>
          {adminList.introduce.map((row, rowI) => (
            <FormTable.Row key={rowI} contentCount={row.length}>
              {row.map((content, contentI) => (
                <FormTable.Content
                  key={content.heading + contentI}
                  require={content.require}
                  heading={content.heading}
                >
                  {contentRender(content)}
                </FormTable.Content>
              ))}
            </FormTable.Row>
          ))}
        </FormTable>
        {makeErrorMessage()}
      </div>
    </DetailPageSectionLayout>
  );
}
