import React from "react";
import styled from "styled-components";

import { RefreshButton } from "components/index";
import SearchFilter from "components/common/SearchFilter";
import useDropdown from "hooks/useDropdown";
import useFilter from "hooks/useFilter";
import { ReportSearchFilter, ReportStatus } from "assets/static/dropdown";

export default function ReportFilter({ reportTypeObj, actionFn }) {
  const [selectType, handleClickType] = useDropdown("", "type");
  const [selectStatus, handleClickStatus] = useDropdown("", "status");
  const [selectSearchInputFilter, handleChangeSearchInputFilter] = useDropdown(
    "from_nickname",
    "personalInfo",
  );

  const {
    inputValue,
    handleFilterSubmit,
    handleChangeInputValue,
    handleResetSubmit,
  } = useFilter(
    {
      type: selectType,
      status: selectStatus,
      inputFilter: selectSearchInputFilter,
    },
    actionFn,
  );

  return (
    <SearchFilter
      topChildren={() => {
        return (
          <SearchFilter.FlexWrapper>
            <Dropdown
              label="신고 유형"
              selectOption={selectType}
              optionObj={reportTypeObj}
              handleClickOption={handleClickType}
            />
            <SearchFilter.LastLabelDropdown
              label="처리상태"
              selectOption={selectStatus}
              optionObj={ReportStatus}
              handleClickOption={handleClickStatus}
            />
          </SearchFilter.FlexWrapper>
        );
      }}
      bottomChildren={() => {
        return (
          <>
            <SearchFilter.FlexWrapper>
              <InputFilter
                selectOption={selectSearchInputFilter}
                optionObj={ReportSearchFilter}
                handleClickOption={handleChangeSearchInputFilter}
              />
              <SearchFilter.Input
                value={inputValue}
                handleChangeInputValue={handleChangeInputValue}
                handleSubmit={handleFilterSubmit}
              />
            </SearchFilter.FlexWrapper>
            <SearchFilter.FlexWrapper>
              <SearchFilter.SubmitButton onClick={handleFilterSubmit} />
              <RefreshButton onClick={handleResetSubmit} />
            </SearchFilter.FlexWrapper>
          </>
        );
      }}
    />
  );
}

const Dropdown = styled(SearchFilter.LabelDropdown)`
  & > :last-child {
    width: 200px;
  }
`;

const InputFilter = styled(SearchFilter.InputFilter)`
  width: 160px;
`;
