import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom/index";
import styled from "styled-components";

import { Heading, RefreshButton } from "components/index";
import MemberTable from "../containers/MemberTable";
import { memberUser } from "store/reducer/memberUser";
import SearchFilter from "components/common/SearchFilter";
import {
  adminUserStatusId,
  admonishment,
  gender,
  internalGrade,
  personalInfo,
} from "assets/static/dropdown";
import useDropdown from "hooks/useDropdown";
import useFilter from "hooks/useFilter";
import { memberUserDetailReset } from "store/reducer/memberUserDetail";

export default function MemberUser() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { dataList, pageData } = useSelector(({ memberUser }) => ({
    dataList: memberUser.dataList,
    pageData: memberUser.pageData,
  }));

  const [selectGender, handleClickGender] = useDropdown("", "gender");
  const [selectInternalGrade, handleClickInternalGrade] = useDropdown(
    "",
    "internal_grade",
  );
  const [selectUserStatusId, handleChangeUserStatusId] = useDropdown(
    "",
    "user_status_id",
  );
  const [selectAdmonishment, handleChangeAdmonishment] = useDropdown(
    "",
    "admonishment",
  );
  const [selectSearchInputFilter, handleChangeSearchInputFilter] = useDropdown(
    "nickname",
    "personalInfo",
  );

  const {
    inputValue,
    handleFilterSubmit,
    handleChangeInputValue,
    handleResetSubmit,
  } = useFilter(
    {
      gender: selectGender,
      internal_grade: selectInternalGrade,
      user_status_id: selectUserStatusId,
      admonishment: selectAdmonishment,
      inputFilter: selectSearchInputFilter,
    },
    memberUser({ type: "normal" }),
  );

  useEffect(() => {
    dispatch(memberUserDetailReset());
  }, []);

  useEffect(() => {
    const filterObj = { ...Object.fromEntries(searchParams) };
    dispatch(memberUser({ ...filterObj, type: "normal" }));
  }, [searchParams]);

  return (
    <Root>
      <Heading as="h2">일반회원 관리</Heading>
      <SearchFilter
        topChildren={() => {
          return (
            <SearchFilter.FlexWrapper>
              <SearchFilter.LabelDropdown
                label="성별"
                selectOption={selectGender}
                optionObj={gender}
                handleClickOption={handleClickGender}
              />
              <SearchFilter.LabelDropdown
                label="관리등급"
                selectOption={selectInternalGrade}
                optionObj={internalGrade}
                handleClickOption={handleClickInternalGrade}
              />
              <SearchFilter.LabelDropdown
                label="활동 상태"
                selectOption={selectUserStatusId}
                optionObj={adminUserStatusId}
                handleClickOption={handleChangeUserStatusId}
              />
              <SearchFilter.LastLabelDropdown
                label="경고 횟수"
                selectOption={selectAdmonishment}
                optionObj={admonishment}
                handleClickOption={handleChangeAdmonishment}
              />
            </SearchFilter.FlexWrapper>
          );
        }}
        bottomChildren={() => {
          return (
            <>
              <SearchFilter.FlexWrapper>
                <SearchFilter.InputFilter
                  selectOption={selectSearchInputFilter}
                  optionObj={personalInfo}
                  handleClickOption={handleChangeSearchInputFilter}
                />
                <SearchFilter.Input
                  value={inputValue}
                  handleChangeInputValue={handleChangeInputValue}
                  handleSubmit={handleFilterSubmit}
                />
              </SearchFilter.FlexWrapper>
              <SearchFilter.FlexWrapper>
                <SearchFilter.SubmitButton onClick={handleFilterSubmit} />
                <RefreshButton onClick={handleResetSubmit} />
              </SearchFilter.FlexWrapper>
            </>
          );
        }}
      />
      <MemberTable
        data={dataList}
        pageData={pageData}
        pageName="memberUser"
        excel="true"
      />
    </Root>
  );
}

const Root = styled.section`
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 50px;
`;
