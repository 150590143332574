import React, { useState, Fragment } from "react";
import styled from "styled-components";

import { Arrow, CloseWhite, Image, Plus } from "assets/icon/index";
import useModal from "hooks/useModal";
import PaginationImageDetailViewModal from "components/modal/PaginationImageDetailViewModal";

export default function FormImageUpload({
  isError,
  register,
  setValue,
  profiles,
  deleteProfiles,
}) {
  const { isModalOpen, handleModalOpen, handleModalClose, handleDimClick } =
    useModal();

  const [showImageIndex, setShowImageIndex] = useState(0);

  const isDisabledPrevBtn = showImageIndex === 0;
  const isDisabledNextBtn = showImageIndex === 4;

  const handlePrevBtn = () => {
    if (showImageIndex !== 0) setShowImageIndex(showImageIndex - 1);
  };

  const handleNextBtn = () => {
    if (showImageIndex !== 4) setShowImageIndex(showImageIndex + 1);
  };

  const handleDeleteImage = (imageIndex) => () => {
    setValue(`profile${imageIndex + 1}`, "");
    deleteProfiles(imageIndex);
  };

  return (
    <Root>
      {isModalOpen && (
        <PaginationImageDetailViewModal
          title="어드민: 프로필 사진"
          images={profiles}
          imageIndex={showImageIndex}
          handleDimClick={handleDimClick}
          handleModalClose={handleModalClose}
        />
      )}
      {profiles.filter((item) => item).length === 0 ? (
        <UploadLabel>
          <Image />
          이미지를 업로드해주세요.
        </UploadLabel>
      ) : profiles[showImageIndex] ? (
        <UploadLabel>
          <button onClick={handleModalOpen}>
            <BigImg
              src={
                profiles[showImageIndex]?.[0]?.name
                  ? URL.createObjectURL(profiles[showImageIndex][0])
                  : profiles[showImageIndex]
              }
              alt="프로필이미지"
            />
          </button>
        </UploadLabel>
      ) : (
        <UploadLabel>
          <Image />
          이미지를 업로드해주세요.
        </UploadLabel>
      )}
      {profiles[showImageIndex] && (
        <DeleteBtn onClick={handleDeleteImage(showImageIndex)}>
          <CloseWhite />
        </DeleteBtn>
      )}
      <SmallImageViewWrapper>
        {Array(5)
          .fill(null)
          .map((_, i) => {
            return (
              <Fragment key={i}>
                {profiles[i] ? (
                  <SmallImageView
                    key={i}
                    src={
                      profiles[i][0]?.name
                        ? URL.createObjectURL(profiles[i][0])
                        : profiles[i]
                    }
                    alt="프로필이미지"
                  />
                ) : (
                  <>
                    <SmallUploadInput
                      type="file"
                      id="smallUploadInput"
                      accept="image/png, image/jpeg, image/jpg"
                      {...register(`profile${i + 1}`)}
                    />
                    <SmallUploadLabel
                      htmlFor="smallUploadInput"
                      isError={isError[i]}
                    >
                      <CustomPlus />
                    </SmallUploadLabel>
                  </>
                )}
              </Fragment>
            );
          })}
      </SmallImageViewWrapper>
      <ButtonWrapper>
        <ArrowBtn isDisabled={isDisabledPrevBtn} onClick={handlePrevBtn}>
          <CustomArrow direction="left" />
        </ArrowBtn>
        <ArrowBtn isDisabled={isDisabledNextBtn} onClick={handleNextBtn}>
          <CustomArrow />
        </ArrowBtn>
      </ButtonWrapper>
    </Root>
  );
}

const Root = styled.div`
  width: 204px;
  position: relative;
`;

const DeleteBtn = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.black};
  border-radius: 50%;
  opacity: 0.7;
  position: absolute;
  top: 8px;
  right: 8px;
`;

const UploadInput = styled.input`
  position: absolute;
  top: -9999px;
  left: -9999px;
`;

const UploadLabel = styled.label`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  row-gap: 13px;
  width: 100%;
  height: 228px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.color.gray_F5};
  font-size: 13px;
  color: ${({ theme }) => theme.color.gray_93};
  border: ${({ theme, isError }) =>
    isError
      ? `1px solid ${theme.color.error}`
      : `1px solid ${theme.color.gray_E6}`};

  button {
    max-height: 226px;
  }
`;

const BigImg = styled.img`
  width: 202px;
  max-height: 226px;
  margin: 0 auto;

  :hover {
    cursor: zoom-in;
  }
`;

const SmallImageViewWrapper = styled.div`
  display: flex;
  column-gap: 6px;
  margin-top: 8px;
  position: relative;
`;

const SmallImageView = styled.img`
  width: 36px;
  height: 40px;
`;

const SmallUploadInput = styled.input`
  position: absolute;
  top: -9999px;
  left: -9999px;
`;

const SmallUploadLabel = styled.label`
  width: 36px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.gray_E6};

  :hover {
    cursor: pointer;
  }
`;

const CustomPlus = styled(Plus)`
  width: 12px;
  height: 12px;
`;

const ButtonWrapper = styled.div`
  width: 72px;
  margin: 12px auto 0 auto;
  display: flex;
  column-gap: 16px;
`;

const CustomArrow = styled(Arrow)`
  width: 28px;
  fill: ${({ theme }) => theme.color.text_gray5b};
  transform: ${({ direction }) =>
    direction === "left" ? "rotate(-90deg)" : "rotate(90deg)"};
`;

const ArrowBtn = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")};

  :hover {
    cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "curser")};
  }
`;
