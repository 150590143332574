import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { Heading } from "components/index";
import { authProfileDetailApproval } from "store/reducer/authProfileDetailApproval";
import { profileBasicInformationTable } from "assets/static/table";
import { authProfileDetailReject } from "store/reducer/authProfileDetailReject";
import {
  profileImageRejectReason,
  profileRejectReason,
} from "assets/static/radioButton";
import { authProfileDetail } from "store/reducer/authProfileDetail";
import {
  AuthDetailPageRootLayout,
  BasicInformation,
  SubmitCancelButton,
} from "pages/auth/containers/index";
import ProfileInformation from "./container/ProfileInformation";

export default function AuthProfileDetail() {
  const [checkState, setCheckState] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { certification, profileSrcArray, profileInformation } = useSelector(
    (state) => state.authProfileDetail,
  );
  const { user_type, status } = certification;

  const handleClickCheckBox = (key) => {
    setCheckState((prev) => {
      if (prev.indexOf(key) === -1) {
        return [...prev, key];
      } else {
        return prev.filter((item) => item !== key);
      }
    });
  };

  const handleSubbmit = () => {
    dispatch(
      authProfileDetailApproval({
        id: id,
        data: {
          type: "approve",
        },
        navigate,
      }),
    );
  };

  useEffect(() => {
    dispatch(authProfileDetail(id));
  }, []);

  return (
    <AuthDetailPageRootLayout>
      <Heading as="h2">프로필 검수 상세</Heading>
      <BasicInformation
        list={profileBasicInformationTable}
        data={certification}
        // ci={true}
      />
      <ProfileInformation
        profileSrcArray={profileSrcArray}
        profileInformation={profileInformation}
        user_type={user_type}
        handleClickCheckBox={handleClickCheckBox}
        status={status}
      />
      {status === "승인대기" && (
        <SubmitCancelButton
          rejectList={
            user_type === "신규회원"
              ? profileRejectReason
              : profileImageRejectReason
          }
          rejectAction={authProfileDetailReject}
          checkState={checkState}
          handleSubbmit={handleSubbmit}
        />
      )}
    </AuthDetailPageRootLayout>
  );
}
