import { ax, baseUrl } from "api/axios";
import Cookies from "js-cookie";

export const logoutAPI = async () => {
  const accessToken = Cookies.get("userToken");
  const refreshToken = Cookies.get("refreshToken");
  await ax.post(
    `${baseUrl}/admin/logout`,
    { refreshtoken: refreshToken },
    { authorization: "bearer " + accessToken },
  );
  Cookies.remove("userToken");
  Cookies.remove("refreshToken");
  Cookies.remove("auth");
};
