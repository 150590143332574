import { memberAdminProfileTable } from "assets/static/table";
import { ComplexTable, DetailPageSectionLayout } from "components/index";
import React from "react";
import styled from "styled-components";

export default function MemberAdminProfileInformation({
  state,
  setState,
  gender,
  errorState,
  setErrorState,
  handleChangeInput,
  handleBlurError,
  tableErrorState,
  duplicateState,
}) {
  return (
    <DetailPageSectionLayout
      heading="프로필 정보"
      description="*필수 정보를 모두 입력해주세요."
    >
      <CustomComplexTable
        list={memberAdminProfileTable}
        state={state}
        setState={setState}
        gender={gender}
        errorState={errorState}
        setErrorState={setErrorState}
        handleChangeInput={handleChangeInput}
        handleBlurError={handleBlurError}
        duplicateState={duplicateState}
      />
      {tableErrorState.profileState.state && (
        <ErrorMessage>{tableErrorState.profileState.message}</ErrorMessage>
      )}
    </DetailPageSectionLayout>
  );
}

const CustomComplexTable = styled(ComplexTable)`
  & > :first-child {
    grid-column: auto / span 2;
  }
`;

const ErrorMessage = styled.span`
  display: block;
  margin-top: 13px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.red_FF};
`;
