import React from "react";
import styled from "styled-components";

import { Table, DetailPageSectionLayout } from "components/index";
import { memberAdminDetailProfileTable } from "assets/static/table";

export default function MemberAdminDetailProfileInformation({ data }) {
  return (
    <DetailPageSectionLayout heading="프로필 정보">
      <CustomComplexTable list={memberAdminDetailProfileTable} data={data} />
    </DetailPageSectionLayout>
  );
}

const CustomComplexTable = styled(Table)`
  & > :first-child {
    grid-column: auto / span 2;
  }
`;
