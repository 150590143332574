import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Heading, SubmitButton } from "components/index";
import MemberAdminProfileInformation from "../containers/MemberAdminProfileInformation";
import MemberAdminIntroduce from "../containers/MemberAdminIntroduce";
import MemberAdminAuthInformation from "../containers/MemberAdminAuthInformation";
import AuthDetailPageRootLayout from "pages/auth/containers/AuthDetailPageRootLayout";
import { memberAdminDetail } from "store/reducer/memberAdminDetail";
import { memberAdminDropdownList } from "store/reducer/memberAdminDropdownList";
import { memberAdminModify } from "store/reducer/memberAdminModify";
import MemberAdminDetailModifyBasicInformation from "./containers/MemberAdminDetailModifyBasicInformation";
import {
  addDashPhoneNumber,
  authInformatinFormatter,
  checkBirthday,
  checkOnlyNumber,
  convertUTCtoBirthday,
} from "utils/helper/formatter";
import {
  memberAdminBasicInformation,
  memberAdminIntroduceTable,
  memberAdminProfileTable,
} from "assets/static/table";
import {
  memberAdminDuplicateEmail,
  memberAdminDuplicateNickname,
} from "store/reducer/memberAdminCreate";
import { checkEmailValidation } from "utils/helper/validation";

const initBasicState = {
  image: {
    imageUrl: [],
    value: [],
  },
  email: "",
  userNumber: "",
  name: "",
  phone: "",
  birthday: "",
  gender: "",
  userStatusId: "",
  created: "",
  lastLogin: "",
  internalGrade: "",
};

const initProfileState = {
  nickname: "",
  area: "",
  height: "",
  body_type: "",
  religion: "",
  drinking: "",
  smoking: "",
  education: "",
  job: "",
};

const initIntroduceState = {
  personality: ["", "", ""],
  hobby: ["", "", ""],
  interest: ["", "", "", "", ""],
  attraction: ["", "", "", "", ""],
  ideal: ["", "", "", "", ""],
  introduction: "",
};

const initAuthInfoState = {
  appearance: 2,
  body: 2,
  occupation: 2,
  university: 2,
  income: 2,
};

const initBasicErrorState = {
  email: {
    state: false,
    message: "",
    touched: false,
  },
  password: {
    state: false,
    message: "",
    touched: false,
  },
  passwordConfirm: {
    state: false,
    message: "",
    touched: false,
  },
  name: {
    state: false,
    message: "",
    touched: false,
  },
  phone: {
    state: false,
    message: "",
    touched: false,
  },
  birthday: {
    state: false,
    message: "",
    touched: false,
  },
  userStatusId: {
    state: false,
    message: "",
    touched: false,
  },
  internalGrade: {
    state: false,
    message: "",
    touched: false,
  },
};

const initProfileErrorState = {
  nickname: {
    state: false,
    message: "",
    touched: false,
  },
  area: {
    state: false,
    message: "",
    touched: false,
  },
  height: {
    state: false,
    message: "",
    touched: false,
  },
  body_type: {
    state: false,
    message: "",
    touched: false,
  },
  religion: {
    state: false,
    message: "",
    touched: false,
  },
  drinking: {
    state: false,
    message: "",
    touched: false,
  },
  smoking: {
    state: false,
    message: "",
    touched: false,
  },
  education: {
    state: false,
    message: "",
    touched: false,
  },
  job: {
    state: false,
    message: "",
    touched: false,
  },
};

const initIntroduceErrorState = {
  personality: {
    state: [false, false, false],
    message: "",
    touched: false,
  },
  hobby: {
    state: [false, false, false],
    message: "",
    touched: false,
  },
  interest: {
    state: [false, false, false, false, false],
    message: "",
    touched: [false, false, false, false, false],
  },
  attraction: {
    state: [false, false, false, false, false],
    message: "",
    touched: [false, false, false, false, false],
  },
  ideal: {
    state: [false, false, false, false, false],
    message: "",
    touched: [false, false, false, false, false],
  },
  introduction: {
    state: false,
    message: "",
    touched: false,
  },
};

const initTableErrorState = {
  basicState: {
    state: false,
    message: "",
  },
  profileState: {
    state: false,
    message: "",
  },
  introduceState: {
    state: false,
    message: "",
  },
  authInfoState: {
    state: false,
    message: "",
    touched: false,
  },
};

export default function MemberAdminModify() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [basicState, setBasicState] = useImmer(initBasicState);
  const [profileState, setProfileState] = useImmer(initProfileState);
  const [introduceState, setIntroduceState] = useImmer(initIntroduceState);
  const [authInfoState, setAuthInfoState] = useImmer(initAuthInfoState);
  const [beforeChangeImage, setBeforeChangeImage] = useImmer({
    imageUrl: [],
    value: [],
  });
  const [isChangeInformation, setIsChangeInformation] = useState(false);
  const [basicErrorState, setBasicErrorState] = useImmer(initBasicErrorState);
  const [profileErrorState, setProfileErrorState] = useImmer(
    initProfileErrorState,
  );
  const [introduceErrorState, setIntroduceErrorState] = useImmer(
    initIntroduceErrorState,
  );
  const [tableErrorState, setTableErrorState] = useImmer(initTableErrorState);
  const [duplicateState, setDuplicateState] = useImmer({
    email: false,
    nickname: false,
  });
  const emailDuplicate = useSelector(
    ({ memberAdminCreate }) => memberAdminCreate.duplicateEmail.isDuplicate,
  );
  const nicknameDuplicate = useSelector(
    ({ memberAdminCreate }) => memberAdminCreate.duplicateNickname.isDuplicate,
  );
  const { data } = useSelector((state) => state.memberAdminDetail);
  const nickname = useSelector(
    (state) => state.memberAdminDetail.data.nickname,
  );

  const isOnlyAddImage = (beforeArray, afterArray) => {
    return beforeArray.every((item) => afterArray.includes(item));
  };

  const deleteImageIndex = (beforeArray, afterArray) => {
    if (JSON.stringify(beforeArray) === JSON.stringify(afterArray)) return;

    //삭제된 이미지 index만 return 해준다.
    return beforeArray
      .map((item, index) => {
        if (!afterArray.includes(item)) return index;
      })
      .filter((item) => item !== undefined);
  };

  const handleSubmit = () => {
    requiredValueCheck();
    requiredValidateionMessage(basicState, "basicState");
    requiredValidateionMessage(profileState, "profileState");
    requiredValidateionMessage(introduceState, "introduceState");

    const modifyState = {
      phone: basicState.phone,
      nickname: profileState.nickname,
      area_id: profileState.area,
      height: profileState.height,
      body_type_id: profileState.body_type,
      religion_id: profileState.religion,
      smoking_id: profileState.smoking,
      drinking_id: profileState.drinking,
      job: profileState.job,
      education: profileState.education,
      introduction: introduceState.introduction,
      personality: introduceState.personality,
      hobby: introduceState.hobby,
      interest: introduceState.interest,
      attraction: introduceState.attraction,
      ideal: introduceState.ideal,
      certification: [
        authInformatinFormatter(authInfoState.appearance),
        authInformatinFormatter(authInfoState.body),
        authInformatinFormatter(authInfoState.occupation),
        authInformatinFormatter(authInfoState.university),
        authInformatinFormatter(authInfoState.income),
      ],
      internal_grade: basicState.internalGrade,
      user_status_id: basicState.userStatusId,
      ...(!isOnlyAddImage(beforeChangeImage.value, basicState.image.value) && {
        delete_file_idx: deleteImageIndex(
          beforeChangeImage.value,
          basicState.image.value,
        ),
      }),
      ...(JSON.stringify(beforeChangeImage.value[0]) !==
        JSON.stringify(basicState.image.value[0]) && {
        rep_img:
          basicState.image.value[0].type ??
          `image/${basicState.image.value[0].file_id.split(".")[1]}`,
      }),
      ...(JSON.stringify(beforeChangeImage.value) !==
        JSON.stringify(basicState.image.value) && {
        new_profile_img: basicState.image.value
          .map((item, index) => {
            if (item.type !== undefined && index !== 0) return item.type;
          })
          .filter((item) => item !== undefined),
      }),
    };

    if (modifyState.new_profile_img?.length === 0) {
      delete modifyState.new_profile_img;
    }

    const payload = {
      id: id,
      state: modifyState,
      contentType: [],
      files: [],
      navigate,
    };

    basicState.image.value.forEach((item) => {
      if (item?.type) {
        payload.contentType = [...payload.contentType, item.type];
        payload.files = [...payload.files, item];
      }
    });

    if (payload.contentType.length === 0) {
      delete payload.contentType;
      delete payload.files;
    }

    dispatch(memberAdminModify(payload));
  };

  const handleChangeBasicInput = (event) => {
    let { name, value } = event.target;

    if (name === "email") {
      if (value.length === 0) {
        setDuplicateState((draft) => {
          draft.email = false;
        });
      }
      if (value.length > 40) return;
    }

    if (name === "phone") {
      setBasicErrorState((draft) => {
        draft.phone.touched = true;
      });
      if (value.length > 13) return;
      if (value.length === 13) {
        setBasicErrorState((draft) => {
          draft[name].state = false;
        });
      }
      value = addDashPhoneNumber(checkOnlyNumber(value));
    }

    if (name === "birthday") {
      if (value.length > 10) return;
      value = checkBirthday(value);
      if (value.length === 10) {
        setBasicErrorState((draft) => {
          draft.birthday.state = false;
        });
      }
    }

    setBasicState((draft) => {
      draft[name] = value;
    });
  };

  const handleChangeProfileInput = (event) => {
    let { name, value } = event.target;

    if (name === "nickname" || name === "education" || name === "job") {
      if (value.length !== 0) {
        setProfileErrorState((draft) => {
          draft[name].state = false;
        });
      }
    }

    if (name === "nickname") {
      if (nickname === basicState.nickname) {
        setDuplicateState((draft) => {
          draft.nickname = true;
        });
      } else {
        setDuplicateState((draft) => {
          draft.nickname = false;
        });
      }
    }

    setProfileState((draft) => {
      draft[name] = value;
    });
  };

  const handleChangeIntroduceInput = (event, index) => {
    let { name, value } = event.target;

    if (name === "introduction") {
      setIntroduceState((draft) => {
        draft[name] = value;
      });

      if (value.length > 0) {
        setIntroduceErrorState((draft) => {
          draft[name].state = false;
        });
      }
    } else {
      if (value.length > 0) {
        setIntroduceErrorState((draft) => {
          draft[name].state[index] = false;
        });
      } else {
        setIntroduceErrorState((draft) => {
          draft[name].state[index] = true;
        });
      }

      setIntroduceState((draft) => {
        draft[name][index] = value;
      });
    }
  };

  const handleBlurBasicError = (event) => {
    const { name, value } = event.target;

    const checkDuplicateEmail = (value) => {
      if (value.length === 0) return;
      dispatch(memberAdminDuplicateEmail({ email: value }));
    };

    if (value.length === 0) {
      setBasicErrorState((draft) => {
        draft[name].state = true;
      });
    } else {
      setBasicErrorState((draft) => {
        draft[name].state = false;
      });
    }

    if (name === "email") {
      const isEmail = checkEmailValidation(value);
      if (isEmail) {
        checkDuplicateEmail(value);
        if (!tableErrorState.basicState) {
          setTableErrorState((draft) => {
            draft.basicState.state = false;
            draft.basicState.message = "";
          });
        }
      } else {
        setBasicErrorState((draft) => {
          draft[name].state = true;
        });
        setTableErrorState((draft) => {
          draft.basicState.state = true;
          draft.basicState.message = "이메일 형식이 아닙니다.";
        });
        setDuplicateState((draft) => {
          draft.email = false;
        });
      }
    }

    if (name === "passwordConfirm") {
      if (basicState.password !== basicState.passwordConfirm) {
        setBasicErrorState((draft) => {
          draft[name].state = true;
        });
        setTableErrorState((draft) => {
          draft.basicState.state = true;
          draft.basicState.message = "비밀번호가 일치하지 않습니다.";
        });
      } else {
        setTableErrorState((draft) => {
          draft.basicState.state = false;
          draft.basicState.message = "";
        });
      }
    }

    if (name === "phone") {
      if (value.length < 13) {
        setBasicErrorState((draft) => {
          draft[name].state = true;
        });
      } else {
        setBasicErrorState((draft) => {
          draft[name].state = false;
        });
      }
    }

    if (name === "birthday") {
      if (value.length < 9) {
        setBasicErrorState((draft) => {
          draft[name].state = true;
        });
      } else {
        setBasicErrorState((draft) => {
          draft[name].state = false;
        });
      }
    }
  };

  const handleBlurProfileError = (event) => {
    let { name, value } = event.target;

    const handleCheckDuplicateNickName = (value) => {
      if (value.length === 0) return;
      if (nickname !== profileState.nickname) {
        dispatch(memberAdminDuplicateNickname({ nickname: value }));
      } else {
        setDuplicateState((draft) => {
          draft.nickname = true;
        });
      }
    };

    if (name === "nickname") {
      handleCheckDuplicateNickName(value);
    }

    if (name === "education" || name === "job") {
      if (value.length === 0) {
        setTableErrorState((draft) => {
          draft.profileState.state = true;
          draft.profileState.message = "필수 입력 값을 입력해주세요.";
        });
      }
    }

    if (value.length === 0) {
      setProfileErrorState((draft) => {
        draft[name].state = true;
      });
    } else {
      setProfileErrorState((draft) => {
        draft[name].state = false;
      });
    }
  };

  const handleBlurIntroduceError = (event, index) => {
    if (index > 2) return;

    let { name, value } = event.target;

    if (name === "introduction") {
      if (value.length === 0) {
        setIntroduceErrorState((draft) => {
          draft[name].state = true;
        });
      } else {
        setIntroduceErrorState((draft) => {
          draft[name].state = false;
        });
      }
    } else {
      if (value.length === 0) {
        setIntroduceErrorState((draft) => {
          draft[name].state[index] = true;
        });
      } else {
        setIntroduceErrorState((draft) => {
          draft[name].state[index] = false;
        });
      }
    }
  };

  const requiredValueCheck = () => {
    const listRequiredValueCheck = (list, state, stateName) => {
      if (stateName !== "introduceState") {
        return list
          .filter((item) => item.essential === true)
          .forEach((item) => {
            if (state[item.key] === "") {
              if (stateName === "basicState") {
                setBasicErrorState((draft) => {
                  draft[item.key].state = true;
                });
              } else if (stateName === "profileState") {
                setProfileErrorState((draft) => {
                  draft[item.key].state = true;
                });
              }
            }
          });
      } else {
        return Object.entries(state).map(([key, value]) => {
          if (typeof value === "object") {
            value.forEach((item, index) => {
              if (item === "") {
                setIntroduceErrorState((draft) => {
                  if (index > 2) return;
                  draft[key].state[index] = true;
                });
              }
            });
          } else {
            if (value === "") {
              setIntroduceErrorState((draft) => {
                draft[key].state = true;
              });
            }
          }
        });
      }
    };

    listRequiredValueCheck(
      memberAdminBasicInformation,
      basicState,
      "basicState",
    );

    listRequiredValueCheck(
      memberAdminProfileTable,
      profileState,
      "profileState",
    );

    listRequiredValueCheck(
      memberAdminIntroduceTable,
      introduceState,
      "introduceState",
    );
  };

  const requiredValidateionMessage = (state, stateName) => {
    let temp = null;
    if (stateName === "basicState") {
      const tempObj = { ...state };
      delete tempObj.image;
      delete tempObj.lastLogin;
      delete tempObj.created;
      temp = Object.values(tempObj).filter((item) => item === "");
    } else {
      temp = Object.values(state).filter((item) => item === "");
    }

    if (temp.length > 0) {
      setTableErrorState((draft) => {
        draft[stateName].state = true;
        draft[stateName].message = "필수 입력 값을 입력해주세요.";
      });
    } else {
      setTableErrorState((draft) => {
        draft[stateName].state = false;
        draft[stateName].message = "";
      });
    }
  };

  const deleteTableErrorMessage = (state, stateName) => {
    const errorState = Object.values(state).filter(
      ({ state }) => state === true,
    );
    if (errorState.length === 0) {
      setTableErrorState((draft) => {
        draft[stateName].state = false;
        draft[stateName].message = "";
      });
    }
  };

  useEffect(() => {
    dispatch(memberAdminDropdownList());
  }, []);

  useEffect(() => {
    if (profileState.area !== "") {
      setProfileErrorState((draft) => {
        draft.area.state = false;
      });
    }
    if (profileState.height !== "") {
      setProfileErrorState((draft) => {
        draft.height.state = false;
      });
    }
    if (profileState.body_type !== "") {
      setProfileErrorState((draft) => {
        draft.body_type.state = false;
      });
    }
    if (profileState.religion !== "") {
      setProfileErrorState((draft) => {
        draft.religion.state = false;
      });
    }
    if (profileState.drinking !== "") {
      setProfileErrorState((draft) => {
        draft.drinking.state = false;
      });
    }
    if (profileState.smoking !== "") {
      setProfileErrorState((draft) => {
        draft.smoking.state = false;
      });
    }
    if (introduceState.personality[0] !== "") {
      setIntroduceErrorState((draft) => {
        draft.personality.state[0] = false;
      });
    }
    if (introduceState.personality[1] !== "") {
      setIntroduceErrorState((draft) => {
        draft.personality.state[1] = false;
      });
    }
    if (introduceState.personality[2] !== "") {
      setIntroduceErrorState((draft) => {
        draft.personality.state[2] = false;
      });
    }
  }, [profileState, introduceState]);
  useEffect(() => {
    if (basicState.email.length === 0) return;

    if (!emailDuplicate) {
      setDuplicateState((draft) => {
        draft.email = true;
      });
    } else {
      setDuplicateState((draft) => {
        draft.email = false;
      });
    }
  }, [emailDuplicate]);
  useEffect(() => {
    if (profileState.nickname.length === 0) return;

    if (!nicknameDuplicate) {
      setDuplicateState((draft) => {
        draft.nickname = true;
      });
    } else {
      setDuplicateState((draft) => {
        draft.nickname = false;
      });
    }
  }, [nicknameDuplicate]);

  useEffect(() => {
    deleteTableErrorMessage(basicErrorState, "basicState");
  }, [basicErrorState]);

  useEffect(() => {
    deleteTableErrorMessage(profileErrorState, "profileState");
  }, [profileErrorState]);

  useEffect(() => {
    deleteTableErrorMessage(introduceErrorState, "introduceState");
  }, [introduceErrorState]);

  useEffect(() => {
    if (
      Object.values(authInfoState).filter((value) => value === 2).length !== 5
    ) {
      setTableErrorState((draft) => {
        draft.authInfoState.touched = true;
      });
    }

    if (
      Object.values(authInfoState).filter((value) => value === 2).length < 5
    ) {
      setTableErrorState((draft) => {
        draft.authInfoState.state = false;
      });
    } else {
      if (tableErrorState.authInfoState.touched) {
        setTableErrorState((draft) => {
          draft.authInfoState.state = true;
          draft.authInfoState.message = "최소 1개 이상의 등급을 선택해 주세요.";
        });
      }
    }
  }, [authInfoState]);

  useEffect(() => {
    if (Object.keys(data).length === 0) return;
    const {
      email,
      name,
      phone,
      birthday,
      user_number,
      created,
      last_login,
      gender,
      user_status_id,
      internal_grade,
      nickname,
      education,
      job,
      height,
      introduction,
      interest,
      attraction,
      ideal,
      badges,
      area_id,
      body_type_id,
      drinking_id,
      religion_id,
      smoking_id,
      personality_id,
      hobby_id,
      profile_img,
    } = data;

    setBeforeChangeImage((draft) => {
      draft.imageUrl = profile_img.map((item) => item.presigned_url);
      draft.value = profile_img.map((item) => item);
    });

    setBasicState((draft) => {
      draft.email = email;
      draft.name = name;
      draft.phone = phone;
      draft.birthday = birthday;
      draft.userNumber = user_number;
      draft.created = convertUTCtoBirthday(created);
      draft.lastLogin = last_login;
      draft.gender = gender === "남자" ? "M" : "F";
      draft.userStatusId = user_status_id;
      draft.internalGrade = internal_grade;
      draft.image.imageUrl = profile_img.map((item) => item.presigned_url);
      draft.image.value = profile_img.map((item) => item);
    });

    setProfileState((draft) => {
      draft.nickname = nickname;
      draft.education = education;
      draft.job = job;
      draft.height = height;
      draft.area = area_id;
      draft.body_type = body_type_id;
      draft.drinking = drinking_id;
      draft.religion = religion_id;
      draft.smoking = smoking_id;
    });

    setIntroduceState((draft) => {
      draft.introduction = introduction;
      draft.interest = interest.split(",");
      draft.attraction = attraction.split(",");
      draft.ideal = ideal.split(",");
      draft.personality = personality_id.map((item) => item + "");
      draft.hobby = hobby_id.map((item) => item + "");
    });

    setAuthInfoState((draft) => {
      draft.appearance = authInformatinFormatter(badges[0]);
      draft.body = authInformatinFormatter(badges[1]);
      draft.occupation = authInformatinFormatter(badges[2]);
      draft.university = authInformatinFormatter(badges[3]);
      draft.income = authInformatinFormatter(badges[4]);
    });
  }, [data]);

  useEffect(() => {
    if (basicErrorState.phone.touched) {
      if (basicState.phone.length !== 13) {
        setBasicErrorState((draft) => {
          draft.phone.state = true;
        });
      }
      if (basicState.phone.length === 0) {
        setTableErrorState((draft) => {
          draft.basicState.state = true;
          draft.basicState.message = "필수 정보를 모두 입력해주세요.";
        });
      }
    }
  }, [basicState]);

  useEffect(() => {
    if (
      basicState.phone !== data.phone ||
      basicState.userStatusId !== data.user_status_id ||
      basicState.internalGrade !== data.internal_grade ||
      JSON.stringify(basicState.image.imageUrl) !==
        JSON.stringify(data.profile_img.map((item) => item.presigned_url)) ||
      profileState.nickname !== data.nickname ||
      profileState.area !== data.area_id ||
      profileState.height !== data.height ||
      profileState.body_type !== data.body_type_id ||
      profileState.religion !== data.religion_id ||
      profileState.drinking !== data.drinking_id ||
      profileState.smoking !== data.smoking_id ||
      profileState.education !== data.education ||
      profileState.job !== data.job ||
      introduceState.introduction !== data.introduction ||
      JSON.stringify(introduceState.interest) !==
        JSON.stringify(data.interest.split(",")) ||
      JSON.stringify(introduceState.attraction) !==
        JSON.stringify(data.attraction.split(",")) ||
      JSON.stringify(introduceState.ideal) !==
        JSON.stringify(data.ideal.split(",")) ||
      JSON.stringify(introduceState.personality) !==
        JSON.stringify(data.personality_id.map((item) => item + "")) ||
      JSON.stringify(introduceState.hobby) !==
        JSON.stringify(data.hobby_id.map((item) => item + "")) ||
      authInfoState.appearance !== authInformatinFormatter(data.badges[0]) ||
      authInfoState.body !== authInformatinFormatter(data.badges[1]) ||
      authInfoState.occupation !== authInformatinFormatter(data.badges[2]) ||
      authInfoState.university !== authInformatinFormatter(data.badges[3]) ||
      authInfoState.income !== authInformatinFormatter(data.badges[4])
    ) {
      setIsChangeInformation(true);
    } else {
      setIsChangeInformation(false);
    }
  }, [
    basicState,
    profileState,
    introduceState,
    authInfoState,
    beforeChangeImage,
  ]);

  useEffect(() => {
    if (nickname === basicState.nickname) {
      setDuplicateState((draft) => {
        draft.nickname = true;
      });
    }

    dispatch(memberAdminDetail(id));
    dispatch(memberAdminDropdownList());
  }, []);

  return (
    <AuthDetailPageRootLayout>
      <HeadingWrapper>
        <Heading>어드민 회원 수정</Heading>
        <CustomSubmitButton
          onClick={handleSubmit}
          disabled={isChangeInformation === false}
        >
          저장
        </CustomSubmitButton>
      </HeadingWrapper>
      <MemberAdminDetailModifyBasicInformation
        state={basicState}
        setState={setBasicState}
        errorState={basicErrorState}
        handleChangeInput={handleChangeBasicInput}
        handleBlurError={handleBlurBasicError}
        tableErrorState={tableErrorState}
        duplicateState={duplicateState}
      />
      <MemberAdminProfileInformation
        state={profileState}
        setState={setProfileState}
        gender={basicState.gender}
        errorState={profileErrorState}
        handleChangeInput={handleChangeProfileInput}
        handleBlurError={handleBlurProfileError}
        tableErrorState={tableErrorState}
        duplicateState={duplicateState}
      />
      <MemberAdminIntroduce
        state={introduceState}
        setState={setIntroduceState}
        errorState={introduceErrorState}
        handleChangeInput={handleChangeIntroduceInput}
        handleBlurError={handleBlurIntroduceError}
        tableErrorState={tableErrorState}
      />
      <MemberAdminAuthInformation
        state={authInfoState}
        setState={setAuthInfoState}
        tableErrorState={tableErrorState}
      />
    </AuthDetailPageRootLayout>
  );
}

const HeadingWrapper = styled.div`
  position: relative;
`;

const CustomSubmitButton = styled(SubmitButton)`
  width: 70px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25px;
  right: 0px;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;
