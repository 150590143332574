import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import {
  DetailPageSectionLayout,
  Table,
  ModalOpenButton,
  ChangePasswordModal,
} from "components/index";
import { memberAdminDetailBasicInformation } from "assets/static/table";
import MemberImageView from "pages/member/containers/MemberImageView";
import { changeMemberAdminPassword } from "store/reducer/password";
import { makeCryptoFunction } from "utils/helper/crypto";

export default function MemberAdminDetailBasicInformation({ data }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { profile_img } = data;
  const imageUrl = profile_img && profile_img;

  const handleChangePassword = (handleModalClose, password) => () => {
    dispatch(
      changeMemberAdminPassword({ id, password: makeCryptoFunction(password) }),
    );
    handleModalClose();
  };

  return (
    <CustomDetailPageSectionLayout heading="기본 정보">
      <ImageViewWrapper>
        {imageUrl && <MemberImageView srcArray={imageUrl} />}
      </ImageViewWrapper>
      <ComplexTableWrapper>
        <CustomComplexTable
          list={memberAdminDetailBasicInformation}
          data={data}
        />
        <ChangePasswordButton
          render={(handleModalClose, handleDimClick) => {
            return (
              <ChangePasswordModal
                handleModalClose={handleModalClose}
                handleDimClick={handleDimClick}
                handleChangePassword={handleChangePassword}
              />
            );
          }}
        >
          변경하기
        </ChangePasswordButton>
      </ComplexTableWrapper>
    </CustomDetailPageSectionLayout>
  );
}

const CustomDetailPageSectionLayout = styled(DetailPageSectionLayout)`
  & > div {
    display: flex;
    column-gap: 30px;
  }
`;

const ImageViewWrapper = styled.div`
  width: 204px;
`;

const ComplexTableWrapper = styled.div`
  position: relative;
`;

const CustomComplexTable = styled(Table)`
  width: 846px;
  height: 295px;

  & > :first-child {
    grid-column: auto / span 2;
  }
`;

const ChangePasswordButton = styled(ModalOpenButton)`
  width: 68px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.color.blue_00};
  position: absolute;
  top: 57px;
  left: 351px;
`;
