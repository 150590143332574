import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import styled from "styled-components";

import { Heading } from "components/index";
import {
  authInspectionDetail,
  authInspectionDetailUnmasking,
} from "store/reducer/authInspectionDetail";
import { authInspectionDetailApproval } from "store/reducer/authInspectionDetailApproval";
import {
  basicInformationTable,
  initOccupationAuthInformation,
} from "assets/static/table";
import { occupationRejectReason } from "assets/static/radioButton";
import { authInspectionDetailReject } from "store/reducer/authInspectionDetailReject";
import {
  AuthDetailPageRootLayout,
  AuthInformation,
  BasicInformation,
  SubmitCancelButton,
} from "../containers/index";
import { PATH } from "utils/constants";
import { makeCryptoFunction } from "utils/helper/crypto";

export default function AuthOccupationDetail() {
  const [authInformation, setAuthInformation] = useImmer(
    initOccupationAuthInformation,
  );
  const [password, setPassword] = useImmer({ password: "" });
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { certification, nickname, job, status, tier } = useSelector(
    ({ authInspectionDetail }) => ({
      certification: authInspectionDetail.certification,
      nickname: authInspectionDetail.certification.nickname,
      job: authInspectionDetail.certification.job,
      status: authInspectionDetail.certification.status,
      tier: authInspectionDetail.certification.tier,
    }),
  );
  const authImageArray = useSelector(
    (state) => state.authInspectionDetail.certificationSrcArray,
  );
  const master = Cookies.get("auth").split(",").includes("master");

  const handleChangeOccupation = (event) => {
    setAuthInformation((draft) => {
      draft.occupation = event.target.value;
    });
  };

  const handleClickRadioButton = (index) => {
    setAuthInformation((draft) => {
      draft.authState = index === 1 ? 1 : 2;
    });
  };

  const { occupation, authState } = authInformation;

  const handleSubbmit = () => {
    if (occupation.length === 0) {
      return alert("직업을 입력해주세요.");
    }

    dispatch(
      authInspectionDetailApproval({
        id,
        data: {
          type: "approve",
          job: occupation,
          tier: authState,
        },
        navigate,
        beforePath: PATH.authOccupation.fullPath,
        toastMessageKey: "occupationSuccess",
      }),
    );
  };

  useEffect(() => {
    dispatch(authInspectionDetail(id));
  }, []);

  useEffect(() => {
    if (authImageArray.length === 0) return;

    setAuthInformation((draft) => {
      draft.authImageArray = authImageArray;
      draft.nickname = nickname;
      draft.occupation = job;
      draft.authState = tier === null ? 2 : tier;
    });
  }, [certification]);

  return (
    <AuthDetailPageRootLayout>
      <Heading as="h2">직업 인증 상세</Heading>
      <LayoutWrapper>
        <BasicInformation
          data={certification}
          list={basicInformationTable}
          unMasking={master}
          submitActionFunction={authInspectionDetailUnmasking({
            id: certification.user_id,
            password: makeCryptoFunction(password.password),
          })}
          setPassword={setPassword}
        />
      </LayoutWrapper>
      <LayoutWrapper>
        <AuthInformation
          data={authInformation}
          pageName="occupation"
          handleChangeInputValue={handleChangeOccupation}
          handleClickRadioButton={handleClickRadioButton}
          status={status}
        />
      </LayoutWrapper>
      {status === "승인대기" && (
        <SubmitCancelButton
          rejectList={occupationRejectReason}
          rejectAction={authInspectionDetailReject}
          handleSubbmit={handleSubbmit}
          beforePath={PATH.authOccupation.fullPath}
          toastMessageKey="occupationReject"
        />
      )}
    </AuthDetailPageRootLayout>
  );
}

const LayoutWrapper = styled.div`
  width: 1140px;
  margin: 0 auto 24px auto;
`;
