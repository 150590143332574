import Dropdown2 from "components/dropdown/Dropdown2";
import SearchDropdown from "components/dropdown/SearchDropdown";
import SearchInput from "components/searchInput/SearchInput";
import React from "react";
import { mixinBlueBtn_70 } from "style/mixin";
import styled from "styled-components";

export default function SearchFilter({ topChildren, bottomChildren }) {
  return (
    <>
      <FilterWrapper>{topChildren()}</FilterWrapper>
      <FilterWrapper spaceBetween={true}>{bottomChildren()}</FilterWrapper>
    </>
  );
}

const FilterWrapper = styled.div`
  display: flex;
  justify-content: ${({ spaceBetween }) => spaceBetween && "space-Between"};
  align-items: center;
  min-width: 1140px;
  height: 77px;
  border-top: ${({ theme }) => `1px solid ${theme.color.border_grayE6}`};
`;

SearchFilter.FlexWrapper = function FlexWrapper({ children }) {
  return <FlexWrapperRoot>{children}</FlexWrapperRoot>;
};

const FlexWrapperRoot = styled.div`
  display: flex;
`;

SearchFilter.LabelDropdown = function LabelDropdown({
  label,
  selectOption,
  optionObj,
  handleClickOption,
  ...restProps
}) {
  return (
    <LabelDropdownRoot {...restProps}>
      {label && <DropdownLabel>{label}</DropdownLabel>}
      <FilterDropdown
        selectOption={selectOption}
        optionObj={optionObj}
        handleClickOption={handleClickOption}
      />
    </LabelDropdownRoot>
  );
};

SearchFilter.LastLabelDropdown = function LastLabelDropdown({
  label,
  selectOption,
  optionObj,
  handleClickOption,
}) {
  return (
    <LastLabelDropdownRoot>
      {label && <DropdownLabel>{label}</DropdownLabel>}
      <FilterDropdown
        selectOption={selectOption}
        optionObj={optionObj}
        handleClickOption={handleClickOption}
      />
    </LastLabelDropdownRoot>
  );
};

const LabelDropdownRoot = styled.div`
  display: flex;
  align-items: center;

  ::after {
    content: "";
    height: 24px;
    margin: 0 28px;
    border-right: 1px solid ${({ theme }) => theme.color.border_grayDe};
  }
`;

const LastLabelDropdownRoot = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownLabel = styled.label`
  font-size: 14px;
  margin-right: 10px;
  color: ${({ theme }) => theme.color.text_gray5b};
`;

const FilterDropdown = styled(Dropdown2)`
  width: 140px;

  & > ul {
    max-height: unset;
  }
`;

SearchFilter.InputFilter = function Dropdown({
  selectOption,
  optionObj,
  handleClickOption,
  ...restProps
}) {
  return (
    <InputFilter
      selectOption={selectOption}
      optionObj={optionObj}
      handleClickOption={handleClickOption}
      {...restProps}
    />
  );
};

const InputFilter = styled(SearchDropdown)`
  width: 110px;
  margin-right: 10px;
`;

SearchFilter.Input = function Input({
  value,
  handleChangeInputValue,
  handleSubmit,
}) {
  return (
    <SearchInput
      value={value}
      handleChangeInputValue={handleChangeInputValue}
      handleSubmit={handleSubmit}
    />
  );
};

SearchFilter.SubmitButton = function SubmitButton({ ...restProps }) {
  return <Button {...restProps}>조회</Button>;
};

const Button = styled.button`
  ${mixinBlueBtn_70}
  margin-right: 10px;
`;
