import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  PortalModal,
  DimLayout,
  RadioButton,
  SubmitButton,
  CancelButton,
} from "components/index";
import { Close } from "assets/icon/index";

export default function RejectModal({
  handleModalClose,
  handleDimClick,
  rejectList,
  rejectAction,
  checkState,
  beforePath,
  toastMessageKey,
  ...restProps
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectNumber, setSelectNumber] = useState(0);

  const handleClickRadioButton = (index) => {
    setSelectNumber(index);
  };

  const handleRejectSubmit = () => {
    dispatch(
      rejectAction({
        id: id,
        data: {
          type: "reject",
          reject_reason: rejectList[selectNumber],
          ...(checkState && { reject_type: checkState }),
        },
        navigate,
        beforePath,
        toastMessageKey,
      }),
    );

    handleModalClose();
  };

  return (
    <PortalModal>
      <DimLayout handleDimClick={handleDimClick}>
        <Root {...restProps}>
          <Heading>반려 사유 선택</Heading>
          <Section>
            <Text>
              반려 시 인증을 등록/변경할 수 없으며 반려 사유와 함께 알림이
              전달됩니다.
            </Text>
            <Text>반드시 반려 사유를 선택하고 검수를 진행해 주세요.</Text>
            <RadioButton
              title="반려 사유"
              rejectList={rejectList}
              selectRadioState={selectNumber}
              handleClickRadioButton={handleClickRadioButton}
            />
          </Section>
          <ButtonWrapper>
            <CancelButton onClick={handleModalClose}>취소</CancelButton>
            <SubmitButton onClick={handleRejectSubmit}>확인</SubmitButton>
          </ButtonWrapper>
          <CloseButton onClick={handleModalClose}>
            <Close width="16px" height="16px" />
          </CloseButton>
        </Root>
      </DimLayout>
    </PortalModal>
  );
}

const Root = styled.div`
  width: 500px;
  background-color: ${({ theme }) => theme.color.white};
  position: relative;
`;

const Heading = styled.h2`
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.black};
  padding: 17px 0 17px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
`;

const Section = styled.section`
  padding: 24px;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.gray_5B};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 15px 24px 15px 0;
  background-color: ${({ theme }) => theme.color.gray_F5};

  button + button {
    margin-left: 10px;
  }
`;

const CloseButton = styled.button`
  width: 16px;
  height: 16px;
  background-color: inherit;
  position: absolute;
  top: 20px;
  right: 20px;
`;
