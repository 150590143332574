import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { memberUserDetail } from "store/reducer/memberUserDetail";
import {
  memberUserAuthInformationTable,
  memberUserBasicInformationTable,
  memberUserIntroductionInformationTable,
  memberUserPaymentInformationTable,
  memberUserProfileInformationTable,
  memberUserReportInformationTable,
} from "assets/static/table";
import AuthDetailPageRootLayout from "pages/auth/containers/AuthDetailPageRootLayout";
import MemberProfileInformation from "pages/member/containers/MemberProfileInformation";
import MemberUserBasicInformation from "./containers/MemberUserBasicInformation";
import MemberUserIntroduction from "./containers/MemberUserIntroduction";
import MemberUserPaymentInformation from "./containers/MemberUserPaymentInformation";
import MemberUserReportInformation from "./containers/MemberUserReportInformation";
import MemberUserCustomHeading from "./containers/MemberUserCustomHeading";
import MemberUserAuthInformation from "./containers/MemberUserAuthInformation";
import { Spinner } from "components/index";
import MemberUserRomanticStyle from "./containers/MemberUserRomanticStyle";
import { adminDetailList } from "assets/static/adminForm";

export default function MemberUserDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    basicInformation,
    profileSrcArray,
    profileInformation,
    introductionInformation,
    answer,
    authInformation,
    payment,
    admonishment,
  } = useSelector((state) => state.memberUserDetail);
  const { isLoading } = useSelector(
    (state) => state.memberUserDetail.memberUserDetail,
  );
  const answerList = useSelector((state) => state.answerList.data);

  useEffect(() => {
    dispatch(memberUserDetail(id));
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <AuthDetailPageRootLayout>
      <MemberUserCustomHeading />
      <MemberUserBasicInformation
        list={memberUserBasicInformationTable}
        profileSrcArray={profileSrcArray}
        data={basicInformation}
      />
      <MemberProfileInformation
        list={memberUserProfileInformationTable}
        data={profileInformation}
      />
      <MemberUserIntroduction
        list={memberUserIntroductionInformationTable}
        data={introductionInformation}
      />
      <MemberUserRomanticStyle
        adminList={adminDetailList}
        answerList={answerList}
        data={answer}
      />
      <MemberUserAuthInformation
        list={memberUserAuthInformationTable}
        data={authInformation}
      />
      <MemberUserPaymentInformation
        list={memberUserPaymentInformationTable}
        data={payment}
      />
      <MemberUserReportInformation
        list={memberUserReportInformationTable}
        data={admonishment}
      />
    </AuthDetailPageRootLayout>
  );
}
