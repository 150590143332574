import React from "react";

import styled from "styled-components";

import { PortalModal, DimLayout } from "components/index";
import { Close } from "assets/icon/index";

export default function DetailPageModal({
  handleModalClose,
  handleDimClick,
  heading,
  children,
  ...restProps
}) {
  return (
    <PortalModal>
      <DimLayout handleDimClick={handleDimClick}>
        <Root {...restProps}>
          <HeadingWrapper>
            <Heading>{heading}</Heading>
            <CloseButton onClick={handleModalClose}>
              <Close />
            </CloseButton>
          </HeadingWrapper>
          {children}
        </Root>
      </DimLayout>
    </PortalModal>
  );
}

const Root = styled.section`
  width: 1140px;
  height: 716px;
  background-color: ${({ theme }) => theme.color.white};
  overflow-y: auto;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
`;

const Heading = styled.h2`
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.black};
  padding: 17px 0 17px 24px;
`;

const CloseButton = styled.button`
  margin-right: 30px;
`;
