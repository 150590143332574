import React from "react";
import { Routes, Route } from "react-router-dom";

import {
  Login,
  Expired,
  NotFound,
  MemberUser,
  MemberUserDetail,
  MemberAdmin,
  MemberAdminCreate,
  MemberAdminDetail,
  MemberAdminModify,
  MemberNoti,
  MemberPush,
  MemberReport,
  MemberSuspended,
  AuthAppearance,
  AuthAppearanceDetail,
  AuthProfile,
  AuthProfileDetail,
  Payment,
  Common,
  ReportProfile,
  ReportProfileDetail,
  ReportCommunity,
  ReportCommunityDetail,
  ReportIndianGame,
  ReportIndianGameDetail,
  AuthBody,
  AuthBodyDetail,
  AuthUniversity,
  AuthUniversityDetail,
  AuthOccupation,
  AuthOccupationDetail,
  AuthIncome,
  AuthIncomeDetail,
} from "pages/index";
import { AuthRoute, PublicRoute } from "components/index";
import { PATH } from "utils/constants";
import MemberAdminCreate2 from "pages/member/MemberAdmin/MemberAdminCreate/MemberAdminCreate2";
import MemberAdminModify2 from "pages/member/MemberAdmin/MemberAdminModify/MemberAdminModify2";

export default function CustomRouter() {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path={PATH.root} element={<Login />} />
        <Route path={PATH.expired} element={<Expired />} />
      </Route>
      <Route element={<AuthRoute />}>
        <Route path={PATH.member.path}>
          <Route path={PATH.memberUser.path}>
            <Route path="" element={<MemberUser />} />
            <Route
              path={PATH.MemberUserDetail.path}
              element={<MemberUserDetail />}
            />
          </Route>
          <Route path={PATH.memberAdmin.path}>
            <Route path="" element={<MemberAdmin />} />
            <Route
              path={PATH.memberAdminCreate.path}
              element={<MemberAdminCreate2 />}
            />
            <Route
              path={PATH.memberAdminDetail.path}
              element={<MemberAdminDetail />}
            />
            <Route
              path={PATH.memberAdminModify.path}
              element={<MemberAdminModify2 />}
            />
          </Route>
          <Route path={PATH.memberNoti.path} element={<MemberNoti />} />
          <Route path={PATH.memberPush.path} element={<MemberPush />} />
          <Route path={PATH.memberReport.path} element={<MemberReport />} />
          <Route
            path={PATH.memberSuspended.path}
            element={<MemberSuspended />}
          />
        </Route>
        <Route path={PATH.auth.path}>
          <Route path={PATH.authProfile.path}>
            <Route path="" element={<AuthProfile />} />
            <Route
              path={PATH.authProfileDetail.path}
              element={<AuthProfileDetail />}
            />
          </Route>
          <Route path={PATH.authInspection.path}>
            <Route path={PATH.authAppearance.path}>
              <Route path="" element={<AuthAppearance />} />
              <Route
                path={PATH.authAppearanceDetail.path}
                element={<AuthAppearanceDetail />}
              />
            </Route>
            <Route path={PATH.authBody.path}>
              <Route path="" element={<AuthBody />} />
              <Route
                path={PATH.authBodyDetail.path}
                element={<AuthBodyDetail />}
              />
            </Route>
            <Route path={PATH.authUniversity.path}>
              <Route path="" element={<AuthUniversity />} />
              <Route
                path={PATH.authUniversityDetail.path}
                element={<AuthUniversityDetail />}
              />
            </Route>
            <Route path={PATH.authOccupation.path}>
              <Route path="" element={<AuthOccupation />} />
              <Route
                path={PATH.authOccupationDetail.path}
                element={<AuthOccupationDetail />}
              />
            </Route>
            <Route path={PATH.authIncome.path}>
              <Route path="" element={<AuthIncome />} />
              <Route
                path={PATH.authIncomeDetail.path}
                element={<AuthIncomeDetail />}
              />
            </Route>
          </Route>
        </Route>
        <Route path={PATH.report.path}>
          <Route path={PATH.reportProfile.path}>
            <Route path="" element={<ReportProfile />} />
            <Route
              path={PATH.reportProfileDetail.path}
              element={<ReportProfileDetail />}
            />
          </Route>
          <Route path={PATH.reportCommunity.path}>
            <Route path="" element={<ReportCommunity />} />
            <Route
              path={PATH.reportCommunityDetail.path}
              element={<ReportCommunityDetail />}
            />
          </Route>
          <Route path={PATH.reportIndianGame.path}>
            <Route path="" element={<ReportIndianGame />} />
            <Route
              path={PATH.reportIndianGameDetail.path}
              element={<ReportIndianGameDetail />}
            />
          </Route>
        </Route>
        <Route path={PATH.payment.path}>
          <Route path={PATH.payment.path} element={<Payment />} />
        </Route>
        <Route path={PATH.common.path}>
          <Route path={PATH.common.path} element={<Common />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
