import React from "react";
import styled from "styled-components";

export default function Heading({ children, ...restProps }) {
  return <CustomHeading {...restProps}>{children}</CustomHeading>;
}

const CustomHeading = styled.h1`
  margin: 0 auto;
  padding: 32px 0 20px 0;
  font-size: 21px;
  font-weight: ${({ as }) => (as === "h2" ? 700 : 500)};
  color: ${({ theme }) => theme.color.black};
  font-weight: 700;
`;
