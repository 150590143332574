import React, { useState } from "react";
import styled from "styled-components";

import { ZoomIn, ZoomOut } from "assets/icon/index";

export default function ImageDetailModal({ src, ...restProps }) {
  const [imageScale, setImageScale] = useState(1);

  const handleZoomIn = () => {
    setImageScale(imageScale + 0.2);
  };

  const handleZoomOut = () => {
    if (imageScale - 0.2 < 1) return;
    setImageScale(imageScale - 0.2);
  };

  return (
    <Root {...restProps}>
      <ImageWrapper>
        <Image src={src} alt="게시글 사진" imageScale={imageScale} />
      </ImageWrapper>
      <ZoomButtonWrapper>
        <ZoomButton onClick={handleZoomOut}>
          <CustomZoomOut />
        </ZoomButton>
        <ZoomButton onClick={handleZoomIn}>
          <CustomZoomIn />
        </ZoomButton>
      </ZoomButtonWrapper>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.figure`
  width: 534px;
  height: 534px;
  overflow: scroll;
  padding: 24px 48px 0 48px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  transform: scale(${({ imageScale }) => imageScale});
`;

const ZoomButtonWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  padding-right: 48px;
  display: flex;
  justify-content: end;
  column-gap: 8px;
`;

const ZoomButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomZoomIn = styled(ZoomIn)`
  transform: rotate(-180deg);
`;
const CustomZoomOut = styled(ZoomOut)`
  transform: rotate(-180deg);
`;
