import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";

export const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Spoqa Han Sans Neo', 'sans-serif';  
  }
  html, body{
    height: 100%; 
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';     
  }

  input {
    outline: 0;
  }

  textarea {
    outline: 0;
  }

  #root{
    display: flex;
    width: 100%;
    background-color: ${({ theme }) => theme.color.bg_primary};
  }

  button{
    border: none;
    cursor:pointer;
    background-color: inherit;

    &:disable {
      cursor: not-allowed
    }
  }

`;
