import React from "react";
import FormInput from "./FormInput";
import styled from "styled-components";

import { CheckBlue } from "assets/icon/index";

const FormIdInput = ({
  className,
  placeholder,
  maxLength,
  isError,
  register,
  isDuplicate,
}) => {
  return (
    <Root>
      <FormInput
        className={className}
        placeholder={placeholder}
        maxLength={maxLength}
        isError={isError}
        register={register}
      />
      <CustomCheck
        isduplicate={
          isDuplicate === null ? "null" : isDuplicate ? "true" : "false"
        }
      />
    </Root>
  );
};

export default FormIdInput;

const Root = styled.div`
  position: relative;
  width: 100%;
`;

const CustomCheck = styled(CheckBlue)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  fill: ${({ theme, isduplicate }) =>
    isduplicate === "null"
      ? theme.color.gray_AD
      : isduplicate === "false"
      ? theme.color.blue_00
      : theme.color.gray_AD};
`;
