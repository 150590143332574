import React from "react";
import styled from "styled-components";

export default function DimLayout({ children, handleDimClick }) {
  return <Root onClick={handleDimClick}>{children}</Root>;
}

const Root = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.MODAL};
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
`;
