import { ax } from "api/axios";

export const getAuthProfileDetail = async (payload) => {
  return await ax.get(`/admin/profile/${payload}`);
};

export const putAuthProfileDetail = async (payload) => {
  const { data, id } = payload;

  return await ax.put(`/admin/profile/${id}`, { ...data });
};
