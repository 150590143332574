import { useForm } from "react-hook-form";
import {
  addDashPhoneNumber,
  checkBirthday,
  checkOnlyNumber,
} from "utils/helper/formatter";
import {
  checkEmailValidation,
  checkPasswordValidateLegacy,
} from "utils/helper/validation";

const useHookForm = (defaultValues) => {
  const {
    control,
    register,
    setError,
    clearErrors,
    setValue,
    watch,
    setFocus,
    trigger,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({ mode: "onTouched", defaultValues });

  const hookFormFormat = (e, type, registerName) => {
    const value = e.target.value.trim();

    switch (type) {
      case "phone":
        setValue(
          registerName ?? "phone",
          addDashPhoneNumber(checkOnlyNumber(value)),
        );
        break;

      case "birthday":
        setValue(registerName ?? "birthday", checkBirthday(value));
        break;
    }
  };

  const hookFormValidation = {
    email: { format: (value) => checkEmailValidation(value) },
    password: {
      format: (value) => checkPasswordValidateLegacy(value),
    },
    passwordConfirm: {
      passwordConfirmMismatch: () =>
        watch("password") === watch("passwordConfirm"),
      format: (value) => checkPasswordValidateLegacy(value),
    },
    phone: (value) => value.trim().length === 13,
    birthday: (value) => value.trim().length === 10,
  };

  const hookFormProps = {
    control,
    register,
    setError,
    clearErrors,
    setValue,
    watch,
    setFocus,
    errors,
    trigger,
  };

  return {
    control,
    register,
    setError,
    clearErrors,
    setValue,
    watch,
    setFocus,
    errors,
    reset,
    trigger,
    handleSubmit,
    hookFormProps,
    hookFormFormat,
    hookFormValidation,
  };
};

export default useHookForm;
