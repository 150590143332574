import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { DetailPageSectionLayout, RadioButton } from "components/index";
import {
  deletePost,
  memberUserSuspendDay,
  reportType,
} from "assets/static/radioButton";
import { reportCommunityProcess } from "store/reducer/reportCommunityProcess";

export default function ReportCommunityProcess({
  data,
  typeState,
  setTypeState,
  dayState,
  setDayState,
  deletePostState,
  setDeletePostState,
  picState,
  reasonState,
  handleChangePicState,
  handleChangeReasonState,
}) {
  const [isModify, setIsModify] = useState(false);
  const { processed, process_type, delete_content } = data;
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleClickOpenModify = () => {
    setIsModify(true);
  };

  const handleCancelModify = () => {
    setIsModify(false);
  };

  const handleClickCloseModify = () => {
    const submitType = typeState + 1;
    if (typeState === 2) {
      //정지 (정지 날짜도 필수 값)
      if (
        typeState === "" ||
        picState === "" ||
        dayState === "" ||
        reasonState === ""
      ) {
        return alert("필수값을 확인해주세요.");
      }
    } else {
      // 반려, 경고, 영구정지
      if (typeState === "" || picState === "" || reasonState === "") {
        return alert("필수값을 확인해주세요.");
      }
    }

    dispatch(
      reportCommunityProcess({
        id,
        picState,
        submitType,
        dayState,
        deletePostState: delete_content === 1 && 1,
        reasonState,
      }),
    );
    setIsModify(false);
  };

  const handleChangeTypeState = (index) => {
    setTypeState(index);
  };

  const handleChangeDayState = (index) => {
    setDayState(index);
  };

  const handleChangeDeletePostState = (index) => {
    setDeletePostState(index);
  };

  const modifyButtons =
    // process_type는 1번부터 시작해서 radio상태에 맞추려면 -1 해야됨
    process_type - 1 !== 0 ? null : isModify ? (
      <>
        <CancelButton onClick={handleCancelModify}>취소</CancelButton>
        <SaveButton onClick={handleClickCloseModify}>저장</SaveButton>
      </>
    ) : (
      <ModifyButton onClick={handleClickOpenModify}>수정</ModifyButton>
    );

  return (
    <CustomDetailPageSectionLayout
      heading="처리 내용"
      description={data.process_date !== null && `*${data.process_date}`}
    >
      {processed === "처리완료" && modifyButtons}
      <FlexWrapper>
        <Title>처리 결과</Title>
        <CustomRadioButton
          rejectList={reportType}
          selectRadioState={typeState}
          radioName="reason"
          handleClickRadioButton={handleChangeTypeState}
          disabled={processed === "처리완료" && isModify === false}
        />
      </FlexWrapper>
      {typeState === 2 && (
        <FlexWrapper>
          <Title>정지 기간</Title>
          <CustomRadioButton
            rejectList={memberUserSuspendDay}
            selectRadioState={dayState}
            radioName="day"
            handleClickRadioButton={handleChangeDayState}
            disabled={processed === "처리완료" && isModify === false}
          />
        </FlexWrapper>
      )}
      <CustomFlexWrapper>
        <Title>담당자</Title>
        <CustomInput
          placeholder="이름을 입력해 주세요"
          value={picState}
          onChange={handleChangePicState}
          disabled={processed === "처리완료" && isModify === false}
        />
      </CustomFlexWrapper>
      <ReasonStateFlexWrapper>
        <Title>처리 내용</Title>
        <CustomTextArea
          placeholder="내용을 입력해 주세요"
          value={reasonState}
          onChange={handleChangeReasonState}
          disabled={processed === "처리완료" && isModify === false}
        />
      </ReasonStateFlexWrapper>
      <FlexWrapper>
        <Title>게시물을 삭제하시겠습니까?</Title>
        <CustomRadioButton
          rejectList={deletePost}
          selectRadioState={deletePostState}
          radioName="deletePost"
          handleClickRadioButton={handleChangeDeletePostState}
          disabled={
            typeState === 0 ||
            delete_content === 1 ||
            (processed === "처리완료" && isModify === false)
          }
        />
      </FlexWrapper>
    </CustomDetailPageSectionLayout>
  );
}

const CustomDetailPageSectionLayout = styled(DetailPageSectionLayout)`
  position: relative;
`;

const ModifyButton = styled.button`
  font-size: 14px;
  color: ${({ theme }) => theme.color.blue_00};
  position: absolute;
  top: 19px;
  right: 38px;
`;

const SaveButton = styled(ModifyButton)`
  top: 19px;
  right: 30px;
`;

const CancelButton = styled(ModifyButton)`
  top: 19px;
  right: 76px;
`;

const FlexWrapper = styled.div`
  display: flex;
  margin: 0 0 28px 0px;
`;

const CustomFlexWrapper = styled(FlexWrapper)`
  margin: 0 0 12px 0px;
`;

const ReasonStateFlexWrapper = styled(FlexWrapper)`
  margin: 0 0 20px 0px;
`;

const Title = styled.label`
  width: 190px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_6C};
  font-weight: 500;

  &::after {
    content: " *";
    color: ${({ theme }) => theme.color.red_FF};
  }
`;

const CustomRadioButton = styled(RadioButton)`
  & > label {
    margin: 0;
  }

  & > div {
    display: flex;
    flex-flow: row;
  }

  & > div > div {
    margin: 0 32px 0 0;
  }
`;

const CustomInput = styled.input`
  width: 214px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  border-radius: 5px;
  padding: 11px 12px;
  font-size: 14px;

  ::placeholder {
    color: ${({ theme }) => theme.color.gray_AD};
  }
`;

const CustomTextArea = styled.textarea`
  width: 672px;
  height: 164px;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  border-radius: 5px;
  padding: 12px;
  resize: none;
  font-size: 14px;

  ::placeholder {
    color: ${({ theme }) => theme.color.gray_AD};
  }
`;
