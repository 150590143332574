import { ax } from "api/axios";

export const getMemberAdmin = async (payload) => {
  return await ax.get("/admin/user", {
    params: payload,
  });
};

export const postMemberAdminCreate = async (payload) => {
  return await ax.post("/admin/user", payload);
};

export const putMemberAdminModify = async (payload) => {
  const { data, id } = payload;
  return await ax.put(`/admin/user/${id}`, data);
};

export const deleteMemberAdmin = async (payload) => {
  const { id } = payload;
  return await ax.delete(`/admin/user/${id}`);
};
