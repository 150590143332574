import React, {
  // useState,
  useEffect,
} from "react";
import { useImmer } from "use-immer";
// import { useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
import styled from "styled-components";

// import { memberUserModifyCertificate } from "store/reducer/memberUserModifyCertificate";
import { DetailPageSectionLayout } from "components/index";
import { memberUserAuthInformationStage } from "assets/static/radioButton";
import MemberUserRadioTable from "./MemberUserRadioTable";
// import { authInformatinFormatter } from "utils/helper/formatter";

export default function MemberUserAuthInformation({ list, data }) {
  // const initDisabled = {
  //   appearance: true,
  //   body: true,
  //   income: true,
  //   occupation: true,
  //   university: true,
  // };
  const [radioState, setRadioState] = useImmer(data);
  // const [disabled, setDisabled] = useImmer(initDisabled);
  // const [isModify, setIsModify] = useState(false);
  // const dispatch = useDispatch();
  // const { id } = useParams();

  // const handleClickModify = () => {
  //   setIsModify(true);
  //   if (data.appearance === 2) {
  //     setDisabled((draft) => {
  //       draft.appearance = false;
  //     });
  //   }

  //   if (data.body === 2) {
  //     setDisabled((draft) => {
  //       draft.body = false;
  //     });
  //   }
  //   setDisabled((draft) => {
  //     draft.income = false;
  //     draft.occupation = false;
  //     draft.university = false;
  //   });
  // };

  // const handleClickCancelModify = () => {
  //   setIsModify(false);
  //   setDisabled(initDisabled);
  // };

  // const handleSubmitModify = () => {
  //   if (
  //     Object.entries(radioState).filter(([, value]) => value === 2).length === 5
  //   ) {
  //     alert("최소 1개 이상의 등급을 선택해 주세요.");
  //     return;
  //   } else {
  //     const { appearance, body, income, occupation, university } = radioState;
  //     dispatch(
  //       memberUserModifyCertificate({
  //         id,
  //         badges: [
  //           authInformatinFormatter(appearance),
  //           authInformatinFormatter(body),
  //           authInformatinFormatter(occupation),
  //           authInformatinFormatter(university),
  //           authInformatinFormatter(income),
  //         ],
  //       }),
  //     );
  //     setIsModify(false);
  //     setDisabled(initDisabled);
  //   }
  // };

  useEffect(() => {
    if (
      Object.entries(radioState).filter(([, value]) => value === 2).length === 5
    ) {
      alert("최소 1개 이상의 등급을 선택해 주세요.");
    }
  }, [radioState]);

  return (
    <CustomDetailPageSectionLayout heading="인증 정보">
      {/* {isModify ? (
        회원 인증 정보 수정은 불가능하는 것이 맞다는 판단에 주석처리함
        <>
          <ModifyCancelButton onClick={handleClickCancelModify}>
            취소
          </ModifyCancelButton>
          <ModifyButton onClick={handleSubmitModify}>저장</ModifyButton>
        </>
      ) : (
        <ChangeSaveButton onClick={handleClickModify}>수정</ChangeSaveButton>
      )} */}
      <CustomRadioTable
        list={list}
        data={radioState}
        radioList={memberUserAuthInformationStage}
        disabled={true}
        setRadioState={setRadioState}
      />
    </CustomDetailPageSectionLayout>
  );
}

const CustomDetailPageSectionLayout = styled(DetailPageSectionLayout)`
  position: relative;
`;

// const ChangeSaveButton = styled.button`
//   position: absolute;
//   top: 19px;
//   right: 30px;
//   color: ${({ theme }) => theme.color.blue_00};
//   font-size: 14px;
// `;

// const ModifyCancelButton = styled.button`
//   position: absolute;
//   top: 19px;
//   right: 77px;
//   color: ${({ theme }) => theme.color.blue_00};
//   font-size: 14px;
// `;

// const ModifyButton = styled.button`
//   position: absolute;
//   top: 19px;
//   right: 30px;
//   color: ${({ theme }) => theme.color.blue_00};
//   font-size: 14px;
// `;

const CustomRadioTable = styled(MemberUserRadioTable)`
  grid-template-columns: 100%;
`;
