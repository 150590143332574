import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { DeleteConfirmModal, Heading, ModalOpenButton } from "components/index";
import AuthDetailPageRootLayout from "pages/auth/containers/AuthDetailPageRootLayout";
import MemberAdminDetailBasicInformation from "./containers/MemberAdminDetailBasicInformation";
import MemberAdminDetailProfileInformation from "./containers/MemberAdminDetailProfileInformation";
import MemberAdminDetailIntroduce from "./containers/MemberAdminDetailIntroduce";
import MemberAdminDetailAuthInformation from "./containers/MemberAdminDetailAuthInformation";
import { memberAdminDetail } from "store/reducer/memberAdminDetail";
import { PATH } from "utils/constants";
import { adminDetailList } from "assets/static/adminForm";
import MemberAdminDetailRomanticStyle from "./containers/MemberAdminDetailRomanticStyle";

export default function MemberAdminDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.memberAdminDetail);

  useEffect(() => {
    dispatch(memberAdminDetail(id));
  }, []);

  return (
    <AuthDetailPageRootLayout>
      <HeadingWrapper>
        <Heading>어드민 회원 상세</Heading>
        <DeleteButton
          render={(handleModalClose, handleDimClick) => {
            return (
              <DeleteConfirmModal
                handleModalClose={handleModalClose}
                handleDimClick={handleDimClick}
              />
            );
          }}
        >
          삭제
        </DeleteButton>
        <SuspendedButton to={`${PATH.memberAdminModify.fullPath}/${id}`}>
          수정
        </SuspendedButton>
      </HeadingWrapper>
      <MemberAdminDetailBasicInformation data={data} />
      <MemberAdminDetailProfileInformation data={data} />
      <MemberAdminDetailIntroduce data={data} />
      <MemberAdminDetailRomanticStyle adminList={adminDetailList} data={data} />
      <MemberAdminDetailAuthInformation data={data} />
    </AuthDetailPageRootLayout>
  );
}

const HeadingWrapper = styled.div`
  position: relative;
`;

const DeleteButton = styled(ModalOpenButton)`
  width: 70px;
  height: 40px;
  color: ${({ theme }) => theme.color.gray_6C};
  font-size: 14px;
  text-decoration: none;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray_AD};
  border-radius: 5px;
  position: absolute;
  top: 22px;
  right: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
`;

const SuspendedButton = styled(Link)`
  width: 70px;
  height: 40px;
  color: ${({ theme }) => theme.color.gray_6C};
  font-size: 14px;
  text-decoration: none;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray_AD};
  border-radius: 5px;
  position: absolute;
  top: 22px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
`;
