import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

const useFilter = (filterState, resetActionFn) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState("");

  const handleFilterSubmit = () => {
    const filterObj = { ...Object.fromEntries(searchParams) };

    Object.entries(filterState).forEach(([key, value]) => {
      if (value === "") {
        delete filterObj[key];
      } else {
        filterObj[key] = value;
      }
    });

    if (inputValue) {
      filterObj[filterState.inputFilter] = inputValue;
    }

    delete filterObj.inputFilter;

    setSearchParams(filterObj);
  };

  const handleResetSubmit = () => {
    setSearchParams();
    setInputValue("");
    dispatch(resetActionFn());
  };

  const handleChangeInputValue = (e) => {
    const value = e.target.value;

    setInputValue(value);
  };

  useEffect(() => {
    if (searchParams.get("nickname")) {
      setInputValue(searchParams.get("nickname"));
    } else if (searchParams.get("user_number")) {
      setInputValue(searchParams.get("user_number"));
    } else if (searchParams.get("to_nickname")) {
      setInputValue(searchParams.get("to_nickname"));
    } else if (searchParams.get("from_nickname")) {
      setInputValue(searchParams.get("from_nickname"));
    }
  }, []);

  return {
    inputValue,
    handleFilterSubmit,
    handleChangeInputValue,
    handleResetSubmit,
  };
};

export default useFilter;
