import React from "react";
import styled from "styled-components";

export default function RadioButton({
  title,
  rejectList,
  selectRadioState,
  handleClickRadioButton,
  radioName,
  disabled,
  ...restProps
}) {
  return (
    <Root {...restProps}>
      <Title>{title}</Title>
      <RadioWrapper>
        {Object.entries(rejectList).map(([key, value], index) => {
          return (
            <RadioItem key={key + index}>
              <input
                type="radio"
                id={radioName ? `radio${radioName}${index}` : `radio${index}`}
                name={radioName ? `${radioName}radio` : "radio"}
                value={value}
                checked={selectRadioState === index}
                onChange={() => handleClickRadioButton(index)}
                disabled={disabled}
              />
              <Label
                htmlFor={
                  radioName ? `radio${radioName}${index}` : `radio${index}`
                }
              >
                {value}
              </Label>
            </RadioItem>
          );
        })}
      </RadioWrapper>
    </Root>
  );
}

const Root = styled.div``;

const Title = styled.label`
  display: block;
  font-size: 13px;
  color: ${({ theme }) => theme.color.gray_93};
  margin: 24px 0 8px 0;
`;

const Label = styled.label`
  margin-left: 8px;
  font-size: 14px;
  color: ${({ value, theme }) =>
    value ? theme.color.black : theme.color.gray_6C};
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const RadioItem = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
