import React from "react";
import styled from "styled-components";

import {
  ComplexTable,
  DetailPageSectionLayout,
  MemberAdminImageUpload,
  PaginationImageDetailViewModal,
} from "components/index";
import { memberAdminBasicInformation } from "assets/static/table";

export default function MemberAdminBasicInformation({
  state,
  setState,
  errorState,
  handleChangeInput,
  handleBlurError,
  tableErrorState,
  duplicateState,
}) {
  const { value, imageUrl } = state.image;

  const handleUploadImage = (event) => {
    const files = event.target.files;

    const isMaximumImageExceed = files.length + imageUrl.length > 5;
    if (isMaximumImageExceed) {
      return alert("이미지는 최대 5장까지 업로드 가능합니다.");
    }

    const temps = Object.values(files).map((item) => URL.createObjectURL(item));

    setState((draft) => {
      draft.image.imageUrl = [...imageUrl, ...temps];
      draft.image.value = [...value, ...files];
    });
  };

  return (
    <CustomDetailPageSectionLayout
      heading="기본 정보"
      description="*필수 정보를 모두 입력해주세요."
    >
      <MemberAdminImageUpload
        imageArray={value}
        imageUrl={imageUrl}
        setState={setState}
        isError={errorState?.image?.state}
      >
        <MemberAdminImageUpload.UploadImage
          type="create"
          imageArray={value}
          imageUrl={imageUrl}
          setState={setState}
          handleUploadImage={handleUploadImage}
          render={(
            src,
            imageNumber,
            value,
            setState,
            handleModalClose,
            handleDimClick,
          ) => {
            return (
              <PaginationImageDetailViewModal
                title="어드민: 프로필 사진"
                src={src}
                imageNumber={imageNumber}
                imageArray={value}
                setState={setState}
                handleModalClose={handleModalClose}
                handleDimClick={handleDimClick}
              />
            );
          }}
        />
        <MemberAdminImageUpload.SmallImageViewList
          imageUrl={imageUrl}
          handleUploadImage={handleUploadImage}
        />
        <MemberAdminImageUpload.Buttons />
      </MemberAdminImageUpload>
      <div>
        <CustomComplexTable
          list={memberAdminBasicInformation}
          state={state}
          setState={setState}
          gender={state.gender.value}
          errorState={errorState}
          handleChangeInput={handleChangeInput}
          handleBlurError={handleBlurError}
          duplicateState={duplicateState}
        />
        {tableErrorState.basicState.state && (
          <ErrorMessage>{tableErrorState.basicState.message}</ErrorMessage>
        )}
      </div>
    </CustomDetailPageSectionLayout>
  );
}

const CustomDetailPageSectionLayout = styled(DetailPageSectionLayout)`
  & > div {
    display: flex;
    column-gap: 30px;
  }
`;

const CustomComplexTable = styled(ComplexTable)`
  width: 846px;
  height: 295px;

  & > :first-child {
    grid-column: auto / span 2;
  }
`;

const ErrorMessage = styled.span`
  display: block;
  margin-top: 13px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.red_FF};
`;
