import React from "react";
import styled from "styled-components";

import Cell from "components/cell/Cell";
import StatusLabel from "components/label/StatusLabel";
import CommunityReportCell from "components/cell/CommunityReportCell";
import DetailModalButton from "components/button/DetailModalButton";
import DetailPageModal from "components/modal/DetailPageModal";
import ImageDetailModal from "pages/report/ReportCommunity/ReportCommunityDetail/containers/ImageDetailModal";

export default function Table({ list, data, ...restProps }) {
  return (
    <Root {...restProps}>
      {Object.entries(list).map(([key, value], index) => {
        return key === "status" ? (
          <Cell key={index} title={value}>
            <StatusLabel status={data[key]} />
          </Cell>
        ) : key === "password" ? (
          <Cell key={index} title={value}>
            &#65121;&#65121;&#65121;&#65121;&#65121;&#65121;
          </Cell>
        ) : key === "user_status_id" ? (
          <Cell key={index} title={value}>
            {data[key] === 1 ? "활동" : data[key] === 3 ? "휴면" : "제명"}
          </Cell>
        ) : key === "created" ? (
          <Cell key={index} title={value}>
            {data[key]}
          </Cell>
        ) : key === "reported_content" ? (
          <Cell key={index} title={value}>
            <ContentTitle>{data[key]?.title}</ContentTitle>
            <Content>{data[key]?.content}</Content>
          </Cell>
        ) : key === "reported_reply" ? (
          <CommunityReportCell
            key={index}
            title={value}
            replyData={data[key]}
          />
        ) : key === "image" ? (
          <Cell key={index} title={value}>
            {data[key] === null || data[key] === undefined ? (
              "-"
            ) : (
              <DetailModalButton
                render={(handleModalClose, handleDimClick) => {
                  return (
                    <CustomDetailPageModal
                      heading="게시글 사진"
                      handleModalClose={handleModalClose}
                      handleDimClick={handleDimClick}
                    >
                      <ImageDetailModal src={data[key]} />
                    </CustomDetailPageModal>
                  );
                }}
              >
                이미지 보기
              </DetailModalButton>
            )}
          </Cell>
        ) : (
          <Cell key={index} title={value}>
            {data[key] ? data[key] : "-"}
          </Cell>
        );
      })}
    </Root>
  );
}

const Root = styled.div`
  width: 1080px;
  border-top: 1px solid ${({ theme }) => theme.color.gray_E6};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
  background-color: ${({ theme }) => theme.color.gray_E6};
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 1px;
  row-gap: 1px;
`;

const ContentTitle = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
`;

const Content = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.5;
`;

const CustomDetailPageModal = styled(DetailPageModal)`
  width: 630px;
  height: 682px;
`;
