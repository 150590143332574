import { ax } from "api/axios";

export const getDuplicateEmail = async (payload) => {
  const { email } = payload;
  return await ax.get(`/user/email?email=${email}`);
};

export const getDuplicateNickname = async (payload) => {
  const { nickname } = payload;
  return await ax.get(`/user/profile/nickname/exist?nickname=${nickname}`);
};
