import React from "react";
import styled from "styled-components";

export default function SubmitButton({ children, onClick, ...restProps }) {
  return (
    <Button onClick={onClick} {...restProps}>
      {children}
    </Button>
  );
}

const Button = styled.button`
  width: 70px;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
  background-color: ${({ theme }) => theme.color.blue_00};
  color: ${({ theme }) => theme.color.white};

  :disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;
