import useModal from "hooks/useModal";
import React from "react";
import styled from "styled-components";

export default function CancelButton({ render, children, ...restProps }) {
  const { isModalOpen, handleModalOpen, handleModalClose, handleDimClick } =
    useModal();

  return (
    <>
      <Button onClick={handleModalOpen} {...restProps}>
        {children}
      </Button>
      {isModalOpen && render(handleModalClose, handleDimClick)}
    </>
  );
}

const Button = styled.button`
  width: 70px;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.gray_6C};
  border: 1px solid ${({ theme }) => theme.color.gray_AD};
`;
