import React from "react";
import styled from "styled-components";

import { DetailPageSectionLayout, Table } from "components/index";
import { reportInformationTable } from "assets/static/table";

export default function ReportInformation({ data }) {
  return (
    <DetailPageSectionLayout heading="신고 내용">
      <CustomTable list={reportInformationTable} data={data} />
    </DetailPageSectionLayout>
  );
}

const CustomTable = styled(Table)`
  & > :last-child {
    grid-column: auto / span 2;
  }

  & > :last-child > div {
    min-height: 98px;
  }
`;
