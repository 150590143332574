import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import styled from "styled-components";

import { DimLayout, PortalModal } from "components/index";
import { Arrow, Close, ZoomIn, ZoomOut } from "assets/icon/index";

export default function ImageDetailViewModal({
  srcArray,
  selectImage,
  title,
  handleModalClose,
  isModalOpen,
  ...restProps
}) {
  const [imageScale, setImageScale] = useState(1);
  const [selectNumber, setSelectNumber] = useState(selectImage);

  const [isPanning, setIsPanning] = useState(false);
  const [position, setPosition] = useImmer({
    oldX: 0,
    oldY: 0,
    x: 0,
    y: 0,
  });

  const handleClickLeftButton = () => {
    if (selectNumber === 0) return;
    setSelectNumber(selectNumber - 1);
    setImageScale(1);
  };

  const handleClickRightButton = () => {
    if (selectNumber === srcArray.length - 1) return;
    setSelectNumber(selectNumber + 1);
    setImageScale(1);
  };

  const handleZoomIn = () => {
    setImageScale(imageScale + 0.2);
  };

  const handleZoomOut = () => {
    if (imageScale - 0.2 < 1) return;
    setImageScale(imageScale - 0.2);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();

    if (imageScale === 1) return;

    setIsPanning(true);
    setPosition((draft) => {
      draft.oldX = e.clientX;
      draft.oldY = e.clientY;
      draft.x = position.x;
      draft.y = position.y;
    });
  };

  useEffect(() => {
    const closeModalKeyboard = (e) => {
      if (e.keyCode === 27) {
        handleModalClose();
      }
    };

    window.addEventListener("keyup", closeModalKeyboard);
    return () => {
      window.removeEventListener("keyup", closeModalKeyboard);
    };
  }, []);

  useEffect(() => {
    setSelectNumber(selectImage);
  }, [selectImage]);

  useEffect(() => {
    const mouseup = () => {
      setIsPanning(false);
    };
    const mousemove = (e) => {
      if (isPanning) {
        setPosition((draft) => {
          draft.oldX = e.clientX;
          draft.oldY = e.clientY;
          draft.x = position.x + e.clientX - position.oldX;
          draft.y = position.y + e.clientY - position.oldY;
        });
      }
    };

    window.addEventListener("mouseup", mouseup);
    window.addEventListener("mousemove", mousemove);
    return () => {
      window.removeEventListener("mouseup", mouseup);
      window.removeEventListener("mousemove", mousemove);
    };
  });

  useEffect(() => {
    if (imageScale === 1) {
      setPosition((draft) => {
        draft.oldX = 0;
        draft.oldY = 0;
        draft.x = 0;
        draft.y = 0;
      });
    }
  }, [imageScale]);

  return (
    <PortalModal>
      <DimLayout>
        <Root open={isModalOpen} {...restProps}>
          <Wrapper>
            <Container>
              <Heading>{title}</Heading>
              <ContentWrapper>
                <ImageWrapper onMouseDown={handleMouseDown}>
                  <Image
                    src={srcArray[selectNumber]}
                    alt={`${title}확대사진`}
                    imageScale={imageScale}
                    position={position}
                  />
                </ImageWrapper>
                <ButtonWrapper>
                  <PageButtonWrapper>
                    <Button
                      onClick={handleClickLeftButton}
                      disabled={selectNumber === 0}
                    >
                      <CustomArrow direction="left" />
                    </Button>
                    {`${selectNumber + 1}/${srcArray.length}`}
                    <Button
                      onClick={handleClickRightButton}
                      disabled={selectNumber === srcArray.length - 1}
                    >
                      <CustomArrow direction="right" />
                    </Button>
                  </PageButtonWrapper>
                  <ZoomButtonWrapper>
                    <ZoomButton
                      onClick={handleZoomOut}
                      disabled={imageScale === 1}
                      rangeValue={imageScale}
                    >
                      <CustomZoomOut />
                    </ZoomButton>
                    <ZoomButton onClick={handleZoomIn}>
                      <CustomZoomIn />
                    </ZoomButton>
                  </ZoomButtonWrapper>
                </ButtonWrapper>
              </ContentWrapper>
              <CloseButton onClick={handleModalClose}>
                <Close width="16px" height="16px" />
              </CloseButton>
            </Container>
          </Wrapper>
        </Root>
      </DimLayout>
    </PortalModal>
  );
}

const Root = styled.dialog`
  min-width: 630px;
  min-height: 767px;
  max-width: 920px;
  width: 40vw;
  background-color: ${({ theme }) => theme.color.white};
  border: 0;
  position: relative;
`;

const Wrapper = styled.div`
  padding-top: 121.74603%;
  position: relative;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Heading = styled.h3`
  height: 56px;
  line-height: 56px;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.black};
  padding-left: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
`;

const ContentWrapper = styled.div`
  height: calc(100% - 56px);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 24px 48px;
`;

const ImageWrapper = styled.figure`
  position: relative;
  padding-top: 112.3595%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.gray_E6};
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transform: ${({ position, imageScale }) =>
    `translate(${position.x}px, ${position.y}px) scale(${imageScale});`};
  cursor: ${({ imageScale }) => (imageScale === 1 ? "cursor" : "grab")};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  color: ${({ theme }) => theme.color.gray_6C};
  position: relative;
`;

const PageButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const Button = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  opacity: ${({ disabled }) => disabled && 0.3};

  &:disabled {
    cursor: not-allowed;
  }
`;

const CustomArrow = styled(Arrow)`
  transform: ${({ direction }) =>
    direction === "left" ? "rotate(-90deg)" : "rotate(90deg)"};
`;

const ZoomButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  column-gap: 8px;
`;

const ZoomButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.color.gray_DE};
  display: flex;
  justify-content: center;
  align-items: center;

  :disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const CustomZoomIn = styled(ZoomIn)`
  transform: rotate(-180deg);
`;
const CustomZoomOut = styled(ZoomOut)`
  transform: rotate(-180deg);
`;

const CloseButton = styled.button`
  width: 16px;
  height: 16px;
  background-color: inherit;
  position: absolute;
  top: 20px;
  right: 24px;
`;
