import { getAnswerList } from "api/index";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  answerList,
  answerListSuccess,
  answerListFailure,
} from "store/reducer/answerList";

function* answerListSaga() {
  try {
    const res = yield call(getAnswerList);

    yield put({
      type: answerListSuccess,
      data: res.data,
    });
  } catch (err) {
    yield put({
      type: answerListFailure,
      error: err.response.data,
    });
  }
}

export function* watchAnswerList() {
  yield takeLatest(answerList, answerListSaga);
}
