import { createSlice } from "@reduxjs/toolkit";

export const memberAdminCreateSlice = createSlice({
  name: "memberAdminCreate",
  initialState: {
    create: {
      isLoading: false,
      isDone: false,
      error: null,
    },
    duplicateEmail: {
      isLoading: false,
      isDuplicate: false,
      isDone: false,
      error: null,
    },
    duplicateNickname: {
      isLoading: false,
      isDuplicate: false,
      isDone: false,
      error: null,
    },
  },
  reducers: {
    memberAdminCreate: (state) => {
      state.create.isLoading = true;
      state.create.isDone = false;
      state.create.error = null;
    },
    memberAdminCreateSuccess: (state) => {
      state.create.isLoading = false;
      state.create.isDone = true;
    },
    memberAdminCreateFailure: (state, action) => {
      state.create.isLoading = false;
      state.create.isDone = false;
      state.create.error = action.error;
    },
    memberAdminDuplicateEmail: (state) => {
      state.duplicateEmail.isLoading = true;
      state.duplicateEmail.isDone = false;
      state.duplicateEmail.error = null;
    },
    memberAdminDuplicateEmailDuplicate: (state) => {
      state.duplicateEmail.isLoading = false;
      state.duplicateEmail.isDone = true;
      state.duplicateEmail.isDuplicate = true;
      state.duplicateEmail.error = null;
    },
    memberAdminDuplicateEmailSuccess: (state) => {
      state.duplicateEmail.isLoading = false;
      state.duplicateEmail.isDone = true;
      state.duplicateEmail.isDuplicate = false;
      state.duplicateEmail.error = null;
    },
    memberAdminDuplicateEmailFailure: (state, action) => {
      state.duplicateEmail.isLoading = false;
      state.duplicateEmail.isDone = false;
      state.duplicateEmail.isDuplicate = false;
      state.duplicateEmail.error = action.error;
    },
    memberAdminDuplicateEmailReset: (state) => {
      state.duplicateEmail.isLoading = false;
      state.duplicateEmail.isDone = false;
      state.duplicateEmail.isDuplicate = null;
      state.duplicateEmail.error = null;
    },
    memberAdminDuplicateNickname: (state) => {
      state.duplicateNickname.isLoading = true;
      state.duplicateNickname.isDone = false;
      state.duplicateNickname.error = null;
    },
    memberAdminDuplicateNicknameDuplicate: (state) => {
      state.duplicateNickname.isLoading = false;
      state.duplicateNickname.isDone = true;
      state.duplicateNickname.isDuplicate = true;
      state.duplicateNickname.error = null;
    },
    memberAdminDuplicateNicknameSuccess: (state) => {
      state.duplicateNickname.isLoading = false;
      state.duplicateNickname.isDone = true;
      state.duplicateNickname.isDuplicate = false;
      state.duplicateNickname.error = null;
    },
    memberAdminDuplicateNicknameFailure: (state, action) => {
      state.duplicateNickname.isLoading = false;
      state.duplicateNickname.isDone = false;
      state.duplicateNickname.error = action.error;
    },
    memberAdminDuplicateNicknameReset: (state) => {
      state.duplicateNickname.isLoading = false;
      state.duplicateNickname.isDone = false;
      state.duplicateNickname.isDuplicate = null;
      state.duplicateNickname.error = null;
    },
  },
});

export const {
  memberAdminCreate,
  memberAdminCreateSuccess,
  memberAdminCreateFailure,
  memberAdminDuplicateEmail,
  memberAdminDuplicateEmailDuplicate,
  memberAdminDuplicateEmailSuccess,
  memberAdminDuplicateEmailFailure,
  memberAdminDuplicateEmailReset,
  memberAdminDuplicateNickname,
  memberAdminDuplicateNicknameDuplicate,
  memberAdminDuplicateNicknameSuccess,
  memberAdminDuplicateNicknameFailure,
  memberAdminDuplicateNicknameReset,
} = memberAdminCreateSlice.actions;

export default memberAdminCreateSlice.reducer;
