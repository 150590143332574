import React from "react";
import styled from "styled-components";

import {
  DetailModalButton,
  DetailPageModal,
  StoneProvideModal,
  DetailPageSectionLayout,
} from "components/index";
import Table from "components/table/Table";
import PaymentDetail from "./PaymentDetail";

export default function MemberUserPaymentInformation({ list, data }) {
  return (
    <CustomDetailPageSectionLayout heading="결제 정보">
      <Table list={list} data={data} />
      <StoneModalButton
        render={(handleModalClose, handleDimClick) => {
          return (
            <StoneProvideModal
              heading="스톤 히스토리"
              handleModalClose={handleModalClose}
              handleDimClick={handleDimClick}
            >
              <PaymentDetail list={list} data={data} />
            </StoneProvideModal>
          );
        }}
      >
        스톤지급
      </StoneModalButton>
      <CustomDetailModalButton
        render={(handleModalClose, handleDimClick) => {
          return (
            <DetailPageModal
              heading="스톤 히스토리"
              handleModalClose={handleModalClose}
              handleDimClick={handleDimClick}
            >
              <PaymentDetail list={list} data={data} />
            </DetailPageModal>
          );
        }}
      >
        상세내역
      </CustomDetailModalButton>
    </CustomDetailPageSectionLayout>
  );
}

const CustomDetailPageSectionLayout = styled(DetailPageSectionLayout)`
  position: relative;
`;

const StoneModalButton = styled(DetailModalButton)`
  width: 60px;
  height: 34px;
  position: absolute;
  top: 10px;
  right: 100px;
  font-size: 14px;
`;

const CustomDetailModalButton = styled(DetailModalButton)`
  width: 60px;
  height: 34px;
  position: absolute;
  top: 10px;
  right: 30px;
  font-size: 14px;
`;
