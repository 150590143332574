import React from "react";
import styled from "styled-components";

import { DimLayout } from "components/index";
import PortalModal from "./PortalModal";

export default function PersistModal({ modalRef, handleModalClose }) {
  return (
    <PortalModal>
      <DimLayout>
        <Root ref={modalRef} tabIndex="-1">
          <input />
          <input />
          <input />
          <input />
          <button onClick={handleModalClose}>close</button>;
        </Root>
      </DimLayout>
    </PortalModal>
  );
}

const Root = styled.article`
  width: 500px;
  height: 500px;
  background-color: white;
`;
