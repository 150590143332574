import { Refresh } from "assets/icon/index";
import React from "react";
import styled from "styled-components";

export default function RefreshButton({ ...restProps }) {
  return (
    <Button {...restProps}>
      <Refresh />
    </Button>
  );
}

const Button = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.border_grayDe};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
