import React from "react";

import { DetailPageSectionLayout } from "components/index";
import AuthFormRadioButton from "components/radioButton/AuthFormRadioButton";
import FormTable from "components/table/FormTable";
import ErrorMessage from "components/errorMessage/ErrorMessage";

export default function AuthInfo({ adminList, register, clearErrors, errors }) {
  const contentRender = (content) => {
    switch (content.key) {
      case "appearance":
      case "body":
      case "occupation":
      case "university":
      case "income":
        return (
          <AuthFormRadioButton
            radioName={content.key}
            radioList={{ 0: "없음", 1: "VS", 2: "VVS" }}
            register={register(content.key, {
              onChange: () => clearErrors("authInfo"),
            })}
          />
        );
    }
  };

  return (
    <DetailPageSectionLayout
      heading="인증 정보"
      description="*한 개 이상의 인증을 선택해 주세요."
    >
      <div>
        <FormTable>
          {adminList.authInfo.map((row, rowI) => (
            <FormTable.Row key={rowI} contentCount={row.length}>
              {row.map((content, contentI) => (
                <FormTable.Content
                  key={content.heading + contentI}
                  require={content.require}
                  heading={content.heading}
                >
                  {contentRender(content)}
                </FormTable.Content>
              ))}
            </FormTable.Row>
          ))}
        </FormTable>
        {errors.authInfo && (
          <ErrorMessage msg="인증을 1개 이상 선택해야 합니다." />
        )}
      </div>
    </DetailPageSectionLayout>
  );
}
