import { ErrorNoti } from "assets/icon/index";
import React from "react";
import { color } from "style/theme";
import styled from "styled-components";
import { VALIDATION_MSG } from "utils/constants";

export default function Validation({ type, top, ...props }) {
  return (
    <Root top={top}>
      <ErrorNoti width="15px" height="15px" />
      <ErrorMsg {...props}>{VALIDATION_MSG[type]}</ErrorMsg>
    </Root>
  );
}

const Root = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: ${({ top }) => top};
`;

const ErrorMsg = styled.span`
  margin-left: 8px;
  color: ${color.text_error};
  font-size: 13px;
`;
