export const WEEKS = ["일", "월", "화", "수", "목", "금", "토"]; // 일주일 배열

export const KEEP_LOGIN_PERIOD = 3;
export const KEEP_LOGIN_3_SECOND = 1 / (480 * 60); // for test

export const EXPIRE_DATE = new Date().setDate(
  new Date().getDate() + KEEP_LOGIN_PERIOD,
);
export const EXPIRE_DATE_3_SECOND = new Date().setSeconds(
  new Date().getSeconds() + 3,
); // for test

export const PATH = {
  root: "/",
  expired: "/expired",
  notFound: "/notFound",
  member: { path: "/member", fullPath: "/member" },
  memberUser: { path: "user", fullPath: "/member/user" },
  MemberUserDetail: {
    path: "userDetail/:id",
    fullPath: "/member/user/userDetail",
  },
  memberAdmin: { path: "admin", fullPath: "/member/admin" },
  memberAdminCreate: {
    path: "adminCreate",
    fullPath: "/member/admin/adminCreate",
  },
  memberAdminDetail: {
    path: "adminDetail/:id",
    fullPath: "/member/admin/adminDetail",
  },
  memberAdminModify: {
    path: "adminModify/:id",
    fullPath: "/member/admin/adminModify",
  },
  memberAdminModify2: {
    path: "adminModify2/:id",
    fullPath: "/member/admin/adminModify2",
  },
  memberReport: { path: "report", fullPath: "/member/report" },
  memberSuspended: { path: "suspended", fullPath: "/member/suspended" },
  memberPush: { path: "push", fullPath: "/member/push" },
  memberNoti: { path: "noti", fullPath: "/member/noti" },
  auth: { path: "/auth", fullPath: "/auth" },
  authProfile: { path: "profile", fullPath: "/auth/profile" },
  authProfileDetail: {
    path: "profileDetail/:id",
    fullPath: "/auth/profileDetail",
  },
  authAppearance: {
    path: "appearance",
    fullPath: "/auth/inspection/appearance",
  },
  authAppearanceDetail: {
    path: "appearanceDetail/:id",
    fullPath: "/auth/inspection/appearance/appearanceDetail",
  },
  authInspection: { path: "inspection", fullPath: "/auth/inspection" },
  authBody: { path: "body", fullPath: "/auth/inspection/body" },
  authBodyDetail: {
    path: "bodyDetail/:id",
    fullPath: "/auth/inspection/body/bodyDetail",
  },
  authUniversity: {
    path: "university",
    fullPath: "/auth/inspection/university",
  },
  authUniversityDetail: {
    path: "universityDetail/:id",
    fullPath: "/auth/inspection/university/universityDetail",
  },
  authOccupation: {
    path: "occupation",
    fullPath: "/auth/inspection/occupation",
  },
  authOccupationDetail: {
    path: "occupationDetail/:id",
    fullPath: "/auth/inspection/occupationDetail",
  },
  authIncome: { path: "income", fullPath: "/auth/inspection/income" },
  authIncomeDetail: {
    path: "incomeDetail/:id",
    fullPath: "/auth/inspection/incomeDetail",
  },
  report: { path: "/report", fullPath: "/report" },
  reportProfile: {
    path: "profile",
    fullPath: "/report/profile",
  },
  reportProfileDetail: {
    path: "profileDetail/:id",
    fullPath: "/report/profile/profileDetail",
  },
  reportCommunity: {
    path: "community",
    fullPath: "/report/community",
  },
  reportCommunityDetail: {
    path: "communityDetail/:id",
    fullPath: "/report/community/communityDetail",
  },
  reportIndianGame: {
    path: "indianGame",
    fullPath: "/report/indianGame",
  },
  reportIndianGameDetail: {
    path: "indianGameDetail/:id",
    fullPath: "/report/indianGame/indianGameDetail",
  },
  payment: { path: "/payment", fullPath: "/payment" },
  common: { path: "/common", fullPath: "/common" },
};

export const LAYOUT_TYPE = Object.freeze({
  PRIVATE: "PRIVATE",
  PUBLIC: "PUBLIC",
});

export const VALIDATION_MSG = {
  idNotFound: "이메일이 올바르지 않습니다.",
  passwordError: "비밀번호가 올바르지 않습니다.",
};
