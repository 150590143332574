import { css } from "styled-components";

import { color } from "./theme";

export const mixinInput = css`
  width: 100%;
  input {
    width: 100%;
    height: 30px;
    border: 1px solid;
    font-size: 20px;

    // 구글 자동완성 css 해제
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
    outline: none;
  }
`;

export const mixinBlueBtn_70 = css`
  width: 70px;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.button_bg_blue};
`;

export const mixinNavElement = css`
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 24px;
  column-gap: 26px;
`;

export const mixinNavFoldElement = css`
  width: 25px;
  height: 25px;
  fill: ${({ theme }) => theme.color.text_white};
`;

export const mixinBtn_29 = css`
  height: 29px;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 500;
`;

export const mixinBtn_32 = css`
  height: 32px;
  padding: 0 12px;
  font-size: 14px;
`;

export const mixinBtn_34 = css`
  height: 34px;
  padding: 0 14px;
  font-size: 14px;
`;

export const mixinBtn_36 = css`
  height: 36px;
  padding: 0 12px;
  font-size: 14px;
`;

export const mixinBtn_38 = css`
  height: 38px;
  padding: 0 46px;
  font-size: 14px;
  font-weight: 700;
`;

export const mixinBtn_44 = css`
  height: 44px;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 400;
`;

export const mixinBtn_45 = css`
  height: 45px;
  padding: 0 158px;
  font-size: 16px;
  font-weight: 500;
`;

const mixinBtn = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  letter-spacing: -0.02em;
`;

export const mixinBtn_base = css`
  ${mixinBtn};
  color: ${color.text_white};
  background-color: ${color.brand_primary};
`;

export const mixinBtn_ghost = css`
  ${mixinBtn};
  color: ${color.text_gray_dark};
  border: 1px solid ${color.border};
`;

export const mixinBtn_disabled = css`
  ${mixinBtn};
  color: ${color.text_white};
  background-color: ${color.disabled};
`;

export const mixinBtn_multi = css`
  ${mixinBtn};
  border: 1px solid ${color.brand_primary};
  color: ${color.brand_primary};
  background-color: ${color.bg_select};
`;
