import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import {
  DetailModalButton,
  DetailPageModal,
  Dropdown,
  Table,
} from "components/index";
import MemberImageView from "pages/member/containers/MemberImageView";
import UserStatusDetail from "./UserStatusDetail";
// import { Copy } from "assets/icon/index";
import { internalGrade } from "assets/static/dropdown";
import { memberUserDetailEditInternalGrade } from "store/reducer/memberUserDetail";

export default function MemberUserBasicInformation({
  profileSrcArray,
  list,
  data,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [internalGradeState, setInternalGradeState] = useImmer({
    internalGrade: "",
  });

  // const handleCopyContent = () => {
  //   const textarea = document.createElement("textarea");
  //   textarea.value = data.ci;
  //   textarea.style.top = 0;
  //   textarea.style.left = 0;
  //   textarea.style.position = "fixed";
  //   document.body.appendChild(textarea);
  //   // focus() -> 사파리 브라우저 서포팅
  //   textarea.focus();
  //   textarea.select();
  //   document.execCommand("copy");
  //   document.body.removeChild(textarea);
  //   alert("클립보드에 복사되었습니다.");
  // };

  const handleClickEditMode = () => {
    setIsEditMode(true);
  };

  const handleEditInternalGrade = () => {
    dispatch(
      memberUserDetailEditInternalGrade({
        id: id,
        data: {
          internal_grade: internalGradeState.internalGrade,
        },
      }),
    );
    setIsEditMode(false);
  };

  useEffect(() => {
    setInternalGradeState((draft) => {
      draft.internalGrade = data.internalGrade;
    });
  }, [data]);

  return (
    <Root>
      <Heading>기본정보</Heading>
      <FlexWrapper>
        <ImageViewWrapper>
          <MemberImageView srcArray={profileSrcArray} />
        </ImageViewWrapper>
        <TableWrapper>
          <CustomTable list={list} data={data} />
          {/* <CopyButton onClick={handleCopyContent}>
            <Copy />
            복사
          </CopyButton> */}
          <CustomDetailModalButton
            render={(handleModalClose, handleDimClick) => {
              return (
                <DetailPageModal
                  heading="활동 상태 히스토리"
                  handleModalClose={handleModalClose}
                  handleDimClick={handleDimClick}
                >
                  <UserStatusDetail />
                </DetailPageModal>
              );
            }}
          >
            상세내역
          </CustomDetailModalButton>
          {isEditMode ? (
            <>
              <CustomDropdown
                selectLabel={internalGrade[internalGradeState.internalGrade]}
              >
                {Object.entries(internalGrade).map(([key, value], index) => {
                  return (
                    <Dropdown.Option
                      key={index}
                      setDropdownState={setInternalGradeState}
                      name="internalGrade"
                      label={key}
                      value={value}
                    >
                      {value}
                    </Dropdown.Option>
                  );
                })}
              </CustomDropdown>
              <EditButton onClick={handleEditInternalGrade}>저장</EditButton>
            </>
          ) : (
            <EditButton onClick={handleClickEditMode}>수정</EditButton>
          )}
        </TableWrapper>
      </FlexWrapper>
    </Root>
  );
}

const Root = styled.div`
  width: 1140px;
  height: 433px;
  margin: 0 auto 32px auto;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.12);
`;

const Heading = styled.h3`
  padding: 17px 0 17px 30px;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.Black};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_E6};
`;

const FlexWrapper = styled.div`
  width: 1080px;
  margin: 30px;
  display: flex;
  justify-content: space-between;
`;

const ImageViewWrapper = styled.div`
  width: 202px;
`;

const TableWrapper = styled.div`
  position: relative;
`;

const CustomTable = styled(Table)`
  width: 846px;
  /* 
  & > :last-child {
    grid-column: auto / span 2;
  } */
`;

// const CopyButton = styled.button`
//   width: 60px;
//   height: 34px;
//   position: absolute;
//   top: 254px;
//   right: 0px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   column-gap: 6px;
//   color: ${({ theme }) => theme.color.blue_00};

//   :hover {
//     background-color: ${({ theme }) => theme.color.gray_F5};
//   }
// `;

const CustomDetailModalButton = styled(DetailModalButton)`
  width: 60px;
  height: 34px;
  position: absolute;
  top: 107px;
  right: 0px;
  :hover {
    background-color: ${({ theme }) => theme.color.gray_F5};
  }
`;

const CustomDropdown = styled(Dropdown)`
  width: 156px;
  position: absolute;
  top: 152px;
  right: 58px;
`;

const EditButton = styled.button`
  width: 42px;
  height: 34px;
  color: ${({ theme }) => theme.color.blue_00};
  position: absolute;
  top: 155px;
  right: 0;

  :hover {
    background-color: ${({ theme }) => theme.color.gray_F5};
  }
`;
