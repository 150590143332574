import React from "react";
import styled from "styled-components";

export default function InputCells({
  name,
  title,
  essential,
  inputPlaceholder,
  inputCount,
  showErrorCount, //validation error시 몇개의 input에 표시할 갯수
  width,
  state,
  errorState,
  handleChangeInput,
  handleBlurError,
  ...restProps
}) {
  const inputArray = new Array(inputCount).fill(null);
  const validationArray = new Array(inputCount).fill(false);

  return (
    <Root {...restProps}>
      <TitleWrapper>
        <Title>
          {title}
          {essential && <Essential>*</Essential>}
        </Title>
      </TitleWrapper>
      <ContentWrapper inputCount={inputCount}>
        {inputArray.map((_, index) => {
          const placeholder =
            typeof inputPlaceholder === "object"
              ? inputPlaceholder[index]
              : inputPlaceholder;
          return (
            <Inputs
              name={name}
              key={index}
              placeholder={placeholder ? placeholder : `#${index + 1}`}
              showErrorBorder={index < showErrorCount}
              isValidation={validationArray[index]}
              width={width}
              value={state[index] ? state[index] : ""}
              errorState={errorState[index]}
              onChange={(event) => handleChangeInput(event, index)}
              onBlur={(event) => handleBlurError(event, index)}
            />
          );
        })}
      </ContentWrapper>
    </Root>
  );
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 190px 1fr;
  column-gap: 1px;
  row-gap: 1px;
`;

const TitleWrapper = styled.div`
  height: 48px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray_F5};
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray_6C};
`;

const Title = styled.span`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const Essential = styled.span`
  margin-left: 2px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.red_FF};
`;

const ContentWrapper = styled.div`
  padding: 7px 20px 7px 20px;
  display: flex;
  background-color: ${({ theme }) => theme.color.white};
`;

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 0 8px 12px;
  border: 1px solid
    ${({ theme, errorState }) =>
      errorState ? theme.color.red_FF : theme.color.gray_DE};
  border-radius: 5px;

  ::placeholder {
    color: ${({ theme }) => theme.color.gray_AD};
  }

  & + & {
    margin-left: 8px;
  }
`;

const Inputs = styled(Input)`
  width: ${({ width }) => (width ? width : "100%")};
`;
